import { Box } from "@mui/system";
import React, { FC, useState } from "react";
import { ClickAwayListener, Typography } from "@mui/material";

import { CompanyStatus } from "integration/api/CompanyApi/models";

import theme from "application/theme";
import { ArrowDropUp } from "application/assets/ArrowDropUp";
import { ArrowDropDown } from "application/assets/ArrowDropDown";
import { RatioStatusIcon } from "application/assets/RationStatusIcon";

import { colorHandler } from "./utils";
import { optionsForStatusSelect } from "./types";
import {
  TextWrapper,
  IconWrapper,
  DropdownItem,
  DropdownStyle,
  SelectContainer,
  CurrencySelectButton,
} from "./styles";

interface Props {
  selected?: CompanyStatus;
  onChange: (value: CompanyStatus) => void;
}

const CompanyStatusSelect: FC<Props> = ({ selected, onChange }) => {
  const [open, setOpen] = useState(false);

  const toggleSelect = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (value: CompanyStatus) => {
    onChange(value);
    handleClose();
  };

  const label = optionsForStatusSelect.filter((e) => e.value === selected)[0]
    ?.label;

  const currentColor = colorHandler(selected);

  return (
    <Box>
      <Typography
        variant="subtitle1"
        color={theme.palette.common.grey500}
        mb={0.5}
      >
        Status:
      </Typography>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <SelectContainer className="company-status-container">
          <CurrencySelectButton onClick={toggleSelect}>
            <TextWrapper
              bgcolor={currentColor.bgColor}
              borderColor={currentColor.borderColor}
            >
              <Typography variant="subtitle2" color={currentColor.borderColor}>
                {label}
              </Typography>
            </TextWrapper>

            <IconWrapper
              bgcolor={currentColor.bgColor}
              borderColor={currentColor.borderColor}
            >
              {open ? (
                <ArrowDropUp color={theme.palette.common.grey500} />
              ) : (
                <ArrowDropDown color={theme.palette.common.grey500} />
              )}
            </IconWrapper>
          </CurrencySelectButton>
          <DropdownStyle visible={open ? 1 : 0}>
            <Box px={1.5} bgcolor={theme.palette.common.white}>
              {optionsForStatusSelect.map((e) => (
                <DropdownItem
                  onClick={() => handleChange(e.value)}
                  active={e.value === selected ? 1 : 0}
                  key={e.value}
                >
                  <Box mr={1.5}>
                    <RatioStatusIcon
                      color={colorHandler(e.value).borderColor}
                    />
                  </Box>
                  {e.label}
                </DropdownItem>
              ))}
            </Box>
          </DropdownStyle>
        </SelectContainer>
      </ClickAwayListener>
    </Box>
  );
};

export default CompanyStatusSelect;
