import React, { FC } from "react";

import { useAppSelector } from "application/hooks/useAppSelector";
import { PaginationMUI } from "application/components";

import TableRow from "../TableRow";

import { TableProps } from "./model";
import { TableStyled, Wrapper } from "./styles";

const Table: FC<TableProps> = ({
  page,
  onPageChange,
  handleDelete,
  handleEdit,
}) => {
  const { referrals } = useAppSelector((state) => state.referrals);

  return (
    <>
      <Wrapper>
        <TableStyled>
          <thead>
            <tr>
              <th>Deal owner</th>
              <th>Deal source</th>
              <th>Url identifier</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {referrals.content.map((item) => (
              <TableRow
                key={item.id}
                owner={item}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
              />
            ))}
          </tbody>
        </TableStyled>
      </Wrapper>

      <PaginationMUI
        page={page}
        variant="text"
        color="primary"
        count={referrals.totalPages}
        onChange={onPageChange}
      />
    </>
  );
};

export default Table;
