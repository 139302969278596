import { styled } from "@mui/system";
import { colors } from "application/theme/palette";

export const Container = styled("div")`
  display: grid;
  grid-template-rows: max-content;
  gap: 20px;
  grid-auto-columns: 520px;
`;

export const UrlField = styled("div")`
  position: relative;
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0px 16px;
  border-radius: 8px;
  border: 1px solid ${colors.grey300};
  background: ${colors.grey50};

  label {
    position: absolute;
    bottom: 36px;
    color: ${colors.grey500};
    font-family: Unica77;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.06px;
    z-index: 1;

    div {
      position: absolute;
      width: 72px;
      height: 2px;
      bottom: 7px;
      right: -2.5px;
      background-color: ${colors.white};
      z-index: -1;
    }
  }

  > h2 {
    overflow: hidden;
    color: ${colors.grey700};
    text-overflow: ellipsis;
    font-family: Unica77;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
    cursor: text;
  }
`;

export const CopyButton = styled("button")`
  all: unset;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 2px;
  margin-right: 8px;

  > p {
    position: absolute;
    left: 28px;
    padding: 4px 8px;
    color: ${colors.green700};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.06px;
    border-radius: 4px;
    background: ${colors.green200};
    z-index: 10;
  }

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${colors.grey500};
    }
  }

  &:hover {
    .tooltip {
      display: flex;
    }
  }
`;

export const CopyTooltip = styled("div")`
  position: absolute;
  left: 36px;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 4px;
  background: ${colors.grey700};
  //font
  color: ${colors.white};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.06px;

  svg {
    right: 32.5px;
    position: absolute;
    width: 29px;
    height: 29px;

    path {
      fill: ${colors.grey700};
    }
  }
`;
