import React from "react";
import { TextFieldStyled } from "../TextFieldStyled/style";
import { InputWrapper } from "./styles";
import { Typography } from "@mui/material";
import theme from "application/theme";
interface InputWithTextProps {
  placeholder: string;
  helperText?: string;
  onChange: (value: string) => void;
  width?: number;
  value?: string;
  error?: boolean;
}

export const InputWithText = ({
  placeholder,
  helperText,
  onChange,
  width,
  value,
  error,
}: InputWithTextProps) => {
  return (
    <InputWrapper error={error}>
      {helperText && (
        <Typography
          variant="subtitle1"
          color={
            error ? theme.palette.common.red700 : theme.palette.common.grey500
          }
          mb={0.5}
        >
          {helperText}
        </Typography>
      )}
      <TextFieldStyled
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        filled={value ? 1 : 0}
        autoComplete="off"
        width={width}
        value={value || ""}
      />
    </InputWrapper>
  );
};
