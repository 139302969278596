import styled from "@emotion/styled";
import { Add } from "@mui/icons-material";

import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const Title = styled.h3`
  color: ${colors.grey900};
  font-family: "Unica77";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;

export const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const Table = styled.table`
  width: 100%;
  border-radius: 8px;
  border-collapse: separate;
  border: 1px solid ${colors.grey200};

  th {
    top: 0;
    height: 42px;
    padding: 0 12px;
    position: sticky;
    vertical-align: middle;
    background-color: white;
    background-color: ${colors.grey200};
    /* font */
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 140%;
    font-style: normal;
    letter-spacing: 0.08px;
    font-family: "Unica77";
    color: ${colors.grey900};
  }

  td {
    height: 42px;
    padding: 0 12px;
    vertical-align: middle;
    background-color: ${colors.white};
    border-top: 1px solid ${colors.grey200};
    border-right: 1px solid ${colors.grey200};
    /* font */
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    font-style: normal;
    font-family: "Unica77";
    letter-spacing: 0.07px;
    color: ${colors.grey900};
    &:last-of-type {
      border-right: none;
    }
  }
`;

export const LoaderContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PlusButton = styled(Add)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 4px;
  color: ${colors.grey700};
  background-color: ${colors.white};
  border: 1px solid ${colors.grey200};
`;
