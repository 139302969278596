import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

interface ExpandButtonProps {
  expanded: boolean;
}

export const Container = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: Unica77;
`;

export const ExpandButton = styled.button<ExpandButtonProps>`
  all: unset;
  width: fit-content;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;

  color: ${colors.black};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    border: 1.2px solid ${colors.grey200};
    background: ${({ expanded }) => (expanded ? colors.grey200 : colors.white)};

    svg {
      transform: ${({ expanded }) =>
        expanded ? "rotate(180deg)" : "rotate(0deg)"};
    }
  }
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.grey200};
  margin: 24px 0;
`;

export const Table = styled.table`
  width: 100%;
  border-radius: 8px;
  border-collapse: separate;
  border: 1px solid ${colors.grey200};

  th {
    top: 0;
    z-index: 1;
    height: 52px;
    padding: 0 12px;
    position: sticky;
    vertical-align: middle;
    background-color: white;
    background-color: ${colors.grey200};
    /* font */
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 140%;
    font-style: normal;
    letter-spacing: 0.08px;
    font-family: "Unica77";
    color: ${colors.grey900};
    width: 50%;
  }

  td {
    height: 52px;
    padding: 10px 12px;
    vertical-align: middle;
    border-top: 1px solid ${colors.grey200};
    border-right: 1px solid ${colors.grey200};
    /* font */
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    font-style: normal;
    font-family: "Unica77";
    letter-spacing: 0.07px;
    color: ${colors.grey900};
    &:last-of-type {
      border-right: none;
    }

    ol {
      list-style: decimal;
      margin-left: 27px;

      li {
        margin-bottom: 12px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const EditSurveyWrapper = styled.div`
  height: 502px;
  width: 556px;
  overflow: scroll;
`;

export const TextNumber = styled.span`
  margin-right: 8px;
`;

export const EditWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
`;
