import React, { FC } from "react";

import { TextEditorWithTitle } from "application/components/TextEditorWithTitle";
import { useAppSelector } from "application/hooks/useAppSelector";

import { CompanyErrorKey } from "features/companies/helpers/formatCompanyError";
import { CompanyWebsite, TeamMembers } from "features/companies/components";

import { CompanyBottomSectionProps } from "./models";
import { Wrapper } from "./styles";
import { InputWithTitle } from "application/components/InputWithTitle";
import { CompanySliceAction } from "features/companies/store/slice";
import { useAppDispatch } from "application/hooks/useAppDispatch";

const CompanyBottomSection: FC<CompanyBottomSectionProps> = ({
  loading,
  handleEditCompanyData,
}) => {
  const { company, errors } = useAppSelector((state) => state.companyList);
  const dispatch = useAppDispatch();
  const handleDescription = (value: string) => {
    handleEditCompanyData({ key: "description", value });
  };

  const handleShortDescription = (value: string) => {
    if (value < company?.shortDescription || value.length <= 40)
      handleEditCompanyData({ key: "shortDescription", value });
    dispatch(
      CompanySliceAction.setErrors(
        errors.filter(
          (item) =>
            item !== "shortDescriptionLength" && item !== "shortDescription"
        )
      )
    );
  };

  const handlePasteShortDescription = (
    e: React.ClipboardEvent<HTMLDivElement>
  ) => {
    e.preventDefault();
    const pasted = e.clipboardData.getData("text");
    handleEditCompanyData({ key: "shortDescription", value: pasted });
    dispatch(
      CompanySliceAction.setErrors(
        errors.filter(
          (item) =>
            item !== "shortDescriptionLength" && item !== "shortDescription"
        )
      )
    );
  };

  return (
    <Wrapper>
      {!loading && (
        <InputWithTitle
          value={company?.shortDescription}
          title="Short description"
          onChange={handleShortDescription}
          hasError={
            errors.includes(CompanyErrorKey.shortDescription) ||
            errors.includes(CompanyErrorKey.shortDescriptionLength)
          }
          onPaste={handlePasteShortDescription}
        />
      )}
      {!loading && (
        <TextEditorWithTitle
          value={company?.description}
          title="Short overview"
          onChange={handleDescription}
          hasError={errors.includes(CompanyErrorKey.description)}
        />
      )}

      <TeamMembers hasError={errors.includes(CompanyErrorKey.team)} />
      <CompanyWebsite
        hasError={errors.includes(CompanyErrorKey.website)}
        handleEditCompanyData={handleEditCompanyData}
      />
    </Wrapper>
  );
};

export default CompanyBottomSection;
