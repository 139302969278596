export interface ResponseDto {
  errorCode?: ErrorCode;
  errorMessage?: string;
}

export enum ErrorCode {
  SIGN_IN_UNSUCCESSFUL = "SIGN_IN_UNSUCCESSFUL",
  ADMIN_SIGN_IN_FAILED = "ADMIN_SIGN_IN_FAILED",
  DEAL_VALIDATION_ERROR = "DEAL_VALIDATION_ERROR",
  EMAIL_VALIDATION_ERROR = "EMAIL_VALIDATION_ERROR",
  PASSWORD_VALIDATION_ERROR = "PASSWORD_VALIDATION_ERROR",
  USER_WITH_SUCH_EMAIL_EXISTS = "USER_WITH_SUCH_EMAIL_EXISTS",
  USER_WITH_SUCH_EMAIL_NOT_EXISTS = "USER_WITH_SUCH_EMAIL_NOT_EXISTS",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  EMAIL_VERIFICATION_CODE_INCORRECT = "EMAIL_VERIFICATION_CODE_INCORRECT",
  USER_NOT_FINISHED_SIGN_UP_PROCESS = "USER_NOT_FINISHED_SIGN_UP_PROCESS",
}
