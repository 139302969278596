import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

export const TipStyled = styled.div`
  margin-top: 8px;

  color: ${colors.grey500};
  font-family: Unica77;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.06px;
`;

export const ErrorStyled = styled.div`
  margin-top: 8px;

  color: ${colors.red700};
  font-family: Unica77;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.06px;
`;
