import React, { FC, useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import LinkIcon from "@mui/icons-material/Link";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import { KYCStatus } from "integration/api/UsersApi/models";

import { KYCStatuses } from "application/helpers/kycStatuses";
import { formatDate } from "application/helpers/formatDate";
import { UserStatus, userStatuses } from "application/helpers/userStatuses";

import { InvestorInformationProps } from "./model";
import {
  Container,
  DetailInfoSection,
  DetailSection,
  IconStyled,
  CountryStyled,
  KYCSection,
  KYCItem,
  UserInfoSection,
  InfoItem,
  CopyButton,
  CopyTooltip,
  Divider,
  Status,
  UserStatusStyled,
} from "./styles";
import { colors } from "application/theme/palette";

const InvestorInformation: FC<InvestorInformationProps> = ({ investor }) => {
  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isPhoneCopied, setIsPhoneCopied] = useState(false);

  const kycStatus =
    KYCStatuses.find((status) => status.value === investor.kycStatus)?.label ||
    KYCStatus.NOT_STARTED;
  const kycStatusDate = formatDate(investor.kycStatusDate);
  const userStatus =
    userStatuses.find((status) => status.value === investor.userStatus)
      ?.label || UserStatus.ACTIVE;

  const registrationDate = formatDate(investor.registrationDate);
  const deletedDate = investor.deletedDate
    ? formatDate(investor.deletedDate)
    : "";
  const investorInitials = useMemo(() => {
    if (!investor.firstName || !investor.lastName) return;
    return (
      Array.from(investor.firstName)[0].toLocaleUpperCase() +
      Array.from(investor.lastName)[0].toLocaleUpperCase()
    );
  }, [investor.firstName, investor.lastName]);

  const copyTextToClipboard = async (text: string) => {
    return await navigator.clipboard.writeText(text);
  };

  const handleCopyEmail = () => {
    copyTextToClipboard(investor.email).then(() => {
      setIsEmailCopied(true);
      setTimeout(() => {
        setIsEmailCopied(false);
      }, 1500);
    });
  };

  const handleCopyPhone = () => {
    copyTextToClipboard(investor.phone).then(() => {
      setIsPhoneCopied(true);
      setTimeout(() => {
        setIsPhoneCopied(false);
      }, 1500);
    });
  };

  return (
    <Container>
      <DetailSection>
        <DetailInfoSection>
          <IconStyled>{investorInitials}</IconStyled>
          <div>
            <h1>{`${investor.firstName} ${investor.lastName}`}</h1>
            {investor.country && (
              <CountryStyled>
                <ReactCountryFlag countryCode={investor.country.isoCode} />
                <p>{investor.country.name}</p>
              </CountryStyled>
            )}
          </div>
        </DetailInfoSection>
        <KYCSection>
          <KYCItem>
            <p>Status:</p>
            <UserStatusStyled status={investor.userStatus}>
              {userStatus}
            </UserStatusStyled>
          </KYCItem>
          <KYCItem>
            <p>KYC status:</p>
            <Status status={investor.kycStatus}>{kycStatus}</Status>
          </KYCItem>
          <KYCItem>
            <p>KYC status date:</p>
            <h2>{kycStatusDate}</h2>
          </KYCItem>
        </KYCSection>
      </DetailSection>
      <UserInfoSection>
        <InfoItem>
          <h3>
            <CalendarTodayOutlinedIcon />
            Registration date:
          </h3>
          <h2>{registrationDate}</h2>
        </InfoItem>
        <InfoItem>
          <h3>
            <AssignmentOutlinedIcon />
            ID:
          </h3>
          <h2>{investor.id}</h2>
        </InfoItem>
        <InfoItem>
          <h3>
            <MailOutlineIcon />
            Email:
          </h3>
          <span>{investor.email}</span>
          <CopyButton type="button" onClick={handleCopyEmail}>
            <LinkIcon />
            <CopyTooltip className="email-tooltip">
              <ArrowLeftIcon />
              Copy
            </CopyTooltip>
          </CopyButton>
          {isEmailCopied && <p>Copied!</p>}
        </InfoItem>
        <InfoItem>
          <h3>
            <LocalPhoneOutlinedIcon />
            Phone number:
          </h3>
          <h2>{investor.phone}</h2>
          <CopyButton type="button" onClick={handleCopyPhone}>
            <LinkIcon />
            <CopyTooltip className="phone-tooltip">
              <ArrowLeftIcon />
              Copy
            </CopyTooltip>
          </CopyButton>
          {isPhoneCopied && <p>Copied!</p>}
        </InfoItem>
        {deletedDate && (
          <InfoItem color={colors.red700}>
            <h3>
              <DeleteOutlinedIcon />
              Deleted:
            </h3>
            <h2>{deletedDate}</h2>
          </InfoItem>
        )}
      </UserInfoSection>
      <Divider />
    </Container>
  );
};

export default InvestorInformation;
