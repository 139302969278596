import React, { FC } from "react";

import { Typography } from "@mui/material";

import PrimaryButton from "application/components/PrimaryButton";

import { HeaderProps } from "./model";
import { AddIcon, HeaderStyled } from "./styles";

const Header: FC<HeaderProps> = ({ handleShowAddModal }) => {
  return (
    <HeaderStyled>
      <Typography variant="h1">Referrals</Typography>
      <PrimaryButton
        loading={false}
        onClick={handleShowAddModal}
        title="Add new owner"
        leftIcon={<AddIcon />}
      />
    </HeaderStyled>
  );
};

export default Header;
