import styled from "@emotion/styled";
import Pagination from "@mui/material/Pagination";

import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const TableStyled = styled.table`
  width: 100%;
  border-radius: 8px;
  border-collapse: separate;

  th {
    top: 0;
    height: 52px;
    padding: 0 12px;
    position: sticky;
    vertical-align: middle;
    background-color: white;
    background-color: ${colors.grey200};
    /* font */
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 140%;
    font-style: normal;
    letter-spacing: 0.08px;
    font-family: "Unica77";
    color: ${colors.grey900};
    box-sizing: border-box;

    &:last-of-type {
      text-align: center;
      border-top-right-radius: 8px;
    }
    &:first-of-type {
      border-top-left-radius: 8px;
    }
  }

  td {
    height: 52px;
    padding: 0 12px;
    vertical-align: middle;
    border-top: 1px solid ${colors.grey200};
    border-right: 1px solid ${colors.grey200};
    /* font */
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    font-style: normal;
    font-family: "Unica77";
    letter-spacing: 0.07px;
    color: ${colors.grey900};
    box-sizing: border-box;

    &:first-of-type {
      border-left: 1px solid ${colors.grey200};
    }
    &:last-of-type {
      width: 160px;
    }
  }

  tbody {
    tr:last-of-type {
      td {
        border-bottom: 1px solid ${colors.grey200};
        &:first-of-type {
          border-bottom-left-radius: 8px;
        }
        &:last-of-type {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;
