import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import BasicPopUp from "application/containers/BasicPopUp";

import { addOwnerSchema } from "features/owners/helpers/validations";
import { OwnerAddModalProps } from "./models";
import { PipedriveOwnersDropdown } from "application/components";

const OwnerAddModal: FC<OwnerAddModalProps> = ({
  isLoading,
  handleClose,
  onSubmit,
}) => {
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{ pipedriveUserId: number }>({
    resolver: yupResolver(addOwnerSchema),
  });

  const onSubmitForm = ({ pipedriveUserId }: { pipedriveUserId: number }) => {
    onSubmit(pipedriveUserId);
  };

  const handleSelectOwner = (id: number) => {
    setValue("pipedriveUserId", id, {
      shouldValidate: !!errors.pipedriveUserId,
    });
  };

  return (
    <BasicPopUp
      title="Add new deal owner"
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmitForm)}
      isLoading={isLoading}
      disabled={isLoading || !watch("pipedriveUserId")}
    >
      <PipedriveOwnersDropdown
        value={watch("pipedriveUserId")}
        onChange={handleSelectOwner}
        placeholder="Deal owner"
      />
    </BasicPopUp>
  );
};

export default OwnerAddModal;
