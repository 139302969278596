import { createAsyncThunk } from "@reduxjs/toolkit";
import PortfolioApi from "integration/api/PortfolioAPI";
import { RequestEnum } from "application/store/request/model";
import DealsApi from "integration/api/DealsApi";
import {
  InvestorParams,
  ChangeInvestmentParams,
  CurrentCurrencyParams,
  UpdateCurrentCurrencyParams,
  DeleteCurrentCurrencyParams,
} from "integration/api/PortfolioAPI/models";
import { decryptAESKey, decryptName, generateRSAKeyPair } from "application/helpers/encryptionWithNoKey";

class InvestorActions {
  static getInvestorById = createAsyncThunk(
    `portfolioInvestor/${RequestEnum.getInvestorById}`,
    async (params: InvestorParams, { rejectWithValue }) => {
      try {
        const keys = await generateRSAKeyPair();
        const response = await PortfolioApi.getInvestorById({...params, key: keys?.publicKeyBase64 as string});

        const secretKeyEncrypted = response.headers["x-data-key"];
        const decriptedKey = await decryptAESKey(
          secretKeyEncrypted,
          keys?.privateKey as CryptoKey
        );

        const firstName = await decryptName(
          response.data.firstName,
          decriptedKey
        ) as string;
        const lastName = await decryptName(
          response.data.lastName,
          decriptedKey
        ) as string;
        
        return {...response.data, firstName, lastName};
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static changeInvestment = createAsyncThunk(
    `portfolioInvestor/${RequestEnum.changeInvestment}`,
    async (params: ChangeInvestmentParams, { rejectWithValue }) => {
      try {
        await PortfolioApi.changeInvestment(params);
        return params;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getDealInvestorById = createAsyncThunk(
    `dealInvestor/${RequestEnum.getDealInvestorById}`,
    async (params: InvestorParams, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.getInvestorById(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static addInvestmentAnalytics = createAsyncThunk(
    `portfolioInvestor/${RequestEnum.addInvestmentAnalytics}`,
    async (params: CurrentCurrencyParams, { rejectWithValue }) => {
      try {
        const { data } = await PortfolioApi.addInvestmentAnalytics(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static updateInvestmentAnalytic = createAsyncThunk(
    `portfolioInvestor/${RequestEnum.updateInvestmentAnalytic}`,
    async (params: UpdateCurrentCurrencyParams, { rejectWithValue }) => {
      try {
        const { data } = await PortfolioApi.updateInvestmentAnalytic(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deleteInvestmentAnalytic = createAsyncThunk(
    `portfolioInvestor/${RequestEnum.deleteInvestmentAnalytic}`,
    async (params: DeleteCurrentCurrencyParams, { rejectWithValue }) => {
      try {
        const { data } = await PortfolioApi.deleteInvestmentAnalytic(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default InvestorActions;
