import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import { colors } from "application/theme/palette";

import Editor from "features/lexical";

interface TextEditorWithTitleProps {
  title: string;
  value: string;
  onChange: (value: string) => void;
  hasError: boolean;
  mb?: number;
}

export const TextEditorWithTitle = ({
  title,
  value,
  onChange,
  hasError,
  mb = 4,
}: TextEditorWithTitleProps) => {
  return (
    <Box mb={mb}>
      <Typography
        variant="h3"
        color={hasError ? colors.red700 : colors.grey900}
        fontWeight={500}
        mb={2.5}
      >
        {title}
      </Typography>
      <Editor onChange={onChange} value={value} hasError={hasError} />
    </Box>
  );
};
