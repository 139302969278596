import { colors } from "application/theme/palette";
import { DealStatus } from "integration/api/DealsApi/models";

export const colorHandler = (selected: DealStatus | undefined) => {
  switch (selected) {
    case DealStatus.ACTIVE:
      return {
        borderColor: colors.green700,
        bgColor: colors.green200,
      };
    case DealStatus.MOVED_TO_PORTFOLIO:
      return {
        borderColor: colors.blue700,
        bgColor: colors.blue50,
      };
    case DealStatus.DRAFT:
    default:
      return {
        borderColor: colors.orange700,
        bgColor: colors.orange200,
      };
  }
};
