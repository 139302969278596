import React, { FC } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";

import { ReactComponent as EditSvg } from "application/assets/icons/edit.svg";
import { ReactComponent as CloseSvg } from "application/assets/icons/close.svg";
import { KYCStatuses } from "application/helpers/kycStatuses";
import { UserStatus, userStatuses } from "application/helpers/userStatuses";
import { SearchParamsRoutesNames } from "application/routes/constants";
import { getNavigationState } from "application/helpers/navigationState";

import { InvestorPageResponseDto } from "integration/api/UsersApi/models";

import { Actions, Status, UserStatusStyled } from "./styles";

interface Props {
  user: InvestorPageResponseDto;
  onDelete: (value: InvestorPageResponseDto) => void;
  selectedUsersIds: number[];
  setSelectedUsersIds: (value: number[]) => void;
}

const UsersTableRow: FC<Props> = ({
  user,
  onDelete,
  selectedUsersIds,
  setSelectedUsersIds,
}) => {
  const isUserSelected = selectedUsersIds.some((id) => id === user.id);
  const isUserActive = user.userStatus === UserStatus.ACTIVE;

  const getStatusName = () => {
    return KYCStatuses.find((item) => item.value === user.kycStatus)?.label;
  };
  const getUserStatus = () => {
    return userStatuses.find((item) => item.value === user.userStatus)?.label;
  };

  const onSelectUser = () => {
    if (isUserSelected) {
      const filteredUsersIds = selectedUsersIds.filter((id) => id !== user.id);
      setSelectedUsersIds(filteredUsersIds);
    } else {
      setSelectedUsersIds([...selectedUsersIds, user.id]);
    }
  };

  return (
    <tr>
      <td>
        <Checkbox checked={isUserSelected} onChange={onSelectUser} />
      </td>
      <td>{user.firstName}</td>
      <td>{user.lastName}</td>
      <td>{user.email}</td>
      <td>
        <UserStatusStyled isActive={isUserActive}>
          {getUserStatus()}
        </UserStatusStyled>
      </td>
      <td>{user.phone}</td>
      <td>
        <Status status={user.kycStatus}>{getStatusName()}</Status>
      </td>
      <td>
        <Actions>
          <Link
            to={`${user.id}/detail`}
            state={getNavigationState(SearchParamsRoutesNames.userDetails)}
          >
            <EditSvg />
          </Link>
          <CloseSvg onClick={() => onDelete(user)} />
        </Actions>
      </td>
    </tr>
  );
};

export default UsersTableRow;
