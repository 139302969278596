import {
  InvestmentDto,
  FundraisingStatus,
} from "integration/api/PortfolioAPI/models";
import { shallowEqual } from "application/helpers/shallowEqual";

export const validate = (
  initial: InvestmentDto,
  data: {
    amount: number;
    status: FundraisingStatus;
  }
) => {
  const applySameFormat = {
    amount: initial.investmentAmount,
    status: initial.fundraisingStatus,
  };

  return !shallowEqual(applySameFormat, data);
};
