import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { InitialState } from "./modules";

const defaultState: InitialState = {
  hasUnsavedChanges: false,
  showModal: false,
  nextPath: null,
};
const initialState = defaultState;

const detectChangesSavedSlice = createSlice({
  name: "detectChangesSavedSlice",
  initialState,
  reducers: {
    setHasUnsavedChanges: (state, actions: PayloadAction<boolean>) => {
      state.hasUnsavedChanges = actions.payload;
    },
    setNextPath: (state, actions: PayloadAction<string | null>) => {
      state.nextPath = actions.payload;
      state.showModal = !!actions.payload;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
    reset: (state) => (state = defaultState),
  },
});

export const DetectChangesSavedSliceActions = detectChangesSavedSlice.actions;

export default detectChangesSavedSlice.reducer;
