import * as yup from "yup";

import { CommonErrors } from "application/helpers/validations";

export const UpdateYearSchema = (isFactMetric: boolean) => {
  return yup.object().shape({
    year: yup
      .number()
      .test("len", CommonErrors.invalidYear, (val) =>
        val && val.toString().length === 4 ? true : false
      )
      .max(
        isFactMetric ? new Date().getFullYear() : Infinity,
        CommonErrors.invalidYear
      ),
  });
};
