import React from "react";

import { TractionTableProps } from "./models";

import TableMore from "../TableMore";
import TractionTableRow from "../TractionTableRow";
import Loader from "application/components/Loader";

import {
  Table,
  Title,
  Header,
  Wrapper,
  Container,
  PlusButton,
  LoaderContainer,
  ActionsContainer,
} from "./styles";
import { colors } from "application/theme/palette";

const TractionTable = ({
  data,
  title,
  thArray,
  showMore,
  onAddRow,
  onEditRow,
  onEditYear,
  onDeleteRow,
  onDeleteYear,
}: TractionTableProps) => {
  return (
    <Wrapper>
      <Header>
        <Title>{title}</Title>
        <ActionsContainer>
          <PlusButton titleAccess="Add Year" onClick={onAddRow} />
          {showMore && (
            <TableMore onDeleteYear={onDeleteYear} onEditYear={onEditYear} />
          )}
        </ActionsContainer>
      </Header>

      <Container>
        {false && (
          <LoaderContainer>
            <Loader color={colors.grey500} />
          </LoaderContainer>
        )}

        <Table>
          <thead>
            <tr>
              {thArray.map((item) => (
                <th key={item}>{item}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {data.map((item, index) => (
              <TractionTableRow
                key={index}
                item={item}
                onEditRow={onEditRow}
                onDeleteRow={onDeleteRow}
              />
            ))}
          </tbody>
        </Table>
      </Container>
    </Wrapper>
  );
};

export default TractionTable;
