import React from "react";

import { LoaderWrapper, Container } from "./styles";

interface Props {
  color?: string;
}

const Loader = ({ color }: Props) => {
  return (
    <LoaderWrapper>
      <Container color={color}>
        <div></div>
        <div></div>
      </Container>
    </LoaderWrapper>
  );
};

export default Loader;
