import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import Loader from "application/components/Loader";
import { colors } from "application/theme/palette";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import Empty from "application/containers/Empty";

import { RegularLeadsOwnerDto } from "integration/api/OwnersApi/models";

import OwnersActions from "../store/actions";
import { Header, OwnerAddModal, Table } from "../components";

import { EmptyPageContainer } from "./styles";

const OwnersPage: FC = () => {
  const dispatch = useAppDispatch();
  const { content, totalPages } = useAppSelector(
    (state) => state.owners
  ).owners;
  const { getOwners, deleteOwner, addOwner } = useAppSelector(requestSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddSuccessModal, setShowAddSuccessModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const [ownerToDelete, setOwnerToDelete] =
    useState<RegularLeadsOwnerDto | null>(null);

  const loading = getOwners.loading || deleteOwner.loading || addOwner.loading;

  const fetchOwners = async () => {
    await dispatch(
      OwnersActions.getOwners({
        page: page - 1,
      })
    );
  };

  useEffect(() => {
    fetchOwners();
  }, []);

  const onPageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    dispatch(
      OwnersActions.getOwners({
        page: page - 1,
      })
    );
    setPage(page);
    setSearchParams({
      page: String(page),
    });
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const onAddSuccess = () => {
    setShowAddSuccessModal(false);
  };

  const onAddSubmit = async (id: number) => {
    await dispatch(OwnersActions.addOwner(id)).then((res) => {
      isResponseFulfilled(res, () => {
        fetchOwners();
        setShowAddModal(false);
        setShowAddSuccessModal(true);
      });
    });
  };

  const onDelete = (owner: RegularLeadsOwnerDto) => {
    setOwnerToDelete(owner);
    setShowDeleteModal(true);
  };

  const onDeleteSuccess = () => {
    const isPageEmpty = page > 1 && content.length === 1;
    if (isPageEmpty) {
      setPage((prev) => prev - 1);
    }
    dispatch(
      OwnersActions.getOwners({
        page: page - 1,
      })
    );
    setOwnerToDelete(null);
    setShowDeleteModal(false);
    setShowDeleteSuccessModal(true);
  };

  const onSubmitDelete = async () => {
    if (!ownerToDelete?.id) return;
    await dispatch(OwnersActions.deleteOwner(ownerToDelete.id)).then((res) => {
      isResponseFulfilled(res, onDeleteSuccess);
    });
  };

  const onSubmitSuccess = () => {
    setShowDeleteSuccessModal(false);
  };

  const onCloseDelete = () => {
    setOwnerToDelete(null);
    setShowDeleteModal(false);
  };

  return (
    <>
      {loading && <Loader color={colors.grey500} />}
      <Header handleShowAddModal={handleShowAddModal} />
      <>
        {loading || !!content.length ? (
          <Table
            page={page}
            onPageChange={onPageChange}
            handleDelete={onDelete}
          />
        ) : (
          <EmptyPageContainer>
            <Empty description="You haven’t any owners yet!" />
            <button type="button" onClick={handleShowAddModal}>
              Add new owner
            </button>
          </EmptyPageContainer>
        )}
      </>
      {showAddModal && (
        <OwnerAddModal
          isLoading={loading}
          handleClose={handleCloseAddModal}
          onSubmit={onAddSubmit}
        />
      )}
      {showAddSuccessModal && (
        <SuccessPopUp title="The deal owner is added" onSubmit={onAddSuccess} />
      )}
      {showDeleteModal && (
        <ConfirmPopUp
          title="Delete deal owner"
          onClose={onCloseDelete}
          onSubmit={onSubmitDelete}
          description={`You want to delete ${ownerToDelete?.name}. Are you sure?`}
        />
      )}
      {showDeleteSuccessModal && (
        <SuccessPopUp
          title="The deal owner is deleted"
          onSubmit={onSubmitSuccess}
        />
      )}
    </>
  );
};

export default OwnersPage;
