import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import Loader from "application/components/Loader";
import { colors } from "application/theme/palette";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import Empty from "application/containers/Empty";
import {
  AddLeadOwnerRequest,
  ReferralLeadsOwnerResponse,
  UpdateLeadOwnerRequest,
} from "integration/api/ReferralsApi/models";

import ReferralsActions from "../store/actions";
import { Header, OwnerAddModal, Table, OwnerEditModal } from "../components";
import { EmptyPageContainer } from "./styles";

const ReferralsPage: FC = () => {
  const dispatch = useAppDispatch();
  const { content } = useAppSelector((state) => state.referrals).referrals;
  const { getReferrals, deleteReferrals, addReferrals, editReferrals } =
    useAppSelector(requestSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddSuccessModal, setShowAddSuccessModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const [selectedOwner, setSelectedOwner] =
    useState<ReferralLeadsOwnerResponse | null>(null);

  const loading =
    getReferrals.loading ||
    deleteReferrals.loading ||
    addReferrals.loading ||
    editReferrals.loading;

  const fetchOwners = async () => {
    await dispatch(
      ReferralsActions.getReferrals({
        page: page - 1,
      })
    );
  };

  useEffect(() => {
    fetchOwners();
  }, []);

  const onPageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    dispatch(
      ReferralsActions.getReferrals({
        page: page - 1,
      })
    );
    setPage(page);
    setSearchParams({
      page: String(page),
    });
  };

  // Add owner
  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const onAddSuccess = () => {
    setShowAddSuccessModal(false);
  };

  const onAddSubmit = async (data: AddLeadOwnerRequest) => {
    await dispatch(ReferralsActions.addReferrals(data)).then((res) => {
      isResponseFulfilled(res, () => {
        fetchOwners();
        setShowAddModal(false);
        setShowAddSuccessModal(true);
      });
    });
  };

  // Edit owner
  const handleShowEditModal = (owner: ReferralLeadsOwnerResponse) => {
    setSelectedOwner(owner);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setSelectedOwner(null);
    setShowEditModal(false);
  };

  const onEditSuccess = () => {
    setShowEditSuccessModal(false);
  };

  const onEditSubmit = async (data: UpdateLeadOwnerRequest) => {
    await dispatch(ReferralsActions.editReferrals(data)).then((res) => {
      isResponseFulfilled(res, () => {
        fetchOwners();
        setSelectedOwner(null);
        setShowEditModal(false);
        setShowEditSuccessModal(true);
      });
    });
  };

  // Delete owner
  const onDelete = (owner: ReferralLeadsOwnerResponse) => {
    setSelectedOwner(owner);
    setShowDeleteModal(true);
  };

  const onDeleteSuccess = () => {
    const isPageEmpty = page > 1 && content.length === 1;
    if (isPageEmpty) {
      setPage((prev) => prev - 1);
    }
    dispatch(
      ReferralsActions.getReferrals({
        page: page - 1,
      })
    );
    setSelectedOwner(null);
    setShowDeleteModal(false);
    setShowDeleteSuccessModal(true);
  };

  const onSubmitDelete = async () => {
    if (!selectedOwner?.id) return;
    await dispatch(ReferralsActions.deleteReferrals(selectedOwner.id)).then(
      (res) => {
        isResponseFulfilled(res, onDeleteSuccess);
      }
    );
  };

  const onSubmitSuccess = () => {
    setShowDeleteSuccessModal(false);
  };

  const onCloseDelete = () => {
    setSelectedOwner(null);
    setShowDeleteModal(false);
  };

  return (
    <>
      {loading && <Loader color={colors.grey500} />}
      <Header handleShowAddModal={handleShowAddModal} />
      <>
        {loading || !!content.length ? (
          <Table
            page={page}
            onPageChange={onPageChange}
            handleDelete={onDelete}
            handleEdit={handleShowEditModal}
          />
        ) : (
          <EmptyPageContainer>
            <Empty description="You haven't any referrals yet!" />
            <button type="button" onClick={handleShowAddModal}>
              Add new owner
            </button>
          </EmptyPageContainer>
        )}
      </>
      {showAddModal && (
        <OwnerAddModal
          isLoading={loading}
          handleClose={handleCloseAddModal}
          onSubmit={onAddSubmit}
        />
      )}
      {showAddSuccessModal && (
        <SuccessPopUp title="The deal owner is added" onSubmit={onAddSuccess} />
      )}
      {showEditModal && (
        <OwnerEditModal
          isLoading={loading}
          handleClose={handleCloseEditModal}
          onSubmit={onEditSubmit}
          selectedOwner={selectedOwner}
        />
      )}
      {showEditSuccessModal && (
        <SuccessPopUp
          title="The deal owner is edited"
          onSubmit={onEditSuccess}
        />
      )}
      {showDeleteModal && (
        <ConfirmPopUp
          title="Delete deal owner"
          onClose={onCloseDelete}
          onSubmit={onSubmitDelete}
          description={`You want to delete ${selectedOwner?.name}. Are you sure?`}
        />
      )}
      {showDeleteSuccessModal && (
        <SuccessPopUp
          title="The deal owner is deleted"
          onSubmit={onSubmitSuccess}
        />
      )}
    </>
  );
};

export default ReferralsPage;
