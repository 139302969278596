import * as yup from "yup";

import { Currency } from "integration/api/DealsApi/models";
import { CommonErrors } from "application/helpers/validations";

export const EditRowSingleTypeSchema = (isFactMetric: boolean) => {
  return yup.object({
    year: yup
      .number()
      .test("len", CommonErrors.invalidYear, (val) =>
        val && val.toString().length === 4 ? true : false
      )
      .max(
        isFactMetric ? new Date().getFullYear() : Infinity,
        CommonErrors.invalidYear
      ),
    index: yup
      .number()
      .typeError(CommonErrors.number)
      .required(CommonErrors.requiredField),
    currency: yup.string(),
  });
};

export interface EditRowSingleTypeFieldValues {
  year: number;
  index: number;
  currency: Currency;
}
