import React, { FC, useEffect, useState } from "react";
import { ArrowBack, SearchOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { CompanyDealsHeaderProps } from "./models";
import {
  Wrapper,
  BackButton,
  SearchSection,
  InputContainer,
  Input,
} from "./styles";

const CompanyDealsHeader: FC<CompanyDealsHeaderProps> = ({
  companyName,
  searchValue,
  setSearchValue,
  searchParams,
  setSearchParams,
}) => {
  const navigate = useNavigate();
  const [searchItem, setSearchItem] = useState(searchValue);

  const hideBackButton = window?.history?.length <= 2;

  useEffect(() => {
    if (!searchValue) {
      setSearchItem("");
    }
  }, [searchValue]);

  const handleSearch = (value: string) => {
    const params = {} as { [key: string]: string };
    searchParams.forEach((value, key) => {
      if (key !== "search") {
        params[key] = value;
      }
    });
    setSearchItem(value);
    setSearchValue(value.length > 2 ? value : "");
    setSearchParams(
      { ...params, ...(value ? { search: String(value) } : {}) },
      {
        state: window?.history?.state?.usr,
      }
    );
  };

  const goBack = () => {
    const path = (window?.history?.state?.usr?.companyDeals || -1) as string;
    navigate(path, {
      state: window?.history?.state?.usr,
    });
  };

  return (
    <Wrapper>
      {!hideBackButton && (
        <BackButton type="button" onClick={goBack}>
          <ArrowBack />
          Back to company overview
        </BackButton>
      )}
      <SearchSection>
        <h1>{companyName}</h1>
        <InputContainer>
          <SearchOutlined />
          <Input
            placeholder="Search"
            value={searchItem}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </InputContainer>
      </SearchSection>
    </Wrapper>
  );
};

export default CompanyDealsHeader;
