import styled from "@emotion/styled";

import { colors } from "application/theme/palette";
import { KYCStatus } from "integration/api/UsersApi/models";
import { getKYCStatusColor } from "application/helpers/kycStatuses";

interface StyledProps {
  status?: KYCStatus;
}

export const Container = styled.div<StyledProps>`
  padding: 4px 32px;
  border-radius: 8px;
  background: ${({ status }) => getKYCStatusColor(status)};

  p {
    all: unset;
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    font-style: normal;
    font-family: "Unica77";
    color: ${colors.white};
    letter-spacing: 0.07px;
  }
`;
