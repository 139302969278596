import axiosInstance from "../axiosInstance";
import {
  ReferralsParams,
  PageReferralLeadsOwnerResponse,
  ReferralLeadsOwnerResponse,
  UpdateLeadOwnerRequest,
  AddLeadOwnerRequest,
} from "./models";

const ReferralsApi = {
  getReferrals: (params: ReferralsParams) => {
    return axiosInstance.get<PageReferralLeadsOwnerResponse>(
      "/api/admin/deal-owners/referral-leads",
      {
        params,
      }
    );
  },
  addReferrals: (data: AddLeadOwnerRequest) => {
    const { pipedriveUserId, pipedriveSourceId } = data;
    return axiosInstance.post<ReferralLeadsOwnerResponse>(
      `/api/admin/deal-owners/referral-leads/${pipedriveUserId}`,
      { pipedriveSourceId }
    );
  },
  editReferrals: (params: UpdateLeadOwnerRequest) => {
    const { id, ...restParams } = params;
    return axiosInstance.put<ReferralLeadsOwnerResponse>(
      `/api/admin/deal-owners/referral-leads/${id}`,
      {
        ...restParams,
      }
    );
  },
  deleteReferrals: (id: number) => {
    return axiosInstance.delete(`/api/admin/deal-owners/referral-leads/${id}`);
  },
};

export default ReferralsApi;
