import React, { FC } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "application/components";
import { PathNames } from "application/routes";

import { Container, BackButton } from "./styles";
import { HeaderProps } from "./model";

const Header: FC<HeaderProps> = ({ onDelete }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isDealInvestor = location.pathname.includes(PathNames.deals);
  const hideBackButton = window?.history?.length <= 2;

  const goBack = () => {
    const path = (window?.history?.state?.usr
      ?.portfolioInvestorsDetailCommitments || -1) as any;
    navigate(path, { state: window?.history?.state?.usr });
  };

  return (
    <Container>
      {!hideBackButton && (
        <BackButton type="button" onClick={goBack}>
          <ArrowBackIcon />
          Back to investors
        </BackButton>
      )}
      <div>
        {!isDealInvestor && (
          <Button label="Delete investor" red onClick={onDelete} />
        )}
      </div>
    </Container>
  );
};

export default Header;
