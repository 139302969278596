import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { AnalyticPageResponseDto } from "integration/api/AnalyticManagementApi/models";

import { ManagementAnalyticsState } from "./model";
import ManagementAnalyticsActions from "./actions";

const initialState: ManagementAnalyticsState = {
  title: "",
  analytics: [],
};

export const managementAnalyticsSlice = createSlice({
  name: "management-analytics",
  initialState,
  reducers: {
    setAnalytics: (state, action: PayloadAction<AnalyticPageResponseDto[]>) => {
      state.analytics = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get analytics list
    builder.addCase(
      ManagementAnalyticsActions.getAnalyticsList.fulfilled,
      (state, action) => {
        state.analytics = action.payload.adminAnalyticResponses;
        state.title = action.payload.dealTitle;
      }
    );
  },
});

export const ManagementAnalyticsSliceAction = managementAnalyticsSlice.actions;
export default managementAnalyticsSlice.reducer;
