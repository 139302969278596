import React, { FC, useEffect, useMemo, useState } from "react";
import { Search, Close } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import { useParams } from "react-router-dom";

import {
  CurrencySymbolSelect,
  SelectWithText,
  PaginationMUI,
} from "application/components";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import SuccessPopUp from "application/containers/SuccessPopUp";
import Loader from "application/components/Loader";
import { requestSelector } from "application/store/request/selectors";
import LostDealPopUp from "application/containers/LostDealPopUp";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { colors } from "application/theme/palette";
import { ReactComponent as MoneysSvg } from "application/assets/icons/moneys.svg";
import { formatTotal } from "application/helpers/formatTotal";
import InvestmentAnalyticPopUp from "application/containers/InvestmentAnalyticPopUp";
import ConfirmPopUp from "application/containers/ConfirmPopUp";

import { InvestmentStatus } from "integration/api/DealsApi/models";
import {
  InvestmentAnalyticRequestDto,
  InvestmentAnalyticsHistoryDto,
} from "integration/api/PortfolioAPI/models";

import InvestorsActions from "features/portfolio-investors/store/actions";
import InvestorActions from "features/portfolio-investor-detail/store/actions";
import CommitmentsPopUp from "../CommitmentsPopUp";
import { CommitmentsPopUpForm } from "../CommitmentsPopUp/model";

import TableRow from "../TableRow";

import { InitialState, TableProps } from "./model";
import {
  TableStyled,
  Wrapper,
  FiltersSection,
  Input,
  InputContainer,
  ActionsContainer,
  DeleteContainer,
  LoaderContainer,
  TotalRowStyled,
  MultiRowStyled,
  Amount,
  RowTitle,
} from "./styles";

const statusOptions = [
  { value: "", label: "All" },
  { value: InvestmentStatus.PENDING, label: "Pending" },
  { value: InvestmentStatus.CONFIRMED, label: "Confirmed" },
  { value: InvestmentStatus.CANCELED, label: "Canceled" },
];

const Table: FC<TableProps> = ({
  getInvestors,
  page,
  setPage,
  selectedStatus,
  setSelectedStatus,
  searchValue,
  setSearchValue,
  onDelete,
  selectedInvestorsIds,
  setSelectedInvestorsIds,
  handleDeleteMultiple,
  currency,
  setCurrency,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const {
    investors,
    totalCurrency,
    totalCurrentValue,
    totalInvestmentAmount,
    totalRealizedProfit,
    multiple,
  } = useAppSelector((state) => state.investors);
  const { loading } = useAppSelector(requestSelector).changeInvestorStatus;
  const [state, setState] = useState<InitialState>({
    commitmentInvestorId: null,
    showCommitmentModal: false,
    showCommitmentSuccessModal: false,
    showLostDealModal: false,
    showLostDealSuccessModal: false,
    selectedAnalytics: null,
    showCurrencyModal: false,
    showCurrencySuccessModal: false,
    showDeleteModal: false,
    showDeleteSuccessModal: false,
    selectedInvestorId: null,
  });

  const {
    commitmentInvestorId,
    showCommitmentModal,
    showCommitmentSuccessModal,
    showLostDealModal,
    showLostDealSuccessModal,
    selectedAnalytics,
    showCurrencyModal,
    showCurrencySuccessModal,
    showDeleteModal,
    showDeleteSuccessModal,
    selectedInvestorId,
  } = state;

  const currentCurrencyPopUpTitle = selectedAnalytics
    ? "Edit current value"
    : "Add new current value";

  const showSuccessModal = showCurrencySuccessModal || showDeleteSuccessModal;
  const successModalTitle = useMemo(() => {
    return showCurrencySuccessModal && selectedAnalytics
      ? "Investment analytics was successfully updated"
      : showDeleteSuccessModal
      ? "Investment analytics was successfully deleted"
      : "Investment analytics was successfully added";
  }, [showSuccessModal, selectedAnalytics]);

  const selectedInvestor = useMemo(() => {
    return investors.content.find(
      (item) => item.investorId === commitmentInvestorId
    );
  }, [investors.content, commitmentInvestorId]);

  const totalInvestment = formatTotal(totalInvestmentAmount, totalCurrency);
  const totalValue = formatTotal(totalCurrentValue, totalCurrency);
  const totalProfit = formatTotal(totalRealizedProfit, totalCurrency);

  const isAllInvestorsSelected =
    investors.content.length === selectedInvestorsIds.length &&
    !!selectedInvestorsIds.length;

  useEffect(() => {
    setSelectedInvestorsIds([]);
  }, [page]);

  const handleSelectAll = () => {
    const investorIds = investors.content.map(
      (investor) => investor.investorId
    );
    setSelectedInvestorsIds(isAllInvestorsSelected ? [] : investorIds);
  };

  const onPageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const handelSelectStatus = (status: InvestmentStatus) => {
    setSelectedStatus(status);
  };

  const handleChangeStatus = (status: InvestmentStatus, investorId: number) => {
    if (status === InvestmentStatus.CANCELED) {
      setState((prev) => ({
        ...prev,
        showLostDealModal: true,
        commitmentInvestorId: investorId,
      }));
    }
    if (status === InvestmentStatus.CONFIRMED) {
      setState((prev) => ({
        ...prev,
        showCommitmentModal: true,
        commitmentInvestorId: investorId,
      }));
    }

    if (status === InvestmentStatus.PENDING) {
      dispatch(
        InvestorsActions.changeInvestorStatus({
          id: Number(id),
          investorId,
          commitmentStatus: status,
        })
      ).then(() => getInvestors({}));
    }
  };

  const onCommitmentClose = () => {
    setState((prev) => ({
      ...prev,
      showCommitmentModal: false,
      commitmentInvestorId: null,
    }));
  };

  const onCommitmentSuccessClose = () => {
    setState((prev) => ({
      ...prev,
      showCommitmentSuccessModal: false,
    }));
  };

  const onCommitmentSubmit = (data: CommitmentsPopUpForm) => {
    dispatch(
      InvestorsActions.changeInvestorStatus({
        id: Number(id),
        investorId: Number(commitmentInvestorId),
        commitmentStatus: InvestmentStatus.CONFIRMED,
        ...data,
      })
    ).then((res) => {
      isResponseFulfilled(res, () => {
        setState((prev) => ({
          ...prev,
          commitmentInvestorId: null,
          showCommitmentModal: false,
          showCommitmentSuccessModal: true,
        }));
        getInvestors({});
      });
    });
  };

  const handleCloseLostDealModal = () => {
    setState((prev) => ({
      ...prev,
      showLostDealModal: false,
    }));
  };

  const onLostDealSuccessClose = () => {
    setState((prev) => ({
      ...prev,
      showLostDealSuccessModal: false,
    }));
  };

  const onSubmitLostDeal = (lostReason: string) => {
    dispatch(
      InvestorsActions.changeInvestorStatus({
        id: Number(id),
        investorId: Number(commitmentInvestorId),
        commitmentStatus: InvestmentStatus.CANCELED,
        lostReason: lostReason,
      })
    ).then((res) => {
      isResponseFulfilled(res, () => {
        setState((prev) => ({
          ...prev,
          commitmentInvestorId: null,
          showLostDealModal: false,
          showLostDealSuccessModal: true,
        }));
        getInvestors({});
      });
    });
  };

  const handleCloseSuccessModal = () => {
    setState((prev) => ({
      ...prev,
      showCurrencySuccessModal: false,
      showDeleteSuccessModal: false,
      selectedAnalytics: null,
    }));
  };

  const openCurrencyModal = (investorId: number) => {
    setState((prev) => ({
      ...prev,
      showCurrencyModal: true,
      selectedInvestorId: investorId,
    }));
  };

  const closeCurrencyModal = () => {
    setState((prev) => ({
      ...prev,
      showCurrencyModal: false,
      selectedAnalytics: null,
      selectedInvestorId: null,
    }));
  };

  const onDeleteAnalytic = ({
    analytics,
    investorId,
  }: {
    analytics: InvestmentAnalyticsHistoryDto;
    investorId: number;
  }) => {
    setState((prev) => ({
      ...prev,
      showDeleteModal: true,
      selectedAnalytics: analytics,
      selectedInvestorId: investorId,
    }));
  };

  const closeDeleteModal = () => {
    setState((prev) => ({
      ...prev,
      showDeleteModal: false,
      selectedAnalytics: null,
      selectedInvestorId: null,
    }));
  };

  const onEditAnalytic = ({
    analytics,
    investorId,
  }: {
    analytics: InvestmentAnalyticsHistoryDto;
    investorId: number;
  }) => {
    setState((prev) => ({
      ...prev,
      showCurrencyModal: true,
      selectedAnalytics: analytics,
      selectedInvestorId: investorId,
    }));
  };

  const deleteAnalytic = async () => {
    const res = await dispatch(
      InvestorActions.deleteInvestmentAnalytic({
        id: Number(id),
        investorId: Number(selectedInvestorId),
        analyticId: Number(selectedAnalytics?.id),
      })
    );
    isResponseFulfilled(res, async () => {
      getInvestors({});
      closeDeleteModal();
      setState((prev) => ({
        ...prev,
        showDeleteSuccessModal: true,
      }));
    });
  };

  const getUpdatedData = (res: { type: string }) => {
    isResponseFulfilled(res, async () => {
      getInvestors({});
      setState((prev) => ({
        ...prev,
        showCurrencyModal: false,
        showCurrencySuccessModal: true,
        selectedInvestorId: null,
      }));
    });
  };

  const onSubmit = async (data: InvestmentAnalyticRequestDto) => {
    if (selectedAnalytics) {
      const res = await dispatch(
        InvestorActions.updateInvestmentAnalytic({
          id: Number(id),
          investorId: Number(selectedInvestorId),
          analyticId: Number(selectedAnalytics?.id),
          data: {
            ...data,
            fmvChangeDate: data.fmvChangeDate,
          },
        })
      );
      getUpdatedData(res);
    } else {
      const res = await dispatch(
        InvestorActions.addInvestmentAnalytics({
          id: Number(id),
          investorId: Number(selectedInvestorId),
          data: {
            ...data,
            fmvChangeDate: data.fmvChangeDate,
          },
        })
      );
      getUpdatedData(res);
    }
  };

  return (
    <>
      {loading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <FiltersSection>
        <div>
          <SelectWithText
            placeholder="Status"
            onChange={(value) => handelSelectStatus(value as InvestmentStatus)}
            options={statusOptions}
            selected={selectedStatus}
            maxHeight={300}
          />
          <CurrencySymbolSelect
            color={colors.white}
            onChange={setCurrency}
            selected={currency}
          />
        </div>
        <InputContainer>
          <Search />
          <Input
            placeholder="Search"
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </InputContainer>
      </FiltersSection>
      <Wrapper>
        <TableStyled>
          <thead>
            <tr>
              <th>
                <ActionsContainer>
                  <Checkbox
                    checked={isAllInvestorsSelected}
                    onChange={handleSelectAll}
                  />
                  {!!selectedInvestorsIds.length && (
                    <DeleteContainer onClick={handleDeleteMultiple}>
                      <Close />
                    </DeleteContainer>
                  )}
                </ActionsContainer>
              </th>
              <th>Investor name</th>
              <th>Investment</th>
              <th>Status</th>
              <th>Investment analytics</th>
              <th>Multiple</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {investors.content.map((item) => (
              <TableRow
                key={item.investorId}
                investor={item}
                handleChangeStatus={handleChangeStatus}
                selectedInvestorsIds={selectedInvestorsIds}
                setSelectedInvestorsIds={setSelectedInvestorsIds}
                onDelete={onDelete}
                totalCurrency={totalCurrency}
                onAddAnalytic={openCurrencyModal}
                onDeleteAnalytic={onDeleteAnalytic}
                onEditAnalytic={onEditAnalytic}
              />
            ))}
            <TotalRowStyled>
              <td></td>
              <td>
                <RowTitle>Total:</RowTitle>
              </td>
              <td colSpan={2}>
                <Amount>
                  <MoneysSvg />
                  {totalInvestment}
                </Amount>
              </td>
              <td>
                <MultiRowStyled>
                  <div>
                    <h2>Current value (FMV)</h2>
                    <p>{totalValue}</p>
                  </div>
                  <div>
                    <h2>Total realized profit</h2>
                    <p>{totalProfit}</p>
                  </div>
                </MultiRowStyled>
              </td>
              <td colSpan={2}>{multiple}</td>
            </TotalRowStyled>
          </tbody>
        </TableStyled>
      </Wrapper>
      <PaginationMUI
        page={page}
        variant="text"
        color="primary"
        count={investors.totalPages}
        onChange={onPageChange}
      />
      {showCommitmentModal && (
        <CommitmentsPopUp
          isLoading={loading}
          onSubmit={onCommitmentSubmit}
          onClose={onCommitmentClose}
          amount={selectedInvestor?.investmentAmount}
          currency={selectedInvestor?.investmentCurrency}
        />
      )}
      {showCommitmentSuccessModal && (
        <SuccessPopUp
          title="Your commitments confirm"
          onSubmit={onCommitmentSuccessClose}
        />
      )}
      {showLostDealModal && (
        <LostDealPopUp
          defaultValue={selectedInvestor?.lostReason}
          isLoading={loading}
          onClose={handleCloseLostDealModal}
          onSubmit={onSubmitLostDeal}
        />
      )}
      {showLostDealSuccessModal && (
        <SuccessPopUp
          title="Deal status changed to lost"
          onSubmit={onLostDealSuccessClose}
        />
      )}
      {showCurrencyModal && (
        <InvestmentAnalyticPopUp
          title={currentCurrencyPopUpTitle}
          onClose={closeCurrencyModal}
          onSubmit={onSubmit}
          defaultValues={selectedAnalytics}
        />
      )}
      {showDeleteModal && (
        <ConfirmPopUp
          title="Delete current value"
          description="You want to delete current value. Are you sure?"
          onClose={closeDeleteModal}
          onSubmit={deleteAnalytic}
        />
      )}
      {showSuccessModal && (
        <SuccessPopUp
          title={successModalTitle}
          onSubmit={handleCloseSuccessModal}
        />
      )}
    </>
  );
};

export default Table;
