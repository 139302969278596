import React, { FC, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import PrimaryButton from "application/components/PrimaryButton";
import { Button } from "application/components";

import AddFilePopUp from "../AddFilePopUp";
import { HeaderProps } from "./model";
import { ButtonSection, HeaderStyled, BackButtonStyled } from "./styles";

const Header: FC<HeaderProps> = ({
  title,
  uploadAnalytic,
  updateAnalyticsList,
  disableSaveButton,
}) => {
  const navigate = useNavigate();

  const [showAddModal, setShowAddModal] = useState(false);

  const hideBackButton = window?.history?.length <= 2;

  const goBack = () => {
    const path = (window?.history?.state?.usr
      ?.portfolioInvestorsManagementAnalytics || -1) as string;
    navigate(path, { state: window?.history?.state?.usr });
  };

  const handleOpenAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleSaveChanges = () => {
    updateAnalyticsList();
  };

  return (
    <>
      <HeaderStyled>
        {!hideBackButton && (
          <BackButtonStyled type="button" onClick={goBack}>
            <ArrowBackIcon />
            Back to investors
          </BackButtonStyled>
        )}
        <div>
          <Typography variant="h1">{title}</Typography>
          <ButtonSection>
            <Button
              variant="outlined"
              label="Save changes"
              onClick={handleSaveChanges}
              disabled={disableSaveButton}
            />
            <PrimaryButton
              loading={false}
              onClick={handleOpenAddModal}
              title="Add new"
              leftIcon={<AddIcon />}
            />
          </ButtonSection>
        </div>
      </HeaderStyled>
      {showAddModal && (
        <AddFilePopUp onClose={handleCloseAddModal} onSubmit={uploadAnalytic} />
      )}
    </>
  );
};

export default Header;
