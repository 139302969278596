import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

import { InvestmentStatus } from "integration/api/DealsApi/models";

interface DropdownContainerProps {
  status?: InvestmentStatus;
}

export const getStatusColor = (status?: InvestmentStatus) => {
  switch (status) {
    case InvestmentStatus.CONFIRMED:
      return colors.green700;
    case InvestmentStatus.PENDING:
      return colors.orange700;
    default:
      return colors.red700;
  }
};

export const Total = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-top: 2px;
    margin-left: 4px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid ${colors.grey200};
  }

  a {
    all: unset;
  }
`;

export const DropdownContainer = styled("div")<DropdownContainerProps>`
  > div {
    > div {
      > div:first-of-type {
        border: none;
        outline: none;
        width: fit-content;
        background-color: transparent;
        p {
          color: ${({ status }) => getStatusColor(status)};
        }
      }
    }
  }
`;
