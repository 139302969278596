import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "application/store";
import DealsApi from "integration/api/DealsApi";
import { RequestEnum } from "application/store/request/model";
import {
  GetDealLogoParams,
  GetDealsDto,
} from "integration/api/DealsApi/models";

class DealsActions {
  static getDeals = createAsyncThunk(
    `deals/${RequestEnum.getDeals}`,
    async (params: GetDealsDto, { getState, rejectWithValue }) => {
      const state = getState() as RootState;
      const sort = state.deals.deals.sort;
      try {
        const { data } = await DealsApi.getDeals({
          ...params,
          sort,
          page: params.page - 1,
        });
        return data;
      } catch (error) {
        console.error("[getDeals] Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
  static deleteDeal = createAsyncThunk(
    `deals/${RequestEnum.deleteDeal}`,
    async (id: number, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.deleteDeal(id);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getDealLogo = createAsyncThunk(
    `deals/${RequestEnum.getDealLogo}`,
    async (params: GetDealLogoParams, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.getDealLogo(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default DealsActions;
