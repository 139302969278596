import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import SuccessPopUp from "application/containers/SuccessPopUp";
import DealsActions from "features/deals/store/actions";
import { PathNames } from "application/routes";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { PaginationMUI } from "application/components";

import { DealsTableRow } from "features/companies/components";
import CompanyListActions from "features/companies/store/actions";

import { DealsTableProps } from "./models";
import { TableStyled, Wrapper } from "./styles";

const DealsTable: FC<DealsTableProps> = ({
  search,
  searchParams,
  setSearchParams,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { totalPages, content } = useAppSelector(
    (state) => state.companyList
  ).deals;

  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const [showSaveSuccessModal, setShowSaveSuccessModal] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(
        CompanyListActions.getCompanyDealsList({
          id: Number(id),
          page: page - 1,
          search,
        })
      );
    }
  }, [id, page, search]);

  const getSearchParams = () => {
    const params = {} as { [key: string]: string };
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  };

  const onPageChange = async (_: React.ChangeEvent<unknown>, page: number) => {
    const params = getSearchParams();
    setPage(page);
    setSearchParams({
      ...params,
      page: String(page),
    });
  };

  const onDelete = () => {
    setShowDelete(true);
  };

  const onCloseDelete = () => {
    setShowDelete(false);
  };

  const onDeleteSuccess = () => {
    setShowDelete(false);
    setShowDeleteSuccessModal(true);
  };

  const onSubmitDelete = async () => {
    await dispatch(DealsActions.deleteDeal(Number(id))).then((res) => {
      isResponseFulfilled(res, onDeleteSuccess);
    });
  };

  const onSubmitSuccessDelete = () => {
    setShowDeleteSuccessModal(false);
    navigate(`${PathNames.companies}/${id}/deals`);
  };

  return (
    <>
      <Wrapper>
        <TableStyled>
          <thead>
            <tr>
              <th>Logo</th>
              <th>Title</th>
              <th>ID</th>
              <th>Start date</th>
              <th>ICLUB allocation</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {content.map((item) => (
              <DealsTableRow key={item.id} item={item} onDelete={onDelete} />
            ))}
          </tbody>
        </TableStyled>
        {showDelete && (
          <ConfirmPopUp
            title="Delete Deal"
            onClose={onCloseDelete}
            onSubmit={onSubmitDelete}
            description="You want to delete deal. Are you sure?"
          />
        )}
        {showDeleteSuccessModal && (
          <SuccessPopUp
            title="The deal is deleted"
            onSubmit={onSubmitSuccessDelete}
          />
        )}
      </Wrapper>
      <PaginationMUI
        page={page}
        variant="text"
        color="primary"
        count={totalPages}
        onChange={onPageChange}
      />
    </>
  );
};

export default DealsTable;
