import { buttonHelper } from "./utils";

import { styled } from "@mui/system";

import { Box, Button } from "@mui/material";
import theme from "application/theme";

export const StyledButton = styled(Button)<{
  red?: number;
}>`
  background: ${(props) =>
    props.red
      ? theme.palette.common.white
      : buttonHelper(props.type).background};
  padding: ${theme.spacing(0, 4)};
  border: ${(props) =>
    props.red
      ? `1px solid ${theme.palette.common.error}`
      : buttonHelper(props.type).border};
  border-radius: ${theme.spacing(4)};
  box-shadow: none;
  color: ${(props) =>
    props.red ? theme.palette.common.grey900 : buttonHelper(props.type).color};
  &:hover {
    box-shadow: none;
    background: ${(props) =>
      props.red
        ? theme.palette.common.errorBg
        : buttonHelper(props.type).hoverBg};
  }
  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
    border: ${(props) =>
      props.red
        ? `1px solid ${theme.palette.common.error}`
        : buttonHelper(props.type).disabledBorderColor};
    background: ${(props) => buttonHelper(props.type).disableBg};
    color: ${theme.palette.common.grey300};
  }
  &.selected {
    box-shadow: none;
    color: ${theme.palette.common.grey900};
    background: ${(props) =>
      props.red ? theme.palette.common.errorBg : theme.palette.common.blue50};
  }
`;

export const spinAnimation = `@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}`;

export const RotatingAdornment = styled(Box)<{ rotate: boolean | number }>`
  display: flex;
  align-items: center;
  ${({ rotate }) =>
    rotate &&
    `
    animation: spin 2s linear infinite;
    ${spinAnimation}
  `}
`;
