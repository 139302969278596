import { authService } from "common";
import { clientRequest } from "../requestHandler";
import axiosInstance from "../axiosInstance";
import axiosNonTokenInstance from "../axiosNonTokenInstance";
import {
  AdminConfirmResetPasswordRequest,
  AdminProfileResponse,
} from "./models";
import { ConfirmVerificationTypes } from "integration/types";

export const AuthAPI = {
  signInApi: ({ email }: { email: string;}) =>
    clientRequest({
      url: `/api/admin/sign-in/email`,
      method: "POST",
      data: {
        email,
      },
    }),
  signInConfirmationApi: ({ email, password, verificationCode }: ConfirmVerificationTypes) =>
    clientRequest({
      url: `/api/admin/sign-in/email/code-verification`,
      method: "POST",
      data: {
        email,
        password,
        verificationCode
      },
    }),
  refreshToken: () => {
    const { refreshToken, userId } = authService.getAuthTokenStorage();

    return axiosNonTokenInstance.post("/api/admin/refresh-token", {
      refreshToken,
      userId,
    });
  },
  logOut: () => {
    const { accessToken } = authService.getAuthTokenStorage();
    return axiosInstance.post("/api/admin/logout", {
      accessToken,
    });
  },
  forgotPassword: (email: String) => {
    return axiosNonTokenInstance.post("/api/admin/sign-in/forgot-password", {
      email,
    });
  },
  confirmForgotPassword: (data: AdminConfirmResetPasswordRequest) => {
    return axiosNonTokenInstance.post(
      "/api/admin/sign-in/confirm-forgot-password",
      data
    );
  },
  getProfile: () => {
    return axiosInstance.get<AdminProfileResponse>("/api/admin/profile");
  },
};
