import React, { FC, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import Loader from "application/components/Loader";
import { requestSelector } from "application/store/request/selectors";
import { useAppSelector } from "application/hooks/useAppSelector";
import { PaginationMUI } from "application/components";

import CompanyListActions from "features/companies/store/actions";
import { InvestorsTable } from "features/companies/containers";
import { CompanyInvestorsHeader } from "features/companies/components";

import { LoaderContainer } from "./styles";

const CompanyInvestorsPage: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { investors } = useAppSelector((state) => state.companyList);
  const { loading } = useAppSelector(requestSelector).getCompanyInvestorsList;

  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );

  useEffect(() => {
    if (id) {
      dispatch(
        CompanyListActions.getCompanyInvestorsList({
          id: Number(id),
          page: page - 1,
        })
      );
    }
  }, [id, page]);

  const getSearchParams = () => {
    const params = {} as { [key: string]: string };
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  };

  const onPageChange = async (_: React.ChangeEvent<unknown>, page: number) => {
    const params = getSearchParams();
    setPage(page);
    setSearchParams({
      ...params,
      page: String(page),
    });
  };

  return (
    <div>
      {loading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <CompanyInvestorsHeader />
      <InvestorsTable />
      {!!investors.totalPages && (
        <PaginationMUI
          page={page}
          variant="text"
          color="primary"
          count={investors.totalPages}
          onChange={onPageChange}
        />
      )}
    </div>
  );
};

export default CompanyInvestorsPage;
