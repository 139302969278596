export enum ConsentErrorKey {
  "name" = "name",
  "description" = "description",
}

const getError = (key: ConsentErrorKey) => {
  switch (key) {
    case ConsentErrorKey["name"]:
      return "Consent Title";
    case ConsentErrorKey["description"]:
      return "Consent agreement text";
    default:
      return key;
  }
};

export const formatConsentError = (error: string): string[] => {
  const array = error?.split(",") || [];
  const errorsArray = array.map((item) => getError(item as ConsentErrorKey));
  return errorsArray;
};
