import styled from "@emotion/styled";
import theme from "application/theme";
import { colors } from "application/theme/palette";

export const Wrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  gap: 60px;
  padding: 32px;
  margin-top: 32px;
  border-radius: 8px;
  background: ${colors.white};
  box-shadow: 0px 0px 30px 0px rgba(222, 224, 230, 0.06);
`;

export const SectionStyled = styled("div")`
  display: flex;
`;

export const ImageWrapper = styled("div")`
  position: relative;
  display: flex;
  margin-right: ${theme.spacing(2)};
  img {
    position: absolute;
    border: 1px solid ${theme.palette.common.grey300};
    width: ${theme.spacing(9)};
    height: ${theme.spacing(9)};
    border-radius: 100%;
  }
`;

export const AvatarPlaceholder = styled("div")<{ hasError: boolean }>`
  background: ${theme.palette.common.grey200};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${theme.spacing(9)};
  height: ${theme.spacing(9)};
  border-radius: 100%;
  border: 1px solid
    ${({ hasError }) =>
      hasError ? theme.palette.common.red700 : theme.palette.common.grey300};
  cursor: pointer;
`;

export const LogoPopup = styled("div")`
  cursor: default;
  position: absolute;
  z-index: 10;
  background: ${theme.palette.common.white};
  border-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(2)};
  top: ${theme.spacing(8)};
  left: ${theme.spacing(4.5)};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 30px 0px rgba(222, 224, 230, 0.8);
`;

export const LogoPopupItem = styled("div")`
  width: ${theme.spacing(12.5)};
  cursor: pointer;
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.08px;
  &:nth-of-type(2) {
    margin-top: 12px;
  }
  &:hover {
    color: ${theme.palette.common.blue700};
  }
`;

export const DeleteContainer = styled("div")`
  margin-top: 12px;
`;

export const EditLogo = styled("div")`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  width: ${theme.spacing(9)};
  height: ${theme.spacing(9)};
  svg {
    display: none;
  }
  &:hover {
    svg {
      display: block;
    }
  }
`;

export const StatusWrapper = styled("div")`
  margin-left: 32px;
  .company-status-container {
    box-sizing: border-box;
    > div:first-of-type {
      box-sizing: border-box;
      height: 26px;
    }
  }
`;

export const FieldsSection = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LinksSection = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const LinkWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 32px;
  border-radius: 6px;
  background: ${colors.grey50};

  p {
    display: flex;
    align-items: center;
    color: ${colors.grey900};
    font-family: Unica77;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      margin-right: 8px;

      path {
        fill: ${colors.grey900};
      }
    }
  }

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    width: fit-content;
    color: ${colors.blue700};
    text-align: center;
    font-family: Unica77;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;
