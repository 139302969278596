import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequestEnum } from "application/store/request/model";
import {
  CompanyAnalyticParams,
  CompanyDeleteLogoParams,
  CompanyGetLogoParams,
  CompanyInvestorsParams,
  CompanyUpdateParams,
  CompanyUploadLogoParams,
  GetCompanyListParams,
  GetCompanyTotalsParams,
  PortfolioDealsParams,
  UpdateCompanyAnalyticParams,
  UploadCompanyAnalyticParams,
} from "integration/api/CompanyApi/models";
import CompanyApi from "integration/api/CompanyApi";
import { decryptAESKey, decryptName, generateRSAKeyPair } from "application/helpers/encryptionWithNoKey";

class CompanyListActions {
  static getCompanyList = createAsyncThunk(
    `companyList/${RequestEnum.getCompanyList}`,
    async (params: GetCompanyListParams, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.getCompanyList(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getCompanyById = createAsyncThunk(
    `companyList/${RequestEnum.getCompanyById}`,
    async (id: string, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.getCompanyById(id);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static addCompany = createAsyncThunk(
    `companyList/${RequestEnum.addCompany}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.addCompany();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deleteCompany = createAsyncThunk(
    `companyList/${RequestEnum.addCompany}`,
    async (id: number, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.deleteCompany(id);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static updateCompany = createAsyncThunk(
    `companyList/${RequestEnum.updateCompany}`,
    async (params: CompanyUpdateParams, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.updateCompany(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getCompanyPortfolioList = createAsyncThunk(
    `companyList/${RequestEnum.getCompanyPortfolioList}`,
    async (params: PortfolioDealsParams, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.getCompanyPortfolioList(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getCompanyDealsList = createAsyncThunk(
    `companyList/${RequestEnum.getCompanyDealsList}`,
    async (params: PortfolioDealsParams, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.getCompanyDealsList(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getCompanyInvestorsList = createAsyncThunk(
    `companyList/${RequestEnum.getCompanyInvestorsList}`,
    async (params: CompanyInvestorsParams, { rejectWithValue }) => {
      try {
        const keys = await generateRSAKeyPair();
        const response = await CompanyApi.getCompanyInvestorsList({...params, key: keys?.publicKeyBase64 as string});

        const secretKeyEncrypted = response.headers["x-data-key"];
        const decriptedKey = await decryptAESKey(
          secretKeyEncrypted,
          keys?.privateKey as CryptoKey
        );

        const content = await Promise.all(
          response.data.content.map(async (e) => {
            const firstName = await decryptName(
              e.firstName as string,
              decriptedKey
            ) as string;
            const lastName = await decryptName(
              e.lastName as string,
              decriptedKey
            ) as string;
            return { ...e, firstName, lastName };
          })
        );
        return {...response.data, content};
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getCompanyLogo = createAsyncThunk(
    `companyList/${RequestEnum.getCompanyLogo}`,
    async (params: CompanyGetLogoParams, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.getCompanyLogo(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deleteCompanyLogo = createAsyncThunk(
    `companyList/${RequestEnum.deleteCompanyLogo}`,
    async (params: CompanyDeleteLogoParams, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.deleteCompanyLogo(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static uploadCompanyLogo = createAsyncThunk(
    `companyList/${RequestEnum.deleteCompanyLogo}`,
    async (params: CompanyUploadLogoParams, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.uploadCompanyLogo(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getCompanyAnalyticsList = createAsyncThunk(
    `companyList/${RequestEnum.getCompanyAnalyticsList}`,
    async (id: number, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.getCompanyAnalyticsList(id);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static updateCompanyAnalyticsList = createAsyncThunk(
    `companyList/${RequestEnum.updateCompanyAnalyticsList}`,
    async (params: UpdateCompanyAnalyticParams, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.updateCompanyAnalyticsList(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static uploadCompanyAnalytic = createAsyncThunk(
    `companyList/${RequestEnum.uploadCompanyAnalytic}`,
    async (params: UploadCompanyAnalyticParams, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.uploadCompanyAnalytic(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getCompanyAnalyticByFileId = createAsyncThunk(
    `companyList/${RequestEnum.uploadCompanyAnalytic}`,
    async (params: CompanyAnalyticParams, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.getCompanyAnalyticByFileId(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deleteCompanyAnalytic = createAsyncThunk(
    `companyList/${RequestEnum.deleteCompanyAnalytic}`,
    async (params: CompanyAnalyticParams, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.deleteCompanyAnalytic(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getCompanySuggestions = createAsyncThunk(
    `companyList/${RequestEnum.getCompanySuggestions}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.getCompanySuggestions();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getCompanyTotals = createAsyncThunk(
    `companyList/${RequestEnum.getCompanyTotals}`,
    async (params: GetCompanyTotalsParams, { rejectWithValue }) => {
      try {
        const { data } = await CompanyApi.getCompanyTotals(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default CompanyListActions;
