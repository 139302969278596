import { createAsyncThunk } from "@reduxjs/toolkit";

import DealsApi from "integration/api/DealsApi";
import { RequestEnum } from "application/store/request/model";
import {
  CreateCommitmentDto,
  DealInvestorsParamsDto,
} from "integration/api/DealsApi/models";
import { decryptAESKey, decryptName, generateRSAKeyPair } from "application/helpers/encryptionWithNoKey";

class DealInvestorsActions {
  static getDealInvestors = createAsyncThunk(
    `deals/${RequestEnum.getDealInvestors}`,
    async (params: DealInvestorsParamsDto, { rejectWithValue }) => {
      try {

        const keys = await generateRSAKeyPair();
        const response = await DealsApi.getDealInvestors({...params, key: keys?.publicKeyBase64 as string});

        const secretKeyEncrypted = response.headers["x-data-key"];
        const decriptedKey = await decryptAESKey(
          secretKeyEncrypted,
          keys?.privateKey as CryptoKey
        );

        const content = await Promise.all(
          response.data.investorsPage.content.map(async (e) => {
            const firstName = await decryptName(
              e.firstName as string,
              decriptedKey
            ) as string;
            const lastName = await decryptName(
              e.lastName as string,
              decriptedKey
            ) as string;
            return { ...e, firstName, lastName };
          })
        );

        return {...response.data, investorsPage: {...response.data.investorsPage, content}};
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static addDealInvestor = createAsyncThunk(
    `deals/${RequestEnum.getDealInvestors}`,
    async (params: CreateCommitmentDto, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.addDealInvestor(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default DealInvestorsActions;
