import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { PathNames } from "application/routes";
import Loader from "application/components/Loader";
import { colors } from "application/theme/palette";
import { requestSelector } from "application/store/request/selectors";
import { getNavigationState } from "application/helpers/navigationState";
import { SearchParamsRoutesNames } from "application/routes/constants";

import InvestorsActions from "features/portfolio-investors/store/actions";

import InvestorActions from "../store/actions";
import { Header, InvestorInformation } from "../components";
import { TabsSection } from "../containers";
import { PortfolioInvestorSliceAction } from "../store/slice";

const InvestorDetailPage: FC = () => {
  const { id, investorId } = useParams();
  const location = useLocation();
  const isDealInvestor = location.pathname.includes(PathNames.deals);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const investor = useAppSelector((state) => state.portfolioInvestor).investor;
  const {
    addInvestmentAnalytics,
    updateInvestmentAnalytic,
    deleteInvestmentAnalytic,
    getDealInvestorById,
    getInvestorById,
  } = useAppSelector(requestSelector);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);

  const loading =
    getDealInvestorById.loading ||
    getInvestorById.loading ||
    addInvestmentAnalytics.loading ||
    updateInvestmentAnalytic.loading ||
    deleteInvestmentAnalytic.loading;

  useEffect(() => {
    return () => {
      dispatch(PortfolioInvestorSliceAction.resetInvestorDetails());
    };
  }, []);

  useEffect(() => {
    if (id && investorId)
      if (isDealInvestor) {
        dispatch(
          InvestorActions.getDealInvestorById({
            dealId: Number(id),
            investorId: Number(investorId),
          })
        );
      } else {
        dispatch(
          InvestorActions.getInvestorById({
            dealId: Number(id),
            investorId: Number(investorId),
          })
        );
      }
  }, [id, investorId]);

  const onDelete = () => {
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseSuccessDeleteModal = () => {
    setShowSuccessDeleteModal(false);
    const path = (window?.history?.state?.usr?.portfolioInvestors ||
      -1) as string;
    navigate(
      path,
      getNavigationState(SearchParamsRoutesNames.portfolioInvestors)
    );
  };

  const handelDeleteInvestor = () => {
    dispatch(
      InvestorsActions.deleteInvestor({
        dealId: Number(id),
        investorId: Number(investorId),
      })
    ).then((res) => {
      isResponseFulfilled(res, () => {
        setShowSuccessDeleteModal(true);
      });
    });
  };

  return (
    <>
      {loading && <Loader color={colors.grey500} />}
      <Header onDelete={onDelete} />
      <InvestorInformation investor={investor} />
      <TabsSection
        dealTitle={investor.dealTitle}
        commitments={investor.commitments}
        investment={investor.investment}
        lostReason={investor.investment?.lostReason}
      />
      {showDeleteModal && (
        <ConfirmPopUp
          title="Delete investor"
          description="Do you want to delete this investor from this portfolio deal?"
          onClose={handleCloseDeleteModal}
          onSubmit={handelDeleteInvestor}
        />
      )}
      {showSuccessDeleteModal && (
        <SuccessPopUp
          title="Investor was successfully deleted from this portfolio deal."
          onSubmit={handleCloseSuccessDeleteModal}
        />
      )}
    </>
  );
};

export default InvestorDetailPage;
