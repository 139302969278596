import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  EditRowMultiTypeSchema,
  EditRowMultiTypeFieldValues,
} from "./validation";
import { getMonthByIndex } from "application/helpers/date";
import { Currency } from "integration/api/DealsApi/models";

import PrimaryButton from "application/components/PrimaryButton";
import CurrencySelect from "application/components/CurrencySelect";
import { CustomSelect, TextFieldStyled } from "application/components";

import {
  Card,
  Title,
  Wrapper,
  Actions,
  CancelButton,
  SelectWrapper,
} from "./styles";

interface Props {
  defaultValues?: EditRowMultiTypeFieldValues;
  onClose: () => void;
  onSubmit: (data: EditRowMultiTypeFieldValues) => void;
  isMonthOptional: boolean;
}

const EditMultiDataPopUp = ({
  defaultValues,
  onClose,
  onSubmit,
  isMonthOptional,
}: Props) => {
  const schema = EditRowMultiTypeSchema(isMonthOptional);

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditRowMultiTypeFieldValues>({
    defaultValues: {
      ...defaultValues,
      month: isMonthOptional ? 0 : defaultValues?.month,
      currency: defaultValues?.currency || undefined,
    },
    resolver: yupResolver(schema),
  });

  const values = watch();

  const onMonthChange = (month: number) => {
    setValue("month", +month, { shouldValidate: true });
  };

  const onCurrencyChange = (currency: Currency) => {
    setValue("currency", currency, { shouldValidate: true });
  };

  const options = Array.from({ length: 12 }).map((_, index) => ({
    label: getMonthByIndex(index + 1),
    value: index + 1,
  }));

  const isValid = !Object.keys(errors).length;

  return (
    <Wrapper>
      <Card>
        <Title>Edit month</Title>

        <SelectWrapper>
          <CustomSelect
            maxHeight={300}
            options={options}
            selected={values.month}
            onChange={(month) => onMonthChange(month as number)}
            placeholder="Choose month"
            error={!!errors.month}
          />
        </SelectWrapper>

        <TextFieldStyled
          mb={20}
          fullWidth
          id="index"
          size="small"
          type="number"
          color="secondary"
          label="Enter index"
          filled={values.index}
          error={!!errors.index}
          {...register("index")}
        />

        <CurrencySelect
          maxHeight={300}
          selected={values.currency}
          onChange={onCurrencyChange}
          placeholder="Choose currency"
          error={!!errors.currency?.message}
        />

        <Actions>
          <CancelButton onClick={onClose}>
            <p>Cancel</p>
          </CancelButton>

          <PrimaryButton
            title="Ok"
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}
          />
        </Actions>
      </Card>
    </Wrapper>
  );
};

export default EditMultiDataPopUp;
