import { Add } from "@mui/icons-material";
import React, { useMemo, useState } from "react";

import { TractionMultiDataProps } from "./models";
import { MetricType } from "integration/api/DealsApi/models";
import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { DealSliceAction } from "features/deal-profile/store/slice";
import { multiTypeThArray } from "features/deal-profile/constants/data";
import { dealPageSelector } from "features/deal-profile/store/selectors";
import { groupTractionMultiType } from "features/deal-profile/helpers/array";
import { TractionTableItem } from "features/deal-profile/components/TractionTable/models";
import { EditRowMultiTypeFieldValues } from "features/deal-profile/popups/EditMultiDataPopUp/validation";
import { DealErrorKey } from "features/deal-profile/helpers/formatDealError";

import ConfirmPopUp from "application/containers/ConfirmPopUp";
import UpdateYearPopUp from "features/deal-profile/popups/UpdateYearPopUp";
import EmptyTraction from "features/deal-profile/components/EmptyTraction";
import TractionTable from "features/deal-profile/components/TractionTable";
import EditMultiDataPopUp from "features/deal-profile/popups/EditMultiDataPopUp";
import ForecastFactSwitch from "features/deal-profile/components/ForecastFactSwitch";

import {
  Title,
  Header,
  Wrapper,
  Container,
  TableContainer,
  AddYearContainer,
} from "./styles";

const TractionMultiData = ({ title, tractionKey }: TractionMultiDataProps) => {
  const key = tractionKey;
  const dispatch = useAppDispatch();
  const data = useAppSelector(dealPageSelector).profile[key];
  const { errors } = useAppSelector((state) => state.dealPage);
  const hasTractionError = errors.includes(DealErrorKey.tractions);
  const [metric, setMetric] = useState(MetricType.FORECAST);
  const [editRowVisible, setEditRowVisible] = useState(false);
  const [deleteRowVisible, setDeleteRowVisible] = useState(false);
  const [updateYearVisible, setUpdateYearVisible] = useState(false);
  const [deleteYearVisible, setDeleteYearVisible] = useState(false);
  const [activeYear, setActiveYear] = useState<number | null>(null);
  const [activeRow, setActiveRow] = useState<TractionTableItem | null>(null);

  const clearLocalState = () => {
    setActiveRow(null);
    setActiveYear(null);
    setEditRowVisible(false);
    setDeleteRowVisible(false);
    setDeleteYearVisible(false);
    setUpdateYearVisible(false);
  };

  // update year
  const onUpdateYear = (year?: number) => () => {
    setActiveYear(year || null);
    setUpdateYearVisible(true);
  };
  const onUpdateYearSubmit = (year: number) => {
    if (activeYear) {
      dispatch(
        DealSliceAction.editTractionMultiTypeYear({
          key,
          year,
          oldYear: activeYear,
        })
      );
    } else {
      dispatch(DealSliceAction.addTractionMultiType({ metric, key, year }));
    }
    clearLocalState();
  };

  // delete year
  const onDeleteYear = (year: number) => () => {
    setActiveYear(year);
    setDeleteYearVisible(true);
  };
  const onDeleteYearSubmit = () => {
    const year = activeYear!;
    dispatch(DealSliceAction.deleteTractionMultiTypeYear({ year, key }));
    clearLocalState();
  };

  // add row
  const onAddRow = (value?: number) => () => {
    const year = value || new Date().getFullYear();
    dispatch(DealSliceAction.addTractionMultiType({ metric, key, year }));
  };

  // edit row
  const onEditRow = (item: TractionTableItem) => {
    setActiveRow(item);
    setEditRowVisible(true);
  };
  const onEditRowSubmit = (data: EditRowMultiTypeFieldValues) => {
    const { month, index, currency } = data;
    const id = activeRow!.id;
    dispatch(
      DealSliceAction.editTractionMultiType({
        ...(!!month ? { month } : {}),
        index,
        currency,
        key,
        id,
      })
    );
    clearLocalState();
    hasTractionError &&
      dispatch(
        DealSliceAction.setErrors(
          errors.filter((item) => item !== DealErrorKey.tractions)
        )
      );
  };

  // delete row
  const onDeleteRow = (item: TractionTableItem) => {
    setActiveRow(item);
    setDeleteRowVisible(true);
  };
  const onDeleteRowSubmit = () => {
    const id = activeRow!.id;
    dispatch(DealSliceAction.deleteTractionMultiType({ id, key }));
    clearLocalState();
  };

  const tablesArray = useMemo(() => {
    return groupTractionMultiType(data, metric, "tractionYear");
  }, [data, metric]);

  const defaultValues = useMemo(() => {
    if (activeRow) {
      return {
        index: activeRow.index,
        currency: activeRow.currency,
        month: activeRow.titleOriginal,
      } as EditRowMultiTypeFieldValues;
    }
  }, [activeRow]);

  const deleteRow = activeRow?.title || "empty row";
  const deleteDescription = `You want to delete ${deleteRow}. Are you sure?`;
  const deleteYear = activeYear || "empty year";
  const deleteYearDescription = `You want to delete ${deleteYear}. Are you sure?`;

  return (
    <React.Fragment>
      <Wrapper>
        <Title>{title}</Title>
        <Container>
          <Header>
            <ForecastFactSwitch metric={metric} setMetric={setMetric} />
            <AddYearContainer onClick={onUpdateYear()}>
              <Add />
              <p>Add Year</p>
            </AddYearContainer>
          </Header>
          {data.length ? (
            Object.keys(tablesArray).map((year) => {
              return (
                <TableContainer key={year}>
                  <TractionTable
                    showMore
                    title={year}
                    onEditRow={onEditRow}
                    data={tablesArray[year]}
                    onDeleteRow={onDeleteRow}
                    thArray={multiTypeThArray}
                    onAddRow={onAddRow(+year)}
                    onEditYear={onUpdateYear(+year)}
                    onDeleteYear={onDeleteYear(+year)}
                  />
                </TableContainer>
              );
            })
          ) : (
            <EmptyTraction onClick={onUpdateYear()} title="new year" />
          )}
        </Container>
      </Wrapper>

      {/* Rows pop ups */}
      {editRowVisible && (
        <EditMultiDataPopUp
          isMonthOptional={title === "GMV"}
          onClose={clearLocalState}
          onSubmit={onEditRowSubmit}
          defaultValues={defaultValues}
        />
      )}
      {deleteRowVisible && (
        <ConfirmPopUp
          title="Delete row"
          onClose={clearLocalState}
          onSubmit={onDeleteRowSubmit}
          description={deleteDescription}
        />
      )}

      {/* Years pop ups */}
      {deleteYearVisible && (
        <ConfirmPopUp
          title="Delete year"
          onClose={clearLocalState}
          onSubmit={onDeleteYearSubmit}
          description={deleteYearDescription}
        />
      )}
      {updateYearVisible && (
        <UpdateYearPopUp
          onClose={clearLocalState}
          onSubmit={onUpdateYearSubmit}
          year={activeYear ? activeYear : undefined}
          isFactMetric={metric === MetricType.FACT}
        />
      )}
    </React.Fragment>
  );
};

export default TractionMultiData;
