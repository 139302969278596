import styled from "@emotion/styled";
import Pagination from "@mui/material/Pagination";

import { colors } from "application/theme/palette";

export const PaginationStyled = styled(Pagination)`
  align-self: center;
  margin-top: 38px;
  & .MuiPaginationItem-root {
    &:hover {
      background-color: ${colors.grey200};
    }
  }
  & .MuiPagination-ul {
    justify-content: center;
  }
  & .MuiPaginationItem-text {
    color: ${colors.grey500};
  }
  & .Mui-selected {
    color: ${colors.white};
    &:hover {
      background-color: ${colors.blue700};
    }
  }
`;
