import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  max-height: 266px;
`;

export const Title = styled.h1`
  margin: 20px 0 12px;

  color: ${colors.grey900};
  font-family: Unica77;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;

export const Table = styled.table`
  width: 100%;
  border-radius: 8px;
  border-collapse: separate;
  border: 1px solid ${colors.grey200};

  th {
    top: 0;
    height: 52px;
    padding: 0 12px;
    position: sticky;
    vertical-align: middle;
    background-color: white;
    background-color: ${colors.grey200};
    /* font */
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 140%;
    font-style: normal;
    letter-spacing: 0.08px;
    font-family: "Unica77";
    color: ${colors.grey900};
    width: 16.6%;
    width: auto;
    &:last-of-type {
      width: 126px;
    }
  }

  td {
    height: 52px;
    padding: 0 12px;
    vertical-align: middle;
    border-top: 1px solid ${colors.grey200};
    border-right: 1px solid ${colors.grey200};
    /* font */
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    font-style: normal;
    font-family: "Unica77";
    letter-spacing: 0.07px;
    color: ${colors.grey900};
    &:last-of-type {
      border-right: none;

      width: 126px;
    }
  }
`;

export const LoaderContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;

  button:first-of-type {
    margin-right: 20px;
  }
`;

export const EmptyStateStyled = styled.div`
  display: flex;
  padding: 60px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  background: ${colors.white};

  svg {
    width: 164px;
    height: 90px;
  }

  h1 {
    color: ${colors.grey900};
    text-align: center;
    font-family: Unica77;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    color: ${colors.blue700};
    text-align: center;
    font-family: Unica77;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;
