import { UserStatus } from "application/helpers/userStatuses";
import { Currency, InvestmentStatus } from "../DealsApi/models";
import { KYCStatus } from "../UsersApi/models";

export enum Status {
  ACTIVE = "ACTIVE",
  DRAFT = "DRAFT",
  MOVED_TO_PORTFOLIO = "MOVED_TO_PORTFOLIO",
}

export enum FundraisingStatus {
  SIGNED_DOCS_AND_RECEIVED_MONEY = "SIGNED_DOCS_AND_RECEIVED_MONEY",
  SIGNED_DOCS = "SIGNED_DOCS",
  RECEIVED_MONEY = "RECEIVED_MONEY",
  COMMITTED = "COMMITTED",
  NOT_STARTED = "NOT_STARTED",
}

export interface PortfolioParams {
  page: number;
  size?: number;
  sort?: string[];
  currency?: Currency;
}

export interface PortfolioInvestmentAnalyticsCurrentDto {
  fmvChangeDate: string;
  totalFmvValue: number;
  totalRealizedProfit: number;
  multiple: number;
  investmentAmount: number;
  currency: Currency;
}

export interface ProfileContentDto {
  id: number;
  businessId: string;
  creationDate: string;
  title: string;
  status: Status;
  investmentAmount: number;
  investmentCurrency: Currency;
  totalFundingGoal: number;
  logoFileId: number;
  investmentAnalytic: PortfolioInvestmentAnalyticsCurrentDto | null;
}

export interface InvestmentAnalyticsCurrentDto {
  currency: Currency;
  investmentAmount: number;
  fmvChangeDate: string;
  fmvValue: number;
  totalRealizedProfit: number;
  multiple: number;
}

export interface InvestmentAnalyticsHistoryDto {
  fmvChangeDate: string;
  fmvValue: number;
  realizedProfit: number;
  currency: Currency;
  multiple: number;
  id: number;
}

export interface InvestorContentDto {
  investorId: number;
  firstName: string;
  lastName: string;
  investmentAmount: number;
  investmentCurrency: Currency;
  investedDate: string;
  investmentStatus: InvestmentStatus;
  lostReason: string;
  investmentAnalytic: InvestmentAnalyticsCurrentDto;
  latestInvestmentAnalytic: InvestmentAnalyticsHistoryDto;
}

interface SortDto {
  empty: boolean;
  sorted?: boolean;
  unsorted?: boolean;
}

interface PageableDto {
  offset: number;
  sort: SortDto;
  pageNumber: number;
  pageSize: number;
  paged: true;
  unpaged: true;
}

interface PageDto {
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: SortDto;
  numberOfElements: number;
  pageable: PageableDto;
  first: true;
  last: true;
  empty: true;
}

export interface PortfolioDto extends PageDto {
  content: ProfileContentDto[];
}

export interface InvestorsPage extends PageDto {
  content: InvestorContentDto[];
}

export interface InvestorDto {
  investorsPage: InvestorsPage;
  title: string;
  dealHasParent: boolean;
  dealImported: boolean;
  dealCurrency: Currency;
  totalCurrency: Currency;
  totalInvestmentAmount: number;
  totalCurrentValue: number;
  totalRealizedProfit: number;
  multiple: number;
}

export interface InvestorsParams {
  id: number;
  page: number;
  size?: number;
  sort?: string[];
  commitmentStatus?: InvestmentStatus;
  search: string;
  currency?: Currency;
}

export interface InvestorsParamsAPI extends InvestorsParams {
  key: string;
}

export interface InvestorStatusParams {
  id: number;
  investorId: number;
  commitmentStatus?: InvestmentStatus;
  investmentAmount?: number;
  currency?: Currency;
  lostReason?: string;
}

export interface CreateInvestmentResponseDto {
  investmentId: number;
  fundraisingStatus: FundraisingStatus;
}

export interface CreateCommitmentDto {
  id: number;
  investorId: number;
  investmentAmount: number;
  currency: Currency;
}

export interface CreateCommitmentResponse {
  commitmentId: number;
}

export interface DeleteInvestorParams {
  dealId: number;
  investorId: number;
}
export interface DeleteMultipleInvestorsParams {
  dealId: number;
  investorsIds: number[];
}

export interface InvestorParams {
  dealId: number;
  investorId: number;
  currency?: Currency;
}

export interface InvestorParamsAPI extends InvestorParams {
  key: string;
}

export interface CountryDto {
  id: number;
  isoCode: string;
  alpha3: string;
  name: string;
}
export interface InvestorCommitmentsResponse {
  commitment: number;
  overcommitment: number;
  totalCommitment: number;
  currency: Currency;
  investedDate: string;
  status: InvestmentStatus;
  id: number;
}
export interface InvestmentDto {
  id: number;
  investmentAmount: number;
  currency: Currency | null;
  fundraisingStatus: FundraisingStatus;
  lostReason: string;
}

export interface InvestmentAnalyticsDto {
  current: InvestmentAnalyticsCurrentDto;
  history: InvestmentAnalyticsHistoryDto[];
}

export interface PortfolioInvestorDetailsResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  country: CountryDto;
  kycStatus: KYCStatus;
  kycStatusDate: string;
  commitments: InvestorCommitmentsResponse[];
  investment: InvestmentDto | null;
  dealTitle: string;
  registrationDate: string;
  userStatus: UserStatus;
  investmentAnalytics: InvestmentAnalyticsDto | null;
  dealCurrency: Currency;
}

export interface ChangeInvestmentParams {
  id: number;
  investmentId: number;
  investmentAmount: number;
  fundraisingStatus: FundraisingStatus;
}

export interface InvestmentAnalyticRequestDto {
  fmvChangeDate: string;
  fmvValue: number;
  realizedProfit: number;
  currency: Currency;
}

export interface CurrentCurrencyParams {
  id: number;
  investorId: number;
  data: InvestmentAnalyticRequestDto;
}

export interface UpdateCurrentCurrencyParams {
  id: number;
  investorId: number;
  analyticId: number;
  data: InvestmentAnalyticRequestDto;
}

export interface DeleteCurrentCurrencyParams {
  id: number;
  investorId: number;
  analyticId: number;
}

export interface GetPortfolioInvestmentParams {
  search?: string;
  currency?: Currency;
}

export interface DealAdminResponseTotalsDto {
  totalInvestments: number;
  totalFmvValue: number;
  totalRealizedProfit: number;
  multiple: number;
}
