import { SaveAlt } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import BasicPopUp from "application/containers/BasicPopUp";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import theme from "application/theme";
import { DragAndDropArea } from "features/deal-profile/components/DragAndDropArea";
import { dealPageSelector } from "features/deal-profile/store/selectors";
import React, { Fragment, useEffect, useState } from "react";
import { Item } from "../Videos";
import { ErrorMessage, FileInput } from "./styles";
import { colors } from "application/theme/palette";
import { OverviewSectionUploads } from "features/deal-profile/pages/OverviewSection/types";
import DealActions from "features/deal-profile/store/actions";
import { FileFormat, FilePurpose } from "features/deal-profile/store/models";
import { useParams } from "react-router-dom";
import { requestSelector } from "application/store/request/selectors";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { AttachmentType, FileType } from "integration/api/DealsApi/models";
import {
  setOverviewPitchDecks,
  setPitchDecks,
} from "features/deal-profile/store/slice";

export const PitchDeckContainer = () => {
  const { overview } = useAppSelector(dealPageSelector).profile;
  const { loading } = useAppSelector(requestSelector).uploadPitchDeck;
  const deletePitchDeck = useAppSelector(requestSelector).deletePitchDeckFile;
  const isDeleting = deletePitchDeck.loading;
  const { id } = useParams();
  const pitchDecks = overview.pitchDecks;
  const dispatch = useAppDispatch();

  const [pitchDeck, setPitchDeck] = useState<Item[]>([]);
  const [newPitchDeck, setNewPitchDeck] = useState<File>();
  const [addPitchDeckModal, setAddPitchDeckModal] = useState(false);
  const [removePitchDeckModal, setRemovePitchDeckModal] = useState(false);
  const [selectedPitchDeck, setSelectedPitchDeck] = useState<FileType>();
  const [fileSizeError, setFileSizeError] = useState(false);

  useEffect(() => {
    const pitchDeckArray = pitchDecks
      .map((e) => {
        return { id: e.id, fileName: e.originalFileName, ordinal: e.ordinal };
      })
      .sort((a, b) => {
        return (a.ordinal || 0) - (b.ordinal || 0);
      });
    setPitchDeck(pitchDeckArray);
  }, [pitchDecks.length]);

  useEffect(() => {
    if (!isDeleting) {
      setRemovePitchDeckModal(false);
    }
  }, [isDeleting]);

  useEffect(() => {
    if (!loading) {
      setAddPitchDeckModal(false);
      setNewPitchDeck(undefined);
      setFileSizeError(false);
    }
  }, [loading]);

  const setPitchDecksInStore = (array: Item[]) => {
    const pitchDecksArray = array.map((e, idx) => {
      return { id: e.id, fileName: e.fileName, ordinal: idx + 1 };
    });
    setPitchDeck(pitchDecksArray);
    const sortedPitchDecks = pitchDecksArray.map((item) => {
      const currentPitchDeck = pitchDecks.find((deck) => deck.id === item.id);
      return currentPitchDeck
        ? { ...currentPitchDeck, ordinal: item.ordinal }
        : item;
    });
    dispatch(setOverviewPitchDecks(sortedPitchDecks));
  };

  const addPitchDeck = () => {
    if (newPitchDeck) {
      dispatch(
        DealActions.uploadPitchDeck({
          file: newPitchDeck,
          type: FileFormat.pdf,
          purpose: FilePurpose.pitchDeck,
          dealId: Number(id),
        })
      ).then((res) => {
        isResponseFulfilled(res, () => {
          const payload = res.payload as { fileId: number };
          dispatch(
            setPitchDecks({
              id: payload.fileId,
              originalFileName: newPitchDeck.name,
              ordinal: pitchDecks.length + 1,
              attachmentType: AttachmentType.PDF,
              attachmentPurpose: FilePurpose.pitchDeck,
              s3FileName: "",
            })
          );
        });
      });
    }
  };

  const handleCloseModal = () => {
    setAddPitchDeckModal(false);
    setFileSizeError(false);
    setNewPitchDeck(undefined);
  };

  const handleDeletePitchDeck = (fileId: number) => {
    const pitchDeckToRemove = pitchDecks.filter((e) => e.id === fileId)[0];
    setSelectedPitchDeck(pitchDeckToRemove);
    setRemovePitchDeckModal(true);
  };

  const submitDeletingPitchDeck = () => {
    dispatch(
      DealActions.deletePitchDeck({
        dealId: Number(id),
        fileId: Number(selectedPitchDeck?.id),
      })
    );
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      const selectableMaxFileSize = 1024 * 1024 * 10;
      const file = e?.target?.files[0];
      if (file.size <= selectableMaxFileSize) {
        setNewPitchDeck(file);
        setFileSizeError(false);
      } else {
        setNewPitchDeck(undefined);
        setFileSizeError(true);
      }
    }
  };

  return (
    <Box>
      <DragAndDropArea
        header={OverviewSectionUploads.pitchDeck}
        cards={pitchDeck}
        setCards={setPitchDecksInStore}
        addCard={() => setAddPitchDeckModal(true)}
        removeCard={handleDeletePitchDeck}
        placeholder="Add pitch deck"
      />
      {addPitchDeckModal && (
        <BasicPopUp
          title="Add pitch deck"
          onClose={handleCloseModal}
          onSubmit={addPitchDeck}
          disabled={!newPitchDeck || loading}
          isLoading={loading}
        >
          <Box>
            <Fragment>
              <input
                color="primary"
                accept="application/pdf"
                type="file"
                onChange={(e) => handleFileInput(e)}
                id="icon-button-file"
                style={{ display: "none" }}
              />
              <label htmlFor="icon-button-file">
                <FileInput hasError={fileSizeError}>
                  <Box mr={1}>
                    <SaveAlt />
                  </Box>
                  <Typography
                    variant="body2"
                    color={fileSizeError ? colors.red700 : colors.grey900}
                  >
                    {newPitchDeck?.name ? newPitchDeck.name : "Upload the file"}
                  </Typography>
                </FileInput>
              </label>
            </Fragment>
            {fileSizeError && (
              <ErrorMessage>The file size is more than 10 MB</ErrorMessage>
            )}
          </Box>
        </BasicPopUp>
      )}
      {removePitchDeckModal && (
        <BasicPopUp
          title="Delete pitch deck"
          onClose={() => setRemovePitchDeckModal(false)}
          onSubmit={submitDeletingPitchDeck}
          disabled={isDeleting}
          isLoading={isDeleting}
        >
          <Box display="flex" alignItems="center">
            <Box mr={1.5}>
              <SaveAlt />
            </Box>
            <Typography variant="body2" color={theme.palette.common.grey900}>
              {selectedPitchDeck?.originalFileName}
            </Typography>
          </Box>
        </BasicPopUp>
      )}
    </Box>
  );
};
