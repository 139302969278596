import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";

import { AnalyticPageResponseDto } from "integration/api/AnalyticManagementApi/models";

import ManagementAnalyticsActions from "../store/actions";

import { DragAndDropArea, Header } from "../components";

const ManagementAnalyticsPage: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { analytics, title } = useAppSelector(
    (state) => state.managementAnalytics
  );
  const [defaultAnalyticsList, setDefaultAnalyticsList] = useState<
    AnalyticPageResponseDto[]
  >([]);

  const getAnalyticsList = async () => {
    await dispatch(
      ManagementAnalyticsActions.getAnalyticsList(Number(id))
    ).then((res) => {
      setDefaultAnalyticsList(res.payload as AnalyticPageResponseDto[]);
    });
  };

  useEffect(() => {
    getAnalyticsList();
  }, [id]);

  const uploadAnalytic = async (file: FormData) => {
    await dispatch(
      ManagementAnalyticsActions.uploadAnalytic({
        dealId: Number(id),
        file,
      })
    ).then(() => {
      getAnalyticsList();
    });
  };

  const deleteFile = async (fileId: number) => {
    await dispatch(
      ManagementAnalyticsActions.deleteAnalytic({
        dealId: Number(id),
        fileId,
      })
    ).then(() => {
      getAnalyticsList();
    });
  };

  const downloadFile = async (fileId: number) => {
    await dispatch(
      ManagementAnalyticsActions.getAnalyticByFileId({
        dealId: Number(id),
        fileId,
      })
    ).then((response) => {
      const fileName =
        analytics.find((file) => file.fileId === fileId)?.fileName || "";
      const data = response.payload as Blob;
      const fileBlob = new Blob([data], {
        type: data.type,
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", fileName);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    });
  };

  const updateAnalyticsList = async () => {
    const formattedData = analytics.map((file, index) => ({
      id: file.fileId,
      ordinal: index,
    }));
    await dispatch(
      ManagementAnalyticsActions.updateAnalyticsList({
        dealId: Number(id),
        data: formattedData,
      })
    ).then(() => {
      setDefaultAnalyticsList(analytics);
    });
  };

  const disableSaveButton = useMemo(() => {
    return JSON.stringify(analytics) === JSON.stringify(defaultAnalyticsList);
  }, [analytics, defaultAnalyticsList]);

  return (
    <>
      <Header
        title={title}
        uploadAnalytic={uploadAnalytic}
        updateAnalyticsList={updateAnalyticsList}
        disableSaveButton={disableSaveButton}
      />
      {!!analytics.length && (
        <DragAndDropArea
          files={analytics}
          deleteFile={deleteFile}
          downloadFile={downloadFile}
        />
      )}
    </>
  );
};

export default ManagementAnalyticsPage;
