import { Box, styled } from "@mui/system";
import { Grid } from "@mui/material";
import theme from "application/theme";
import { colors } from "application/theme/palette";

interface StyledProps {
  hasError?: boolean;
}

export const CropperWrapper = styled("div")`
  height: 100%;
  width: 840px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    margin-top: 20px;
    max-width: 300px;
  }
    
  .cropper-crop-box,
  .cropper-view-box {
    border-radius: 50%;
  }

  .cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
  }
`;

export const TopSectionWrapper = styled(Box)`
  display: flex;
  padding: ${theme.spacing(4)};
  background-color: ${theme.palette.common.white};
  border-radius: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(3)};
`;

export const ImageWrapper = styled(Box)`
  position: relative;
  display: flex;
  margin-right: ${theme.spacing(2)};
  img {
    position: absolute;
    border: 1px solid ${theme.palette.common.grey300};
    width: ${theme.spacing(9)};
    height: ${theme.spacing(9)};
    border-radius: 100%;
  }
`;

export const AvatarPlaceholder = styled(Box)`
  background: ${theme.palette.common.grey200};
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${theme.spacing(9)};
  height: ${theme.spacing(9)};
  border-radius: 100%;
  border: 1px solid ${theme.palette.common.grey300};
  cursor: pointer;
`;

export const LogoPopup = styled(Box)`
  cursor: default;
  position: absolute;
  z-index: 10;
  background: ${theme.palette.common.white};
  border-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(2)};
  top: ${theme.spacing(8)};
  left: ${theme.spacing(4.5)};
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 30px 0px rgba(222, 224, 230, 0.8);
`;

export const LogoPopupItem = styled(Box)`
  width: ${theme.spacing(12.5)};
  cursor: pointer;
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.08px;
  &:nth-child(2) {
    margin-top: 12px;
  }
  &:hover {
    color: ${theme.palette.common.blue700};
  }
`;

export const DeleteContainer = styled("div")`
  margin-top: 12px;
`;

export const EditLogo = styled("div")`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  width: ${theme.spacing(9)};
  height: ${theme.spacing(9)};
  svg {
    display: none;
  }
  &:hover {
    svg {
      display: block;
    }
  }
`;

export const TextContainer = styled("div")`
  position: relative;
  display: flex;
  align-items: center;

  h2 {
    color: ${colors.grey900};
    font-family: Unica77;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }

  p {
    padding: 4px 8px;
    color: ${colors.green700};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.06px;
    border-radius: 4px;
    background: ${colors.green200};
  }
`;

export const Title = styled("h6")`
  color: ${colors.grey500};
  font-family: Unica77;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.07px;
  margin-bottom: 4px;
`;

export const CopyButton = styled("button")`
  all: unset;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 2px;
  border-radius: 4px;
  border: 1.2px solid ${colors.grey200};
  background: ${colors.white};
  margin: 0 12px;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    .id-tooltip {
      display: flex;
    }
    .dynamicLink-tooltip {
      display: flex;
    }
  }
`;

export const CopyTooltip = styled("div")`
  position: absolute;
  left: 36px;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 4px;
  background: ${colors.grey700};
  //font
  color: ${colors.white};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.06px;
  z-index: 10;

  svg {
    right: 32.5px;
    position: absolute;
    width: 29px;
    height: 29px;

    path {
      fill: ${colors.grey700};
    }
  }
`;

export const CurrencyWrapper = styled("div")<StyledProps>`
  display: flex;
  flex-direction: column;

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    font-family: Unica77;
    color: ${({ hasError }) => (hasError ? colors.red700 : colors.grey500)};
    margin-bottom: 4px;
  }
`;

export const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
