import { Link } from "@mui/material";
import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const StyledLink = styled(Link)`
  font-family: Unica77;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.07px;
  cursor: pointer;
  color: ${theme.palette.common.blue900};
  text-decoration: none;
`;

export const VideoLinkWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;
