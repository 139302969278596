import React, { FC, useEffect, useState } from "react";
import {
  PersonOutlineOutlined,
  EditOutlined,
  CloseOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { PathNames } from "application/routes";
import { formatDate } from "application/helpers/formatDate";
import { formatTotal } from "application/helpers/formatTotal";
import { ReactComponent as MoneysSvg } from "application/assets/icons/moneys.svg";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { SearchParamsRoutesNames } from "application/routes/constants";
import { getNavigationState } from "application/helpers/navigationState";

import { LogoType } from "integration/api/DealsApi/models";

import DealsActions from "features/deals/store/actions";

import { DealsTableRowProps } from "./models";
import { TableRowStyled, ActionsStyled, Total, ImageContainer } from "./styles";

const DealsTableRow: FC<DealsTableRowProps> = ({ item, onDelete }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [logo, setLogo] = useState("");

  const date = formatDate(item.creationDate);
  const total = formatTotal(item.totalFundingGoal, item.investmentCurrency);

  useEffect(() => {
    if (item.logoFileId) {
      dispatch(
        DealsActions.getDealLogo({
          id: item.id,
          logoId: item.logoFileId,
          logoType: LogoType.THUMBNAIL,
        })
      ).then((response) => {
        const data = response.payload as Blob;
        const logoBlob = new Blob([data], {
          type: data.type as string,
        });
        const logoUrl = URL.createObjectURL(logoBlob);
        setLogo(logoUrl);
      });
    }
  }, [item.logoFileId]);

  const onPreview = () => {
    navigate(
      `${PathNames.deals}/${item.id}/commitments`,
      getNavigationState(SearchParamsRoutesNames.commitments)
    );
  };

  const onEdit = () => {
    navigate(
      `${PathNames.deals}/${item.id}`,
      getNavigationState(SearchParamsRoutesNames.deal)
    );
  };

  return (
    <TableRowStyled>
      <td>
        {logo && (
          <ImageContainer>
            <img src={logo} alt="table-row" />
          </ImageContainer>
        )}
      </td>
      <td>{item.title}</td>
      <td>{item.businessId}</td>
      <td>{date}</td>
      <td>
        <Total>
          <MoneysSvg />
          <p>{total}</p>
        </Total>
      </td>
      <td>
        <ActionsStyled>
          <button type="button" onClick={onPreview}>
            <PersonOutlineOutlined />
          </button>
          <button type="button" onClick={onEdit}>
            <EditOutlined />
          </button>
          <button type="button" onClick={onDelete}>
            <CloseOutlined />
          </button>
        </ActionsStyled>
      </td>
    </TableRowStyled>
  );
};

export default DealsTableRow;
