import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";

import { Button } from "application/components";
import PrimaryButton from "application/components/PrimaryButton";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { colors } from "application/theme/palette";

import { Currency, InvestmentStatus } from "integration/api/DealsApi/models";

import {
  CommitmentsTableRow,
  CommitmentsPopup,
} from "features/portfolio-investor-detail/components";
import InvestorsActions from "features/portfolio-investors/store/actions";
import { CommitmentsPopUpForm } from "features/portfolio-investor-detail/components/CommitmentsPopUp/model";
import InvestorActions from "features/portfolio-investor-detail/store/actions";

import { CommitmentsTableProps } from "./model";
import { Table, Wrapper, ButtonSection } from "./styles";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";
import LostDealPopUp from "application/containers/LostDealPopUp";

const CommitmentsTable: FC<CommitmentsTableProps> = ({
  commitments,
  isDealInvestor,
  lostReason,
}) => {
  const { id, investorId } = useParams();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(requestSelector).changeInvestorStatus;
  const [showCommitmentModal, setShowCommitmentModal] = useState(false);
  const [showCommitmentSuccessModal, setShowCommitmentSuccessModal] =
    useState(false);
  const [
    showCancelCommitmentSuccessModal,
    setShowCancelCommitmentSuccessModal,
  ] = useState(false);
  const [showLostDealModal, setShowLostDealModal] = useState(false);

  const showSuccessModal =
    showCommitmentSuccessModal || showCancelCommitmentSuccessModal;
  const successModalTitle = showCommitmentSuccessModal
    ? "Your commitments confirm"
    : "Your commitments canceled";

  const canConfirmCommitments = commitments.some(
    (item) => item.status !== InvestmentStatus.CONFIRMED
  );
  const canCancelCommitments = commitments.some(
    (item) => item.status !== InvestmentStatus.CANCELED
  );

  const totalCommitmentsAmount = commitments.reduce((acc, curr) => {
    return (acc = curr.totalCommitment + acc);
  }, 0);
  const commitmentsCurrency = commitments.length
    ? commitments[0].currency
    : Currency.USD;

  const handleOpenCommitmentModal = () => {
    setShowCommitmentModal(true);
  };

  const handleCloseCommitmentModal = () => {
    setShowCommitmentModal(false);
  };

  const handleCloseCommitmentSuccessClose = () => {
    setShowCommitmentSuccessModal(false);
    setShowCancelCommitmentSuccessModal(false);
  };

  const getInvestor = () => {
    dispatch(
      InvestorActions.getInvestorById({
        dealId: Number(id),
        investorId: Number(investorId),
      })
    );
  };

  const handleOpenLostDealModal = () => {
    setShowLostDealModal(true);
  };

  const handleCloseLostDealModal = () => {
    setShowLostDealModal(false);
  };

  const handleCancelCommitments = (lostReason: string) => {
    dispatch(
      InvestorsActions.changeInvestorStatus({
        id: Number(id),
        investorId: Number(investorId),
        commitmentStatus: InvestmentStatus.CANCELED,
        lostReason: lostReason,
      })
    ).then((res) => {
      isResponseFulfilled(res, () => {
        setShowCancelCommitmentSuccessModal(true);
        getInvestor();
      });
    });
  };

  const handleConfirmCommitments = async (data: CommitmentsPopUpForm) => {
    if (loading) return;
    await dispatch(
      InvestorsActions.changeInvestorStatus({
        id: Number(id),
        investorId: Number(investorId),
        commitmentStatus: InvestmentStatus.CONFIRMED,
        ...data,
      })
    ).then((res) => {
      isResponseFulfilled(res, () => {
        setShowCommitmentSuccessModal(true);
        handleCloseCommitmentModal();
        getInvestor();
      });
    });
  };

  return (
    <>
      <Wrapper>
        <Table>
          <thead>
            <tr>
              <th>Commitment</th>
              <th>Overcommitment</th>
              <th>Total commitment</th>
              <th>Invested date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {commitments.map((commitment) => (
              <CommitmentsTableRow
                key={commitment.id}
                commitment={commitment}
              />
            ))}
          </tbody>
        </Table>
      </Wrapper>
      {!isDealInvestor && (
        <ButtonSection>
          <Button
            variant="outlined"
            label="Cancel commitment"
            onClick={handleOpenLostDealModal}
            disabled={!canCancelCommitments}
          />
          <PrimaryButton
            loading={false}
            onClick={handleOpenCommitmentModal}
            title="Confirm commitment"
            disabled={!canConfirmCommitments}
          />
        </ButtonSection>
      )}
      {showCommitmentModal && (
        <CommitmentsPopup
          title="Your commitments confirm"
          onClose={handleCloseCommitmentModal}
          onSubmit={handleConfirmCommitments}
          defaultValues={{
            investmentAmount: totalCommitmentsAmount,
            currency: commitmentsCurrency,
          }}
        />
      )}
      {showSuccessModal && (
        <SuccessPopUp
          title={successModalTitle}
          onSubmit={handleCloseCommitmentSuccessClose}
          iconColor={showCancelCommitmentSuccessModal ? colors.red700 : ""}
        />
      )}
      {showLostDealModal && (
        <LostDealPopUp
          defaultValue={lostReason}
          isLoading={loading}
          onClose={handleCloseLostDealModal}
          onSubmit={handleCancelCommitments}
        />
      )}
    </>
  );
};

export default CommitmentsTable;
