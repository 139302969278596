import React, { FC } from "react";

import { useAppSelector } from "application/hooks/useAppSelector";

import InvestorsTableRow from "features/companies/components/InvestorsTableRow";

import { TableStyled, Wrapper } from "./styles";

const InvestorsTable: FC = () => {
  const { investors } = useAppSelector((state) => state.companyList);

  return (
    <>
      <Wrapper>
        <TableStyled>
          <thead>
            <tr>
              <th>First name</th>
              <th>Last name</th>
              <th>Investment amount</th>
              <th>Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {investors.content.map((item) => (
              <InvestorsTableRow key={item.id} item={item} />
            ))}
          </tbody>
        </TableStyled>
      </Wrapper>
    </>
  );
};

export default InvestorsTable;
