import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { getEditAmountSchema } from "./validation";
import { Currency, DealTractionAmount } from "integration/api/DealsApi/models";

import { TextFieldStyled } from "application/components";
import PrimaryButton from "application/components/PrimaryButton";
import CurrencySelect from "application/components/CurrencySelect";

import { Card, Title, Wrapper, Actions, CancelButton } from "./styles";

interface Props {
  title: string;
  hideCurrency?: boolean;
  defaultValues?: DealTractionAmount;
  onClose: () => void;
  onSubmit: (data: DealTractionAmount) => void;
}

const formatter = new Intl.NumberFormat("en-US");

const EditAmountPopUp = ({
  title,
  hideCurrency,
  defaultValues,
  onClose,
  onSubmit,
}: Props) => {
  const EditRowSchema = getEditAmountSchema(hideCurrency);

  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<DealTractionAmount>({
    defaultValues: {
      ...defaultValues,
      amount: defaultValues?.amount || 0,
      currency: defaultValues?.currency || undefined,
    },
    resolver: yupResolver(EditRowSchema),
  });

  const values = watch();

  const onAmountChange = (value: string) => {
    const numberValue = Number(value.replaceAll(",", ""));
    if (Number.isNaN(numberValue)) return;
    setValue("amount", numberValue, { shouldValidate: !!errors.amount });
  };

  const onCurrencyChange = (currency: Currency) => {
    setValue("currency", currency, { shouldValidate: true });
  };

  return (
    <Wrapper>
      <Card>
        <Title>Edit {title}</Title>

        <TextFieldStyled
          mb={20}
          fullWidth
          id="amount"
          size="small"
          color="secondary"
          label="Enter amount"
          filled={values.amount}
          error={!!errors.amount}
          value={
            watch("amount") ? formatter.format(Number(watch("amount"))) : ""
          }
          onChange={(e) => {
            onAmountChange(e.target.value);
          }}
        />

        {!hideCurrency && (
          <CurrencySelect
            maxHeight={300}
            selected={values.currency}
            onChange={onCurrencyChange}
            placeholder="Choose currency"
            error={!!errors.currency?.message}
          />
        )}

        <Actions>
          <CancelButton onClick={onClose}>
            <p>Cancel</p>
          </CancelButton>

          <PrimaryButton
            title="Ok"
            disabled={!isValid}
            onClick={handleSubmit(onSubmit)}
          />
        </Actions>
      </Card>
    </Wrapper>
  );
};

export default EditAmountPopUp;
