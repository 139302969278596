import { styled } from "@mui/system";


export const CropperWrapper = styled("div")`
  height: 100%;
  width: 840px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    margin-top: 20px;
    max-width: 300px;
  }
    
  .cropper-crop-box,
  .cropper-view-box {
    border-radius: 50%;
  }

  .cropper-view-box {
    box-shadow: 0 0 0 1px #39f;
    outline: 0;
  }
`;