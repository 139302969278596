import { styled } from "@mui/system";
import { colors } from "application/theme/palette";

interface InputProps {
  hasError?: boolean;
}

export const Wrapper = styled("div")`
  display: flex;
  position: relative;
  width: 100%;
`;

export const Input = styled("input")<InputProps>`
  height: 44px;
  width: 100%;
  outline: none;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid
    ${({ hasError }) => (hasError ? colors.error : colors.grey300)};

  /* font */
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  font-style: normal;
  letter-spacing: 0.08px;
  font-family: Unica77;
  color: ${colors.grey700};
  background-color: ${({ hasError }) =>
    hasError ? colors.errorBg : colors.grey50};

  &:focus {
    background-color: ${({ hasError }) =>
      hasError ? colors.errorBg : colors.blue50};
    border: 1px solid
      ${({ hasError }) => (hasError ? colors.error : colors.blue700)};
  }
`;

export const List = styled("div")`
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  top: 48px;
  z-index: 2;
  position: absolute;
  border-radius: 4px;
  background: ${colors.white};
  box-shadow: 0px 4px 30px 0px rgba(222, 224, 230, 0.8);
`;

export const ListItem = styled("div")`
  padding: 6px 16px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey100};
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    font-style: normal;
    font-family: "Unica77";
    letter-spacing: 0.08px;
    color: ${colors.grey900};
  }
  svg {
    width: 24px;
    height: 24px;
    color: ${colors.grey500};
  }
`;
