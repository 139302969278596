import React, { FC } from "react";
import { CallMade } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { PathNames } from "application/routes";
import { formatDate } from "application/helpers/formatDate";
import { formatTotal } from "application/helpers/formatTotal";
import { ReactComponent as MoneysSvg } from "application/assets/icons/moneys.svg";
import { getKYCStatusTitle } from "application/helpers/kycStatuses";
import { SearchParamsRoutesNames } from "application/routes/constants";
import { getNavigationState } from "application/helpers/navigationState";

import { InvestorsTableRowProps } from "./models";
import { TableRowStyled, ActionsStyled, Total, StatusStyled } from "./styles";

const InvestorsTableRow: FC<InvestorsTableRowProps> = ({ item }) => {
  const navigate = useNavigate();

  const date = formatDate(item.registrationDate);
  const total = formatTotal(item.investmentAmount || 0, item.currency);

  const onPreview = () => {
    navigate(
      `${PathNames.users}/${item.id}/detail`,
      getNavigationState(SearchParamsRoutesNames.userDetails)
    );
  };

  return (
    <TableRowStyled>
      <td>{item.firstName}</td>
      <td>{item.lastName}</td>
      <td>
        <Total>
          <MoneysSvg />
          <p>{total}</p>
        </Total>
      </td>
      <td>{date}</td>
      <td>
        <StatusStyled status={item.kycStatus}>
          {getKYCStatusTitle(item.kycStatus)}
        </StatusStyled>
      </td>
      <td>
        <ActionsStyled>
          <button type="button" onClick={onPreview}>
            <CallMade />
          </button>
        </ActionsStyled>
      </td>
    </TableRowStyled>
  );
};

export default InvestorsTableRow;
