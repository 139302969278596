import { Box } from "@mui/system";
import React, { useState } from "react";
import { ClickAwayListener, Typography } from "@mui/material";

import { colors } from "application/theme/palette";
import { Currency } from "integration/api/DealsApi/models";
import { getCurrencyTitle } from "application/helpers/currency";

import {
  SelectButton,
  DropdownItem,
  DropdownStyle,
  SelectContainer,
} from "./styles";
import { ArrowDropUp } from "application/assets/ArrowDropUp";
import { ArrowDropDown } from "application/assets/ArrowDropDown";

export interface CustomSelectI {
  error?: boolean;
  maxHeight: number;
  placeholder: string;
  selected?: Currency | null;
  onChange: (value: Currency) => void;
}

const CurrencySelect = ({
  error,
  selected,
  maxHeight,
  placeholder,
  onChange,
}: CustomSelectI) => {
  const [open, setOpen] = useState(false);

  const toggleSelect = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (value: Currency) => {
    onChange(value);
    handleClose();
  };

  const currencyArray = Object.keys(Currency) as Currency[];

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <SelectContainer>
        <SelectButton
          error={error ? 1 : 0}
          active={open ? 1 : 0}
          onClick={toggleSelect}
          filled={selected ? 1 : 0}
        >
          <Typography
            mr={1}
            variant="body2"
            color={error ? colors.error : colors.grey900}
          >
            {selected ? getCurrencyTitle(selected) : placeholder}
          </Typography>
          {open ? (
            <ArrowDropUp color={colors.grey500} />
          ) : (
            <ArrowDropDown color={colors.grey500} />
          )}
        </SelectButton>
        <DropdownStyle visible={open ? 1 : 0} maxHeight={open ? maxHeight : 0}>
          <Box px={1.5} bgcolor={colors.white}>
            {currencyArray.map((item) => (
              <DropdownItem
                onClick={() => handleChange(item)}
                active={item === selected ? 1 : 0}
                key={item}
              >
                {getCurrencyTitle(item)}
              </DropdownItem>
            ))}
          </Box>
        </DropdownStyle>
      </SelectContainer>
    </ClickAwayListener>
  );
};

export default CurrencySelect;
