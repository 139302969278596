import React from "react";

import {
  TractionAmountKey,
  TractionMultiTableKey,
  TractionSingleTableKey,
} from "features/deal-profile/store/models";

import TractionNPS from "features/deal-profile/containers/TractionNPS";
import TractionAmount from "features/deal-profile/containers/TractionAmount";
import TractionMultiData from "features/deal-profile/containers/TractionMultiData";
import TractionSingleData from "features/deal-profile/containers/TractionSingleData";

import { Title } from "./styles";
import TractionOverview from "features/deal-profile/containers/TractionOverview";

const TractionSection = () => {
  return (
    <React.Fragment>
      <Title variant="h2">Traction</Title>
      <TractionSingleData
        title="EBITDA"
        tractionKey={TractionSingleTableKey.ebitda}
      />
      <TractionSingleData
        title="Revenue"
        tractionKey={TractionSingleTableKey.revenue}
      />
      <TractionSingleData
        title="ARR"
        tractionKey={TractionSingleTableKey.arr}
      />

      <TractionMultiData title="MRR" tractionKey={TractionMultiTableKey.mrr} />
      <TractionMultiData title="GMV" tractionKey={TractionMultiTableKey.gmv} />

      <TractionAmount
        title="Life-time Value (LTV)"
        amountKey={TractionAmountKey.ltv}
      />
      <TractionAmount
        title="Customer Acquisition Cost (CAC)"
        amountKey={TractionAmountKey.cac}
      />
      <TractionAmount
        title="Average Order Value (AOV)"
        amountKey={TractionAmountKey.aov}
      />
      <TractionNPS />
      <TractionOverview />
    </React.Fragment>
  );
};

export default TractionSection;
