import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import UsersActions from "features/users/store/actions";
import { UsersSliceAction } from "features/users/store/slice";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import Loader from "application/components/Loader";
import { colors } from "application/theme/palette";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";
import { SearchParamsRoutesNames } from "application/routes/constants";
import { getNavigationState } from "application/helpers/navigationState";

import ConfirmPopUp from "application/containers/ConfirmPopUp";

import {
  UserDetailHeader,
  UserInformation,
  SurveyInformation,
} from "features/users/components";

import { LoaderContainer } from "./styles";
import { usersSelector } from "features/users/store/selectors";

const UserDetails: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selected } = useAppSelector(usersSelector);
  const {
    deleteUser,
    updateUserEmail,
    updateUserName,
    updateUserPhone,
    getInvestorById,
    generateUserPassword,
    updateSurveyInformation,
  } = useAppSelector(requestSelector);

  const loading =
    deleteUser.loading ||
    generateUserPassword.loading ||
    updateUserEmail.loading ||
    updateUserName.loading ||
    updateUserPhone.loading ||
    getInvestorById.loading ||
    updateSurveyInformation.loading;

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const userFullName = `${selected?.firstName} ${selected?.lastName}`;

  const onDelete = () => {
    setShowDeleteModal(true);
  };

  const onCloseDelete = () => {
    setShowDeleteModal(false);
  };

  const onSubmitDelete = () => {
    const path = (window?.history?.state?.usr?.userDetails || -1) as string;
    dispatch(UsersActions.deleteInvestor(Number(id))).then(() => {
      setShowDeleteModal(false);
      navigate(path, getNavigationState(SearchParamsRoutesNames.userDetails));
    });
  };

  useEffect(() => {
    if (id) {
      dispatch(UsersActions.getInvestorById(Number(id)));
    }
    return () => {
      dispatch(UsersSliceAction.clearSelected());
    };
  }, [id]);

  return (
    <React.Fragment>
      {loading && (
        <LoaderContainer>
          <Loader color={colors.grey700} />
        </LoaderContainer>
      )}
      <UserDetailHeader onDelete={onDelete} />
      <UserInformation />
      <SurveyInformation />
      {showDeleteModal && (
        <ConfirmPopUp
          title="Delete user"
          description={`You want to delete ${userFullName}. Are you sure?`}
          onClose={onCloseDelete}
          onSubmit={onSubmitDelete}
        />
      )}
    </React.Fragment>
  );
};

export default UserDetails;
