import { Link } from "@mui/material";
import { Box, styled } from "@mui/system";
import theme from "application/theme";
import { colors } from "application/theme/palette";

interface StyledProps {
  isMemberResponsible: boolean;
}

export const StyledLink = styled(Link)`
  font-family: Unica77;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.07px;
  cursor: pointer;
  color: ${theme.palette.common.blue900};
  text-decoration: none;
`;

export const TeamMemberWrapper = styled(Box)`
  display: flex;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const ImageContainer = styled("div")`
  display: flex;
  margin-right: 12px;
  img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 1px solid ${colors.grey100};
  }
`;

export const Responsibility = styled("div")<StyledProps>`
  display: flex;
  align-items: center;
  margin-top: 4px;

  p {
    color: ${colors.grey900};
    font-family: Unica77;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    path {
      fill: ${({ isMemberResponsible }) =>
        isMemberResponsible ? colors.grey500 : colors.red700};
    }
  }
`;

export const MemberLogo = styled("div")`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: ${colors.grey800};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  font-style: normal;
  color: ${colors.white};
  letter-spacing: 0.08px;
  font-family: Unica77;
`;
