import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

export const Container = styled.div`
  width: 100%;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${colors.white};
  border: 1px solid ${colors.grey200};
  cursor: pointer;

  p {
    color: ${colors.grey900};
    text-align: center;
    font-family: "Unica77";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
    &:first-of-type {
      text-decoration-line: underline;
    }
  }
`;
