import { styled } from "@mui/system";

import theme from "application/theme";

export const HeaderStyled = styled("header")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${theme.spacing(4)};

  h1 {
    color: ${theme.palette.common.grey900};
    font-size: ${theme.spacing(4)};
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

export const Container = styled("div")`
  display: flex;
  align-items: center;
  gap: 12px;
`;
