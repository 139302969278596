import React, { FC, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";

import {
  AdminAnalyticResponse,
  CompanyAnalyticAdminResponse,
} from "integration/api/CompanyApi/models";

import {
  DragAndDropAreaAnalytics,
  ManagementAnalyticsHeader,
} from "features/companies/components";
import CompanyListActions from "features/companies/store/actions";

import { Wrapper } from "./styles";

const CompanyManagementAnalyticsPage: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { analytics, companyName } = useAppSelector(
    (state) => state.companyList
  );
  const [defaultAnalyticsList, setDefaultAnalyticsList] = useState<
    AdminAnalyticResponse[]
  >([]);

  const getAnalyticsList = async () => {
    await dispatch(CompanyListActions.getCompanyAnalyticsList(Number(id))).then(
      (res) => {
        const data = res.payload as CompanyAnalyticAdminResponse;
        setDefaultAnalyticsList(
          data.adminAnalyticResponses as AdminAnalyticResponse[]
        );
      }
    );
  };

  useEffect(() => {
    getAnalyticsList();
  }, [id]);

  const uploadAnalytic = async (file: FormData) => {
    await dispatch(
      CompanyListActions.uploadCompanyAnalytic({
        id: Number(id),
        file,
      })
    ).then(() => {
      getAnalyticsList();
    });
  };

  const deleteFile = async (fileId: number) => {
    await dispatch(
      CompanyListActions.deleteCompanyAnalytic({
        id: Number(id),
        fileId,
      })
    ).then(() => {
      getAnalyticsList();
    });
  };

  const downloadFile = async (fileId: number) => {
    await dispatch(
      CompanyListActions.getCompanyAnalyticByFileId({
        id: Number(id),
        fileId,
      })
    ).then((response) => {
      const fileName =
        analytics?.find((file) => file.fileId === fileId)?.fileName || "";
      const data = response.payload as Blob;
      const fileBlob = new Blob([data], {
        type: data.type,
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", fileName);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    });
  };

  const updateAnalyticsList = async () => {
    const formattedData = analytics?.map((file, index) => ({
      id: file.fileId,
      ordinal: index,
    }));
    await dispatch(
      CompanyListActions.updateCompanyAnalyticsList({
        id: Number(id),
        data: formattedData,
      })
    ).then(() => {
      setDefaultAnalyticsList(analytics);
    });
  };

  const disableSaveButton = useMemo(() => {
    return JSON.stringify(analytics) === JSON.stringify(defaultAnalyticsList);
  }, [analytics, defaultAnalyticsList]);

  return (
    <Wrapper>
      <ManagementAnalyticsHeader
        companyName={companyName}
        disableSaveButton={disableSaveButton}
        updateAnalyticsList={updateAnalyticsList}
        uploadAnalytic={uploadAnalytic}
      />
      {!!analytics?.length && (
        <DragAndDropAreaAnalytics
          files={analytics}
          deleteFile={deleteFile}
          downloadFile={downloadFile}
        />
      )}
    </Wrapper>
  );
};

export default CompanyManagementAnalyticsPage;
