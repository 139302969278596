import React, { FC } from "react";

import { ReactComponent as MoneysSvg } from "application/assets/icons/moneys.svg";

import { formatTotal } from "application/helpers/formatTotal";
import { formatDealTableDate } from "features/deal-investors/helpers/date";

import { Status, Total } from "./styles";
import { CommitmentsTableRowProps } from "./model";

const CommitmentsTableRow: FC<CommitmentsTableRowProps> = ({ commitment }) => {
  const status = commitment.status.toLowerCase();
  const date = formatDealTableDate(commitment.investedDate);
  const commitmentAmount = formatTotal(
    commitment.commitment,
    commitment.currency
  );
  const overCommitment = formatTotal(
    commitment.overcommitment,
    commitment.currency
  );
  const totalCommitment = formatTotal(
    commitment.totalCommitment,
    commitment.currency
  );

  return (
    <tr>
      <td>
        <Total>
          <MoneysSvg />
          <p>{commitmentAmount}</p>
        </Total>
      </td>
      <td>
        <Total>
          <MoneysSvg />
          <p>{overCommitment}</p>
        </Total>
      </td>
      <td>
        <Total>
          <MoneysSvg />
          <p>{totalCommitment}</p>
        </Total>
      </td>
      <td>{date}</td>
      <td>
        <Status status={commitment.status}>{status}</Status>
      </td>
    </tr>
  );
};

export default CommitmentsTableRow;
