import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const DragAndDropWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.palette.common.grey200};
  border-radius: 8px;
  padding: 32px 20px;
  background-color: ${theme.palette.common.white};
`;

export const EmptyAreaWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.palette.common.grey200};
  border-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(4, 2.5)};
  text-decoration: underline;
`;

export const Placeholder = styled(Box)`
  color: ${theme.palette.common.grey900};
  text-align: center;
  font-family: Unica77;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.07px;
  text-decoration-line: underline;
  cursor: pointer;
  width: fit-content;
`;

export const CommonIconWrapper = styled(Box)`
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.palette.common.grey200};
  border-radius: ${theme.spacing(0.5)};
  cursor: pointer;
`;
