import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  FundraisingStatus,
  ChangeInvestmentParams,
} from "integration/api/PortfolioAPI/models";
import { validate } from "./helpers";
import { Currency, InvestmentStatus } from "integration/api/DealsApi/models";
import { formatTotal } from "application/helpers/formatTotal";
import { CommitmentsPopUpForm } from "../CommitmentsPopUp/model";
import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { requestSelector } from "application/store/request/selectors";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import InvestorActions from "features/portfolio-investor-detail/store/actions";
import { investmentSelector } from "features/portfolio-investor-detail/store/selectors";
import { fundraisingStatuses } from "features/portfolio-investor-detail/helpers/constants";
import InvestorsActions from "features/portfolio-investors/store/actions";

import CommitmentsPopUp from "../CommitmentsPopUp";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { Button, SelectWithText } from "application/components";
import PrimaryButton from "application/components/PrimaryButton";
import LostDealPopUp from "application/containers/LostDealPopUp";
import { PathNames } from "application/routes";

import {
  Container,
  ButtonSection,
  InvestmentAmount,
  LostButton,
} from "./styles";

const Investments = () => {
  const { id, investorId } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const data = useAppSelector(investmentSelector);
  const investor = useAppSelector((state) => state.portfolioInvestor).investor;
  const { loading } = useAppSelector(requestSelector).changeInvestment;

  const [showEditModal, setShowEditModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showLostDealModal, setShowLostDealModal] = useState(false);
  const [showLostDealSuccessModal, setShowLostDealSuccessModal] =
    useState(false);

  const [amount, setAmount] = useState(data?.investmentAmount || 0);
  const [status, setStatus] = useState(
    data?.fundraisingStatus || FundraisingStatus.NOT_STARTED
  );

  const total = formatTotal(amount, data?.currency || Currency.USD);
  const isValid = data ? validate(data, { status, amount }) : false;
  const isDealInvestor = location.pathname.includes(PathNames.deals);

  const getInvestorById = () => {
    if (id && investorId)
      if (isDealInvestor) {
        dispatch(
          InvestorActions.getDealInvestorById({
            dealId: Number(id),
            investorId: Number(investorId),
          })
        );
      } else {
        dispatch(
          InvestorActions.getInvestorById({
            dealId: Number(id),
            investorId: Number(investorId),
          })
        );
      }
  };

  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const toggleSuccessModal = () => {
    setShowSuccessModal(!showSuccessModal);
  };

  const onChangeStatus = (value: string | number) => {
    setStatus(value as FundraisingStatus);
  };

  const onSubmitEdit = (data: CommitmentsPopUpForm) => {
    setAmount(data.investmentAmount);
    toggleEditModal();
  };

  const handleReset = () => {
    setAmount(data?.investmentAmount || 0);
    setStatus(data?.fundraisingStatus || FundraisingStatus.NOT_STARTED);
  };

  const handleSubmit = () => {
    const params: ChangeInvestmentParams = {
      id: Number(id),
      investmentId: data?.id!,
      investmentAmount: amount,
      fundraisingStatus: status,
    };
    dispatch(InvestorActions.changeInvestment(params)).then((response) => {
      isResponseFulfilled(response, toggleSuccessModal);
    });
  };

  const handleOpenLostDealModal = () => {
    setShowLostDealModal(true);
  };

  const handleCloseLostDealModal = () => {
    setShowLostDealModal(false);
  };

  const onLostDealSuccessClose = () => {
    setShowLostDealSuccessModal(false);
  };

  const onSubmitLostDeal = (lostReason: string) => {
    dispatch(
      InvestorsActions.changeInvestorStatus({
        id: Number(id),
        investorId: Number(investorId),
        commitmentStatus: InvestmentStatus.CANCELED,
        lostReason: lostReason,
      })
    ).then((res) => {
      isResponseFulfilled(res, () => {
        setShowLostDealModal(false);
        setShowLostDealSuccessModal(true);
        getInvestorById();
      });
    });
  };

  return (
    <Container>
      <SelectWithText
        maxHeight={300}
        selected={status}
        onChange={onChangeStatus}
        placeholder="Set status to"
        options={fundraisingStatuses}
        helperText="Fundraising status:"
      />
      <InvestmentAmount onClick={toggleEditModal}>{total}</InvestmentAmount>

      <ButtonSection>
        <Button
          variant="text"
          label="Cancel"
          disabled={!isValid}
          onClick={handleReset}
        />
        <PrimaryButton
          loading={loading}
          disabled={!isValid}
          title="Save changes"
          onClick={handleSubmit}
        />
        <LostButton type="button" onClick={handleOpenLostDealModal}>
          Lost deal
        </LostButton>
      </ButtonSection>

      {showEditModal && (
        <CommitmentsPopUp
          hideCurrency
          onSubmit={onSubmitEdit}
          onClose={toggleEditModal}
          title="Edit investment amount"
          defaultValues={{
            investmentAmount: amount,
            currency: data?.currency || Currency.USD,
          }}
        />
      )}
      {showSuccessModal && (
        <SuccessPopUp
          onSubmit={toggleSuccessModal}
          title="Investment was successfully updated."
        />
      )}
      {showLostDealModal && (
        <LostDealPopUp
          defaultValue={investor.investment?.lostReason}
          isLoading={loading}
          onClose={handleCloseLostDealModal}
          onSubmit={onSubmitLostDeal}
        />
      )}
      {showLostDealSuccessModal && (
        <SuccessPopUp
          title="Deal status changed to lost"
          onSubmit={onLostDealSuccessClose}
        />
      )}
    </Container>
  );
};

export default Investments;
