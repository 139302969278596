import { UserStatus } from "application/helpers/userStatuses";
import { FundraisingStatus } from "../PortfolioAPI/models";
import { CountryDto, KYCStatus } from "../UsersApi/models";

export interface GetDealsDto {
  page: number;
  size?: number;
  sort?: DealSort;
  search?: string;
}

export interface DeleteFileParams {
  fileId: number | string;
  dealId: number;
}

export enum DealSort {
  asc = "creationDate,asc",
  desc = "creationDate,desc",
}

export interface GetDealsResponseDto {
  totalPages: number;
  content: DealReducedDto[];
}

export interface DealReducedDto {
  id: number;
  title: string;
  logoFileId: number;
  status: DealStatus;
  businessId: string;
  creationDate: string;
  totalFundingGoal: number;
  investmentCurrency: Currency;
}

export enum Currency {
  USD = "USD",
  EURO = "EURO",
  POUNDS = "POUNDS",
  SWISS_FRANCS = "SWISS_FRANCS",
}

export enum DealStatus {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  MOVED_TO_PORTFOLIO = "MOVED_TO_PORTFOLIO",
}

export enum InvestmentStatus {
  PENDING = "PENDING",
  CONFIRMED = "CONFIRMED",
  CANCELED = "CANCELED",
}

export enum ValuationType {
  POST_MONEY = "POST_MONEY",
  PRE_MONEY = "PRE_MONEY",
}

export enum AttachmentType {
  IMAGE = "IMAGE",
  PDF = "PDF",
}

export interface CreateDealResponseDto {
  id: number;
  businessId: string;
  creationDate: string;
}

export interface DealCreatedBy {
  id: number;
  firstName: string;
  authUserId: string;
  lastName: string;
  email: string;
  creationDate: string;
  deleted: boolean;
  deletedDate: string;
}

export interface DealDeletedBy {
  id: number;
  firstName: string;
  authUserId: string;
  lastName: string;
  email: string;
  creationDate: string;
  deleted: boolean;
  deletedDate: string;
}

export interface DealTotalFundingGoal {
  amount: number | null;
  currency: Currency;
  commitmentDeadline: string;
}

export interface VideoType {
  id: number;
  ordinality: number;
  url: string;
  deepDiveVideo: boolean;
}

export interface FileType {
  id: number;
  ordinal: number;
  attachmentType: AttachmentType;
  attachmentPurpose: string;
  originalFileName: string;
  s3FileName: string;
}

export interface TeamType {
  id: number | string;
  ordinality: number;
  firstName?: string;
  lastName?: string;
  title?: string;
  linkedinUrl?: string;
  url?: string;
  imageUrl?: string;
  imageName?: string;
  isResponsibleForQa?: boolean;
  base64Image?: string | null;
  imageDeleted?: boolean;
}

export interface DealOverview {
  shortDescription: string;
  description: string;
  problem: string;
  solution: string;
  market: string;
  videos: VideoType[];
  team: TeamType[];
  pitchDecks: FileType[];
  additionalMaterials: FileType[];
}

export interface DealDetails {
  commitmentDeadline: string;
  availableAllocation: {
    amount: number | string | null;
    currency: Currency;
  };
  committedAllocationFromTaVentures: {
    amount: number | string | null;
    currency: Currency;
  };
  valuation: {
    type: ValuationType;
    amount: number | string | null;
    currency: Currency;
  };
  totalRoundSize: {
    amount: number | string | null;
    currency: Currency;
  };
}

export enum MetricType {
  FACT = "FACT",
  FORECAST = "FORECAST",
}

export enum LogoType {
  ORIGINAL = "ORIGINAL",
  THUMBNAIL = "THUMBNAIL",
}

export interface DealTractionAmount {
  amount: number | null;
  currency: Currency | null;
}

export interface DealTractionType {
  id?: number | string;
  amount: number | null;
  currency: Currency | null;
  tractionYear: number | null;
  metricType: MetricType | null;
}

export interface DealTractionMultiType {
  id?: number | string;
  amount: number | null;
  currency: Currency | null;
  tractionYear: number | null;
  tractionMonth?: number | null;
  metricType: MetricType | null;
}

export interface NewsletterPictureDto {
  id: number;
  ordinal: number;
  attachmentType: AttachmentType;
  attachmentPurpose: string;
  originalFileName: string;
  s3FileName: string;
}

export interface NotificationsDataDto {
  strengths: string;
  risks: string;
  linkToQACall: string;
  notificationText: string;
  newsletterPicture: NewsletterPictureDto | null;
  callWithFounderDate: string;
}

export interface DealDto {
  id: number;
  title: string;
  businessId: string;
  logoFileId: number;
  content: string;
  creationDate: string;
  activationDate: string;
  createdBy: DealCreatedBy;
  deletedBy: DealDeletedBy;
  deletionDate: string;
  status: DealStatus;
  investmentCurrency: Currency;
  minInvestmentAmount: number;
  broadCategoryId: number;
  stageId: number;
  businessModelId: number;
  totalFundingGoal: DealTotalFundingGoal;
  overview: DealOverview;
  ebitda: DealTractionType[];
  revenue: DealTractionType[];
  arr: DealTractionType[];
  mrr: DealTractionMultiType[];
  gmv: DealTractionMultiType[];
  ltv: DealTractionAmount;
  cac: DealTractionAmount;
  aov: DealTractionAmount;
  nps: number | null;
  tractionOverview: string | null;
  details: DealDetails;
  notificationsData: NotificationsDataDto | null;
  dealCurrency: Currency;
  foundedYear: string;
  website: string;
  countryId: number | null;
  companyId: number;
  copyLogoFromCompany: false;
  dynamicLink: string;
  dealUrl: string;
}

export interface TechnologyDto {
  id: number;
  name: string;
  sector: string;
}

export interface StageDto {
  id: number;
  name: string;
}

export interface IndustryDto {
  id: number;
  name: string;
  sector: string;
}

export interface BusinessModelDto {
  id: number;
  name: string;
}

export interface BroadCategoryDto {
  id: number;
  name: string;
}

export interface GetDealLogoParams {
  id: number;
  logoId: number;
  logoType: LogoType;
}

export interface DeleteDealLogoParams {
  id: number;
  logoId: number;
}

export interface UploadDealLogoParams {
  id: number;
  data: FormData;
}

export interface UploadDealLogoDto {
  fileId: number;
}

export interface DealInvestorsParamsDto {
  dealId: number;
  page: number;
  size?: number;
  sort?: DealSort;
}

export interface DealInvestorsParamsAPI extends DealInvestorsParamsDto {
  key: string;
}

interface SortDto {
  empty: boolean;
  sorted?: boolean;
  unsorted?: boolean;
}
interface PageableDto {
  offset: number;
  sort: SortDto;
  pageNumber: number;
  pageSize: number;
  paged: true;
  unpaged: true;
}

interface PageDto {
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  sort: SortDto;
  numberOfElements: number;
  pageable: PageableDto;
  first: true;
  last: true;
  empty: true;
}

export interface DealInvestorDto {
  id: number;
  firstName: string;
  lastName: string;
  investmentAmount: number;
  status: InvestmentStatus;
  investmentCurrency: Currency;
  date: string;
  lostReason: string;
}

export interface PageDealInvestorDto extends PageDto {
  content: DealInvestorDto[];
}

export interface DealInvestorResponse {
  dealStatus: DealStatus;
  investorsPage: PageDealInvestorDto;
  dealImported: boolean;
}

export interface InvestorParams {
  dealId: number;
  investorId: number;
}

export interface InvestorCommitmentsResponse {
  commitment: number;
  overcommitment: number;
  totalCommitment: number;
  currency: Currency;
  investedDate: string;
  status: InvestmentStatus;
  id: number;
}
export interface InvestmentDto {
  id: number;
  investmentAmount: number;
  currency: Currency | null;
  fundraisingStatus: FundraisingStatus;
  lostReason: string;
}

export interface InvestmentAnalyticsCurrentDto {
  investmentAmount: number;
  currency: Currency;
  fmvChangeDate: string;
  fmvValue: number;
  totalRealizedProfit: number;
  multiple: number;
}

export interface InvestmentAnalyticsHistoryDto {
  fmvChangeDate: string;
  fmvValue: number;
  realizedProfit: number;
  currency: Currency;
  multiple: number;
  id: number;
}

export interface InvestmentAnalyticsDto {
  current: InvestmentAnalyticsCurrentDto;
  history: InvestmentAnalyticsHistoryDto[];
}

export interface InvestorDetailsResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  registrationDate: string;
  dealTitle: string;
  country: CountryDto;
  kycStatus: KYCStatus;
  kycStatusDate: string;
  commitments: InvestorCommitmentsResponse[];
  investment: InvestmentDto | null;
  userStatus: UserStatus;
  deletedDate?: string;
  investmentAnalytics: InvestmentAnalyticsDto | null;
  dealCurrency: Currency;
}
export interface CreateCommitmentResponse {
  commitmentId: string;
}

export interface CreateCommitmentDto {
  dealId: number;
  investorId: number;
  investmentAmount: number;
}
export interface SendTestEmailParams {
  id: number;
  email: string;
}

export interface GetAttachmentFilePrams {
  id: number;
  fileId: number;
}
