import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { AuthActions } from "application/constants/actions";

import { RequestEnum } from "../request/model";

export const initialState = {
  error: "",
};

const excludeActionTypes = (action: PayloadAction<any>) => {
  switch (action.type) {
    case `auth/${AuthActions.signInApi}/rejected`:
      return false;
    case `deal/${RequestEnum.updateDealById}/rejected`:
      return false;
    case `companyList/${RequestEnum.updateCompany}/rejected`:
      return false;
    case `consents/${RequestEnum.updateConsent}/rejected`:
      return false;
    default:
      return true;
  }
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type.endsWith("/rejected") && excludeActionTypes(action),
      (state) => {
        state.error = "Something went wrong!";
        toast.error("Something went wrong!");
      }
    );
  },
});

export default errorSlice.reducer;
