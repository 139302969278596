import styled from "@emotion/styled";
import { Typography } from "@mui/material";

import { colors } from "application/theme/palette";

export const Title = styled(Typography)`
  margin-bottom: 32px;
  color: ${colors.grey900};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border-radius: 8px;
  margin-bottom: 12px;
  background-color: ${colors.grey50};

  h3 {
    flex: 1;
    /* font */
    color: ${colors.grey900};
    font-family: "Unica77";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.08px;
  }
`;
