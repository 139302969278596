import React from "react";
import { RightSideItemWrapper } from "./styles";
import { Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/system";

import { Currency } from "integration/api/DealsApi/models";

import theme from "application/theme";
import {
  Button,
  CurrencySymbolSelect,
  TextFieldStyled,
} from "application/components";
import { PathNames } from "application/routes";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { DetectChangesSavedSliceActions } from "application/store/DetectChangesSaved/DetectChangesSavedSlice";
import { SearchParamsRoutesNames } from "application/routes/constants";
import { getNavigationState } from "application/helpers/navigationState";

interface RightSideItemProps {
  label: string;
  withSelect?: boolean;
  placeholder?: string;
  value?: number;
  selected?: Currency;
  onChange?: (value: number | string) => void;
  onChangeSelect?: (value: string) => void;
  amountError?: boolean;
  currencyError?: boolean;
}

export const RightSideItem = ({
  label,
  withSelect,
  placeholder,
  value,
  selected,
  onChange,
  onChangeSelect,
  amountError,
  currencyError,
}: RightSideItemProps) => {
  const { id, tab } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { hasUnsavedChanges } = useAppSelector(
    (state) => state.detectChangesSavedSlice
  );
  const amount = value === null || value === undefined ? Number(0) : value;

  const handleOnChange = (e: string) => {
    if (onChange) {
      const value = !e
        ? e.replace(/[^0-9]/g, "")
        : Number(e.replace(/[^0-9]/g, ""));
      onChange(value);
    }
  };

  const handleSelect = (e: string) => {
    if (onChangeSelect) {
      onChangeSelect(e);
    }
  };
  const goToCommitments = () => {
    if (hasUnsavedChanges) {
      dispatch(
        DetectChangesSavedSliceActions.setNextPath(
          `${PathNames.deals}/${id}/commitments`
        )
      );
    } else {
      navigate(
        `${PathNames.deals}/${id}/commitments`,
        getNavigationState(SearchParamsRoutesNames.commitments)
      );
    }
  };

  return (
    <RightSideItemWrapper>
      <Typography
        variant="h3"
        color={
          amountError || currencyError
            ? theme.palette.common.red700
            : theme.palette.common.grey500
        }
        mb={2}
      >
        {label}
      </Typography>
      {withSelect ? (
        <Box display="flex">
          <Box mr={1.5}>
            <TextFieldStyled
              placeholder={placeholder}
              autoComplete="off"
              width={268}
              onChange={(e) => handleOnChange(e.target.value)}
              value={amount}
              filled={value ? 1 : 0}
              inputProps={{
                pattern: "[0-9]{10}",
              }}
              error={amountError}
            />
          </Box>
          <CurrencySymbolSelect
            onChange={(e) => handleSelect(e)}
            selected={selected}
            error={currencyError}
          />
        </Box>
      ) : (
        <Button
          type="submit"
          label="See all commitments"
          onClick={goToCommitments}
        />
      )}
    </RightSideItemWrapper>
  );
};
