import styled from "@emotion/styled";
import { MoreVert } from "@mui/icons-material";

import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  position: relative;
`;

export const MoreButton = styled(MoreVert)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 10px;
  color: ${colors.grey700};
`;

export const Menu = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${colors.white};
  right: 0;
  z-index: 1;
  top: 100%;
  position: absolute;
  white-space: nowrap;
  p {
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    font-style: normal;
    font-family: "Unica77";
    letter-spacing: 0.08px;
    color: ${colors.grey900};
  }
  p:hover {
    color: ${colors.blue700};
  }
  p:first-of-type {
    margin-bottom: 12px;
  }
`;
