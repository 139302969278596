import React, { FC } from "react";
import { Box } from "@mui/system";

import { Button, LoadingButton } from "application/components";

import { CompanyAttachModalProps } from "./models";
import {
  Card,
  Title,
  Wrapper,
  Actions,
  CopyAttributesStyled,
  Description,
} from "./styles";
import { ErrorOutlineOutlined } from "@mui/icons-material";

const CompanyAttachModal: FC<CompanyAttachModalProps> = ({
  onClose,
  onSubmit,
  applyWithoutCopy,
  disabled,
  isLoading,
}) => {
  const onNotCopy = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    applyWithoutCopy();
  };

  const handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Wrapper>
      <Card>
        <Title>Change Attached Company</Title>
        <Description>
          <ErrorOutlineOutlined />
          <div>
            <p>
              You are changing the attached company. Do you want to copy
              attributes from the new company such as:
            </p>

            <CopyAttributesStyled>
              <li>Logo</li>
              <li>Company name</li>
              <li>Date founded</li>
              <li>Company Website</li>
              <li>Short overview</li>
            </CopyAttributesStyled>
          </div>
        </Description>
        <Actions>
          <Box mr={1.5}>
            <Button label="Cancel" onClick={onClose} disabled={isLoading} />
          </Box>
          <Button label="Don't copy" type="reset" onClick={onNotCopy} />
          <LoadingButton
            onClick={handleSubmit}
            type="submit"
            label="Copy attributes"
            disabled={disabled}
            isLoading={isLoading}
          />
        </Actions>
      </Card>
    </Wrapper>
  );
};

export default CompanyAttachModal;
