import React, { FC, useEffect } from "react";

import { useParams } from "react-router-dom";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";
import Loader from "application/components/Loader";
import { colors } from "application/theme/palette";
import compareObjectsChanges from "application/helpers/compareObjectsChanges";
import { DetectChangesSavedSliceActions } from "application/store/DetectChangesSaved/DetectChangesSavedSlice";

import { CompanyHeader } from "features/companies/components";
import {
  CompanyTopSection,
  CompanyBottomSection,
} from "features/companies/containers";
import CompanyListActions from "features/companies/store/actions";
import { CompanyData } from "integration/api/CompanyApi/models";
import { CompanySliceAction } from "features/companies/store/slice";

import { Container, LoaderContainer } from "./styles";

const CompanyPage: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { company, initialCompany } = useAppSelector(
    (state) => state.companyList
  );
  const { updateCompany, getCompanyById, deleteCompany } =
    useAppSelector(requestSelector);
  const loading =
    updateCompany.loading || getCompanyById.loading || deleteCompany.loading;

  useEffect(() => {
    if (id) {
      dispatch(CompanyListActions.getCompanyById(String(id)));
    }
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(CompanySliceAction.resetCompany());
    };
  }, []);

  const handleEditCompanyData = ({ key, value }: CompanyData) => {
    dispatch(CompanySliceAction.setCompanyData({ key, value }));
  };

  useEffect(() => {
    const isChanged = compareObjectsChanges(company, initialCompany, true);
    dispatch(DetectChangesSavedSliceActions.setHasUnsavedChanges(isChanged));
  }, [company, initialCompany]);

  return (
    <Container>
      {loading && (
        <LoaderContainer>
          <Loader color={colors.grey500} />
        </LoaderContainer>
      )}
      <CompanyHeader />
      <CompanyTopSection handleEditCompanyData={handleEditCompanyData} />
      <CompanyBottomSection
        loading={getCompanyById.loading}
        handleEditCompanyData={handleEditCompanyData}
      />
    </Container>
  );
};

export default CompanyPage;
