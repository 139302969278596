import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

export const TableRowStyled = styled("tr")`
  height: 52px;

  td {
    box-sizing: border-box;
    height: 52px;
    padding: 10px 12px;
    vertical-align: middle;
    border-top: 1px solid ${colors.grey200};
    border-right: 1px solid ${colors.grey200};
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    font-style: normal;
    font-family: Unica77;
    letter-spacing: 0.07px;
    color: ${colors.grey900};
    box-sizing: border-box;

    &:nth-of-type(3) {
      width: 364px;
    }
    &:last-of-type {
      width: 144px;
      text-align: center;
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 1px solid ${colors.grey100};
  }
`;

export const Total = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-top: 2px;
    margin-left: 4px;
  }
`;

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1.2px solid ${colors.grey200};
    background: ${colors.white};

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;

      path {
        fill: ${colors.grey700};
      }
    }
  }
`;
