import { createAsyncThunk } from "@reduxjs/toolkit";

import { RequestEnum } from "application/store/request/model";
import AnalyticManagementApi from "integration/api/AnalyticManagementApi";
import {
  AnalyticParamsDto,
  UpdateOrdinalDto,
  UploadAnalyticParamsDto,
} from "integration/api/AnalyticManagementApi/models";

class ManagementAnalyticsActions {
  static getAnalyticsList = createAsyncThunk(
    `management-analytics/${RequestEnum.getAnalyticsList}`,
    async (id: number, { rejectWithValue }) => {
      try {
        const { data } = await AnalyticManagementApi.getAnalyticsList(id);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static uploadAnalytic = createAsyncThunk(
    `management-analytics/${RequestEnum.uploadAnalytic}`,
    async (data: UploadAnalyticParamsDto, { rejectWithValue }) => {
      try {
        const { data: responseData } =
          await AnalyticManagementApi.uploadAnalytic(data);
        return responseData;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deleteAnalytic = createAsyncThunk(
    `management-analytics/${RequestEnum.deleteAnalytic}`,
    async (data: AnalyticParamsDto, { rejectWithValue }) => {
      try {
        const { data: responseData } =
          await AnalyticManagementApi.deleteAnalytic(data);
        return responseData;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getAnalyticByFileId = createAsyncThunk(
    `management-analytics/${RequestEnum.getAnalyticByFileId}`,
    async (data: AnalyticParamsDto, { rejectWithValue }) => {
      try {
        const { data: responseData } =
          await AnalyticManagementApi.getAnalyticByFileId(data);
        return responseData;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static updateAnalyticsList = createAsyncThunk(
    `management-analytics/${RequestEnum.updateAnalyticsList}`,
    async (data: UpdateOrdinalDto, { rejectWithValue }) => {
      try {
        const { data: responseData } =
          await AnalyticManagementApi.updateAnalyticsList(data);
        return responseData;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default ManagementAnalyticsActions;
