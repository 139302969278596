import React from "react";

import SmallLoader from "../SmallLoader";

import { colors } from "application/theme/palette";
import { Container, LoaderContainer } from "./styles";

interface Props {
  title: string;
  loading?: boolean;
  disabled?: boolean;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  style?: React.CSSProperties | undefined;
  onClick?: () => void;
  type?: "submit" | "reset" | "button";
}

const PrimaryButton = ({
  title,
  style,
  loading,
  disabled,
  leftIcon,
  rightIcon,
  onClick,
  type,
}: Props) => {
  return (
    <Container
      style={style}
      onClick={onClick}
      $loading={loading}
      disabled={disabled}
      type={type}
    >
      {loading && (
        <LoaderContainer>
          <SmallLoader color={colors.blue700} />
        </LoaderContainer>
      )}
      {leftIcon}
      <p>{title}</p>
      {rightIcon}
    </Container>
  );
};

export default PrimaryButton;
