import { createAsyncThunk } from "@reduxjs/toolkit";

import { UploadFile } from "./models";
import { RootState } from "application/store";
import DealsApi from "integration/api/DealsApi";
import { formatTractionData } from "../helpers/array";
import { RequestEnum } from "application/store/request/model";
import {
  DeleteDealLogoParams,
  DeleteFileParams,
  GetAttachmentFilePrams,
  SendTestEmailParams,
  UploadDealLogoParams,
} from "integration/api/DealsApi/models";

class DealActions {
  static createDeal = createAsyncThunk(
    `deal/${RequestEnum.createDeal}`,
    async (companyId: number, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.createDeal(companyId);
        return data;
      } catch (error) {
        console.error("[createDeal] Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static getDealById = createAsyncThunk(
    `deal/${RequestEnum.getDealById}`,
    async (id: number, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.getDeal(id);
        return data;
      } catch (error) {
        console.error("[getDealById] Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static uploadLogoFile = createAsyncThunk(
    `deal/${RequestEnum.uploadLogoFile}`,
    async (
      { file, type, purpose, dealId }: UploadFile,
      { rejectWithValue }
    ) => {
      const form = new FormData();
      form.append("file", file);
      form.append("type", type);
      form.append("purpose", purpose);
      try {
        const { data } = await DealsApi.uploadFile({
          data: form,
          dealId,
        });
        return data;
      } catch (error) {
        console.error("[uploadFile] Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
  static uploadPitchDeck = createAsyncThunk(
    `deal/${RequestEnum.uploadPitchDeck}`,
    async (
      { file, type, purpose, dealId }: UploadFile,
      { rejectWithValue }
    ) => {
      const form = new FormData();
      form.append("file", file);
      form.append("type", type);
      form.append("purpose", purpose);
      try {
        const { data } = await DealsApi.uploadFile({
          data: form,
          dealId,
        });
        return data;
      } catch (error) {
        console.error("[uploadFile] Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
  static uploadAdditionalMaterials = createAsyncThunk(
    `deal/${RequestEnum.uploadAdditionalMaterials}`,
    async (
      { file, type, purpose, dealId }: UploadFile,
      { rejectWithValue }
    ) => {
      const form = new FormData();
      form.append("file", file);
      form.append("type", type);
      form.append("purpose", purpose);
      try {
        const { data } = await DealsApi.uploadFile({
          data: form,
          dealId,
        });
        return data;
      } catch (error) {
        console.error("[uploadFile] Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
  static uploadNewsletterPicture = createAsyncThunk(
    `deal/${RequestEnum.uploadNewsletterPicture}`,
    async (
      { file, type, purpose, dealId }: UploadFile,
      { rejectWithValue }
    ) => {
      const form = new FormData();
      form.append("file", file);
      form.append("type", type);
      form.append("purpose", purpose);
      try {
        const { data } = await DealsApi.uploadFile({
          data: form,
          dealId,
        });
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deletePitchDeck = createAsyncThunk(
    `deal/${RequestEnum.deletePitchDeckFile}`,
    async (
      { fileId, dealId }: DeleteFileParams,
      { getState, rejectWithValue }
    ) => {
      const state = getState() as RootState;
      const pitchDecks = state.dealPage.profile.overview.pitchDecks;
      try {
        await DealsApi.deleteFile({
          fileId,
          dealId,
        });
        const filesLeft = pitchDecks.filter((e) => e.id !== fileId);
        return filesLeft;
      } catch (error) {
        console.error("[uploadFile] Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
  static deleteAdditionalMaterials = createAsyncThunk(
    `deal/${RequestEnum.deleteAdditionalMaterials}`,
    async (
      { fileId, dealId }: DeleteFileParams,
      { getState, rejectWithValue }
    ) => {
      const state = getState() as RootState;
      const additionalMaterials =
        state.dealPage.profile.overview.additionalMaterials;
      try {
        await DealsApi.deleteFile({
          fileId,
          dealId,
        });
        const filesLeft = additionalMaterials.filter((e) => e.id !== fileId);
        return filesLeft;
      } catch (error) {
        console.error("[uploadFile] Error: ", error);
        return rejectWithValue(error);
      }
    }
  );
  static deleteNewsletterPicture = createAsyncThunk(
    `deal/${RequestEnum.deleteNewsletterPicture}`,
    async ({ fileId, dealId }: DeleteFileParams, { rejectWithValue }) => {
      try {
        const data = await DealsApi.deleteFile({
          fileId,
          dealId,
        });
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deleteLogoFile = createAsyncThunk(
    `deal/${RequestEnum.deleteLogoFile}`,
    async ({ fileId, dealId }: DeleteFileParams, { rejectWithValue }) => {
      try {
        await DealsApi.deleteFile({
          fileId,
          dealId,
        });
        return null;
      } catch (error) {
        console.error("[uploadFile] Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static updateDealById = createAsyncThunk(
    `deal/${RequestEnum.updateDealById}`,
    async (_, { getState, rejectWithValue }) => {
      const { dealPage } = getState() as RootState;
      const requestData = formatTractionData(dealPage.profile);
      try {
        const { data } = await DealsApi.updateDealById({
          data: requestData,
        });
        return data;
      } catch (error) {
        console.error("[updateDeal] Error: ", error);
        return rejectWithValue(error);
      }
    }
  );

  static getTechnologies = createAsyncThunk(
    `deals/${RequestEnum.getTechnologies}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.getTechnologies();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static getStages = createAsyncThunk(
    `deals/${RequestEnum.getStages}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.getStages();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static getIndustries = createAsyncThunk(
    `deals/${RequestEnum.getIndustries}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.getIndustries();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static getBusinessModels = createAsyncThunk(
    `deals/${RequestEnum.getBusinessModels}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.getBusinessModels();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static getBroadCategories = createAsyncThunk(
    `deals/${RequestEnum.getBroadCategories}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.getBroadCategories();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static uploadDealLogo = createAsyncThunk(
    `deals/${RequestEnum.uploadDealLogo}`,
    async (data: UploadDealLogoParams, { rejectWithValue }) => {
      try {
        const { data: responseData } = await DealsApi.uploadDealLogo(data);
        return responseData;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static deleteLogo = createAsyncThunk(
    `deals/${RequestEnum.deleteLogo}`,
    async (data: DeleteDealLogoParams, { rejectWithValue }) => {
      try {
        const { data: responseData } = await DealsApi.deleteLogo(data);
        return responseData;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static sendTestEmail = createAsyncThunk(
    `deals/${RequestEnum.sendTestEmail}`,
    async (params: SendTestEmailParams, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.sendTestEmail(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  static getAttachmentFile = createAsyncThunk(
    `deals/${RequestEnum.getAttachmentFile}`,
    async (params: GetAttachmentFilePrams, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.getAttachmentFile(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default DealActions;
