export enum Sort {
  asc = "createdDate,asc",
  desc = "createdDate,desc",
}

export enum ConsentStatuses {
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}

export interface ConsentListParams {
  page: number;
  size: number;
  status?: string;
}

interface SortDto {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

interface PageableDto {
  offset: number;
  sort: SortDto;
  paged: boolean;
  unpaged: boolean;
  pageNumber: number;
  pageSize: number;
}

interface PageDto {
  totalPages: number;
  totalElements: number;
  first: boolean;
  last: boolean;
  size: number;
  number: number;
  sort: SortDto;
  numberOfElements: number;
  pageable: PageableDto;
  empty: boolean;
}

export interface ConsentDto {
  id: number;
  name: string;
  status: ConsentStatuses;
  description: string;
  publishedDate: string;
}

export interface PageConsentDto extends PageDto {
  content: ConsentDto[];
}

export interface UpdateConsentRequest {
  id: number;
  name: string;
  description: string;
  status: ConsentStatuses;
}

export interface CreateConsentDto {
  id: number;
  businessId: string;
}
