import { NumberOfCompaniesToInvest } from "application/helpers/numberOfCompaniesToInvest";
import { UserStatus } from "application/helpers/userStatuses";

export enum KYCStatus {
  NOT_STARTED = "NOT_STARTED",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface StageDto {
  id: number;
  name: string;
  isChosen: boolean;
}

export interface BroadCategoryDto {
  id: number;
  name: string;
  isChosen: boolean;
}

export interface NumberOfCompaniesToInvestDto {
  numberOfCompaniesToInvest: NumberOfCompaniesToInvest;
  isChosen: boolean;
}

export interface SurveyInfoDto {
  stages: StageDto[];
  broadCategories: BroadCategoryDto[];
  numberOfCompaniesToInvest: NumberOfCompaniesToInvestDto[];
}

export interface InvestorPageResponseDto {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  kycStatus: KYCStatus;
  userStatus: UserStatus;
}

export interface SortDto {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

export interface PageableDto {
  offset: number;
  sort: SortDto;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

export interface PageInvestorPageResponseDto {
  totalPages: number;
  totalElements: number;
  size: number;
  content: InvestorPageResponseDto[];
  number: number;
  sort: SortDto;
  numberOfElements: number;
  pageable: PageableDto;
  first: boolean;
  last: boolean;
  empty: boolean;
}

export interface InvestorsParamsDto {
  page: number;
  size: number;
  sort: string[];
  search: string;
  status?: UserStatus;
}

export interface InvestorsParamsAPI extends InvestorsParamsDto {
  key: string;
}

export interface InvestorAutosuggestionsResponseDto {
  id: number;
  firstName: string;
  lastName: string;
}

export interface DocumentInfoDto {
  id: number;
  docType: string;
  docNumber: string;
}

export interface CountryDto {
  id: number;
  name: string;
  alpha3: string;
  isoCode: string;
}

export interface Investor {
  id: number;
  email: string;
  phone: string;
  lastName: string | null;
  firstName: string | null;
  kycStatus: KYCStatus;
  kycStatusDate: string;
  country: CountryDto | null;
  documentInfo: DocumentInfoDto[];
  userStatus: UserStatus;
  surveyInfoDto: SurveyInfoDto;
}

export interface UpdateUserNameParams {
  id: number;
  firstName: string;
  lastName: string;
}

export interface UpdateUserPhoneParams {
  id: number;
  phoneNumber: string;
}

export interface UpdateUserEmailParams {
  id: number;
  email: string;
}

export interface SurveyRequestDto {
  stageIds: number[];
  broadCategoryIds: number[];
  numberOfCompaniesToInvest: NumberOfCompaniesToInvest;
}

export interface UpdateSurveyInfoParams {
  id: number;
  data: SurveyRequestDto;
}

export interface GetSearchAutosuggestionsParams {
  search: string;
  status?: string;
}

export interface AutosuggestionsParamsAPI extends GetSearchAutosuggestionsParams {
  key: string;
}
