import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { DetectChangesSavedSliceActions } from "application/store/DetectChangesSaved/DetectChangesSavedSlice";

import ConfirmPopUp from "../ConfirmPopUp";

const LeavePagePopUp: FC<{
  onSubmit: () => Promise<"fulfilled" | "rejected" | undefined>;
}> = ({ onSubmit }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showModal, nextPath } = useAppSelector(
    (state) => state.detectChangesSavedSlice
  );

  const handleClose = () => {
    dispatch(DetectChangesSavedSliceActions.reset());
    navigate(nextPath!);
  };

  const handleSubmit = async () => {
    await onSubmit().then((res) => {
      if (res === "fulfilled") {
        dispatch(DetectChangesSavedSliceActions.reset());
        navigate(nextPath!);
      } else {
        dispatch(DetectChangesSavedSliceActions.setShowModal(false));
      }
    });
  };

  return (
    <>
      {showModal && (
        <ConfirmPopUp
          title="Unsaved changes"
          description="You are about to close this item with unsaved changes. Would you like to save these changes before closing?"
          onClose={handleClose}
          onSubmit={handleSubmit}
          submitButtonTitle="Save changes"
        />
      )}
    </>
  );
};

export default LeavePagePopUp;
