import React, { FC } from "react";
import CallMadeIcon from "@mui/icons-material/CallMade";
import ClearIcon from "@mui/icons-material/Clear";
import Checkbox from "@mui/material/Checkbox";
import { Link, useParams } from "react-router-dom";
import { Add, Close, EditOutlined } from "@mui/icons-material";

import { formatDealTableDate } from "features/deals/helpers/date";
import { SelectWithText } from "application/components";
import { PathNames } from "application/routes";
import { formatTotal } from "application/helpers/formatTotal";
import { getNavigationState } from "application/helpers/navigationState";
import { SearchParamsRoutesNames } from "application/routes/constants";

import { InvestmentStatus } from "integration/api/DealsApi/models";

import { TableRowProps } from "./model";
import {
  Actions,
  DropdownContainer,
  MultiRowStyled,
  InvestmentAnalyticsStyled,
} from "./styles";

const statusOptions = [
  { value: InvestmentStatus.PENDING, label: "Pending" },
  { value: InvestmentStatus.CONFIRMED, label: "Confirmed" },
  { value: InvestmentStatus.CANCELED, label: "Canceled" },
];

const TableRow: FC<TableRowProps> = ({
  investor,
  handleChangeStatus,
  selectedInvestorsIds,
  setSelectedInvestorsIds,
  onDelete,
  totalCurrency,
  onAddAnalytic,
  onEditAnalytic,
  onDeleteAnalytic,
}) => {
  const { id } = useParams();
  const showAddAnalyticButton =
    investor.investmentStatus === InvestmentStatus.CONFIRMED;
  const investorName = `${investor.firstName} ${investor.lastName}`;
  const date = formatDealTableDate(investor.investedDate);
  const fmvChangeDate = investor.investmentAnalytic
    ? formatDealTableDate(investor.investmentAnalytic.fmvChangeDate)
    : "";
  const investmentAmount = formatTotal(
    investor.investmentAmount,
    investor.investmentCurrency
  );
  const totalFmvValue = investor.investmentAnalytic
    ? formatTotal(investor.investmentAnalytic.fmvValue, totalCurrency)
    : "";
  const totalRealizedProfit = investor.investmentAnalytic
    ? formatTotal(
        investor.investmentAnalytic.totalRealizedProfit,
        totalCurrency
      )
    : "";
  const isInvestorSelected = selectedInvestorsIds.some(
    (id) => id === investor.investorId
  );

  const onSelectInvestor = () => {
    if (isInvestorSelected) {
      const filteredInvestorsIds = selectedInvestorsIds.filter(
        (id) => id !== investor.investorId
      );
      setSelectedInvestorsIds(filteredInvestorsIds);
    } else {
      setSelectedInvestorsIds([...selectedInvestorsIds, investor.investorId]);
    }
  };

  const handleDelete = () => {
    onDelete(investor);
  };

  return (
    <tr>
      <td>
        <Checkbox checked={isInvestorSelected} onChange={onSelectInvestor} />
      </td>
      <td>{investorName}</td>
      <td>
        <MultiRowStyled>
          <div>
            <h2>Amount</h2>
            <p>{investmentAmount}</p>
          </div>
          <div>
            <h2>Date</h2>
            <p>{date}</p>
          </div>
        </MultiRowStyled>
      </td>
      <td>
        <DropdownContainer status={investor.investmentStatus}>
          <SelectWithText
            placeholder="Status"
            onChange={(value) =>
              handleChangeStatus(value as InvestmentStatus, investor.investorId)
            }
            options={statusOptions}
            selected={investor.investmentStatus}
            maxHeight={300}
          />
        </DropdownContainer>
      </td>
      <td>
        <InvestmentAnalyticsStyled>
          <MultiRowStyled>
            <div>
              <h2>Current value (FMV)</h2>
              <p>{totalFmvValue}</p>
            </div>
            <div>
              <h2>Total realized profit</h2>
              <p>{totalRealizedProfit}</p>
            </div>
            <div>
              <h2>Date of last FMV change</h2>
              <p>{fmvChangeDate}</p>
            </div>
          </MultiRowStyled>
          <Actions>
            {investor.latestInvestmentAnalytic && (
              <button
                onClick={() =>
                  onEditAnalytic({
                    analytics: investor.latestInvestmentAnalytic,
                    investorId: investor.investorId,
                  })
                }
              >
                <EditOutlined />
              </button>
            )}
            {showAddAnalyticButton && (
              <button onClick={() => onAddAnalytic(investor.investorId)}>
                <Add />
              </button>
            )}
            {investor.latestInvestmentAnalytic && (
              <button
                onClick={() =>
                  onDeleteAnalytic({
                    analytics: investor.latestInvestmentAnalytic,
                    investorId: investor.investorId,
                  })
                }
              >
                <Close />
              </button>
            )}
          </Actions>
        </InvestmentAnalyticsStyled>
      </td>
      <td>{investor.investmentAnalytic?.multiple || ""}</td>
      <td>
        <Actions>
          <Link
            to={`${PathNames.portfolio}/${id}/investors/${investor.investorId}/detail-commitments`}
            state={getNavigationState(
              SearchParamsRoutesNames.portfolioInvestorsDetailCommitments
            )}
          >
            <CallMadeIcon />
          </Link>
          <button onClick={handleDelete}>
            <ClearIcon />
          </button>
        </Actions>
      </td>
    </tr>
  );
};

export default TableRow;
