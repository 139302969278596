import React from "react";

import { EmptyWrapper } from "./styles";
import { ReactComponent as EmptyFolderSvg } from "application/assets/images/empty-folder.svg";

interface Props {
  description: string;
}

const Empty = ({ description }: Props) => {
  return (
    <EmptyWrapper>
      <EmptyFolderSvg />
      <p>{description}</p>
    </EmptyWrapper>
  );
};

export default Empty;
