import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BackButton = styled("button")`
  all: unset;
  min-width: fit-content;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.blue700};
  text-align: center;
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
  cursor: pointer;

  svg {
    margin-right: 8px;
    path {
      fill: ${colors.blue700};
    }
  }
`;

export const ButtonSection = styled("div")`
  display: grid;
  grid-template-columns: 169px 187px;
  gap: 20px;
  width: 100%;
  justify-content: flex-end;
`;
