import { Box, styled } from "@mui/system";
import theme from "application/theme";
import { colors } from "application/theme/palette";

interface StyledProps {
  hasError: boolean;
}

export const FileInput = styled(Box)<StyledProps>`
  border: 1px solid
    ${({ hasError }) => (hasError ? colors.red700 : colors.grey200)};
  display: flex;
  align-items: center;
  border-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(0, 2)};
  height: ${theme.spacing(5.5)};
  cursor: pointer;

  svg {
    path {
      fill: ${({ hasError }) => (hasError ? colors.red700 : colors.grey500)};
    }
  }
`;

export const ErrorMessage = styled("span")`
  color: ${colors.red700};
  font-family: Unica77;
  font-weight: 400;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 14px;
`;
