import React from "react";

import { Container } from "./styles";

interface Props {
  title: string;
  onClick: () => void;
}

const EmptyTraction = ({ title, onClick }: Props) => {
  return (
    <Container onClick={onClick}>
      <p>Add</p>&nbsp;
      <p>{title}</p>
    </Container>
  );
};

export default EmptyTraction;
