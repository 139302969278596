import { styled } from "@mui/system";

import PageBg from "application/assets/images/no-internet-connection-bg.png";
import { colors } from "application/theme/palette";

interface StyledProps {
  isNoInternetConnection: boolean;
}

export const NoInternetContainer = styled("main")<StyledProps>`
  position: absolute;
  top: 0;
  z-index: 10000000;
  visibility: ${({ isNoInternetConnection }) =>
    isNoInternetConnection ? "visible" : "hidden"};
  width: 100%;
  height: 100%;
  background-image: url(${PageBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Unica77";

  img {
    width: 100px;
    height: 100px;
    margin-bottom: 12px;
  }

  h1 {
    color: ${colors.white};
    text-align: center;
    font-size: 120px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 40px;
  }
  h2 {
    text-align: center;
    color: ${colors.white};
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
  h3 {
    margin-top: 16px;
    text-align: center;
    max-width: 720px;
    color: ${colors.white};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  a {
    all: unset;
    margin-top: 43px;
  }
`;
