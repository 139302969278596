import React, { FC } from "react";

import UsersSearchInput from "../UsersSearchInput";

import { SelectWithText } from "application/components";
import { UserStatus, userStatuses } from "application/helpers/userStatuses";

import { Title, Container, FiltersSection } from "./styles";

interface Props {
  searchItem: string;
  setSearchItem: (value: string) => void;
  selectedStatus: UserStatus | "";
  handelSelectStatus: (value: UserStatus | "") => void;
}

const UsersHeader: FC<Props> = ({
  searchItem,
  setSearchItem,
  selectedStatus,
  handelSelectStatus,
}) => {
  return (
    <Container>
      <Title>Users</Title>
      <FiltersSection>
        <SelectWithText
          placeholder="Status"
          onChange={(value) => handelSelectStatus(value as UserStatus)}
          options={userStatuses}
          selected={selectedStatus}
          maxHeight={300}
        />
        <UsersSearchInput
          searchItem={searchItem}
          setSearchItem={setSearchItem}
          status={selectedStatus}
        />
      </FiltersSection>
    </Container>
  );
};

export default UsersHeader;
