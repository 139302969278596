import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackToPageWrapper } from "./styles";
import { LeftArrowIcon } from "application/assets/LeftArrowIcon";
import { DetectChangesSavedSliceActions } from "application/store/DetectChangesSaved/DetectChangesSavedSlice";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import theme from "application/theme";
import { PathNames } from "application/routes";

interface BackToPageProps {
  title: string;
  backPath: string;
}

export const BackToPage = ({ title, backPath }: BackToPageProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { hasUnsavedChanges } = useAppSelector(
    (state) => state.detectChangesSavedSlice
  );

  const goBack = () => {
    if (hasUnsavedChanges) {
      dispatch(
        DetectChangesSavedSliceActions.setNextPath(
          (window?.history?.state?.usr?.deal as string) || PathNames.deals
        )
      );
    } else {
      const path = (window?.history?.state?.usr?.deal || -1) as string;
      navigate(path, { state: window?.history?.state?.usr });
    }
  };

  return (
    <BackToPageWrapper onClick={goBack}>
      <Box mr={1.5}>
        <LeftArrowIcon />{" "}
      </Box>
      <Typography variant="button" color={theme.palette.common.blue700}>
        Back to {title}
      </Typography>
    </BackToPageWrapper>
  );
};
