import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { SaveAlt, Edit } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { ClickAwayListener, Grid } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

import {
  CountryDropdown,
  CurrencySymbolSelect,
  DatePicker,
  ImageLoader,
  InputWithText,
  SelectWithText,
} from "application/components";
import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { StatusSelect } from "application/components/StatusSelect";
import { colors } from "application/theme/palette";
import { getYearsOptions } from "application/helpers/getYearsOptions";

import {
  Currency,
  DealStatus,
  LogoType,
} from "integration/api/DealsApi/models";
import { CompanySuggestionDto } from "integration/api/CompanyApi/models";

import {
  setDealVocabularyData,
  setTitle,
  setMinimalInvestments,
  setMinimalInvestmentsCurency,
  setStartDate,
  setStatus,
  setTotalGoal,
  setTotalGoalCurrency,
  DealSliceAction,
  setDealCurrency,
  setFoundedYear,
  setCountryId,
} from "features/deal-profile/store/slice";
import DealActions from "features/deal-profile/store/actions";
import { VocabularyDataDto } from "features/deal-profile/store/models";
import { dealPageSelector } from "features/deal-profile/store/selectors";
import DealsActions from "features/deals/store/actions";
import { DealErrorKey } from "features/deal-profile/helpers/formatDealError";
import CompanyAttachModal from "features/deal-profile/components/CompanyAttachModal";
import AttachedCompany from "features/deal-profile/components/AttachedCompany";
import CompanyListActions from "features/companies/store/actions";

import { RightSideItem } from "../../components/RightSideItem/RightSideItem";
import {
  AvatarPlaceholder,
  ImageWrapper,
  LogoPopup,
  LogoPopupItem,
  TopSectionWrapper,
  EditLogo,
  DeleteContainer,
  CopyButton,
  CopyTooltip,
  TextContainer,
  Title,
  CurrencyWrapper,
  Container,
} from "./style";
import CropImage from "features/crop-image";

const formatter = new Intl.NumberFormat("en-US");

const TopSection = () => {
  const { id } = useParams();
  const { errors, initialProfile } = useAppSelector((state) => state.dealPage);
  const dealPage = useAppSelector(dealPageSelector).profile;
  const { stages, businessModels, broadCategories } =
    useAppSelector(dealPageSelector);
  const dispatch = useAppDispatch();

  const [showLogoPopup, setShowLogoPopup] = useState(false);
  const [logo, setLogo] = useState("");
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isDynamicLinkCopied, setIsDynamicLinkCopied] = useState(false);
  const [isDealLinkCopied, setIsDealLinkCopied] = useState(false);
  const [selectedCompany, setSelectedCompany] =
    useState<CompanySuggestionDto | null>(null);

  const [image, setImage] = useState<string>("");
  const [showCropperModal, setShowCropperModal] = useState<boolean>(false);

  const yearsOptions = getYearsOptions(1901);
  const showDynamicLink = initialProfile.status === DealStatus.ACTIVE;

  useEffect(() => {
    dispatch(DealActions.getStages());
    dispatch(DealActions.getBusinessModels());
    dispatch(DealActions.getBroadCategories());
  }, []);

  const copyTextToClipboard = async (text: string) => {
    return await navigator.clipboard.writeText(text);
  };

  const handleCopyID = () => {
    copyTextToClipboard(dealPage.businessId).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };
  const handleCopyDynamicLink = () => {
    copyTextToClipboard(dealPage.dynamicLink).then(() => {
      setIsDynamicLinkCopied(true);
      setTimeout(() => {
        setIsDynamicLinkCopied(false);
      }, 1500);
    });
  };
  const handleCopyDealLink = () => {
    copyTextToClipboard(dealPage.dealUrl).then(() => {
      setIsDealLinkCopied(true);
      setTimeout(() => {
        setIsDealLinkCopied(false);
      }, 1500);
    });
  };

  const broadCategoriesOptions = useMemo(() => {
    return broadCategories.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [broadCategories]);

  const stagesOptions = useMemo(() => {
    return stages.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [stages]);

  const businessModelsOptions = useMemo(() => {
    return businessModels.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [businessModels]);

  const setDealLogo = (data: Blob) => {
    const logoBlob = new Blob([data], {
      type: data.type as string,
    });
    const logoUrl = URL.createObjectURL(logoBlob);
    setLogo(logoUrl);
  };

  useEffect(() => {
    if (dealPage.logoFileId && !dealPage.copyLogoFromCompany) {
      dispatch(
        DealsActions.getDealLogo({
          id: dealPage.id,
          logoId: dealPage.logoFileId,
          logoType: LogoType.THUMBNAIL,
        })
      ).then((response) => {
        const data = response.payload as Blob;
        setDealLogo(data);
      });
    }
  }, [dealPage.logoFileId, dealPage.copyLogoFromCompany]);

  useEffect(() => {
    const isCompanyLogo =
      dealPage.logoFileId && dealPage.copyLogoFromCompany && dealPage.companyId;

    if (isCompanyLogo) {
      dispatch(
        CompanyListActions.getCompanyLogo({
          id: Number(dealPage.companyId),
          logoId: Number(dealPage.logoFileId),
          logoType: LogoType.THUMBNAIL,
        })
      ).then((response) => {
        const data = response.payload as Blob;
        setDealLogo(data);
      });
    }
  }, [dealPage.logoFileId, dealPage.copyLogoFromCompany, dealPage.companyId]);

  const handleImageUpload = async (file: File) => {
    setIsFileLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    await dispatch(
      DealActions.uploadDealLogo({ id: Number(id), data: formData })
    ).then(() => setShowLogoPopup(false));
    setIsFileLoading(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e.target.files;
    if (files?.length) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.onloadend = () => {
        setShowCropperModal(true);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const handleDeleteFile = () => {
    const logoId = dealPage.logoFileId;
    if (logoId) {
      dispatch(DealActions.deleteLogo({ id: Number(id), logoId })).then(() => {
        setLogo("");
        setShowLogoPopup(false);
      });
    }
  };

  const handleLogoClick = () => {
    setShowLogoPopup(true);
  };

  const handleDealStatus = (value: DealStatus) => {
    dispatch(setStatus(value));
  };
  const handleDealTitle = (value: string) => {
    dispatch(setTitle(value));
    dispatch(
      DealSliceAction.setErrors(
        errors.filter((item) => item !== DealErrorKey.title)
      )
    );
  };
  const handleStartDate = (value: string) => {
    dispatch(setStartDate(value));
  };
  const handleChangeVocabulary = useCallback(
    (data: VocabularyDataDto) => {
      dispatch(setDealVocabularyData(data));
      dispatch(
        DealSliceAction.setErrors(errors.filter((item) => item !== data.key))
      );
    },
    [
      errors,
      dealPage.broadCategoryId,
      dealPage.stageId,
      dealPage.businessModelId,
    ]
  );
  const handleTotalGoal = (value: number | string) => {
    dispatch(setTotalGoal(value));
    dispatch(
      DealSliceAction.setErrors(
        errors.filter(
          (item) => item !== DealErrorKey["totalFundingGoal.amount"]
        )
      )
    );
  };
  const handleTotalGoalCurrency = (value: string) => {
    dispatch(setTotalGoalCurrency(value));
    dispatch(
      DealSliceAction.setErrors(
        errors.filter(
          (item) => item !== DealErrorKey["totalFundingGoal.currency"]
        )
      )
    );
  };

  const handleMinimalInvestments = (value: number | string) => {
    dispatch(setMinimalInvestments(value));
    dispatch(
      DealSliceAction.setErrors(
        errors.filter((item) => item !== DealErrorKey.minInvestmentAmount)
      )
    );
  };
  const handleMinimalInvestmentsCurrency = (value: string) => {
    dispatch(setMinimalInvestmentsCurency(value));
    dispatch(
      DealSliceAction.setErrors(
        errors.filter((item) => item !== DealErrorKey.investmentCurrency)
      )
    );
  };

  const handleChangeDealCurrency = (currency: Currency) => {
    dispatch(setDealCurrency(currency));
    dispatch(
      DealSliceAction.setErrors(
        errors.filter((item) => item !== DealErrorKey.dealCurrency)
      )
    );
  };

  const handleChangeFoundedYear = (year: string) => {
    dispatch(setFoundedYear(year));
  };

  const handleChangeCountry = (id: number) => {
    dispatch(setCountryId(id));
  };

  const closeAttachCompanyModal = () => {
    setSelectedCompany(null);
  };

  const applyWithoutCopy = () => {
    if (selectedCompany?.id) {
      dispatch(DealSliceAction.setCompanyId(selectedCompany.id));
      dispatch(DealSliceAction.setCopyLogoFromCompany(false));
      closeAttachCompanyModal();
    }
  };

  const copyCompanyData = () => {
    if (selectedCompany) {
      dispatch(
        DealSliceAction.setAttachedCompanyData({
          companyId: selectedCompany.id,
          title: selectedCompany.companyName,
          logoFileId: selectedCompany.logoFileId,
          foundedYear: String(selectedCompany.yearFounded),
          website: selectedCompany.website,
          description: selectedCompany.description,
          copyLogoFromCompany: true,
        })
      );
      closeAttachCompanyModal();
    }
  };

  return (
    <TopSectionWrapper>
      <Box display="flex" mr={4}>
        <ImageWrapper>
          <AvatarPlaceholder onClick={handleLogoClick}>
            {isFileLoading ? (
              <ImageLoader />
            ) : (
              <>
                {logo && <img src={logo} alt="deal-avatar" />}
                {dealPage.logoFileId ? (
                  <EditLogo>
                    <Edit />
                  </EditLogo>
                ) : (
                  <SaveAlt />
                )}
              </>
            )}
          </AvatarPlaceholder>
          {!isFileLoading && showLogoPopup && (
            <ClickAwayListener onClickAway={() => setShowLogoPopup(false)}>
              <LogoPopup>
                <input
                  color="primary"
                  accept="image/jpeg,image/png"
                  type="file"
                  onChange={(e) => onChange(e)}
                  id="icon-button-file"
                  style={{ display: "none" }}
                />
                <Box mb={dealPage.logoFileId ? 1.5 : 0}>
                  <label htmlFor="icon-button-file">
                    <LogoPopupItem>Upload file</LogoPopupItem>
                  </label>
                </Box>

                {dealPage.logoFileId && (
                  <DeleteContainer>
                    <LogoPopupItem onClick={handleDeleteFile}>
                      Delete file
                    </LogoPopupItem>
                  </DeleteContainer>
                )}
              </LogoPopup>
            </ClickAwayListener>
          )}
        </ImageWrapper>
        <Box display="flex" flexDirection="column">
          <Grid
            container
            gridRow={2}
            gridColumn={2}
            rowSpacing={2}
            columnSpacing={4}
            mb={4}
            alignContent={"center"}
          >
            <Container item xs={6}>
              <Grid item>
                <Title>Deal ID:</Title>
                <TextContainer>
                  <h2>{dealPage.businessId}</h2>
                  <CopyButton type="button" onClick={handleCopyID}>
                    <LinkIcon />
                    <CopyTooltip className="id-tooltip ">
                      <ArrowLeftIcon />
                      Copy
                    </CopyTooltip>
                  </CopyButton>
                  {isCopied && <p>Copied!</p>}
                </TextContainer>
              </Grid>
              {showDynamicLink && (
                <Grid item>
                  <Title>Deal dynamic link:</Title>
                  <TextContainer>
                    <h2>{dealPage.dynamicLink}</h2>
                    <CopyButton type="button" onClick={handleCopyDynamicLink}>
                      <LinkIcon />
                      <CopyTooltip className="dynamicLink-tooltip ">
                        <ArrowLeftIcon />
                        Copy
                      </CopyTooltip>
                    </CopyButton>
                    {isDynamicLinkCopied && <p>Copied!</p>}
                  </TextContainer>
                </Grid>
              )}
              {showDynamicLink && (
                <Grid item>
                  <Title>Miniapp deal link:</Title>
                  <TextContainer>
                    <h2>{dealPage.dealUrl}</h2>
                    <CopyButton type="button" onClick={handleCopyDealLink}>
                      <LinkIcon />
                      <CopyTooltip className="dynamicLink-tooltip ">
                        <ArrowLeftIcon />
                        Copy
                      </CopyTooltip>
                    </CopyButton>
                    {isDealLinkCopied && <p>Copied!</p>}
                  </TextContainer>
                </Grid>
              )}
            </Container>
            <Grid item xs={6} style={{ display: "flex" }}>
              <Box width={200}>
                <StatusSelect
                  onChange={(e) => handleDealStatus(e)}
                  selected={dealPage.status}
                />
              </Box>
              <CurrencyWrapper
                hasError={errors.includes(DealErrorKey.dealCurrency)}
              >
                <label>Currency:</label>
                <CurrencySymbolSelect
                  color={colors.white}
                  onChange={handleChangeDealCurrency}
                  selected={dealPage.dealCurrency || undefined}
                  error={errors.includes(DealErrorKey.dealCurrency)}
                />
              </CurrencyWrapper>
            </Grid>
            <Grid item xs={6}>
              <InputWithText
                placeholder="Title"
                helperText="Deal title:"
                onChange={(e) => handleDealTitle(e)}
                value={dealPage.title}
                error={errors.includes(DealErrorKey.title)}
              />
            </Grid>
          </Grid>
          <Grid
            container
            gridRow={2}
            gridColumn={2}
            rowSpacing={2}
            columnSpacing={4}
          >
            <Grid item xs={6}>
              <DatePicker
                helperText="Start date:"
                placeholder="Start date"
                onChange={handleStartDate}
                value={dealPage.creationDate}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectWithText
                helperText="Industry:"
                placeholder="Industry"
                onChange={(value) =>
                  handleChangeVocabulary({
                    key: "broadCategoryId",
                    value: value as number,
                  })
                }
                options={broadCategoriesOptions}
                selected={dealPage.broadCategoryId || ""}
                maxHeight={300}
                error={errors.includes(DealErrorKey.broadCategoryId)}
              />
            </Grid>
            <Grid item xs={6}>
              <CountryDropdown
                value={dealPage.countryId}
                onChange={handleChangeCountry}
                label="Country:"
                placeholder="Country"
              />
            </Grid>
            <Grid item xs={6}>
              <SelectWithText
                helperText="Founded year:"
                placeholder="Founded year"
                onChange={(value) => handleChangeFoundedYear(value as string)}
                options={yearsOptions}
                selected={dealPage.foundedYear || ""}
                maxHeight={300}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectWithText
                helperText="Stage:"
                placeholder="Stage"
                onChange={(value) =>
                  handleChangeVocabulary({
                    key: "stageId",
                    value: value as number,
                  })
                }
                options={stagesOptions}
                selected={dealPage.stageId || ""}
                maxHeight={300}
                error={errors.includes(DealErrorKey.stageId)}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectWithText
                helperText="Business model:"
                placeholder="Business model"
                onChange={(value) =>
                  handleChangeVocabulary({
                    key: "businessModelId",
                    value: value as number,
                  })
                }
                options={businessModelsOptions}
                selected={dealPage.businessModelId || ""}
                maxHeight={300}
                error={errors.includes(DealErrorKey.businessModelId)}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box>
        <Box mb={2.5}>
          <RightSideItem
            withSelect
            label="ICLUB allocation:"
            placeholder="Total goal"
            onChange={handleTotalGoal}
            onChangeSelect={handleTotalGoalCurrency}
            value={
              formatter.format(dealPage.totalFundingGoal?.amount || 0) as any
            }
            selected={dealPage.totalFundingGoal?.currency}
            amountError={errors.includes(
              DealErrorKey["totalFundingGoal.amount"]
            )}
            currencyError={errors.includes(
              DealErrorKey["totalFundingGoal.currency"]
            )}
          />
        </Box>
        <Box mb={2.5}>
          <RightSideItem
            withSelect
            label="Minimal investment:"
            placeholder="Minimal investment"
            onChange={handleMinimalInvestments}
            onChangeSelect={handleMinimalInvestmentsCurrency}
            value={formatter.format(dealPage.minInvestmentAmount || 0) as any}
            selected={dealPage.investmentCurrency}
            amountError={errors.includes(DealErrorKey.minInvestmentAmount)}
            currencyError={errors.includes(DealErrorKey.investmentCurrency)}
          />
        </Box>
        <AttachedCompany
          hasError={false}
          companyId={dealPage.companyId}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
        <RightSideItem label="List of commitments" />
      </Box>
      {!!selectedCompany && (
        <CompanyAttachModal
          onClose={closeAttachCompanyModal}
          onSubmit={copyCompanyData}
          applyWithoutCopy={applyWithoutCopy}
        />
      )}

      <CropImage
        handleImageUpload={handleImageUpload}
        image={image}
        showCropperModal={showCropperModal}
        setShowCropperModal={setShowCropperModal}
      />
    </TopSectionWrapper>
  );
};

export default TopSection;
