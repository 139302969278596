import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  margin-bottom: 32px;
`;

export const Title = styled.h3`
  margin-bottom: 12px;
  /* font */
  color: ${colors.grey900};
  font-family: "Unica77";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 20px 24px;
  background: ${colors.natural100};
`;

export const Button = styled.button`
  all: unset;
  height: 44px;
  width: 44px;
  border-radius: 8px;
  background-color: ${colors.white};
  border: 1.2px solid ${colors.grey200};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:first-of-type {
    margin-right: 8px;
    margin-left: 20px;
  }

  svg {
    width: 20px;
    height: 20px;
    padding: 2px;
    border-radius: 4px;
    color: ${colors.grey700};
    border: 1.2px solid ${colors.grey200};
  }
`;
