import React from "react";

import { getCurrencySymbol } from "application/helpers/currency";
import { DealTractionAmount } from "integration/api/DealsApi/models";

import { Container } from "./styles";

const TractionAmountInput = ({ amount, currency }: DealTractionAmount) => {
  return (
    <Container>
      <p>
        {amount}
        {getCurrencySymbol(currency)}
      </p>
    </Container>
  );
};

export default TractionAmountInput;
