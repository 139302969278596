import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const BackToPageWrapper = styled(Box)`
  all: unset;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: ${theme.spacing(3)};
  text-align: center;
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
  cursor: pointer;
`;
