import { Box, styled } from "@mui/system";
import theme from "application/theme";
import { colors } from "application/theme/palette";

interface SelectButtonProps {
  active: number;
  filled: number;
  hasError?: boolean;
}

export const SelectButton = styled("div")<SelectButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing(0, 2)};
  cursor: pointer;
  background-color: ${({ active, hasError }) =>
    hasError
      ? theme.palette.common.errorBg
      : active
      ? theme.palette.common.blue50
      : theme.palette.common.grey50};

  border: 1px solid
    ${({ active, filled, hasError }) =>
      hasError
        ? theme.palette.common.red700
        : active
        ? theme.palette.common.blue500
        : filled
        ? theme.palette.common.grey700
        : theme.palette.common.grey300};
  border-radius: ${theme.spacing(1)};
  height: ${theme.spacing(5.5)};
`;

export const StyledSelect = styled("select")`
  cursor: pointer;
`;

export const SelectContainer = styled("div")`
  position: relative;
  margin: ${theme.spacing(0)};
`;

export const DropdownStyle = styled(Box)<{ visible: number }>`
  position: absolute;
  top: ${theme.spacing(6)};
  background-color: ${theme.palette.common.white};
  z-index: 10;
  left: 0;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  overflow-y: scroll;
  border-radius: ${theme.spacing(0.5)};
  box-shadow: 0px 4px 30px 0px rgba(222, 224, 230, 0.8);
  padding: ${(props) => (props.visible ? theme.spacing(1.5, 0) : "0px")};
`;

export const DropdownItem = styled(Box)<{ active: number }>`
  font-family: Unica77;
  display: flex;
  align-items: center;
  font-size: ${theme.spacing(2)};
  font-weight: ${(props) => (props.active ? 700 : 400)};
  color: ${theme.palette.common.grey800};
  height: ${theme.spacing(4.75)};
  padding: ${theme.spacing(0, 1.5)};
  border-radius: ${theme.spacing(0.5)};
  margin-bottom: ${theme.spacing(0.5)};
  text-overflow: clip;
  white-space: nowrap;
  cursor: pointer;
  background-color: ${(props) =>
    !!props.active ? theme.palette.common.grey100 : "white"};
  &:hover,
  :focus,
  :focus:hover {
    background-color: ${(props) =>
      !!props.active
        ? theme.palette.common.blue50
        : theme.palette.common.grey100};
    outline: none;
  }
`;

export const SelectWithTextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const InputSearch = styled("input")`
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  outline: none;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid ${colors.grey300};

  /* font */
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  font-style: normal;
  letter-spacing: 0.08px;
  font-family: Unica77;
  color: ${colors.grey700};
  background-color: ${colors.grey50};
  margin-bottom: 10px;

  &:focus {
    background-color: ${colors.blue50};
    border: 1px solid ${colors.blue700};
  }
`;

export const NoResultsText = styled("p")`
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  font-family: Unica77;
  color: ${colors.grey900};
  text-align: center;
`;
