import React from "react";

import { DealReducedDto } from "integration/api/DealsApi/models";

import Loader from "application/components/Loader";
import DealsTableRow from "features/deals/components/DealsTableRow";

import { colors } from "application/theme/palette";
import { LoaderContainer, Table, Wrapper } from "./styles";

interface Props {
  loading: boolean;
  data: DealReducedDto[];
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

const DealsTable = ({ loading, data, onEdit, onDelete }: Props) => {
  return (
    <Wrapper>
      {loading && (
        <LoaderContainer>
          <Loader color={colors.grey500} />
        </LoaderContainer>
      )}

      <Table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Title</th>
            <th>ID</th>
            <th>Start date</th>
            <th>Status</th>
            <th>ICLUB allocation</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {data.map((item) => (
            <DealsTableRow
              item={item}
              onEdit={onEdit}
              onDelete={onDelete}
              key={item.businessId}
            />
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default DealsTable;
