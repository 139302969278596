import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Empty from "application/containers/Empty";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { requestSelector } from "application/store/request/selectors";
import { PathNames } from "application/routes";
import { useDebounce } from "application/hooks/useDebounce";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { PaginationMUI } from "application/components";

import {
  CompanyListDto,
  CreateCompanyResponse,
  Sort,
} from "integration/api/CompanyApi/models";
import { Currency } from "integration/api/DealsApi/models";

import { Table } from "features/companies/containers";
import CompanyListActions from "features/companies/store/actions";
import { FilterSection, Header } from "features/companies/components";
import { companiesStatuses } from "features/companies/helpers/companiesStatuses";

import { EmptyPageContainer } from "./styles";

const defaultSize = 10;

const CompaniesPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getCompanyList, getCompanyById, addCompany, getCompanyTotals } =
    useAppSelector(requestSelector);
  const { content, totalPages } = useAppSelector(
    (state) => state.companyList.companies
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [companyToDelete, setCompanyToDelete] = useState<CompanyListDto | null>(
    null
  );
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const [sort, setSort] = useState<Sort>(
    (searchParams.get("sort") as Sort) || Sort.asc
  );
  const [status, setStatus] = useState<string>(
    searchParams.get("status") || companiesStatuses[0]?.value
  );
  const [currency, setCurrency] = useState<Currency>(
    (searchParams.get("currency") as Currency) || Currency.USD
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const search = useDebounce(searchValue, 300);

  const loading =
    getCompanyList.loading ||
    getCompanyById.loading ||
    addCompany.loading ||
    getCompanyTotals.loading;

  useEffect(() => {
    dispatch(
      CompanyListActions.getCompanyList({
        page: page - 1,
        search,
        size: defaultSize,
        sort,
        status: status === "ALL" ? "" : status,
        currency,
      })
    );
  }, [search, page, sort, status, currency]);

  useEffect(() => {
    dispatch(
      CompanyListActions.getCompanyTotals({
        search,
        status: status === "ALL" ? "" : status,
        currency,
      })
    );
  }, [search, status, currency]);

  const getSearchParams = () => {
    const params = {} as { [key: string]: string };
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  };

  const onPageChange = async (_: React.ChangeEvent<unknown>, page: number) => {
    const params = getSearchParams();
    setPage(page);
    setSearchParams({
      ...params,
      page: String(page),
    });
  };

  const onAddCompany = async () => {
    const response = await dispatch(CompanyListActions.addCompany());

    isResponseFulfilled(response, () => {
      const company = response.payload as CreateCompanyResponse;
      navigate(`${PathNames.companies}/${company.id}`, {
        state: location.search || "",
      });
    });
  };

  const handleSearch = (value: string) => {
    const params = {} as { [key: string]: string };
    searchParams.forEach((value, key) => {
      if (key !== "search") {
        params[key] = value;
      }
    });

    setSearchValue(value);
    setPage(1);
    setSearchParams({
      ...params,
      page: "1",
      ...(value ? { search: String(value) } : {}),
    });
  };

  const handleChangeStatus = (value: string) => {
    const params = getSearchParams();
    setStatus(value);
    setPage(1);
    setSearchParams({
      ...params,
      page: "1",
      status: value,
    });
  };

  const onDeleteCompany = (company: CompanyListDto) => {
    setCompanyToDelete(company);
  };

  const onCloseDelete = () => {
    setCompanyToDelete(null);
  };

  const onSubmitDelete = () => {
    dispatch(
      CompanyListActions.deleteCompany(Number(companyToDelete?.id))
    ).then((res) => {
      isResponseFulfilled(res, () => {
        const isEmptyPage = content.length === 1;
        const currentPage = isEmptyPage && page > 1 ? page - 2 : page - 1;
        onCloseDelete();
        setShowSuccessDeleteModal(true);
        if (isEmptyPage) {
          const params = getSearchParams();
          setPage(page - 1);
          setSearchParams({
            ...params,
            page: String(page - 1),
          });
        } else {
          dispatch(
            CompanyListActions.getCompanyList({
              page: currentPage,
              size: defaultSize,
              search: search,
              sort,
              status: status === "ALL" ? "" : status,
              currency,
            })
          );
        }
      });
    });
  };

  const handleCloseSuccessDeleteModal = () => {
    setShowSuccessDeleteModal(false);
  };

  const toggleSort = () => {
    const params = getSearchParams();
    const isAscSort = sort === Sort.asc;
    setSort(isAscSort ? Sort.desc : Sort.asc);
    setSearchParams({
      ...params,
      page: "1",
      sort: isAscSort ? Sort.desc : Sort.asc,
    });
  };

  return (
    <React.Fragment>
      <Header currency={currency} onAddCompany={onAddCompany} />
      <FilterSection
        searchValue={searchValue}
        setSearchValue={handleSearch}
        status={status}
        handleChangeStatus={handleChangeStatus}
        sort={sort}
        toggleSort={toggleSort}
        currency={currency}
        setCurrency={setCurrency}
      />
      {!!content.length || loading ? (
        <>
          <Table
            currency={currency}
            loading={loading}
            onDeleteCompany={onDeleteCompany}
          />
          {!!totalPages && (
            <PaginationMUI
              variant="text"
              color="primary"
              page={page}
              count={totalPages}
              onChange={onPageChange}
            />
          )}
        </>
      ) : (
        <>
          <EmptyPageContainer>
            <Empty description="You haven’t any company yet!" />
            <button type="button" onClick={onAddCompany}>
              Add new company
            </button>
          </EmptyPageContainer>
        </>
      )}
      {!!companyToDelete && (
        <ConfirmPopUp
          title="Delete company"
          onClose={onCloseDelete}
          onSubmit={onSubmitDelete}
          description={`You want to delete ${
            companyToDelete.companyName || ""
          } company. Are you sure?`}
        />
      )}
      {showSuccessDeleteModal && (
        <SuccessPopUp
          title={"The company is deleted"}
          onSubmit={handleCloseSuccessDeleteModal}
        />
      )}
    </React.Fragment>
  );
};

export default CompaniesPage;
