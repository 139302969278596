import { CompanyStatus } from "integration/api/CompanyApi/models";

export const companiesStatuses = [
  { value: "ALL", label: "All" },
  { value: CompanyStatus.DRAFT, label: "Draft" },
  { value: CompanyStatus.ACTIVE, label: "Active" },
  { value: CompanyStatus.ARCHIVED, label: "Archived" },
  { value: CompanyStatus.RIGHT_OFF, label: "Right off" },
  { value: CompanyStatus.EXIT, label: "Exit" },
];
