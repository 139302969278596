import styled from "@emotion/styled";
import Add from "@mui/icons-material/Add";

import { colors } from "application/theme/palette";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 140%;
  font-style: normal;
  font-family: "Unica77";
  color: ${colors.grey900};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SortButton = styled.div`
  height: 44px;
  padding: 0 16px;
  margin: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid ${colors.grey300};
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    color: ${colors.grey700};
  }
  p {
    margin-left: 12px;
    /* font */
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    font-style: normal;
    font-family: "Unica77";
    letter-spacing: 0.08px;
    color: ${colors.grey900};
  }
`;

export const AddIcon = styled(Add)`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  color: ${colors.white};
`;
