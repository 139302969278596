import React, { FC, useEffect, useMemo, useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

import { ReactComponent as CloseSvg } from "application/assets/icons/close.svg";
import { ReactComponent as EditSvg } from "application/assets/icons/edit.svg";
import { useAppSelector } from "application/hooks/useAppSelector";
import PipedriveActions from "application/store/Pipedrive/ActionCreators";
import { useAppDispatch } from "application/hooks/useAppDispatch";

import { TableProps } from "./model";
import {
  ActionButton,
  Actions,
  CopyButton,
  CopyTooltip,
  OwnerInfo,
} from "./styles";

const TableRow: FC<TableProps> = ({ owner, handleDelete, handleEdit }) => {
  const dispatch = useAppDispatch();
  const { pipedriveSources } = useAppSelector((state) => state.pipedrive);
  const [isCopied, setIsCopied] = useState(false);

  const dealSource = useMemo(() => {
    const sourceName =
      pipedriveSources.find((source) => source.id === owner.pipedriveSourceId)
        ?.label || "";

    return sourceName;
  }, [pipedriveSources, owner]);

  useEffect(() => {
    dispatch(PipedriveActions.getPipedriveSources());
  }, []);

  const copyTextToClipboard = async (text: string) => {
    return await navigator.clipboard.writeText(text);
  };

  const handleCopy = () => {
    copyTextToClipboard(owner.referralUrl).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  return (
    <tr>
      <td>
        <OwnerInfo>
          <h2>{owner.name}</h2>
          <p>{owner.email}</p>
        </OwnerInfo>
      </td>
      <td>{dealSource}</td>
      <td>{owner.referralUrl}</td>
      <td>
        <Actions>
          <CopyButton type="button" onClick={handleCopy}>
            <LinkIcon />
            <CopyTooltip className="tooltip">
              <ArrowLeftIcon />
              Copy
            </CopyTooltip>
            {isCopied && <p>Copied!</p>}
          </CopyButton>
          <ActionButton onClick={() => handleEdit(owner)}>
            <EditSvg />
          </ActionButton>
          <ActionButton onClick={() => handleDelete(owner)}>
            <CloseSvg />
          </ActionButton>
        </Actions>
      </td>
    </tr>
  );
};

export default TableRow;
