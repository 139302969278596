import React, { FC } from "react";
import { Typography } from "@mui/material";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { colors } from "application/theme/palette";
import { TextAreaStyled } from "application/components";

import {
  DealSliceAction,
  setNotificationsData,
} from "features/deal-profile/store/slice";
import { DealErrorKey } from "features/deal-profile/helpers/formatDealError";

import { Container, Content } from "./styles";

export const NotificationDealText: FC = () => {
  const dispatch = useAppDispatch();
  const { profile, errors } = useAppSelector((state) => state.dealPage);
  const notificationsData = profile.notificationsData;
  const hasError = errors.includes(
    DealErrorKey["notificationsData.notificationText"]
  );

  const handleChangeNotificationsData = (value: string) => {
    dispatch(setNotificationsData({ key: "notificationText", value }));
    if (value && hasError) {
      dispatch(
        DealSliceAction.setErrors(
          errors.filter(
            (item) =>
              item !== DealErrorKey["notificationsData.notificationText"]
          )
        )
      );
    }
  };

  return (
    <Container>
      <Typography
        variant="h3"
        color={hasError ? colors.red700 : colors.grey900}
        fontWeight={500}
      >
        Notification deal text
      </Typography>
      <Content>
        <TextAreaStyled
          value={notificationsData?.notificationText || ""}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChangeNotificationsData(e.target.value)
          }
          filled={notificationsData?.notificationText?.length}
          hasError={hasError}
        />
      </Content>
    </Container>
  );
};
