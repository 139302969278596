import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import BasicPopUp from "application/containers/BasicPopUp";
import { TextFieldStyled } from "application/components";
import CurrencySelect from "application/components/CurrencySelect";
import UsersSearchInput from "application/components/UsersSearchInput";

import { Currency } from "integration/api/DealsApi/models";

import { addInvestorSchema } from "features/portfolio-investors/helpers/validations";

import { AddInvestorForm, AddInvestorProps } from "./models";
import { FieldsContainer } from "./styles";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";

const AddInvestor: FC<AddInvestorProps> = ({ onClose, onSubmit }) => {
  const { id } = useParams();
  const { loading } = useAppSelector(requestSelector).createInvestor;
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<AddInvestorForm>({
    resolver: yupResolver(addInvestorSchema),
  });

  const handleChangeInvestorId = (id: number | null) => {
    setValue("investorId", Number(id), { shouldValidate: true });
  };
  const handleChangeCurrency = (currency: Currency) => {
    setValue("currency", currency, { shouldValidate: true });
  };

  const onSubmitForm = () => {
    if (loading) return;
    const { investmentAmount, investorId, currency } = watch();
    onSubmit({
      id: Number(id),
      investmentAmount,
      currency,
      investorId: Number(investorId),
    });
  };

  return (
    <BasicPopUp
      isLoading={loading}
      title="Add investor"
      onClose={onClose}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <FieldsContainer>
        <UsersSearchInput
          searchItem={watch("investorId")}
          setSearchItem={handleChangeInvestorId}
          error={!!errors.investmentAmount?.message}
        />
        <TextFieldStyled
          id="investmentAmount"
          fullWidth
          size="small"
          type="number"
          color="secondary"
          placeholder="Enter investment amount"
          filled={watch("investmentAmount")}
          error={!!errors.investmentAmount?.message}
          {...register("investmentAmount")}
        />
        <CurrencySelect
          maxHeight={300}
          selected={watch("currency")}
          onChange={handleChangeCurrency}
          placeholder="Choose currency"
          error={!!errors.currency?.message}
        />
      </FieldsContainer>
    </BasicPopUp>
  );
};

export default AddInvestor;
