import React, { FC } from "react";
import { Typography } from "@mui/material";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { colors } from "application/theme/palette";
import { TextAreaStyled } from "application/components";

import {
  DealSliceAction,
  setNotificationsData,
} from "features/deal-profile/store/slice";
import { DealErrorKey } from "features/deal-profile/helpers/formatDealError";

import { Container, Content } from "./styles";

export const CallWithFounderDate: FC = () => {
  const dispatch = useAppDispatch();
  const { profile, errors } = useAppSelector((state) => state.dealPage);
  const notificationsData = profile.notificationsData;
  const hasError =
    !notificationsData?.callWithFounderDate &&
    errors.includes(DealErrorKey["notificationsData.callWithFounderDate"]);

  const handleChangeCallWithFounderDate = (value: string) => {
    dispatch(setNotificationsData({ key: "callWithFounderDate", value }));
    if (value && hasError) {
      dispatch(
        DealSliceAction.setErrors(
          errors.filter(
            (item) =>
              item !== DealErrorKey["notificationsData.callWithFounderDate"]
          )
        )
      );
    }
  };

  return (
    <Container>
      <Typography
        variant="h3"
        color={hasError ? colors.red700 : colors.grey900}
        fontWeight={500}
      >
        Call with founder date
      </Typography>
      <Content>
        <TextAreaStyled
          value={notificationsData?.callWithFounderDate || ""}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChangeCallWithFounderDate(e.target.value)
          }
          filled={notificationsData?.callWithFounderDate?.length}
          hasError={hasError}
        />
      </Content>
    </Container>
  );
};
