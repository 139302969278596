import { createSlice } from "@reduxjs/toolkit";
import { getCountries } from "./ActionCreators";
import { CountryDto } from "integration/api/CountryApi/models";

interface InitialState {
  countries: CountryDto[];
}

const initialState: InitialState = {
  countries: [],
};

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
  },
});

export default countrySlice.reducer;
