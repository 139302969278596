import styled from "@emotion/styled";

import { colors } from "application/theme/palette";
import {
  UserStatus,
  getUserStatusColor,
} from "application/helpers/userStatuses";

interface UserStatusProps {
  status: UserStatus;
}

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  font-family: Unica77;
`;

export const DetailSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px;
  border-radius: 8px;
  background: ${colors.grey100};
  margin-bottom: 32px;
`;

export const DetailInfoSection = styled.div`
  display: flex;
  align-items: center;

  > div:last-of-type {
    h1 {
      color: #000;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }
  }
`;

export const NameWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 4px;
  align-items: center;
`;

export const IconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
  border-radius: 50%;
  background-color: ${colors.grey500};
  //font
  color: ${colors.white};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-right: 12px;
`;

export const CountryStyled = styled.div`
  display: flex;
  align-items: center;

  span {
    width: 16px;
    height: 16px;
  }

  p {
    color: ${colors.grey900};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
    margin-left: 8px;
  }
`;

export const KYCSection = styled.div`
  display: flex;
  align-items: center;
`;

export const KYCItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  p {
    color: ${colors.grey500};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
    margin-bottom: 4px;
  }

  h2 {
    color: ${colors.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;

export const UserInfoSection = styled.div`
  display: grid;
  grid-template-columns: max-content;
  gap: 16px;
  padding: 0 24px;
`;

export const InfoItem = styled.div`
  display: grid;
  grid-template-columns: 240px max-content max-content max-content;
  gap: 12px;

  > h3 {
    display: flex;
    align-items: center;
    color: ${colors.grey500};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.07px;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      margin-bottom: 2px;
      path {
        fill: ${colors.grey500};
      }
    }
  }

  > h2 {
    color: ${colors.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }

  > span {
    color: ${colors.blue700};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }

  > p {
    padding: 4px 8px;
    color: ${colors.green700};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.06px;
    border-radius: 4px;
    background: ${colors.green200};
  }
`;

export const CopyButton = styled("button")`
  all: unset;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 2px;
  border-radius: 4px;
  border: 1.2px solid ${colors.grey200};
  background: ${colors.white};

  svg {
    width: 20px;
    height: 20px;
  }

  &:first-of-type:hover {
    .email-tooltip {
      display: flex;
    }
  }
  &:last-of-type:hover {
    .phone-tooltip {
      display: flex;
    }
  }
`;

export const CopyTooltip = styled("div")`
  position: absolute;
  left: 36px;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 4px;
  background: ${colors.grey700};
  //font
  color: ${colors.white};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.06px;

  svg {
    right: 32.5px;
    position: absolute;
    width: 29px;
    height: 29px;

    path {
      fill: ${colors.grey700};
    }
  }
`;

export const UserStatusStyled = styled("div")<UserStatusProps>`
  padding: 4px 32px;
  border-radius: 8px;
  background: ${({ status }) => getUserStatusColor(status)};
  color: ${colors.white};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.07px;
`;

export const GeneratePasswordButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  color: ${colors.blue700};
  text-align: center;
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
`;
