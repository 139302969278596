import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

export const OwnerInfo = styled("div")`
  display: flex;
  flex-direction: column;

  h2 {
    color: ${colors.grey900};
    font-family: Unica77;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
    margin-bottom: 4px;
  }
  p {
    color: ${colors.grey500};
    font-family: Unica77;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.06px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid ${colors.grey200};
    width: 24px;
    height: 24px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
