import React, { useRef, useState } from "react";

import useOnClickOutside from "application/hooks/useOnClickOutside";

import { Menu, Wrapper, MoreButton } from "./styles";

interface Props {
  onEditYear?: () => void;
  onDeleteYear?: () => void;
}

const TableMore = ({ onEditYear, onDeleteYear }: Props) => {
  const ref = useRef(null);
  const [visible, setVisible] = useState(false);

  const toggleMore = () => {
    setVisible(!visible);
  };

  useOnClickOutside(ref, () => setVisible(false));

  const handleDelete = () => {
    toggleMore();
    onDeleteYear && onDeleteYear();
  };

  const handleEdit = () => {
    toggleMore();
    onEditYear && onEditYear();
  };

  return (
    <Wrapper ref={ref}>
      <MoreButton onClick={toggleMore} />

      {visible && (
        <Menu>
          <p onClick={handleEdit}>Edit Year</p>
          <p onClick={handleDelete}>Delete Year</p>
        </Menu>
      )}
    </Wrapper>
  );
};

export default TableMore;
