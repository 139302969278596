import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

interface StyledProps {
  $loading?: boolean;
  disabled?: boolean;
}

export const LoaderContainer = styled.div`
  z-index: 1;
  position: absolute;
`;

export const Container = styled.button<StyledProps>`
  all: unset;
  box-sizing: border-box;
  height: 44px;
  padding: 0 32px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $loading, disabled }) =>
    disabled ? colors.grey200 : $loading ? "transparent" : colors.blue700};

  cursor: pointer;
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    font-style: normal;
    text-align: center;
    letter-spacing: 0.08px;
    font-family: "Unica77";
    opacity: ${({ $loading }) => ($loading ? 0 : 1)};
    color: ${({ disabled }) => (disabled ? colors.grey300 : colors.white)};
  }
  svg {
    opacity: ${({ $loading }) => ($loading ? 0 : 1)};
  }
`;
