import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

export const Container = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 140%;
  font-style: normal;
  font-family: "Unica77";
  color: ${colors.grey900};
`;

export const FiltersSection = styled("div")`
  display: flex;
  align-items: center;
  & > :first-of-type {
    width: 220px;
    margin-right: 20px;
  }
`;
