import React from "react";

export const SwissFrancsIcon = ({ color = "#111928" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9962 5H9.73222C9.36468 5 9.06686 5.29781 9.06686 5.66536V14.0619H7.66536C7.29781 14.0619 7 14.3597 7 14.7272C7 15.0948 7.29781 15.3926 7.66536 15.3926H9.06686V17.3346C9.06686 17.7022 9.36468 18 9.73222 18C10.0998 18 10.3976 17.7022 10.3976 17.3346V15.3926H12.8642C13.2317 15.3926 13.5295 15.0948 13.5295 14.7272C13.5295 14.3597 13.2317 14.0619 12.8642 14.0619H10.3976V11.7361H15.0423C15.4098 11.7361 15.7077 11.4383 15.7077 11.0707C15.7077 10.7032 15.4098 10.4054 15.0423 10.4054H10.3976V6.33071H15.9962C16.3637 6.33071 16.6615 6.0329 16.6615 5.66536C16.6615 5.29781 16.3637 5 15.9962 5Z"
        fill={color}
      />
    </svg>
  );
};
