import React, { FC, useState } from "react";
import { ArrowLeft, InsertLinkOutlined } from "@mui/icons-material";

import { CopyButtonProps } from "./models";
import { CopyTooltip, Wrapper, CopyButtonStyled } from "./styles";

const CopyButton: FC<CopyButtonProps> = ({ copyText }) => {
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const copyTextToClipboard = async (text: string) => {
    return await navigator.clipboard.writeText(text);
  };

  const handleCopyId = () => {
    copyTextToClipboard(copyText).then(() => {
      setIsLinkCopied(true);
      setTimeout(() => {
        setIsLinkCopied(false);
      }, 1500);
    });
  };
  return (
    <Wrapper>
      <CopyButtonStyled type="button" onClick={handleCopyId}>
        <InsertLinkOutlined />
        <CopyTooltip className="id-tooltip">
          <ArrowLeft />
          Copy
        </CopyTooltip>
      </CopyButtonStyled>
      {isLinkCopied && <p>Copied!</p>}
    </Wrapper>
  );
};

export default CopyButton;
