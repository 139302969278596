import { TextFieldProps } from "@mui/material";
import React, { useRef, useState } from "react";
import { TextFieldStyled } from "../TextFieldStyled/style";
import { InvestmentAnalyticRequestDto } from "integration/api/PortfolioAPI/models";

type TextFieldT = Omit<TextFieldProps, "value" | "onChange">;

interface FormattedTextFieldProps extends TextFieldT {
  value: string;
  filled: number;
  onChange: (value: string, key: keyof InvestmentAnalyticRequestDto) => void;
}

export const FormattedTextField = ({
  id,
  size,
  color,
  value,
  placeholder,
  filled,
  error,
  label,
  onChange,
}: FormattedTextFieldProps) => {
  const [inputValue, setInputValue] = useState<string>(value ?? "");
  const inputRef = useRef<HTMLInputElement>(null);
  const formatter = new Intl.NumberFormat("en-US");

  const formatValue = (value: string): string => {
    const numberValue = parseFloat(value.replace(/,/g, ""));
    return isNaN(numberValue) ? "" : formatter.format(numberValue);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let rawValue = e.target.value;
    rawValue = rawValue.replace(/[^0-9.,]/g, ""); // Allow only digits and decimal point
    const newValue = formatValue(rawValue);
    
    // Save the current caret position
    const caretPosition = e.target.selectionStart || 0;
    const originalLength = rawValue.length; // Length before formatting
    const formattedLength = newValue.length; // Length after formatting

    setInputValue(newValue);
    onChange(rawValue, id as keyof InvestmentAnalyticRequestDto);

    // Calculate the new caret position
    const newCaretPosition = caretPosition + (formattedLength - originalLength);
    
    // Set the focus and the new cursor position
    if (inputRef.current) {
      requestAnimationFrame(() => {
        inputRef.current?.setSelectionRange(newCaretPosition, newCaretPosition);
      });
    }
  };

  return (
    <TextFieldStyled
      fullWidth
      inputRef={inputRef}
      value={inputValue}
      id={id}
      size={size}
      color={color}
      placeholder={placeholder}
      filled={filled}
      error={error}
      label={label}
      onChange={handleChange}
      onFocus={() => inputRef.current?.focus()}
    />
  );
};
