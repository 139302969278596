import axiosInstance from "../axiosInstance";
import { PipedriveDealOwnerDto, PipedriveSourceDto } from "./models";

const PipedriveApi = {
  getPipedriveOwnersRegulars: () => {
    return axiosInstance.get<PipedriveDealOwnerDto[]>(
      "/api/admin/pipedrive/owners/regulars"
    );
  },
  getPipedriveOwnersReferrals: () => {
    return axiosInstance.get<PipedriveDealOwnerDto[]>(
      "/api/admin/pipedrive/owners/referrals"
    );
  },
  getPipedriveSources: () => {
    return axiosInstance.get<PipedriveSourceDto[]>(
      "/api/admin/pipedrive/sources"
    );
  },
};

export default PipedriveApi;
