import React, { FC } from "react";
import { SaveAlt } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import BasicPopUp from "application/containers/BasicPopUp";
import { colors } from "application/theme/palette";

import { DeleteFilePopUpProps } from "./model";

const DeleteFilePopUp: FC<DeleteFilePopUpProps> = ({
  fileName,
  onClose,
  onSubmit,
  isLoading,
}) => {
  return (
    <BasicPopUp
      title="Delete file"
      onClose={onClose}
      onSubmit={onSubmit}
      disabled={isLoading}
      isLoading={isLoading}
    >
      <Box display="flex" alignItems="center">
        <Box mr={1.5}>
          <SaveAlt />
        </Box>
        <Typography variant="subtitle1" color={colors.grey900}>
          {fileName}
        </Typography>
      </Box>
    </BasicPopUp>
  );
};

export default DeleteFilePopUp;
