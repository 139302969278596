import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import useOnClickOutside from "application/hooks/useOnClickOutside";
import { ArrowDropDown } from "application/assets/ArrowDropDown";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import PipedriveActions from "application/store/Pipedrive/ActionCreators";

import { PipedriveOwnersDropdownProps } from "./model";
import {
  DropdownStyled,
  TextField,
  DropdownList,
  ListItem,
  Input,
} from "./styles";

const PipedriveOwnersDropdown: FC<PipedriveOwnersDropdownProps> = ({
  value,
  onChange,
  label,
  placeholder,
  helperText,
  isReferrals,
}) => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const { pipedriveOwners } = useAppSelector((state) => state.pipedrive);
  const [showList, setShowList] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useOnClickOutside(ref, () => setShowList(false));

  useEffect(() => {
    if (isReferrals) {
      dispatch(PipedriveActions.getPipedriveOwnersReferrals());
    } else {
      dispatch(PipedriveActions.getPipedriveOwnersRegulars());
    }
  }, []);

  const filteredOwnerList = useMemo(() => {
    return pipedriveOwners.filter((owner) =>
      owner.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, pipedriveOwners]);

  const selectedOwnerName = useMemo(() => {
    return pipedriveOwners.find((owner) => owner.id === value)?.name;
  }, [value, pipedriveOwners]);

  const handleSelectOwner = (code: number) => {
    setShowList(false);
    setSearchValue("");
    onChange(code);
  };

  return (
    <DropdownStyled ref={ref}>
      {label && <h3>{label}</h3>}
      <TextField
        isActive={showList}
        filled={!!value}
        onClick={() => setShowList((prev) => !prev)}
      >
        {helperText && (
          <label>
            {helperText}
            <div />
          </label>
        )}
        <p>{selectedOwnerName || placeholder}</p>
        <ArrowDropDown />
      </TextField>
      <DropdownList opened={showList}>
        <div>
          <Input
            placeholder="Search owner"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onClick={() => setShowList(true)}
          />
          {filteredOwnerList?.length ? (
            filteredOwnerList.map((owner) => (
              <ListItem
                key={owner.id}
                selected={owner.id === value}
                onClick={() => handleSelectOwner(owner.id)}
              >
                <h2>{owner.name}</h2>
                <p>{owner.email}</p>
              </ListItem>
            ))
          ) : (
            <span>No results</span>
          )}
        </div>
      </DropdownList>
    </DropdownStyled>
  );
};

export default PipedriveOwnersDropdown;
