export async function generateRSAKeyPair(): Promise<{
  privateKey: CryptoKey;
  publicKeyBase64: string;
} | null> {
  try {
    const keyPair = (await window.crypto.subtle.generateKey(
      {
        name: "RSA-OAEP",
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: { name: "SHA-256" },
      },
      true, // Extractable
      ["encrypt", "decrypt"] // Key usages
    )) as CryptoKeyPair;

    const exportedPublicKey = await window.crypto.subtle.exportKey(
      "spki",
      keyPair.publicKey
    );
    const publicKeyBase64 = arrayBufferToBase64(exportedPublicKey);

    return {
      privateKey: keyPair.privateKey,
      publicKeyBase64,
    };
  } catch (error) {
    console.error("Error generating RSA key pair:", error);
    return null;
  }
}

export async function decryptAESKey(
  encryptedMessage: string,
  privateKey: CryptoKey
): Promise<string> {
  try {
    // Decode the Base64-encoded encrypted message
    const encryptedBuffer = Uint8Array.from(
      window.atob(encryptedMessage),
      (c) => c.charCodeAt(0)
    );

    const decrypted = await window.crypto.subtle.decrypt(
      {
        name: "RSA-OAEP",
      },
      privateKey,
      encryptedBuffer
    );

    return new TextDecoder().decode(decrypted);
  } catch (error) {
    console.error("Decryption failed:", error);
    throw new Error("Decryption failed.");
  }
}

// Helper functions
const base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

function arrayBufferToBase64(buffer: ArrayBufferLike): string {
  return window.btoa(String.fromCharCode(...new Uint8Array(buffer)));
}

// Function to decrypt the message using the decrypted secret key

export const decryptName = async (
  encryptedFirstName: string,
  keyBase64: string
) => {
  const encryptedBytes = base64ToArrayBuffer(encryptedFirstName);
  const keyBytes = base64ToArrayBuffer(keyBase64);

  const iv = encryptedBytes.slice(0, 12); // Extract the first 12 bytes for the IV
  const encryptedData = encryptedBytes.slice(12); // The rest is the encrypted data

  const cryptoKey = await window.crypto.subtle.importKey(
    "raw",
    keyBytes,
    "AES-GCM",
    false,
    ["decrypt"]
  );

  try {
    const decryptedBuffer = await window.crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: new Uint8Array(iv),
      },
      cryptoKey,
      encryptedData
    );

    const decoder = new TextDecoder();
    return decoder.decode(decryptedBuffer);
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

function generateRandomIV() {
  const iv = new Uint8Array(12);
  window.crypto.getRandomValues(iv); // Generates cryptographically strong random values
  return iv;
}

export const encryptName = async (name: string, keyBase64: string) => {
  // Convert the name to Base64 for simplicity in handling
  const base64String = window.btoa(name);
  const nameBytes = base64ToArrayBuffer(base64String);
  const keyBytes = base64ToArrayBuffer(keyBase64);

  // Generate a random IV
  const iv = generateRandomIV(); // 12 bytes for AES-GCM

  const cryptoKey = await window.crypto.subtle.importKey(
    "raw",
    keyBytes,
    "AES-GCM",
    false,
    ["encrypt"]
  );

  try {
    // Encrypt the name bytes
    const encryptedBuffer = await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      cryptoKey,
      nameBytes
    );

    // Combine IV and encrypted data into a single buffer
    const combinedBuffer = new Uint8Array(iv.length + encryptedBuffer.byteLength);
    combinedBuffer.set(iv); // Set the IV at the start
    combinedBuffer.set(new Uint8Array(encryptedBuffer), iv.length); // Append the encrypted data

    // Convert combined buffer to Base64 for easy handling
    return arrayBufferToBase64(combinedBuffer);
  } catch (error) {
    console.error("Encryption failed:", error);
    return null;
  }
};
