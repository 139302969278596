import { createSlice } from "@reduxjs/toolkit";

import LostReasonsActions from "./actions";

interface InitialState {
  lostReasons: string[];
}

const initialState: InitialState = {
  lostReasons: [],
};

export const lostReasonsSlice = createSlice({
  name: "lostReasons",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get lost reasons
    builder.addCase(
      LostReasonsActions.getLostReasons.fulfilled,
      (state, action) => {
        state.lostReasons = action.payload;
      }
    );
  },
});

export const DealInvestorsSliceAction = lostReasonsSlice.actions;
export default lostReasonsSlice.reducer;
