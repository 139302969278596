import React from "react";
import update from "immutability-helper";
import { useCallback } from "react";
import { DragAndDropItem } from "../DragAndDropCard";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { colors } from "application/theme/palette";
import {
  CommonIconWrapper,
  DragAndDropWrapper,
  EmptyAreaWrapper,
  Placeholder,
} from "./styles";
import { Add } from "@mui/icons-material";
import { VideoLink } from "../VideoLink";
import { Item } from "features/deal-profile/containers/Videos";
import { TeamMember } from "../TeamMember";
import { FileItem } from "../FileItem";
import { OverviewSectionUploads } from "features/deal-profile/pages/OverviewSection/types";

export interface ContainerState {
  cards: Item[];
}

export interface DragAndDropAreaProps {
  header: OverviewSectionUploads;
  cards: Item[];
  setCards: (array: Item[]) => void;
  addCard: () => void;
  removeCard: (id: number) => void;
  editCard?: (id: number) => void;
  placeholder: string;
  hasError?: boolean;
}

export const DragAndDropArea = ({
  header,
  cards,
  setCards,
  addCard,
  placeholder,
  removeCard,
  editCard,
  hasError,
}: DragAndDropAreaProps) => {
  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setCards(
        update(cards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, cards[dragIndex] as Item],
          ],
        })
      );
    },
    [cards]
  );

  const renderCard = (card: Item, index: number) => {
    return (
      <DragAndDropItem
        key={card.id}
        index={index}
        id={card.id}
        text={card.url}
        moveCard={moveCard}
        cardItem={handleContent({
          id: card.id,
          url: card.url,
          firstName: card.firstName,
          lastName: card.lastName,
          title: card.title,
          fileName: card.fileName,
          imageUrl: card.imageUrl,
          imageName: card.imageName,
          isResponsibleForQa: card.isResponsibleForQa,
          base64Image: card.base64Image,
          imageDeleted: card.imageDeleted,
        })}
        removeCard={removeCard}
        editCard={editCard}
      />
    );
  };

  const handleContent = ({
    id,
    url,
    firstName,
    lastName,
    title,
    fileName,
    imageUrl,
    imageName,
    isResponsibleForQa,
    base64Image,
    imageDeleted,
  }: Item) => {
    switch (header) {
      case OverviewSectionUploads.video:
      default:
        return <VideoLink url={url} />;
      case OverviewSectionUploads.team:
        return (
          <TeamMember
            url={url}
            firstName={firstName}
            lastName={lastName}
            title={title}
            imageUrl={imageUrl}
            imageName={imageName}
            isResponsibleForQa={!!isResponsibleForQa}
            base64Image={base64Image}
            imageDeleted={imageDeleted}
          />
        );
      case OverviewSectionUploads.pitchDeck:
      case OverviewSectionUploads.additionalMaterials:
        return <FileItem fileId={id} fileName={fileName} />;
    }
  };

  return (
    <>
      <Box mb={4}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2.5}
        >
          <Typography
            variant="h3"
            color={hasError ? colors.red700 : colors.grey900}
            fontWeight={500}
          >
            {header}
          </Typography>
          <CommonIconWrapper onClick={addCard}>
            <Add />
          </CommonIconWrapper>
        </Box>
        {cards.length ? (
          <DragAndDropWrapper>
            <ol style={{ listStyleType: "decimal" }}>
              {cards.map((card, i) => renderCard(card, i))}
            </ol>
          </DragAndDropWrapper>
        ) : (
          <EmptyAreaWrapper hasError={hasError}>
            <Placeholder onClick={addCard}>{placeholder}</Placeholder>
          </EmptyAreaWrapper>
        )}
      </Box>
    </>
  );
};
