import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useDebounce } from "application/hooks/useDebounce";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";
import Loader from "application/components/Loader";
import { colors } from "application/theme/palette";

import { Currency } from "integration/api/DealsApi/models";

import PortfolioActions from "../store/actions";
import { Header, Table } from "../components";

const PortfolioPage = () => {
  const dispatch = useAppDispatch();
  const { getPortfolio, getPortfolioInvestmentTotal } =
    useAppSelector(requestSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const [currency, setCurrency] = useState<Currency>(
    (searchParams.get("currency") as Currency) || Currency.USD
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const search = useDebounce(searchValue, 300);

  const loading = getPortfolioInvestmentTotal.loading || getPortfolio.loading;

  const getPortfolioTotal = (currentCurrency?: Currency) => {
    dispatch(
      PortfolioActions.getPortfolioInvestmentTotal({
        ...(search ? { search: search } : {}),
        currency: currentCurrency || currency,
      })
    );
  };

  useEffect(() => {
    dispatch(
      PortfolioActions.getPortfolio({
        page: page - 1,
        ...(search ? { search: search } : {}),
        currency,
      })
    );
    getPortfolioTotal();
  }, [search]);

  const getSearchParams = () => {
    const params = {} as { [key: string]: string };
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  };

  const onPageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    const params = getSearchParams();
    dispatch(
      PortfolioActions.getPortfolio({
        page: page - 1,
        ...(searchValue ? { search: searchValue } : {}),
        currency,
      })
    );
    setPage(page);
    setSearchParams({
      ...params,
      page: String(page),
      ...(searchValue ? { search: searchValue } : {}),
    });
  };

  const handleSearch = (value: string) => {
    const params = getSearchParams();
    setSearchValue(value);
    setPage(1);
    setSearchParams({
      ...params,
      page: "1",
      ...(value ? { search: String(value) } : {}),
    });
  };

  const handleChangeCurrency = (value: Currency) => {
    const params = getSearchParams();
    setCurrency(value);
    dispatch(
      PortfolioActions.getPortfolio({
        page: page - 1,
        ...(search ? { search: search } : {}),
        currency: value,
      })
    );
    setSearchParams({
      ...params,
      currency: value,
    });
    getPortfolioTotal(value);
  };

  return (
    <>
      {loading && <Loader color={colors.grey500} />}
      <Header
        searchValue={searchValue}
        setSearchValue={handleSearch}
        currency={currency}
        setCurrency={handleChangeCurrency}
      />
      <Table page={page} onPageChange={onPageChange} currency={currency} />
    </>
  );
};

export default PortfolioPage;
