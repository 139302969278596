import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";

import { Button, CompanyDropdown, LoadingButton } from "application/components";
import { PathNames } from "application/routes";

import { CompanyAttachModalProps } from "./models";
import { Card, Title, Wrapper, Actions, TipStyled } from "./styles";

const CompanyAttachModal: FC<CompanyAttachModalProps> = ({
  onClose,
  onSubmit,
  disabled,
  isLoading,
  selectedCompany,
  setSelectedCompany,
}) => {
  const navigate = useNavigate();

  const goToCompaniesPage = () => {
    navigate(PathNames.companies);
  };

  const handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Wrapper>
      <Card>
        <Title>Attach the deal to the company</Title>
        <CompanyDropdown
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
        <TipStyled>
          *Don't see the necessary company in the list? You can create a new
          company
        </TipStyled>
        <Actions>
          <Box mr={1.5}>
            <Button label="Cancel" onClick={onClose} disabled={isLoading} />
          </Box>
          <Button
            label="Create new company"
            type="reset"
            onClick={goToCompaniesPage}
          />
          <LoadingButton
            onClick={handleSubmit}
            type="submit"
            label="Add new deal"
            disabled={!selectedCompany}
            isLoading={isLoading}
          />
        </Actions>
      </Card>
    </Wrapper>
  );
};

export default CompanyAttachModal;
