import React, { lazy, Suspense } from "react";

import { PathNames } from "./constants";
import ErrorPage from "../pages/ErrorPage";

import Deals from "features/deals/pages/Deals";
import Users from "features/users/pages/Users";
import DealProfile from "features/deal-profile";
import Loader from "application/components/Loader";
import WithSideBar from "application/layout/WithSideBar";
import PortfolioPage from "features/portfolio/pages/PortfolioPage";
import InvestorsPage from "features/portfolio-investors/pages/InvestorsPage";
import UserDetailsPage from "features/users/pages/UserDetails";
import ManagementAnalyticsPage from "features/management-analytics/pages/ManagementAnalyticsPage";
import DealInvestorsPage from "features/deal-investors/pages/DealInvestorsPage";
import InvestorDetailPage from "features/portfolio-investor-detail/pages/InvestorDetailPage";
import OwnersPage from "features/owners/pages/OwnersPage";
import ReferralsPage from "features/referrals/pages/ReferralsPage";
import CompaniesPage from "features/companies/pages/CompaniesPage";
import CompanyPage from "features/companies/pages/CompanyPage";
import CompanyDealsPage from "features/companies/pages/CompanyDealsPage";
import CompanyInvestorsPage from "features/companies/pages/CompanyInvestorsPage";
import CompanyManagementAnalyticsPage from "features/companies/pages/CompanyManagementAnalyticsPage";
import ConsentsPage from "features/consents/pages/ConsentsPage";
import ConsentPage from "features/consents/pages/ConsentPage";

const AuthPage = lazy(() => import("../pages/AuthPage"));

const AuthVerification = lazy(() => import("../pages/AuthVerification"));

const ResentPassword = lazy(() =>
  import("../../features/reset-password/pages/ResentPassword")
);
const ResetPassword = lazy(() =>
  import("../../features/reset-password/pages/ResetPassword")
);
const ForgotPassword = lazy(() =>
  import("../../features/reset-password/pages/ForgotPassword")
);

export const authRouts = [
  {
    path: PathNames.deals,
    title: "Deals",
    component: (
      <WithSideBar>
        <Deals />
      </WithSideBar>
    ),
  },
  {
    path: "/",
    title: "Deals",
    component: (
      <WithSideBar>
        <Deals />
      </WithSideBar>
    ),
  },
  {
    path: PathNames.users,
    title: "Users",
    component: (
      <WithSideBar>
        <Users />
      </WithSideBar>
    ),
  },
  {
    path: `${PathNames.deals}/:id`,
    title: "Deal",
    component: (
      <WithSideBar>
        <DealProfile />
      </WithSideBar>
    ),
  },
  {
    path: `${PathNames.portfolio}`,
    title: "Portfolio",
    component: (
      <WithSideBar>
        <PortfolioPage />
      </WithSideBar>
    ),
  },
  {
    path: `${PathNames.users}/:id/detail`,
    title: "User Detail",
    component: (
      <WithSideBar>
        <UserDetailsPage />
      </WithSideBar>
    ),
  },
  {
    path: `${PathNames.portfolio}/:id/investors`,
    title: "Investors",
    component: (
      <WithSideBar>
        <InvestorsPage />
      </WithSideBar>
    ),
  },
  {
    path: `${PathNames.portfolio}/:id/investors/management-analytics`,
    title: "Management Analytics",
    component: (
      <WithSideBar>
        <ManagementAnalyticsPage />
      </WithSideBar>
    ),
  },
  {
    path: `${PathNames.deals}/:id/commitments`,
    title: "Commitments",
    component: (
      <WithSideBar>
        <DealInvestorsPage />
      </WithSideBar>
    ),
  },
  {
    path: `${PathNames.portfolio}/:id/investors/:investorId/detail-commitments`,
    title: "Investor detail commitments",
    component: (
      <WithSideBar>
        <InvestorDetailPage />
      </WithSideBar>
    ),
  },
  {
    path: `${PathNames.deals}/:id/investors/:investorId/detail`,
    title: "Investor detail",
    component: (
      <WithSideBar>
        <InvestorDetailPage />
      </WithSideBar>
    ),
  },
  {
    path: PathNames.referrals,
    title: "Referrals",
    component: (
      <WithSideBar>
        <ReferralsPage />
      </WithSideBar>
    ),
  },
  {
    path: PathNames.owners,
    title: "Owners",
    component: (
      <WithSideBar>
        <OwnersPage />
      </WithSideBar>
    ),
  },
  {
    path: PathNames.companies,
    title: "Companies",
    component: (
      <WithSideBar>
        <CompaniesPage />
      </WithSideBar>
    ),
  },
  {
    path: `${PathNames.companies}/:id`,
    title: "Company",
    component: (
      <WithSideBar>
        <CompanyPage />
      </WithSideBar>
    ),
  },
  {
    path: `${PathNames.companies}/:id/deals`,
    title: "Company deals",
    component: (
      <WithSideBar>
        <CompanyDealsPage />
      </WithSideBar>
    ),
  },
  {
    path: `${PathNames.companies}/:id/investors`,
    title: "Company investors",
    component: (
      <WithSideBar>
        <CompanyInvestorsPage />
      </WithSideBar>
    ),
  },
  {
    path: `${PathNames.companies}/:id/management-analytics`,
    title: "Company management analytics",
    component: (
      <WithSideBar>
        <CompanyManagementAnalyticsPage />
      </WithSideBar>
    ),
  },
  {
    path: PathNames.consents,
    title: "Consents",
    component: (
      <WithSideBar>
        <ConsentsPage />
      </WithSideBar>
    ),
  },
  {
    path: `${PathNames.consent}/:id`,
    title: "Consent",
    component: (
      <WithSideBar>
        <ConsentPage />
      </WithSideBar>
    ),
  },
  {
    path: "/*",
    title: "Error",
    component: <ErrorPage />,
  },
];

export const publicRouts = [
  {
    path: PathNames.login,
    title: "Login",
    component: (
      <Suspense fallback={<Loader />}>
        <AuthPage />
      </Suspense>
    ),
  },
  {
    path: PathNames.loginVerification,
    title: "Login Verification",
    component: (
      <Suspense fallback={<Loader />}>
        <AuthVerification />
      </Suspense>
    ),
  },
  {
    path: PathNames.resentPassword,
    title: "Resent Password",
    component: (
      <Suspense fallback={<Loader />}>
        <ResentPassword />
      </Suspense>
    ),
  },
  {
    path: PathNames.confirmPassword,
    title: "Reset Password",
    component: (
      <Suspense fallback={<Loader />}>
        <ResetPassword />
      </Suspense>
    ),
  },
  {
    path: PathNames.forgotPassword,
    title: "Forgot Password",
    component: (
      <Suspense fallback={<Loader />}>
        <ForgotPassword />
      </Suspense>
    ),
  },
];
