import { Box, styled } from "@mui/system";
import theme from "application/theme";

interface StyledProps {
  active: number;
  hasError: boolean;
}

export const DealPageTab = styled("div")<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing(1.25, 2)};
  cursor: pointer;
  margin-right: ${theme.spacing(2.5)};
  border-bottom: ${({ active, hasError }) =>
    active &&
    `2px solid  ${
      hasError ? theme.palette.common.red700 : theme.palette.common.blue700
    }`};
`;

export const IconWrapper = styled(Box)`
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${theme.spacing(0.75)};
`;
