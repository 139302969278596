import React from "react";

export const LinkedinIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4.5088 0H19.4912C21.9688 0 24 2.02715 24 4.5088V19.4912C24 21.9688 21.9729 24 19.4912 24H4.5088C2.02715 24 0 21.9688 0 19.4912V4.5088C0 2.02715 2.02715 0 4.5088 0Z"
        fill="#0050C9"
      />
      <path
        d="M7.03049 5.61084C6.14562 5.61084 5.42969 6.32678 5.42969 7.21164C5.42969 8.09651 6.14562 8.81245 7.03049 8.81245C7.91536 8.81245 8.6313 8.09651 8.6313 7.21164C8.6313 6.32678 7.91536 5.61084 7.03049 5.61084Z"
        fill="white"
      />
      <path
        d="M8.47442 9.88623H5.58252V18.6907H8.47442V9.88623Z"
        fill="white"
      />
      <path
        d="M15.352 9.68115C14.2379 9.68115 13.4576 10.2121 12.9106 10.8918V9.8903H10.0146V18.6947H12.9066V13.5343C12.983 12.4242 13.7753 12.1346 14.3384 12.1346C14.9297 12.1346 15.6778 12.4242 15.6778 14.0411V18.6907H18.5697V14.0411V12.4926C18.5737 12.4926 18.441 9.68115 15.352 9.68115Z"
        fill="white"
      />
    </svg>
  );
};
