import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const AttachedCompanyWrapper = styled(Box)`
  padding: ${theme.spacing(2.5, 4)};
  background-color: ${theme.palette.common.grey50};
  border-radius: ${theme.spacing(0.75)};
  margin-bottom: 20px;
`;

export const TipStyled = styled("div")`
  color: ${theme.palette.common.grey500};
  font-family: Unica77;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.06px;
  margin-top: 16px;

  a {
    margin-left: 4px;

    text-decoration: none;
    color: ${theme.palette.common.grey700};
    text-align: center;
    font-family: Unica77;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.06px;
  }
`;
