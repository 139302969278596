import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PayloadAction } from "@reduxjs/toolkit";
import SwapVertIcon from "@mui/icons-material/SwapVert";

import {
  DealSort,
  CreateDealResponseDto,
} from "integration/api/DealsApi/models";
import { CompanySuggestionDto } from "integration/api/CompanyApi/models";

import { PathNames } from "application/routes";
import { DealsSliceAction } from "features/deals/store/slice";
import DealActions from "features/deal-profile/store/actions";
import { dealsSelector } from "features/deals/store/selectors";
import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { requestSelector } from "application/store/request/selectors";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { SearchParamsRoutesNames } from "application/routes/constants";
import { getNavigationState } from "application/helpers/navigationState";
import PrimaryButton from "application/components/PrimaryButton";
import { InputWithText } from "application/components";

import CompanyAttachModal from "../CompanyAttachModal";

import { DealsHeaderProps } from "./model";
import {
  Title,
  AddIcon,
  Container,
  SortButton,
  ActionsContainer,
} from "./styles";

const DealsHeader: FC<DealsHeaderProps> = ({ searchValue, setSearchValue }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { sort } = useAppSelector(dealsSelector).deals;
  const { loading } = useAppSelector(requestSelector).createDeal;
  const [searchItem, setSearchItem] = useState(searchValue);
  const [showAttachCompanyModal, setShowAttachCompanyModal] = useState(false);
  const [selectedCompany, setSelectedCompany] =
    useState<CompanySuggestionDto | null>(null);

  const sortTitle = sort === DealSort.desc ? "Newest" : "Oldest";

  const toggleSort = () => {
    if (sort === DealSort.asc) {
      dispatch(DealsSliceAction.setSort(DealSort.desc));
    } else {
      dispatch(DealsSliceAction.setSort(DealSort.asc));
    }
  };

  const onCreateSuccess = (response: PayloadAction<unknown>) => () => {
    const { id } = response.payload as CreateDealResponseDto;
    navigate(
      `${PathNames.deals}/${id}`,
      getNavigationState(SearchParamsRoutesNames.deal)
    );
    dispatch(DealActions.getDealById(+id));
  };

  const onCreate = () => {
    if (selectedCompany?.id) {
      dispatch(DealActions.createDeal(selectedCompany.id)).then((res) => {
        isResponseFulfilled(res, onCreateSuccess(res));
      });
    }
  };

  const handleSearch = (value: string) => {
    setSearchItem(value);
    setSearchValue(value.length > 2 ? value : "");
  };

  const handleShowAttachCompanyModal = () => {
    setShowAttachCompanyModal(true);
  };

  const handleCloseAttachCompanyModal = () => {
    setShowAttachCompanyModal(false);
  };

  return (
    <Container>
      <Title>Deals</Title>

      <ActionsContainer>
        <InputWithText
          placeholder="Search..."
          onChange={handleSearch}
          value={searchItem}
        />
        <SortButton onClick={toggleSort}>
          <SwapVertIcon />
          <p>Sort: {sortTitle}</p>
        </SortButton>

        <PrimaryButton
          loading={loading}
          onClick={handleShowAttachCompanyModal}
          title="Add new deal"
          leftIcon={<AddIcon />}
        />
      </ActionsContainer>
      {showAttachCompanyModal && (
        <CompanyAttachModal
          onClose={handleCloseAttachCompanyModal}
          onSubmit={onCreate}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
      )}
    </Container>
  );
};

export default DealsHeader;
