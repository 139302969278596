import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px;
  border-radius: 8px;
  background: ${colors.white};
  box-shadow: 0px 0px 30px 0px rgba(222, 224, 230, 0.06);

  h3 {
    margin-bottom: 12px;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > h1 {
    color: ${colors.grey900};
    font-family: Unica77;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

export const DateStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: ${colors.grey500};
    }
  }

  p {
    margin: 0 12px 0 8px;
    color: ${colors.grey500};
    font-family: Unica77;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
  }

  h2 {
    color: ${colors.grey700};
    font-family: Unica77;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 32px;

  input {
    width: 420px;
  }

  .status-select {
    height: 26px;
  }
`;
