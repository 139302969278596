import React from "react";

import { DealsTabs } from "features/deal-profile/types";

import { TabItem } from "features/deal-profile/components/TabItem";

import { TabsWrapper } from "./styles";
import { useAppSelector } from "application/hooks/useAppSelector";
import { DealErrorKey } from "features/deal-profile/helpers/formatDealError";

export const Tabs = () => {
  const { errors } = useAppSelector((state) => state.dealPage);

  const hasOverviewError = errors.some((item) => item.includes("overview"));
  const hasTractionError = errors.includes(DealErrorKey.tractions);
  const hasDetailsError = errors.some((item) =>
    item.includes(DealErrorKey.details)
  );
  const hasNotificationsError = errors.some((item) =>
    item.includes(DealErrorKey.notificationsData)
  );

  return (
    <TabsWrapper>
      <TabItem tabName={DealsTabs.overview} hasError={hasOverviewError} />
      <TabItem tabName={DealsTabs.traction} hasError={hasTractionError} />
      <TabItem tabName={DealsTabs.details} hasError={hasDetailsError} />
      <TabItem
        tabName={DealsTabs.emailNotifications}
        hasError={hasNotificationsError}
      />
    </TabsWrapper>
  );
};
