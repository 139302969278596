import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ConsentData, ConsentDataKeys, ConsentsState } from "./model";
import ConsentsActions from "./actions";
import { ConsentStatuses } from "integration/api/ConsentsApi/models";
import { AxiosError } from "axios";

const defaultConsentsState = {
  totalElements: 0,
  totalPages: 0,
  size: 0,
  content: [],
  number: 0,
  sort: {
    empty: true,
    sorted: true,
    unsorted: true,
  },
  numberOfElements: 0,
  pageable: {
    offset: 0,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    pageNumber: 0,
    pageSize: 0,
    paged: true,
    unpaged: true,
  },
  first: true,
  last: true,
  empty: true,
};

export const defaultConsentState = {
  id: 0,
  name: "",
  status: ConsentStatuses.DRAFT,
  description: "",
  publishedDate: "",
};

const initialState: ConsentsState = {
  consents: defaultConsentsState,
  consent: defaultConsentState,
  initialConsent: defaultConsentState,
  hasErrors: false,
  errors: [],
};

export const consentsSlice = createSlice({
  name: "consentsSlice",
  initialState,
  reducers: {
    resetConsent: (state) => {
      state.consent = defaultConsentState;
      state.initialConsent = defaultConsentState;
      state.hasErrors = false;
      state.errors = [];
    },
    setConsentData: (state, action: PayloadAction<ConsentData>) => {
      Object.keys(action.payload).forEach(
        (key) =>
          (state.consent[key as ConsentDataKeys] =
            action.payload[key as ConsentDataKeys] || "")
      );
    },
    setConsentStatus: (state, action: PayloadAction<ConsentStatuses>) => {
      state.consent.status = action.payload;
    },
    setHasErrors: (state, action: PayloadAction<boolean>) => {
      state.hasErrors = action.payload;
    },
    setErrors: (state, action: PayloadAction<string[]>) => {
      state.errors = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get consent list
    builder.addCase(
      ConsentsActions.getConsentList.fulfilled,
      (state, action) => {
        state.consents = action.payload;
      }
    );
    // get consent by id
    builder.addCase(
      ConsentsActions.getConsentById.fulfilled,
      (state, action) => {
        state.consent = action.payload;
        state.initialConsent = action.payload;
      }
    );
    // get consent by id
    builder.addCase(ConsentsActions.updateConsent.rejected, (state, action) => {
      const response = action.payload as AxiosError<{
        errorCode: string;
        errorMessage: string;
      }>;
      state.errors =
        response?.response?.data.errorMessage.replace(/\s/g, "").split(",") ||
        [];
      state.hasErrors = !!response?.response?.data.errorMessage;
    });
  },
});

export const ConsentsSliceAction = consentsSlice.actions;
export default consentsSlice.reducer;
