import styled from "@emotion/styled";
import Add from "@mui/icons-material/Add";

import { colors } from "application/theme/palette";

export const HeaderStyled = styled.header`
  display: grid;
  grid-template-rows: max-content;
  gap: 12px;
`;
export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 140%;
  font-style: normal;
  font-family: "Unica77";
  color: ${colors.grey900};
`;

export const AddIcon = styled(Add)`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  color: ${colors.white};
`;

export const BackButton = styled("button")`
  all: unset;
  display: flex;
  align-items: center;
  width: fit-content;
  text-decoration: none;
  color: ${colors.blue700};
  text-align: center;
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
  cursor: pointer;

  svg {
    margin-right: 8px;
    path {
      fill: ${colors.blue700};
    }
  }
`;
