import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const ItemWrapper = styled(Box)`
  display: flex;
  align-items: start;
  justify-items: space-between;
  cursor: pointer;
  min-height: ${theme.spacing(3.25)};
  padding: ${theme.spacing(0.5, 3.25, 0.5, 0.5)};
  &:hover {
    background-color: ${theme.palette.common.grey100};
  }
`;
