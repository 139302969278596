export enum DealErrorKey {
  // Main
  "title" = "title",
  "stageId" = "stageId",
  "businessModelId" = "businessModelId",
  "broadCategoryId" = "broadCategoryId",
  "investmentCurrency" = "investmentCurrency",
  "minInvestmentAmount" = "minInvestmentAmount",
  "dealCurrency" = "dealCurrency",

  // Total Funding Goal
  "totalFundingGoal" = "totalFundingGoal",
  "totalFundingGoal.amount" = "totalFundingGoal.amount",
  "totalFundingGoal.currency" = "totalFundingGoal.currency",

  // Overview
  "overview.team" = "overview.team",
  "overview.market" = "overview.market",
  "overview.problem" = "overview.problem",
  "overview.solution" = "overview.solution",
  "overview.shortDescription" = "overview.shortDescription",
  "overview.shortDescriptionLength" = "overview.shortDescriptionLength",
  "overview.description" = "overview.description",

  // Details
  "details" = "details",
  "details.valuation.amount" = "details.valuation.amount",
  "details.valuation.currency" = "details.valuation.currency",
  "details.valuation.type" = "details.valuation.type",
  "details.totalRoundSize.amount" = "details.totalRoundSize.amount",
  "details.totalRoundSize.currency" = "details.totalRoundSize.currency",
  "details.commitmentDeadline" = "details.commitmentDeadline",
  "details.availableAllocation.amount" = "details.availableAllocation.amount",
  "details.availableAllocation.currency" = "details.availableAllocation.currency",
  "details.committedAllocationFromTaVentures.amount" = "details.committedAllocationFromTaVentures.amount",
  "details.committedAllocationFromTaVentures.currency" = "details.committedAllocationFromTaVentures.currency",

  // Traction
  "tractions" = "tractions",

  // Email Notifications
  "notificationsData" = "notificationsData",
  "notificationsData.risks" = "notificationsData.risks",
  "notificationsData.linkToQACall" = "notificationsData.linkToQACall",
  "notificationsData.callWithFounderDate" = "notificationsData.callWithFounderDate",
  "notificationsData.notificationText" = "notificationsData.notificationText",
  "notificationsData.newsletterPicture" = "notificationsData.newsletterPicture",
  "notificationsData.strengths" = "notificationsData.strengths",
}

const getError = (key: DealErrorKey) => {
  switch (key) {
    // Main
    case DealErrorKey["title"]:
      return "Deal title";
    case DealErrorKey["stageId"]:
      return "Stage";
    case DealErrorKey["businessModelId"]:
      return "Business model";
    case DealErrorKey["broadCategoryId"]:
      return "Broad category";
    case DealErrorKey["investmentCurrency"]:
      return "Investment currency";
    case DealErrorKey["minInvestmentAmount"]:
      return "Minimal investment amount";
    case DealErrorKey["dealCurrency"]:
      return "Currency";

    // Total Funding Goal
    case DealErrorKey["totalFundingGoal"]:
      return "Total funding goal";
    case DealErrorKey["totalFundingGoal.amount"]:
      return "Total funding goal amount";
    case DealErrorKey["totalFundingGoal.currency"]:
      return "Total funding goal currency";

    // Overview
    case DealErrorKey["overview.team"]:
      return "Team";
    case DealErrorKey["overview.market"]:
      return "Market";
    case DealErrorKey["overview.problem"]:
      return "Problem";
    case DealErrorKey["overview.solution"]:
      return "Solution";
    case DealErrorKey["overview.shortDescription"]:
      return "Short description";
    case DealErrorKey["overview.shortDescriptionLength"]:
      return "Short description is longer than 40 characters";
    case DealErrorKey["overview.description"]:
      return "Short overview";

    // Details
    case DealErrorKey["details"]:
      return "Details";
    case DealErrorKey["details.valuation.amount"]:
      return "Valuation amount";
    case DealErrorKey["details.valuation.currency"]:
      return "Valuation currency";
    case DealErrorKey["details.valuation.type"]:
      return "Valuation type";
    case DealErrorKey["details.totalRoundSize.amount"]:
      return "Total Round size amount";
    case DealErrorKey["details.totalRoundSize.currency"]:
      return "Total Round size currency";
    case DealErrorKey["details.commitmentDeadline"]:
      return "Commitment deadline";
    case DealErrorKey["details.availableAllocation.amount"]:
      return "Available allocation amount";
    case DealErrorKey["details.availableAllocation.currency"]:
      return "Available allocation currency";
    case DealErrorKey["details.committedAllocationFromTaVentures.amount"]:
      return "Committed allocation from TA Ventures amount";
    case DealErrorKey["details.committedAllocationFromTaVentures.currency"]:
      return "Committed allocation from TA Ventures currency";

    // Traction
    case DealErrorKey["tractions"]:
      return "At least one traction section should be filled in";

    // Email Notifications
    case DealErrorKey["notificationsData"]:
      return "Email Notifications";
    case DealErrorKey["notificationsData.newsletterPicture"]:
      return "Newsletter picture";
    case DealErrorKey["notificationsData.strengths"]:
      return "Strengths";
    case DealErrorKey["notificationsData.risks"]:
      return "Risks";
    case DealErrorKey["notificationsData.notificationText"]:
      return "Notification deal text";
    case DealErrorKey["notificationsData.callWithFounderDate"]:
      return "Call with founder date";
    case DealErrorKey["notificationsData.linkToQACall"]:
      return "Link to Q&A call";

    default:
      return key;
  }
};

export const formatDealError = (error: string): string[] => {
  const array = error?.split(",") || [];
  const errorsArray = array.map((item) => getError(item as DealErrorKey));
  return errorsArray;
};
