import axios from 'axios';
import { path, isNil } from 'ramda';
import { authService } from 'common';
import type {
  AxiosRequestConfig,
  AxiosInstance,
} from 'axios';

const httpSuccessHandler = (httpResponse: any) => {
  const response: any | null = httpResponse.data;

  if ((!response || (response.responseCode && response.responseCode !== 200)) && httpResponse.status !== 200) {
    throw new Error(httpResponse);
  }

  return isNil(response.data) ? response : response.data;
};

const commonFailureHandler = (err: any): any => {
  if (path(['response', 'status'], err) === 401) {
    authService.eraseSessionData();
  }

  if (axios.isCancel(err)) {
    throw new Error(err);
  }

  throw err;
};

const baseURL = process.env.REACT_APP_BASE_URL;

export const clientRequest = (reqProps: any): any => {
  const { method = 'GET' } = reqProps;
  const { headers = {} } = reqProps;
  const { url } = reqProps;

  const config: AxiosRequestConfig = {
    baseURL,
  };
  const client: AxiosInstance = axios.create(config);

  if (!headers['content-type'] && ['POST', 'PUT', 'PATCH'].includes(method)) {
    headers['content-type'] = 'application/json';
  }

  const {
    timeout, params, data, responseType,
  } = reqProps;

  return client({
    url,
    method,
    params,
    headers,
    timeout,
    data,
    responseType,
  })
    .then(httpSuccessHandler)
    .catch(commonFailureHandler);
};
