import React, { FC } from "react";

import { colors } from "application/theme/palette";
import Loader from "application/components/Loader";
import { useAppSelector } from "application/hooks/useAppSelector";

import { TableRow } from "features/consents/components";

import { TableProps } from "./models";
import { TableStyled, LoaderContainer } from "./styles";

const Table: FC<TableProps> = ({ loading, onDeleteConsent }) => {
  const { consents } = useAppSelector((state) => state.consents);

  return (
    <section>
      {loading && (
        <LoaderContainer>
          <Loader color={colors.grey500} />
        </LoaderContainer>
      )}

      <TableStyled>
        <thead>
          <tr>
            <th>Name</th>
            <th className="date-column">Date Published</th>
            <th className="status-column">Status</th>
            <th className="action-column">Action</th>
          </tr>
        </thead>

        <tbody>
          {consents.content?.map((consent) => (
            <TableRow
              key={consent.id}
              item={consent}
              onDeleteConsent={onDeleteConsent}
            />
          ))}
        </tbody>
      </TableStyled>
    </section>
  );
};

export default Table;
