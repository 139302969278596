import React, { FC, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import { ReactComponent as HomeIcon } from "application/assets/home-2.svg";
import { ReactComponent as HomeActiveIcon } from "application/assets/home-2-active.svg";
import { ReactComponent as BriefcaseIcon } from "application/assets/briefcase.svg";
import { ReactComponent as BriefcaseActiveIcon } from "application/assets/briefcase-active.svg";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import Loader from "application/components/Loader";
import { requestSelector } from "application/store/request/selectors";
import { useAppSelector } from "application/hooks/useAppSelector";
import { colors } from "application/theme/palette";
import { useDebounce } from "application/hooks/useDebounce";

import { DealsTable, PortfolioTable } from "features/companies/containers";
import { CompanyDealsHeader } from "features/companies/components";
import { DealsTab } from "features/companies/constants.ts/companyDeals";

import { LoaderContainer, TabsSection, TabStyled } from "./styles";

const CompanyDealsPage: FC = () => {
  const { id } = useParams();
  const { companyName } = useAppSelector((state) => state.companyList);
  const { getCompanyDealsList, getCompanyPortfolioList } =
    useAppSelector(requestSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<DealsTab>(
    (searchParams.get("tab") as DealsTab) || DealsTab.ACTIVE_DEALS
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const search = useDebounce(searchValue, 300);

  const loading =
    getCompanyDealsList.loading || getCompanyPortfolioList.loading;

  const isActiveDealsTab = currentTab === DealsTab.ACTIVE_DEALS;

  const toggleCurrentTab = () => {
    setCurrentTab(
      isActiveDealsTab ? DealsTab.PORTFOLIO_DEALS : DealsTab.ACTIVE_DEALS
    );
    setSearchValue("");
    setSearchParams(
      {
        tab: isActiveDealsTab
          ? DealsTab.PORTFOLIO_DEALS
          : DealsTab.ACTIVE_DEALS,
      },
      { state: window?.history?.state?.usr }
    );
  };

  return (
    <div>
      {loading && (
        <LoaderContainer>
          <Loader color={colors.grey500} />
        </LoaderContainer>
      )}
      <CompanyDealsHeader
        companyName={companyName}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      <TabsSection>
        <TabStyled
          isActive={isActiveDealsTab}
          type="button"
          onClick={toggleCurrentTab}
        >
          {isActiveDealsTab ? <HomeActiveIcon /> : <HomeIcon />}
          Active deals
        </TabStyled>
        <TabStyled
          isActive={!isActiveDealsTab}
          type="button"
          onClick={toggleCurrentTab}
        >
          {!isActiveDealsTab ? <BriefcaseActiveIcon /> : <BriefcaseIcon />}
          Portfolio deals
        </TabStyled>
      </TabsSection>
      {isActiveDealsTab ? (
        <DealsTable
          search={search}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      ) : (
        <PortfolioTable
          search={search}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      )}
    </div>
  );
};

export default CompanyDealsPage;
