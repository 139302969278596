import { createAsyncThunk } from "@reduxjs/toolkit";
import { PipedriveOwnersActions } from "application/constants/actions";
import PipedriveApi from "integration/api/PipedriveApi";

class PipedriveActions {
  static getPipedriveOwnersRegulars = createAsyncThunk(
    `pipedriveOwners/${PipedriveOwnersActions.getPipedriveOwnersRegulars}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await PipedriveApi.getPipedriveOwnersRegulars();
        return data;
      } catch (e) {
        return rejectWithValue(e);
      }
    }
  );
  static getPipedriveOwnersReferrals = createAsyncThunk(
    `pipedriveOwners/${PipedriveOwnersActions.getPipedriveOwnersReferrals}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await PipedriveApi.getPipedriveOwnersReferrals();
        return data;
      } catch (e) {
        return rejectWithValue(e);
      }
    }
  );
  static getPipedriveSources = createAsyncThunk(
    `pipedriveOwners/${PipedriveOwnersActions.getPipedriveSources}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await PipedriveApi.getPipedriveSources();
        return data;
      } catch (e) {
        return rejectWithValue(e);
      }
    }
  );
}

export default PipedriveActions;
