import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { ClickAwayListener, Typography } from "@mui/material";

import { ArrowDropUp } from "application/assets/ArrowDropUp";
import { ArrowDropDown } from "application/assets/ArrowDropDown";

import {
  StyledDateCalendar,
  DatePickerContainer,
  DatePickerWithTextWrapper,
} from "./styles";
import theme from "application/theme";
import { SelectButton } from "../CustomSelect/styles";

interface DatePickerProps {
  helperText?: string;
  placeholder?: string;
  value?: string | null;
  onChange: (value: string) => void;
  hasError?: boolean;
}

export const DatePicker = ({
  value,
  helperText,
  placeholder,
  onChange,
  hasError,
}: DatePickerProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectDate = (value: Dayjs) => {
    onChange(value.format("YYYY-MM-DD"));
  };

  return (
    <DatePickerWithTextWrapper>
      {helperText && (
        <Typography
          variant="subtitle1"
          color={
            hasError
              ? theme.palette.common.red700
              : theme.palette.common.grey500
          }
          mb={0.5}
        >
          {helperText}
        </Typography>
      )}

      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <DatePickerContainer>
          <SelectButton
            onClick={toggleOpen}
            active={isOpen ? 1 : 0}
            filled={value ? 1 : 0}
            hasError={hasError}
          >
            <Typography
              variant="body2"
              mr={1}
              color={theme.palette.common.grey900}
            >
              {value ? dayjs(value).format("MMMM DD, YYYY") : placeholder}
            </Typography>
            {isOpen ? (
              <ArrowDropUp color={theme.palette.common.grey500} />
            ) : (
              <ArrowDropDown color={theme.palette.common.grey500} />
            )}
          </SelectButton>
          <StyledDateCalendar
            opened={isOpen ? 1 : 0}
            value={value ? dayjs(value) : null}
            onChange={(value) => handleSelectDate(value)}
            views={["year", "month", "day"]}
          />
        </DatePickerContainer>
      </ClickAwayListener>
    </DatePickerWithTextWrapper>
  );
};
