import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

interface StyledProps {
  color?: string;
}

const ldsRipple = keyframes`
 0% {
    top: 20px;
    left: 20px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 20px;
    left: 20px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 20px;
    left: 20px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 40px;
    height: 40px;
    opacity: 0;
  }
 `;

export const Container = styled.div<StyledProps>`
  width: 40px;
  height: 40px;
  position: relative;
  display: inline-block;

  div {
    opacity: 1;
    border-radius: 50%;
    position: absolute;
    animation: ${ldsRipple} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    border: ${({ color }) => (color ? `4px solid ${color}` : "4px solid #fff")};
  }
  div:nth-of-type(2) {
    animation-delay: -0.5s;
  }
`;
