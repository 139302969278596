import React, { FC, useEffect, useState } from "react";
import { ClickAwayListener } from "@mui/material";
import { Box } from "@mui/system";
import {
  SaveAlt,
  Edit,
  PeopleAltOutlined,
  AssignmentOutlined,
  AssessmentOutlined,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";

import {
  CompanyStatusSelect,
  ImageLoader,
  InputWithText,
  SelectWithText,
} from "application/components";
import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { getYearsOptions } from "application/helpers/getYearsOptions";
import { PathNames } from "application/routes";
import { getNavigationState } from "application/helpers/navigationState";
import { SearchParamsRoutesNames } from "application/routes/constants";

import { CompanySliceAction } from "features/companies/store/slice";
import { CompanyErrorKey } from "features/companies/helpers/formatCompanyError";
import CompanyListActions from "features/companies/store/actions";

import { CompanyStatus, LogoType } from "integration/api/CompanyApi/models";

import { CompanyTopSectionProps } from "./models";
import {
  Wrapper,
  ImageWrapper,
  AvatarPlaceholder,
  LogoPopup,
  EditLogo,
  LogoPopupItem,
  DeleteContainer,
  StatusWrapper,
  FieldsSection,
  SectionStyled,
  LinksSection,
  LinkWrapper,
} from "./styles";
import CropImage from "features/crop-image";

const CompanyTopSection: FC<CompanyTopSectionProps> = ({
  handleEditCompanyData,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { company, errors } = useAppSelector((state) => state.companyList);

  const [logo, setLogo] = useState("");
  const [showLogoPopup, setShowLogoPopup] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);

  const [image, setImage] = useState<string>("");
  const [showCropperModal, setShowCropperModal] = useState<boolean>(false);

  const yearsOptions = getYearsOptions(1901);

  useEffect(() => {
    if (company.logoFileId && id) {
      dispatch(
        CompanyListActions.getCompanyLogo({
          id: Number(id),
          logoId: company.logoFileId,
          logoType: LogoType.THUMBNAIL,
        })
      ).then((response) => {
        const data = response.payload as Blob;
        const logoBlob = new Blob([data], {
          type: data.type as string,
        });
        const logoUrl = URL.createObjectURL(logoBlob);
        setLogo(logoUrl);
      });
    }
  }, [company.logoFileId, id]);

  const handleImageUpload = async (file: File) => {
    setIsFileLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    await dispatch(
      CompanyListActions.uploadCompanyLogo({ id: Number(id), file: formData })
    ).then(() => {
      setShowLogoPopup(false);
      dispatch(
        CompanySliceAction.setErrors(
          errors.filter((item) => item !== CompanyErrorKey.logoFileId)
        )
      );
    });
    setIsFileLoading(false);
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e.target.files;
    if (files?.length) {
      const reader = new FileReader();
      
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.onloadend = () => {
        setShowCropperModal(true);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const handleDeleteFile = () => {
    const logoId = company?.logoFileId;
    if (logoId) {
      dispatch(
        CompanyListActions.deleteCompanyLogo({ id: Number(id), logoId })
      ).then(() => {
        setLogo("");
        setShowLogoPopup(false);
      });
    }
  };

  const handleLogoClick = () => {
    setShowLogoPopup(true);
  };

  const handleChangeStatus = (status: CompanyStatus) => {
    dispatch(CompanySliceAction.setCompanyStatus(status));
  };

  const onSeeInvestorsList = () => {
    navigate(
      `${PathNames.companies}/${id}/investors`,
      getNavigationState(SearchParamsRoutesNames.companyInvestors)
    );
  };

  const onSeeDealsList = () => {
    navigate(
      `${PathNames.companies}/${id}/deals`,
      getNavigationState(SearchParamsRoutesNames.companyDeals)
    );
  };

  const goToManagementAnalytics = () => {
    navigate(
      `${PathNames.companies}/${id}/management-analytics`,
      getNavigationState(SearchParamsRoutesNames.companyManagementAnalytics)
    );
  };

  return (
    <Wrapper>
      <SectionStyled>
        <SectionStyled>
          <ImageWrapper>
            <AvatarPlaceholder
              hasError={errors.includes(CompanyErrorKey.logoFileId)}
              onClick={handleLogoClick}
            >
              {isFileLoading ? (
                <ImageLoader />
              ) : (
                <>
                  {logo && <img src={logo} alt="deal-avatar" />}
                  {company?.logoFileId ? (
                    <EditLogo>
                      <Edit />
                    </EditLogo>
                  ) : (
                    <SaveAlt />
                  )}
                </>
              )}
            </AvatarPlaceholder>
            {!isFileLoading && showLogoPopup && (
              <ClickAwayListener onClickAway={() => setShowLogoPopup(false)}>
                <LogoPopup>
                  <input
                    color="primary"
                    accept="image/jpeg,image/png"
                    type="file"
                    onChange={(e) => handleFileInput(e)}
                    id="icon-button-file"
                    style={{ display: "none" }}
                  />
                  <Box mb={company?.logoFileId ? 1.5 : 0}>
                    <label htmlFor="icon-button-file">
                      <LogoPopupItem>Upload file</LogoPopupItem>
                    </label>
                  </Box>

                  {company?.logoFileId && (
                    <DeleteContainer>
                      <LogoPopupItem onClick={handleDeleteFile}>
                        Delete file
                      </LogoPopupItem>
                    </DeleteContainer>
                  )}
                </LogoPopup>
              </ClickAwayListener>
            )}
          </ImageWrapper>
          <FieldsSection>
            <InputWithText
              placeholder="Name"
              helperText="Company Name:"
              onChange={(value) =>
                handleEditCompanyData({ key: "name", value })
              }
              value={company?.name}
              error={errors.includes(CompanyErrorKey.name)}
            />
            <SelectWithText
              helperText="Year founded:"
              placeholder="Year founded"
              onChange={(value) =>
                handleEditCompanyData({
                  key: "foundedYear",
                  value: value as string,
                })
              }
              options={yearsOptions}
              selected={company?.foundedYear || ""}
              maxHeight={300}
              error={errors.includes(CompanyErrorKey.foundedYear)}
            />
          </FieldsSection>
        </SectionStyled>
        <StatusWrapper>
          <CompanyStatusSelect
            onChange={handleChangeStatus}
            selected={company?.status}
          />
        </StatusWrapper>
      </SectionStyled>
      <LinksSection>
        <LinkWrapper>
          <p>
            <PeopleAltOutlined />
            List of investors
          </p>
          <button type="button" onClick={onSeeInvestorsList}>
            See all investors
          </button>
        </LinkWrapper>
        <LinkWrapper>
          <p>
            <AssignmentOutlined />
            List of active and portfolio deals
          </p>
          <button type="button" onClick={onSeeDealsList}>
            Company deals
          </button>
        </LinkWrapper>
        <LinkWrapper>
          <p>
            <AssessmentOutlined />
            Management analytics
          </p>
          <button type="button" onClick={goToManagementAnalytics}>
            Management analytics
          </button>
        </LinkWrapper>
      </LinksSection>
      <CropImage
        handleImageUpload={handleImageUpload}
        image={image}
        showCropperModal={showCropperModal}
        setShowCropperModal={setShowCropperModal}
      />
    </Wrapper>
  );
};

export default CompanyTopSection;
