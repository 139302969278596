import { styled } from "@mui/system";
import { TextField } from "@mui/material";
import theme from "application/theme";

interface TextFieldStyledProps {
  mt?: number;
  mb?: number;
  width?: number;
  filled?: number | null;
}

export const TextFieldStyled = styled(TextField)<TextFieldStyledProps>`
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)}px;
  margin-top: ${(props) => (props.mt ? props.mt : 0)}px;
  height: ${theme.spacing(5.5)};
  width: ${(props) => props.width && props.width}px;
  font-size: ${theme.spacing(2)};
  font-weight: 400;
  color: ${theme.palette.common.grey900};
  & .MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-notchedOutline {
    background-color: ${theme.palette.common.grey50};
  }
  & .MuiOutlinedInput-notchedOutline {
    height: ${theme.spacing(5.5)};
    border-color: ${theme.palette.common.grey300};
    .Mui-focused {
      border-color: ${theme.palette.common.blue50};
    }
  }

  .MuiInputBase-input {
    font-size: ${theme.spacing(2)};
    font-weight: 400;
    z-index: 1;
  }

  & .MuiInputLabel-root {
    color: ${theme.palette.common.grey900};
    font-weight: 400;
  }

  & .MuiOutlinedInput-input::placeholder {
    font-size: ${theme.spacing(2)};
    line-height: 140%;
    opacity: 1;
    color: ${theme.palette.common.grey700};
  }

  & .MuiOutlinedInput-notchedOutline,
  .MuiInputBase-input {
    display: flex;
    align-items: center;
    height: ${theme.spacing(5.5)};
    padding: ${theme.spacing(0, 2)};
    border-radius: 8px;
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      height: ${theme.spacing(6)};
      border-color: ${(props) =>
        props.filled
          ? theme.palette.common.grey700
          : theme.palette.common.grey300};
      background-color: ${theme.palette.common.grey50};
    }

    &:hover fieldset {
      border-width: 1px;
      border-color: ${theme.palette.common.grey700};
    }
    &.Mui-focused fieldset {
      background-color: ${theme.palette.common.blue50};
      border-width: 1px;
      border-color: ${theme.palette.common.blue700};
    }
  }
  & label {
    font-size: ${theme.spacing(2)};
    font-weight: 400;
    color: ${theme.palette.common.grey500};
    left: 10px;
  }
  & label.Mui-focused {
    left: 10px;
    color: ${theme.palette.common.blue700};
    margin-top: 2px;
  }
  & .MuiOutlinedInput-root.Mui-error {
    & fieldset,
    &:hover fieldset,
    &.Mui-focused fieldset {
      background-color: ${theme.palette.common.errorBg};
      border: 1px solid ${theme.palette.common.error};
    }
  }
  & .MuiInputLabel-root.Mui-error {
    color: ${theme.palette.common.error};
  }
  & .MuiInputLabel-root {
    margin-top: 2px;
  }
  input:-internal-autofill-selected {
    background-color: ${theme.palette.common.blue50} !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;
