import React, { FC, useEffect } from "react";

import { useParams } from "react-router-dom";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";
import Loader from "application/components/Loader";
import { colors } from "application/theme/palette";
import compareObjectsChanges from "application/helpers/compareObjectsChanges";
import { DetectChangesSavedSliceActions } from "application/store/DetectChangesSaved/DetectChangesSavedSlice";

import ConsentsActions from "features/consents/store/actions";
import { ConsentsSliceAction } from "features/consents/store/slice";
import { ConsentForm, ProfileHeader } from "features/consents/components";

import { Container, LoaderContainer } from "./styles";

const ConsentPage: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { consent, initialConsent } = useAppSelector((state) => state.consents);
  const { getConsentById, updateConsent, deleteConsent } =
    useAppSelector(requestSelector);
  const loading =
    getConsentById.loading || updateConsent.loading || deleteConsent.loading;

  useEffect(() => {
    if (id) {
      dispatch(ConsentsActions.getConsentById(Number(id)));
    }
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(ConsentsSliceAction.resetConsent());
    };
  }, []);

  useEffect(() => {
    const isChanged = compareObjectsChanges(consent, initialConsent, true);

    dispatch(DetectChangesSavedSliceActions.setHasUnsavedChanges(isChanged));
  }, [consent, initialConsent]);

  return (
    <Container>
      {loading && (
        <LoaderContainer>
          <Loader color={colors.grey500} />
        </LoaderContainer>
      )}
      <ProfileHeader />
      <ConsentForm />
    </Container>
  );
};

export default ConsentPage;
