import React, { FC, useState } from "react";
import { Typography } from "@mui/material";

import { colors } from "application/theme/palette";

import { AttachedCompanyProps } from "./models";
import { AttachedCompanyWrapper, TipStyled } from "./styles";
import { CompanyDropdown } from "application/components";
import { Link } from "react-router-dom";
import { PathNames } from "application/routes";

const AttachedCompany: FC<AttachedCompanyProps> = ({
  hasError,
  companyId,
  selectedCompany,
  setSelectedCompany,
}) => {
  return (
    <AttachedCompanyWrapper>
      <Typography
        variant="h3"
        color={hasError ? colors.red700 : colors.grey500}
        mb={2}
      >
        Attached company
      </Typography>

      <CompanyDropdown
        companyId={companyId}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
      />

      <TipStyled>
        You can view the attached company's profile.
        <Link to={PathNames.companies} target="_blank">
          See profile
        </Link>
      </TipStyled>
    </AttachedCompanyWrapper>
  );
};

export default AttachedCompany;
