import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { DetectChangesSavedSliceActions } from "application/store/DetectChangesSaved/DetectChangesSavedSlice";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";

import { MenuListItem } from "features/side-menu/config/menu-list";

import { Title, Wrapper } from "./styles";

interface Props {
  open: boolean;
  item: MenuListItem;
}

const MenuItem = ({ open, item }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { hasUnsavedChanges } = useAppSelector(
    (state) => state.detectChangesSavedSlice
  );

  const $active = location.pathname === item.path;
  const Icon = $active ? item.iconActive : item.icon;

  const onClick = () => {
    if ($active) return;
    if (hasUnsavedChanges) {
      dispatch(DetectChangesSavedSliceActions.setNextPath(item.path));
    } else {
      navigate(item.path);
    }
  };

  return (
    <Wrapper open={open} onClick={onClick} $active={$active}>
      <Icon />
      {open && <Title>{item.title}</Title>}
    </Wrapper>
  );
};

export default MenuItem;
