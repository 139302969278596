import React from "react";

export const PoundIcon = ({ color = "#111928" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5117 11.6022V12.5529H11.1072C11.2839 13.0484 11.3722 13.4518 11.3722 13.7631V13.7805C11.3722 14.4143 11.0304 15.1288 10.3469 15.924C10.0757 16.2468 9.72207 16.6214 9.28608 17.0476C9.98144 16.5982 10.7004 16.3734 11.4429 16.3734C11.8555 16.3734 12.3388 16.4599 12.8928 16.633C13.541 16.8173 14.0067 16.9094 14.2898 16.9094C14.7614 16.9094 15.2506 16.7366 15.7575 16.3908L16.5 17.5319C15.828 18.1773 15.0558 18.5 14.1835 18.5C13.6648 18.5 12.9163 18.3386 11.9382 18.0157L11.602 17.8949C11.2132 17.7679 10.8241 17.7044 10.4349 17.7044C9.81039 17.7044 9.16808 17.9178 8.50797 18.3444L7.65918 17.2033C8.86141 16.189 9.56865 15.3188 9.78089 14.5929C9.83989 14.3854 9.86938 14.1665 9.86939 13.9361C9.86939 13.9361 9.86938 13.9303 9.86939 13.9187V13.9013C9.86938 13.4634 9.72783 13.014 9.44472 12.5529H7.5V11.6022H8.9321C8.38998 10.7265 8.08942 10.0985 8.03043 9.71833C8.00668 9.61458 7.99481 9.49922 7.99481 9.37225V9.19921C7.99481 8.0583 8.46642 7.12488 9.40965 6.39895C10.1877 5.79966 11.113 5.50001 12.1854 5.5H12.2032C13.5352 5.50001 14.5668 5.92645 15.2978 6.77932L15.5983 7.21139C15.9163 7.74141 16.0813 8.50777 16.0931 9.51047H14.5375C14.4903 7.73579 13.6887 6.84844 12.1325 6.84843C11.0835 6.84844 10.3468 7.2114 9.92227 7.9373C9.73376 8.26018 9.63951 8.62911 9.63952 9.04411V9.06099C9.63951 9.49922 9.7692 9.96031 10.0286 10.4442V10.4617L10.0464 10.4785C10.0935 10.5823 10.1582 10.7091 10.2406 10.8589L10.3998 11.1185L10.6826 11.6022H13.5117Z"
        fill={color}
      />
    </svg>
  );
};
