import React, { FC, useMemo } from "react";
import { EditOutlined, CloseOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { PathNames } from "application/routes";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { getNavigationState } from "application/helpers/navigationState";
import { SearchParamsRoutesNames } from "application/routes/constants";

import { CompanySliceAction } from "features/companies/store/slice";
import { formateDate } from "features/consents/helpers/formatDate";

import { TableRowProps } from "./models";
import {
  StatusStyled,
  TableRowStyled,
  ActionsStyled,
  NameStyled,
} from "./styles";

const TableRow: FC<TableRowProps> = ({ item, onDeleteConsent }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const status = useMemo(() => {
    const capitalizeFirstLetter =
      item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase();
    return capitalizeFirstLetter.replace("_", " ");
  }, [item.status]);

  const date = item.publishedDate ? formateDate(item.publishedDate) : "";

  const onEdit = () => {
    navigate(
      `${PathNames.consent}/${item.id}`,
      getNavigationState(SearchParamsRoutesNames.consent)
    );
    dispatch(CompanySliceAction.resetCompanies());
  };

  const onDelete = () => {
    onDeleteConsent(item);
  };

  return (
    <TableRowStyled>
      <td>
        <NameStyled>{item.name}</NameStyled>
      </td>
      <td>{date}</td>
      <td>
        <StatusStyled status={item.status}>{status}</StatusStyled>
      </td>
      <td>
        <ActionsStyled>
          <button type="button" onClick={onEdit}>
            <EditOutlined />
          </button>
          <button type="button" onClick={onDelete}>
            <CloseOutlined />
          </button>
        </ActionsStyled>
      </td>
    </TableRowStyled>
  );
};

export default TableRow;
