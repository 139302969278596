import styled from "@emotion/styled";
import theme from "application/theme";

import { colors } from "application/theme/palette";

export const TableStyled = styled("table")`
  width: 100%;
  border-collapse: separate;
  border-radius: 8px;
  background: ${theme.palette.common.white};
  box-shadow: 0px 0px 30px 0px rgba(222, 224, 230, 0.3);

  tr {
    height: 52px;
    th {
      padding: 10px 12px;
      text-align: start;
      vertical-align: middle;
      background: ${theme.palette.common.grey200};
      color: ${theme.palette.common.grey900};
      font-family: Unica77;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.08px;
      box-sizing: border-box;

      &.date-column {
        max-width: 240px;
        min-width: 240px;
        width: 240px;
      }

      &.status-column {
        max-width: 200px;
        min-width: 200px;
        width: 200px;
      }
      &.action-column {
        max-width: 180px;
        min-width: 180px;
        width: 180px;
        text-align: center;
        border-top-right-radius: 8px;
      }
    }

    &:last-of-type {
      height: 62px;
      td {
        border-bottom: 1px solid ${colors.grey200};
        &:first-of-type {
          border-bottom-left-radius: 8px;
        }
        &:last-of-type {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;
