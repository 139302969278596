import { FormControlLabel } from "@mui/material";
import { Box, styled } from "@mui/system";
import { colors } from "application/theme/palette";

interface ImageWrapperProps {
  hasError: boolean;
}

export const LabelStyled = styled(FormControlLabel)`
  span {
    color: ${colors.grey900};
    font-family: Unica77;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;

export const ImageWrapper = styled(Box)<ImageWrapperProps>`
  position: relative;
  display: flex;
  margin-right: 8px;
  width: fit-content;
  margin-bottom: 20px;
  img {
    position: absolute;
    border: 1px solid ${colors.grey300};
    width: 72px;
    height: 72px;
    border-radius: 100%;
  }

  > div:first-of-type {
    border-color: ${({ hasError }) =>
      hasError ? colors.red700 : colors.grey300};

    svg {
      path {
        fill: ${({ hasError }) => (hasError ? colors.red700 : colors.grey800)};
      }
    }
  }
`;

export const AvatarPlaceholder = styled(Box)`
  background: ${colors.grey200};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 100%;
  border: 1px solid ${colors.grey300};
  cursor: pointer;
`;

export const LogoPopup = styled(Box)`
  cursor: default;
  position: absolute;
  z-index: 10;
  background: ${colors.white};
  border-radius: 4px;
  top: 44px;
  left: 90%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 30px 0px rgba(222, 224, 230, 0.8);
  padding: 16px;
`;

export const LogoPopupItem = styled(Box)`
  width: 100px;
  cursor: pointer;
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.08px;
  &:nth-of-type(2) {
    margin-top: 12px;
  }
  &:hover {
    color: ${colors.blue700};
  }
`;

export const DeleteContainer = styled("div")`
  margin-top: 12px;
`;

export const EditLogo = styled("div")`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  width: 72px;
  height: 72px;
  svg {
    display: none;
  }
  &:hover {
    svg {
      display: block;
    }
  }
`;
