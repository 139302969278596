import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  margin-bottom: 32px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h3`
  margin-bottom: 12px;
  /* font */
  color: ${colors.grey900};
  font-family: "Unica77";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;
`;

export const Container = styled.div`
  border-radius: 8px;
  padding: 20px 24px;
  background: ${colors.natural100};
`;

export const TableContainer = styled.div`
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const AddYearContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    margin-left: 8px;
    /* font */
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    font-style: normal;
    font-family: "Unica77";
    letter-spacing: 0.08px;
    color: ${colors.grey900};
  }
  svg {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    color: ${colors.grey700};
    background-color: ${colors.white};
    border: 1px solid ${colors.grey200};
  }
`;
