export enum AuthActions {
  refreshToken = "refreshToken",
  logout = "logout",
  getProfile = "getProfile",
  signInApi = "signInApi",
}

export enum CountryActions {
  getCountries = "getCountries",
}

export enum PipedriveOwnersActions {
  getPipedriveOwnersRegulars = "getPipedriveOwnersRegulars",
  getPipedriveOwnersReferrals = "getPipedriveOwnersReferrals",
  getPipedriveSources = "getPipedriveSources",
}
