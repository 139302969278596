import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Empty from "application/containers/Empty";
import NotFound from "application/containers/NotFound";
import UsersTable from "features/users/containers/UsersTable";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import UsersHeader from "features/users/components/UsersHeader";
import { PaginationMUI } from "application/components";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import UsersActions from "features/users/store/actions";
import { requestSelector } from "application/store/request/selectors";
import { InvestorPageResponseDto } from "integration/api/UsersApi/models";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { UserStatus } from "application/helpers/userStatuses";

const defaultSize = 10;

const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const [searchItem, setSearchItem] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<UserStatus | "">("");
  const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>([]);
  const [deletedUser, setDeletedUser] =
    useState<InvestorPageResponseDto | null>(null);
  const [showMultipleDeleteModal, setShowMultipleDeleteModal] = useState(false);
  const [showSuccessMultipleDeleteModal, setShowSuccessMultipleDeleteModal] =
    useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);

  const dispatch = useAppDispatch();
  const { content, totalPages } = useAppSelector(
    (state) => state.users.investors
  );
  const { loading } = useAppSelector(requestSelector).getInvestors;

  useEffect(() => {
    dispatch(
      UsersActions.getInvestors({
        page: page - 1,
        size: defaultSize,
        sort: [],
        search: searchItem,
        status: selectedStatus || undefined,
      })
    );
  }, [searchItem, page, selectedStatus]);

  const onPageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
    setSearchParams({ page: String(page) });
  };

  const handelSearch = (value: string) => {
    setSearchItem(value);
    setPage(1);
  };

  const handelSelectStatus = (status: UserStatus | "") => {
    setSelectedStatus(status);
    setPage(1);
  };

  const onDeleteUser = (user: InvestorPageResponseDto) => {
    setDeletedUser(user);
  };

  const onCloseDelete = () => {
    setDeletedUser(null);
  };

  const onSubmitDelete = () => {
    dispatch(UsersActions.deleteInvestor(Number(deletedUser?.id))).then(
      (res) => {
        isResponseFulfilled(res, () => {
          const isEmptyPage = content.length === 1;
          const currentPage = isEmptyPage && page > 1 ? page - 2 : page - 1;
          onCloseDelete();
          setShowSuccessDeleteModal(true);
          dispatch(
            UsersActions.getInvestors({
              page: currentPage,
              size: defaultSize,
              sort: [],
              search: searchItem,
              status: selectedStatus || undefined,
            })
          );
        });
      }
    );
  };

  const handleOpenMultipleDeleteModal = () => {
    setShowMultipleDeleteModal(true);
  };

  const handleCloseMultipleDeleteModal = () => {
    setShowMultipleDeleteModal(false);
  };
  const handleCloseSuccessDeleteModal = () => {
    setShowSuccessMultipleDeleteModal(false);
    setShowSuccessDeleteModal(false);
    setSelectedUsersIds([]);
  };

  const handleDeleteMultiple = () => {
    dispatch(UsersActions.deleteInvestors(selectedUsersIds)).then((res) => {
      isResponseFulfilled(res, () => {
        const isEmptyPage = selectedUsersIds.length === content.length;
        const currentPage = isEmptyPage && page > 1 ? page - 2 : page - 1;
        handleCloseMultipleDeleteModal();
        setShowSuccessDeleteModal(true);
        dispatch(
          UsersActions.getInvestors({
            page: currentPage,
            size: defaultSize,
            sort: [],
            search: searchItem,
            status: selectedStatus || undefined,
          })
        );
      });
    });
  };

  const showSuccessDeletePopUp =
    showSuccessDeleteModal || showSuccessMultipleDeleteModal;

  return (
    <React.Fragment>
      <UsersHeader
        searchItem={searchItem}
        setSearchItem={handelSearch}
        selectedStatus={selectedStatus}
        handelSelectStatus={handelSelectStatus}
      />

      {loading || content.length ? (
        <React.Fragment>
          <UsersTable
            users={content}
            onDelete={onDeleteUser}
            loading={loading}
            page={page}
            selectedUsersIds={selectedUsersIds}
            setSelectedUsersIds={setSelectedUsersIds}
            handleDeleteMultiple={handleOpenMultipleDeleteModal}
          />

          <PaginationMUI
            variant="text"
            color="primary"
            page={page}
            count={totalPages}
            onChange={onPageChange}
          />
        </React.Fragment>
      ) : !searchItem ? (
        <Empty description="You haven’t users yet!" />
      ) : (
        <NotFound />
      )}

      {!!deletedUser && (
        <ConfirmPopUp
          title="Delete User"
          onClose={onCloseDelete}
          onSubmit={onSubmitDelete}
          description={`You want to delete ${deletedUser.firstName} ${deletedUser.lastName}. Are you sure?`}
        />
      )}
      {showMultipleDeleteModal && (
        <ConfirmPopUp
          title="Delete Users"
          onClose={handleCloseMultipleDeleteModal}
          onSubmit={handleDeleteMultiple}
          description="You want to delete users. Are you sure?"
        />
      )}
      {showSuccessDeletePopUp && (
        <SuccessPopUp
          title={`The ${showSuccessDeleteModal ? "user" : "users"} are deleted`}
          onSubmit={handleCloseSuccessDeleteModal}
        />
      )}
    </React.Fragment>
  );
};

export default Users;
