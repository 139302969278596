import { colors } from "application/theme/palette";
import { CompanyStatus } from "integration/api/CompanyApi/models";

export const colorHandler = (selected: CompanyStatus | undefined) => {
  switch (selected) {
    case CompanyStatus.ACTIVE:
      return {
        borderColor: colors.green700,
        bgColor: colors.green200,
      };
    case CompanyStatus.ARCHIVED:
      return {
        borderColor: colors.grey700,
        bgColor: colors.grey200,
      };
    case CompanyStatus.RIGHT_OFF:
      return {
        borderColor: colors.blue900,
        bgColor: colors.blue50,
      };
    case CompanyStatus.EXIT:
      return {
        borderColor: colors.red700,
        bgColor: colors.white,
      };
    case CompanyStatus.DRAFT:
    default:
      return {
        borderColor: colors.orange700,
        bgColor: colors.orange200,
      };
  }
};
