import React, { FC, useEffect, useRef, useState } from "react";

import useOnClickOutside from "application/hooks/useOnClickOutside";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { useDebounce } from "application/hooks/useDebounce";

import UsersActions from "features/users/store/actions";

import { Input, List, ListItem, Wrapper } from "./styles";
import { UserStatus } from "application/helpers/userStatuses";

interface Props {
  searchItem: number | null;
  setSearchItem: (value: number | null) => void;
  error?: boolean;
  status?: UserStatus;
}

const UsersSearchInput: FC<Props> = ({
  searchItem,
  setSearchItem,
  error,
  status,
}) => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const { autosuggestions } = useAppSelector((state) => state.users);

  const [showList, setShowList] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const search = useDebounce(searchValue, 300);

  useOnClickOutside(ref, () => setShowList(false));

  useEffect(() => {
    if (showList) {
      dispatch(UsersActions.getSearchAutosuggestions({ search, status }));
    }
  }, [search, status]);

  const handleSearch = (value: string) => {
    if (!value && searchItem) {
      setSearchItem(null);
    }
    setSearchValue(value);
  };

  const handleChoseSuggest = (name: string, id: number) => {
    setSearchValue(name);
    setSearchItem(id);
    setShowList(false);
  };

  const showSuggestions = showList && !!autosuggestions.length && searchValue;

  return (
    <Wrapper ref={ref}>
      <Input
        placeholder="Choose investor"
        value={searchValue}
        onChange={(e) => handleSearch(e.target.value)}
        onClick={() => setShowList(true)}
        hasError={!!error}
      />

      {showSuggestions && (
        <List>
          {autosuggestions.map((user) => (
            <ListItem
              key={user.id}
              onClick={() =>
                handleChoseSuggest(
                  `${user.firstName} ${user.lastName}`,
                  user.id
                )
              }
            >
              <p>
                {user.firstName} {user.lastName}
              </p>
            </ListItem>
          ))}
        </List>
      )}
    </Wrapper>
  );
};

export default UsersSearchInput;
