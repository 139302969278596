import { Box, styled } from "@mui/system";
import theme from "application/theme";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

export const DatePickerWithTextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const DatePickerContainer = styled(Box)`
  position: relative;
  margin: ${theme.spacing(0)};
`;

export const StyledDateCalendar = styled(DateCalendar)<{ opened: number }>`
  position: absolute;
  background: ${theme.palette.common.white};
  width: 100%;
  min-width: ${theme.spacing(40)};
  height: ${(props) => !props.opened && "0px"};
  z-index: 10;
  border-radius: ${theme.spacing(1)};
  transition: height 0.3s ease-in-out;
  box-shadow: 0px 4px 30px 0px rgba(222, 224, 230, 0.8);
  & .css-1olyt5c-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: ${theme.palette.common.blue700};
  }
  & .css-9t8ja3-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: ${theme.palette.common.blue700};
  }
  & .css-9t8ja3-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
    border: none;
  }

  & .MuiPickersMonth-monthButton.Mui-selected {
    background-color: ${theme.palette.common.blue700} !important;
  }
  & .MuiPickersYear-yearButton.Mui-selected {
    background-color: ${theme.palette.common.blue700} !important;
  }

  & .MuiMonthCalendar-root {
    box-shadow: none !important;
  }
`;
