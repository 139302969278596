import {
  InvestmentDto,
  InvestorCommitmentsResponse,
} from "integration/api/PortfolioAPI/models";

export enum Tabs {
  Commitments = "Commitments",
  Investments = "Investments",
  InvestmentAnalytics = "InvestmentAnalytics",
}

export interface ControllerButtonProps {
  isActive: boolean;
}

export interface TabSectionProps {
  commitments: InvestorCommitmentsResponse[];
  investment: InvestmentDto | null;
  dealTitle: string;
  lostReason?: string;
}
