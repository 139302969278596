import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import PhoneLockedOutlinedIcon from "@mui/icons-material/PhoneLockedOutlined";
import { Typography } from "@mui/material";

import { LinkedinIcon } from "application/assets/icons/LinkedinIcon";
import theme from "application/theme";

import {
  ImageContainer,
  MemberLogo,
  Responsibility,
  StyledLink,
  TeamMemberWrapper,
} from "./styles";
import { dataUrlToFileUsingFetch } from "application/helpers/dataUrlToFileUsingFetch";

interface TeamMemberProps {
  url: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  title: string | undefined;
  imageUrl?: string;
  imageName?: string;
  isResponsibleForQa?: boolean;
  base64Image?: string;
  imageDeleted?: boolean;
}

export const TeamMember = ({
  url,
  firstName,
  lastName,
  title,
  imageUrl,
  imageName,
  isResponsibleForQa,
  base64Image,
  imageDeleted,
}: TeamMemberProps) => {
  const [logoUrl, setLogoUrl] = useState("");
  const memberInitials = `${firstName ? firstName[0] : ""}${
    lastName ? lastName[0] : ""
  }`;

  const getLogoUrl = async () => {
    const file = await dataUrlToFileUsingFetch(
      base64Image || "",
      "image",
      "image/png"
    );
    const logoUrl = URL.createObjectURL(file);
    setLogoUrl(logoUrl);
  };

  useEffect(() => {
    if (base64Image) {
      getLogoUrl();
    }
    if (imageUrl && !base64Image) {
      setLogoUrl(imageDeleted ? "" : imageUrl);
    }
    if (!imageUrl && !base64Image) {
      setLogoUrl("");
    }
  }, [base64Image, imageUrl, imageDeleted]);

  const urlLink = url?.startsWith("https") ? url : `https://${url}`;

  return (
    <TeamMemberWrapper>
      {logoUrl ? (
        <ImageContainer>
          <img src={logoUrl} alt={imageName} />
        </ImageContainer>
      ) : (
        <MemberLogo>{memberInitials}</MemberLogo>
      )}
      <div>
        <Typography
          variant="button"
          color={theme.palette.common.grey900}
          mb={0.5}
        >{`${firstName} ${lastName}`}</Typography>
        <Typography
          variant="subtitle1"
          color={theme.palette.common.grey700}
          mb={0.5}
        >
          {title}
        </Typography>
        {url && (
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={1.5}>
              <LinkedinIcon />
            </Box>
            <StyledLink target="_blank" href={urlLink}>
              {url}
            </StyledLink>
          </Box>
        )}
        <Responsibility isMemberResponsible={!!isResponsibleForQa}>
          {isResponsibleForQa ? (
            <>
              <PhoneOutlinedIcon />
              <p>Responsible for Q&A</p>
            </>
          ) : (
            <>
              <PhoneLockedOutlinedIcon />
              <p>Not responsible for Q&A</p>
            </>
          )}
        </Responsibility>
      </div>
    </TeamMemberWrapper>
  );
};
