import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export const Container = styled.div`
  flex: 1;
  padding: 32px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: ${colors.grey50};
  overflow-y: auto;
`;
