import React from "react";

import { KYCStatus } from "integration/api/UsersApi/models";
import { getKYCStatusTitle } from "application/helpers/kycStatuses";

import { Container } from "./styles";

interface Props {
  status: KYCStatus | undefined;
}

const KYCStatusCard = ({ status }: Props) => {
  return (
    <Container status={status}>
      <p>{getKYCStatusTitle(status)}</p>
    </Container>
  );
};

export default KYCStatusCard;
