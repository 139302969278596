import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { requestSelector } from "application/store/request/selectors";
import { PaginationMUI } from "application/components";

import { Header } from "features/deal-investors/components";
import DealInvestorsTable from "features/deal-investors/containers/DealInvestorsTable";
import DealInvestorsActions from "features/deal-investors/store/actions";

const DealInvestorsPage: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { getDealInvestors } = useAppSelector(requestSelector);
  const { dealInvestors } = useAppSelector((state) => state.dealInvestors);
  const loading = getDealInvestors.loading;
  const [page, setPage] = useState(1);

  const getInvestors = async (page?: number) => {
    await dispatch(
      DealInvestorsActions.getDealInvestors({
        dealId: Number(id),
        page: page || 0,
      })
    );
  };

  const onPageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
    getInvestors(page - 1);
  };

  useEffect(() => {
    getInvestors();
  }, []);

  return (
    <React.Fragment>
      <Header getInvestors={getInvestors} />

      <React.Fragment>
        <DealInvestorsTable
          data={dealInvestors?.content}
          loading={loading}
          getInvestors={getInvestors}
        />

        <PaginationMUI
          page={page}
          variant="text"
          color="primary"
          count={dealInvestors?.totalPages}
          onChange={onPageChange}
        />
      </React.Fragment>
    </React.Fragment>
  );
};

export default DealInvestorsPage;
