import React, { FC, useMemo } from "react";
import { Typography } from "@mui/material";

import { useAppSelector } from "application/hooks/useAppSelector";
import { colors } from "application/theme/palette";

import { dealPageSelector } from "features/deal-profile/store/selectors";

import { Container } from "./styles";

export const NotificationPreview: FC = () => {
  const { profile } = useAppSelector((state) => state.dealPage);
  const { businessModels, broadCategories } = useAppSelector(dealPageSelector);

  const title = useMemo(() => {
    return profile.title || "[Deal title]";
  }, [profile.title]);

  const broadCategory = useMemo(() => {
    const selectedCategory = broadCategories.find(
      (item) => item.id === profile.broadCategoryId
    );
    return selectedCategory?.name || "[Industry]";
  }, [broadCategories, profile.broadCategoryId]);

  const businessModel = useMemo(() => {
    const selectedModel = businessModels.find(
      (item) => item.id === profile.businessModelId
    );
    return selectedModel?.name || "[Business Model]";
  }, [businessModels, profile.businessModelId]);

  const notificationText = useMemo(() => {
    return (
      profile.notificationsData?.notificationText ||
      "[add additional field to admin panel - text for notification, where this part will be added]"
    );
  }, [profile.notificationsData?.notificationText]);

  return (
    <Container>
      <Typography variant="h3" color={colors.grey900} fontWeight={500}>
        Notification preview
      </Typography>
      <Typography variant="h4" color={colors.grey900} fontWeight={400}>
        {`Explore now: ${title}, a ${businessModel} ${broadCategory} with ${notificationText} is raising.`}
      </Typography>
    </Container>
  );
};
