import { colors } from "application/theme/palette";

import { ConsentStatuses } from "integration/api/ConsentsApi/models";

export const colorHandler = (selected: ConsentStatuses | undefined) => {
  switch (selected) {
    case ConsentStatuses.PUBLISHED:
      return {
        borderColor: colors.green700,
        bgColor: colors.green200,
      };
    default:
      return {
        borderColor: colors.orange700,
        bgColor: colors.orange200,
      };
  }
};
