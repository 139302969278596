import { colors } from "application/theme/palette";
import { KYCStatus } from "integration/api/UsersApi/models";

export const KYCStatuses = [
  { value: KYCStatus.APPROVED, label: "Approved" },
  { value: KYCStatus.PENDING, label: "Pending" },
  { value: KYCStatus.REJECTED, label: "Rejected" },
  { value: KYCStatus.NOT_STARTED, label: "Not Started" },
];

export const getKYCStatusColor = (status?: KYCStatus) => {
  switch (status) {
    case KYCStatus.APPROVED:
      return colors.green700;
    case KYCStatus.PENDING:
      return colors.orange700;
    case KYCStatus.REJECTED:
      return colors.red700;
    default:
      return colors.grey500;
  }
};

export const getKYCStatusTitle = (status?: KYCStatus) => {
  switch (status) {
    case KYCStatus.APPROVED:
      return "Approved";
    case KYCStatus.PENDING:
      return "Pending";
    case KYCStatus.REJECTED:
      return "Rejected";
    case KYCStatus.NOT_STARTED:
      return "Not Started";
    default:
      return "";
  }
};
