import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:first-of-type {
      margin-right: 20px;
    }
    border-radius: 4px;
    border: 1px solid ${colors.grey200};
  }
`;
