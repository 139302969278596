import { HTMLProps } from "react";
import { styled } from "@mui/system";

import { colors } from "application/theme/palette";

interface TextFieldStyledProps extends HTMLProps<HTMLTextAreaElement> {
  mb?: number;
  width?: number;
  filled?: number | null;
  hasError?: boolean;
}

export const TextAreaStyled = styled("textarea")<TextFieldStyledProps>`
  box-sizing: border-box;
  margin-bottom: ${({ mb }) => (mb ? mb : 0)}px;
  height: 108px;
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  background-color: ${colors.grey50};
  border: 1px solid
    ${({ filled, hasError }) =>
      filled ? colors.grey700 : hasError ? colors.red700 : colors.grey300};
  resize: none;
  outline: none;
  border-radius: 8px;
  padding: 12px 16px;
  //font
  color: ${colors.grey900};
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.08px;

  &:focus {
    border-color: ${colors.blue500};
    background: ${colors.blue50};
  }

  textarea:-internal-autofill-selected {
    background-color: ${colors.blue50} !important;
  }
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
`;
