import React, { FC, useState } from "react";

import { useAppSelector } from "application/hooks/useAppSelector";
import { ReactComponent as MoneysSvg } from "application/assets/icons/moneys.svg";
import { formatTotal } from "application/helpers/formatTotal";
import BasicPopUp from "application/containers/BasicPopUp";
import { PaginationMUI } from "application/components";

import CopyButton from "../CopyButton";
import TableRow from "../TableRow";

import { TableProps } from "./model";
import {
  Amount,
  CopyModalStyled,
  MultiRowStyled,
  RowTitle,
  TableStyled,
  TotalRowStyled,
  Wrapper,
} from "./styles";

const Table: FC<TableProps> = ({ page, onPageChange, currency }) => {
  const {
    portfolio,
    totalInvestments,
    totalFmvValue,
    totalRealizedProfit,
    multiple,
  } = useAppSelector((state) => state.portfolio);
  const [selectedBusinessId, setSelectedBusinessId] = useState("");

  const totalInvestment = formatTotal(totalInvestments, currency);
  const totalValue = formatTotal(totalFmvValue, currency);
  const totalProfit = formatTotal(totalRealizedProfit, currency);

  const handleCloseIdInformationModal = () => {
    setSelectedBusinessId("");
  };

  return (
    <>
      <Wrapper>
        <TableStyled>
          <thead>
            <tr>
              <th className="logo-column">Logo</th>
              <th className="id-column">ID</th>
              <th className="title-column">Title</th>
              <th className="date-column">Start date</th>
              <th className="total-column">Total invested</th>
              <th className="analytics-column">Investment analytics</th>
              <th className="multiple-column">Multiple</th>
              <th className="action-column">Action</th>
            </tr>
          </thead>
          <tbody>
            {portfolio.content.map((item) => (
              <TableRow
                key={item.id}
                portfolio={item}
                setSelectedBusinessId={setSelectedBusinessId}
                currency={currency}
              />
            ))}
            <TotalRowStyled>
              <td></td>
              <td colSpan={3}>
                <RowTitle>Total:</RowTitle>
              </td>
              <td>
                <Amount>
                  <MoneysSvg />
                  {totalInvestment}
                </Amount>
              </td>
              <td>
                <MultiRowStyled>
                  <div>
                    <h2>Current value (FMV)</h2>
                    <p>{totalValue}</p>
                  </div>
                  <div>
                    <h2>Total realized profit</h2>
                    <p>{totalProfit}</p>
                  </div>
                </MultiRowStyled>
              </td>
              <td colSpan={2}>{multiple}</td>
            </TotalRowStyled>
          </tbody>
        </TableStyled>
      </Wrapper>
      {selectedBusinessId && (
        <BasicPopUp
          title="ID information"
          onClose={handleCloseIdInformationModal}
          onSubmit={handleCloseIdInformationModal}
        >
          <CopyModalStyled>
            <p>{selectedBusinessId}</p>
            <CopyButton copyText={selectedBusinessId} />
          </CopyModalStyled>
        </BasicPopUp>
      )}

      <PaginationMUI
        page={page}
        variant="text"
        color="primary"
        count={portfolio.totalPages}
        onChange={onPageChange}
      />
    </>
  );
};

export default Table;
