import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  Card,
  Title,
  Wrapper,
  Actions,
  SubmitButton,
  CancelButton,
  DescriptionContainer,
} from "./styles";

interface Props {
  title: string;
  description: string;
  onClose: () => void;
  onSubmit: () => void;
  submitButtonTitle?: string;
}

const ConfirmPopUp = ({
  title,
  description,
  onClose,
  onSubmit,
  submitButtonTitle,
}: Props) => {
  return (
    <Wrapper>
      <Card>
        <Title>{title}</Title>
        <DescriptionContainer>
          <InfoOutlinedIcon />
          <p>{description}</p>
        </DescriptionContainer>

        <Actions>
          <CancelButton onClick={onClose}>
            <p>Cancel</p>
          </CancelButton>

          <SubmitButton onClick={onSubmit}>
            <p>{submitButtonTitle || "Ok"}</p>
          </SubmitButton>
        </Actions>
      </Card>
    </Wrapper>
  );
};

export default ConfirmPopUp;
