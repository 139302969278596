import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

import { colors } from "application/theme/palette";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
 `;
const scaleIn = keyframes`
 from { scale: 0.5; transform: translateY(-50vh)  }
 to { scale: 1; transform: translateY(0) }
`;

export const Wrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  animation: ${fadeIn} 0.35s;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 32px;
  min-width: 620px;
  border-radius: 8px;
  box-sizing: border-box;
  animation: ${scaleIn} 0.35s;
  background-color: ${colors.white};
`;

export const Title = styled.h3`
  margin-bottom: -4px;
  /* font */
  font-size: 20px;
  font-weight: 500;
  line-height: 140%;
  font-style: normal;
  font-family: "Unica77";
  color: ${colors.grey900};
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;
`;

export const CancelButton = styled.div`
  padding: 10px 32px;
  border-radius: 34px;
  background-color: ${colors.white};
  cursor: pointer;
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    font-style: normal;
    text-align: center;
    font-family: "Unica77";
    letter-spacing: 0.08px;
    color: ${colors.blue700};
  }
`;
