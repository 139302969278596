import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import BasicPopUp from "application/containers/BasicPopUp";
import {
  PipedriveOwnersDropdown,
  PipedriveSourcesDropdown,
} from "application/components";

import { addOwnerSchema } from "features/owners/helpers/validations";

import { FormData, OwnerAddModalProps } from "./models";
import { Container } from "./styles";

const OwnerAddModal: FC<OwnerAddModalProps> = ({
  isLoading,
  handleClose,
  onSubmit,
}) => {
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(addOwnerSchema),
  });

  const onSubmitForm = (data: FormData) => {
    onSubmit(data);
  };

  const handleSelectOwner = (id: number) => {
    setValue("pipedriveUserId", id, {
      shouldValidate: !!errors.pipedriveUserId,
    });
  };
  const handleSelectSource = (id: number) => {
    setValue("pipedriveSourceId", id, {
      shouldValidate: !!errors.pipedriveSourceId,
    });
  };

  const disabled =
    isLoading || !watch("pipedriveUserId") || !watch("pipedriveSourceId");

  return (
    <BasicPopUp
      title="Add new deal owner"
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmitForm)}
      isLoading={isLoading}
      disabled={disabled}
    >
      <Container>
        <PipedriveOwnersDropdown
          value={watch("pipedriveUserId")}
          onChange={handleSelectOwner}
          placeholder="Deal owner"
          isReferrals={true}
        />
        <PipedriveSourcesDropdown
          value={watch("pipedriveSourceId")}
          onChange={handleSelectSource}
          placeholder="Deal source"
        />
      </Container>
    </BasicPopUp>
  );
};

export default OwnerAddModal;
