import { styled } from "@mui/system";

export const LoaderStyled = styled("div")`
  width: 40px;
  aspect-ratio: 1;
  display: grid;
  border-radius: 50%;
  background: linear-gradient(0deg, #ffffff 30%, #0000 0 70%, #0050c9 0) 50%/8%
      100%,
    linear-gradient(90deg, #ffffff 30%, #ffffff 30%, #0000 0 70%, #ccdfff 0) 50%/100%
      8%;
  background-repeat: no-repeat;
  animation: l23 1s infinite steps(8);

  &::before,
  &::after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(40deg);
  }
  &::after {
    opacity: 0.83;
    transform: rotate(40deg);
  }
  @keyframes l23 {
    100% {
      transform: rotate(1turn);
    }
  }
`;
