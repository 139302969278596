import { createAsyncThunk } from "@reduxjs/toolkit";

import { RequestEnum } from "application/store/request/model";

import ConsentsApi from "integration/api/ConsentsApi";
import {
  ConsentListParams,
  UpdateConsentRequest,
} from "integration/api/ConsentsApi/models";

class ConsentsActions {
  static getConsentList = createAsyncThunk(
    `consents/${RequestEnum.getConsentList}`,
    async (params: ConsentListParams, { rejectWithValue }) => {
      try {
        const { data } = await ConsentsApi.getConsentList(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getConsentById = createAsyncThunk(
    `consents/${RequestEnum.getConsentById}`,
    async (id: number, { rejectWithValue }) => {
      try {
        const { data } = await ConsentsApi.getConsentById(id);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static addConsent = createAsyncThunk(
    `consents/${RequestEnum.addConsent}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await ConsentsApi.addConsent();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deleteConsent = createAsyncThunk(
    `consents/${RequestEnum.deleteConsent}`,
    async (id: number, { rejectWithValue }) => {
      try {
        const { data } = await ConsentsApi.deleteConsent(id);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static updateConsent = createAsyncThunk(
    `consents/${RequestEnum.updateConsent}`,
    async (params: UpdateConsentRequest, { rejectWithValue }) => {
      try {
        const { data } = await ConsentsApi.updateConsent(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default ConsentsActions;
