import { styled } from "@mui/system";

export const Container = styled("section")`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  box-sizing: border-box;

  h4 {
    margin-top: 20px;
  }
`;
