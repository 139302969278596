import { styled } from "@mui/system";
import { colors } from "application/theme/palette";
import { ListItemProps, TextFieldProps } from "./model";

interface StyledProps {
  opened: boolean;
}

export const DropdownStyled = styled("div")`
  position: relative;

  > h3 {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    text-transform: none;
    font-family: Unica77;
    color: ${colors.grey500};
    margin-bottom: 4px;
  }
`;

export const TextField = styled("div")<TextFieldProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 0px 16px;
  border-radius: 8px;
  border: 1px solid
    ${({ isActive, filled }) =>
      isActive ? colors.blue500 : filled ? colors.grey700 : colors.grey300};
  background: ${({ isActive }) => (isActive ? colors.blue50 : colors.grey50)};
  cursor: pointer;

  p {
    color: ${({ filled }) => (filled ? colors.grey900 : colors.grey700)};
    font-family: Unica77;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }

  svg {
    transform: ${({ isActive }) =>
      isActive ? "rotate(180deg)" : "rotate(0deg)"};
    path {
      fill: ${colors.grey500};
    }
  }
`;

export const DropdownList = styled("div")<StyledProps>`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 418px;
  padding: ${({ opened }) => (opened ? "12px 0" : 0)};
  max-height: ${({ opened }) => (opened ? "304px" : 0)};
  overflow-y: scroll;
  border-radius: 4px;
  background: ${colors.white};
  box-shadow: 0px 4px 30px 0px rgba(222, 224, 230, 0.8);
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  > div {
    display: flex;
    flex-direction: column;
    padding: 0 12px;

    > span {
      padding: 10px 0;
      color: ${colors.grey700};
      font-family: Unica77;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.08px;
      text-align: center;
    }
  }
`;

export const ListItem = styled("div")<ListItemProps>`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  background-color: ${({ selected }) =>
    selected ? colors.blue50 : colors.white};
  margin-bottom: 4px;

  color: ${({ selected }) => (selected ? colors.grey900 : colors.grey800)};
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: ${({ selected }) => (selected ? 700 : 400)};
  line-height: 140%;
  letter-spacing: 0.08px;
  text-overflow: clip;
  white-space: nowrap;
  cursor: pointer;

  &:hover,
  :focus,
  :focus:hover {
    background-color: ${colors.blue50};
    outline: none;
  }

  span {
    font-size: 22px !important;
    line-height: 18px !important;
    margin-right: 8px;
  }
`;

export const Input = styled("input")`
  height: 40px;
  width: 100%;
  outline: none;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid ${colors.grey300};
  box-sizing: border-box;
  margin-bottom: 8px;

  /* font */
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  font-style: normal;
  letter-spacing: 0.08px;
  font-family: Unica77;
  color: ${colors.grey700};
  background-color: ${colors.grey50};

  &:focus {
    background-color: ${colors.blue50};
    border: 1px solid ${colors.blue700};
  }
`;
