import { styled } from "@mui/system";
import Add from "@mui/icons-material/Add";

import theme from "application/theme";
import { colors } from "application/theme/palette";

export const HeaderStyled = styled("header")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${theme.spacing(4)};

  h1 {
    color: ${theme.palette.common.grey900};
    font-size: ${theme.spacing(4)};
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

export const AddIcon = styled(Add)`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  color: ${colors.white};
`;
