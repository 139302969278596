import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { UpdateYearSchema } from "./validation";

import { TextFieldStyled } from "application/components";
import PrimaryButton from "application/components/PrimaryButton";

import { Card, Title, Wrapper, Actions, CancelButton } from "./styles";

interface Props {
  year?: number;
  isFactMetric: boolean;
  onClose: () => void;
  onSubmit: (year: number) => void;
}

const UpdateYearPopUp = ({ year, onClose, onSubmit, isFactMetric }: Props) => {
  const updateYearSchema = UpdateYearSchema(isFactMetric);
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ year: number }>({
    mode: "onChange",
    defaultValues: { year },
    resolver: yupResolver(updateYearSchema),
  });

  const values = watch();

  return (
    <Wrapper>
      <Card>
        <Title>{year ? "Edit" : "Add"} year</Title>

        <TextFieldStyled
          mb={20}
          fullWidth
          id="year"
          size="small"
          color="secondary"
          label="Enter year"
          filled={values.year}
          error={!!errors.year}
          {...register("year")}
        />

        <Actions>
          <CancelButton onClick={onClose}>
            <p>Cancel</p>
          </CancelButton>

          <PrimaryButton
            title="Ok"
            disabled={!isValid}
            onClick={handleSubmit((data) => onSubmit(data.year))}
          />
        </Actions>
      </Card>
    </Wrapper>
  );
};

export default UpdateYearPopUp;
