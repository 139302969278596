import React, { useState } from "react";
import { Close, CreateOutlined } from "@mui/icons-material";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { DealSliceAction } from "features/deal-profile/store/slice";
import { DealTractionAmount } from "integration/api/DealsApi/models";
import { TractionAmountKey } from "features/deal-profile/store/models";
import { dealPageSelector } from "features/deal-profile/store/selectors";

import ConfirmPopUp from "application/containers/ConfirmPopUp";
import EmptyTraction from "features/deal-profile/components/EmptyTraction";
import EditAmountPopUp from "features/deal-profile/popups/EditAmountPopUp";
import TractionAmountInput from "features/deal-profile/components/TractionAmountInput";

import { Button, Container, Title, Wrapper } from "./styles";

interface Props {
  title: string;
  amountKey: TractionAmountKey;
}

const formatter = new Intl.NumberFormat("en-US");

const TractionAmount = ({ title, amountKey }: Props) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(dealPageSelector).profile[amountKey];
  const amount = formatter.format(data?.amount || 0);

  const [editVisible, setEditVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);

  // add
  const onAdd = () => {
    dispatch(DealSliceAction.addTractionAmount(amountKey));
  };

  // delete
  const onDelete = () => {
    setDeleteVisible(true);
  };
  const onDeleteSubmit = () => {
    setDeleteVisible(false);
    dispatch(DealSliceAction.deleteTractionAmount(amountKey));
  };
  const onDeleteClose = () => {
    setDeleteVisible(false);
  };

  // edit
  const onEdit = () => {
    setEditVisible(true);
  };
  const onEditClose = () => {
    setEditVisible(false);
  };
  const onEditSubmit = (newData: DealTractionAmount) => {
    setEditVisible(false);
    dispatch(
      DealSliceAction.editTractionAmount({ key: amountKey, data: newData })
    );
  };

  return (
    <React.Fragment>
      <Wrapper>
        <Title>{title}</Title>
        <Container>
          {!data || !Object.keys(data).length ? (
            <EmptyTraction onClick={onAdd} title="amount" />
          ) : (
            <React.Fragment>
              <TractionAmountInput
                amount={amount as any}
                currency={data.currency}
              />

              <Button onClick={onEdit}>
                <CreateOutlined />
              </Button>

              <Button onClick={onDelete}>
                <Close />
              </Button>
            </React.Fragment>
          )}
        </Container>
      </Wrapper>

      {deleteVisible && (
        <ConfirmPopUp
          title="Delete value"
          onClose={onDeleteClose}
          onSubmit={onDeleteSubmit}
          description={`You want to delete ${amountKey.toUpperCase()}. Are you sure?`}
        />
      )}
      {editVisible && (
        <EditAmountPopUp
          defaultValues={data}
          onClose={onEditClose}
          onSubmit={onEditSubmit}
          title={amountKey.toUpperCase()}
        />
      )}
    </React.Fragment>
  );
};

export default TractionAmount;
