import React, { FC } from "react";

import { colors } from "application/theme/palette";
import Loader from "application/components/Loader";
import { ReactComponent as MoneysSvg } from "application/assets/icons/moneys.svg";
import { useAppSelector } from "application/hooks/useAppSelector";
import { formatTotal } from "application/helpers/formatTotal";

import { TableRow } from "features/companies/components";

import { TableProps } from "./models";
import {
  Container,
  TableStyled,
  LoaderContainer,
  TotalRowStyled,
  RowTitle,
  Amount,
  MultiRowStyled,
} from "./styles";

const Table: FC<TableProps> = ({ currency, loading, onDeleteCompany }) => {
  const {
    companies,
    totalInvestments,
    totalFmvValue,
    totalRealizedProfit,
    multiple,
  } = useAppSelector((state) => state.companyList);

  const totalInvestmentValue = formatTotal(totalInvestments, currency);
  const totalValue = formatTotal(totalFmvValue, currency);
  const totalProfit = formatTotal(totalRealizedProfit, currency);

  return (
    <Container>
      {loading && (
        <LoaderContainer>
          <Loader color={colors.grey500} />
        </LoaderContainer>
      )}

      <TableStyled>
        <thead>
          <tr>
            <th className="logo-column">Logo</th>
            <th className="name-column">Company name</th>
            <th className="founded-column">Founded</th>
            <th className="status-column">Status</th>
            <th className="total-column">Total invested</th>
            <th className="analytics-column">Investment analytics</th>
            <th className="multiple-column">Multiple</th>
            <th className="action-column">Action</th>
          </tr>
        </thead>

        <tbody>
          {companies.content?.map((company) => (
            <TableRow
              key={company.id}
              item={company}
              onDeleteCompany={onDeleteCompany}
            />
          ))}
          <TotalRowStyled>
            <td></td>
            <td colSpan={3}>
              <RowTitle>Total:</RowTitle>
            </td>
            <td>
              <Amount>
                <MoneysSvg />
                {totalInvestmentValue}
              </Amount>
            </td>
            <td>
              <MultiRowStyled>
                <div>
                  <h2>Current value (FMV)</h2>
                  <p>{totalValue}</p>
                </div>
                <div>
                  <h2>Total realized profit</h2>
                  <p>{totalProfit}</p>
                </div>
              </MultiRowStyled>
            </td>
            <td colSpan={2}>{multiple}</td>
          </TotalRowStyled>
        </tbody>
      </TableStyled>
    </Container>
  );
};

export default Table;
