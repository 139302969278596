import React, { FC } from "react";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { Wrapper, BackButton } from "./styles";

const CompanyInvestorsHeader: FC = () => {
  const navigate = useNavigate();

  const hideBackButton = window?.history?.length <= 2;

  const goBack = () => {
    const path = (window?.history?.state?.usr?.companyInvestors ||
      -1) as string;
    navigate(path, { state: window?.history?.state?.usr });
  };

  return (
    <Wrapper>
      {!hideBackButton && (
        <BackButton type="button" onClick={goBack}>
          <ArrowBack />
          Back to company overview
        </BackButton>
      )}
      <h1>Investors</h1>
    </Wrapper>
  );
};

export default CompanyInvestorsHeader;
