import * as yup from "yup";

import { CommonErrors } from "application/helpers/validations";

export const investmentAnalyticSchema = yup.object({
  fmvChangeDate: yup.string().required(CommonErrors.requiredField),
  fmvValue: yup.number().required(CommonErrors.requiredField),
  realizedProfit: yup.number().required(CommonErrors.requiredField),
  currency: yup.string().required(CommonErrors.requiredField),
});
