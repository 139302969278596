import axiosInstance from "../axiosInstance";
import { OwnersParams, PageRegularLeadsOwnerDto } from "./models";

const OwnersApi = {
  getOwners: (params: OwnersParams) => {
    return axiosInstance.get<PageRegularLeadsOwnerDto>(
      "/api/admin/deal-owners/regular-leads",
      {
        params,
      }
    );
  },
  addOwner: (pipedriveUserId: number) => {
    return axiosInstance.post(
      `/api/admin/deal-owners/regular-leads/${pipedriveUserId}`
    );
  },
  deleteOwner: (id: number) => {
    return axiosInstance.delete(`/api/admin/deal-owners/regular-leads/${id}`);
  },
};

export default OwnersApi;
