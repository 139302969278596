import React from "react";

import { MetricType } from "integration/api/DealsApi/models";

import { Button, Container } from "./styles";

interface Props {
  metric: MetricType;
  setMetric: (metric: MetricType) => void;
}

const ForecastFactSwitch = ({ metric, setMetric }: Props) => {
  return (
    <Container>
      <Button
        active={metric === MetricType.FORECAST}
        disabled={metric === MetricType.FORECAST}
        onClick={() => setMetric(MetricType.FORECAST)}
      >
        <p>Forecast</p>
      </Button>

      <Button
        active={metric === MetricType.FACT}
        disabled={metric === MetricType.FACT}
        onClick={() => setMetric(MetricType.FACT)}
      >
        <p>Fact</p>
      </Button>
    </Container>
  );
};

export default ForecastFactSwitch;
