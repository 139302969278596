import { createSlice } from "@reduxjs/toolkit";

import PortfolioActions from "./actions";
import { PortfolioState } from "./model";

const initialState: PortfolioState = {
  portfolio: {
    totalElements: 0,
    totalPages: 0,
    size: 0,
    content: [],
    number: 0,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    numberOfElements: 0,
    pageable: {
      offset: 0,
      sort: {
        empty: true,
        sorted: true,
        unsorted: true,
      },
      pageNumber: 0,
      pageSize: 0,
      paged: true,
      unpaged: true,
    },
    first: true,
    last: true,
    empty: true,
  },
  totalInvestments: 0,
  totalFmvValue: 0,
  totalRealizedProfit: 0,
  multiple: 0,
};

export const portfolioSlice = createSlice({
  name: "portfolio",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get portfolio
    builder.addCase(
      PortfolioActions.getPortfolio.fulfilled,
      (state, action) => {
        state.portfolio = action.payload;
      }
    );
    // get portfolio investment total
    builder.addCase(
      PortfolioActions.getPortfolioInvestmentTotal.fulfilled,
      (state, action) => {
        state.totalInvestments = action.payload.totalInvestments || 0;
        state.totalFmvValue = action.payload.totalFmvValue || 0;
        state.totalRealizedProfit = action.payload.totalRealizedProfit || 0;
        state.multiple = action.payload.multiple || 0;
      }
    );
  },
});

export const PortfolioSliceAction = portfolioSlice.actions;
export default portfolioSlice.reducer;
