import React, { useCallback } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import { InputEvent } from "application/models";
import { Currency, ValuationType } from "integration/api/DealsApi/models";
import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { DealSliceAction } from "features/deal-profile/store/slice";
import { dealPageSelector } from "features/deal-profile/store/selectors";

import {
  DatePicker,
  CustomSelect,
  TextFieldStyled,
  CurrencySymbolSelect,
} from "application/components";

import {
  Empty,
  Wrapper,
  Container,
  SelectContainer,
  CurrencyWrapper,
} from "./styles";
import { colors } from "application/theme/palette";
import dayjs from "dayjs";
import { getValidNumberValue } from "features/deal-profile/helpers/getValidNumberValue";
import { DealErrorKey } from "features/deal-profile/helpers/formatDealError";

const formatter = new Intl.NumberFormat("en-US");

const DetailsSection = () => {
  const dispatch = useAppDispatch();
  const profile = useAppSelector(dealPageSelector).profile;
  const { errors } = useAppSelector((state) => state.dealPage);

  const hasCommitmentDeadlineError = errors.some((item) =>
    item.includes(DealErrorKey["details.commitmentDeadline"])
  );
  const hasAvailableAllocationAmountError = errors.some((item) =>
    item.includes(DealErrorKey["details.availableAllocation.amount"])
  );
  const hasAvailableAllocationCurrencyError = errors.some((item) =>
    item.includes(DealErrorKey["details.availableAllocation.currency"])
  );
  const hasValuationAmountError = errors.some((item) =>
    item.includes(DealErrorKey["details.valuation.amount"])
  );
  const hasValuationCurrencyError = errors.some((item) =>
    item.includes(DealErrorKey["details.valuation.currency"])
  );
  const hasValuationTypeError = errors.some((item) =>
    item.includes(DealErrorKey["details.valuation.type"])
  );
  const hasTotalRoundSizeAmountError = errors.some((item) =>
    item.includes(DealErrorKey["details.totalRoundSize.amount"])
  );
  const hasTotalRoundSizeCurrencyError = errors.some((item) =>
    item.includes(DealErrorKey["details.totalRoundSize.currency"])
  );
  const hasCommittedAllocationFromTaVenturesAmountError = errors.some((item) =>
    item.includes(
      DealErrorKey["details.committedAllocationFromTaVentures.amount"]
    )
  );
  const hasCommittedAllocationFromTaVenturesCurrencyError = errors.some(
    (item) =>
      item.includes(
        DealErrorKey["details.committedAllocationFromTaVentures.currency"]
      )
  );

  const valuationOptions = [
    { value: ValuationType.POST_MONEY, label: "post money" },
    { value: ValuationType.PRE_MONEY, label: "pre-money" },
  ];

  const setErrors = (error: DealErrorKey) => {
    dispatch(
      DealSliceAction.setErrors(errors.filter((item) => item !== error))
    );
  };

  const setDeadLine = (date: string) => {
    dispatch(
      DealSliceAction.setDetailsCommitmentDeadline(dayjs(date).toISOString())
    );
    hasCommitmentDeadlineError &&
      setErrors(DealErrorKey["details.commitmentDeadline"]);
  };

  // Available allocation
  // const setAllocation = (event: InputEvent) => {
  //   const value = event.target.value;
  //   dispatch(DealSliceAction.setDetailsAllocation(!!value ? +value : ""));
  //   hasAvailableAllocationAmountError &&
  //     setErrors(DealErrorKey["details.availableAllocation.amount"]);
  // };
  // const setAllocationCurrency = (currency: Currency) => {
  //   dispatch(DealSliceAction.setDetailsAllocationCurrency(currency));
  //   hasAvailableAllocationCurrencyError &&
  //     setErrors(DealErrorKey["details.availableAllocation.currency"]);
  // };

  // Committed Allocation From TA Ventures
  const setAllocationTA = (event: InputEvent) => {
    const value = event.target.value;
    const numberValue = Number(value.replaceAll(",", ""));
    if (Number.isNaN(numberValue)) return;
    dispatch(
      DealSliceAction.setDetailsAllocationTA(!!numberValue ? +numberValue : "")
    );
    hasCommittedAllocationFromTaVenturesAmountError &&
      setErrors(
        DealErrorKey["details.committedAllocationFromTaVentures.amount"]
      );
  };
  const setAllocationTACurrency = (currency: Currency) => {
    dispatch(DealSliceAction.setDetailsAllocationTACurrency(currency));
    hasCommittedAllocationFromTaVenturesCurrencyError &&
      setErrors(
        DealErrorKey["details.committedAllocationFromTaVentures.currency"]
      );
  };

  // Valuation
  const setValuation = (event: InputEvent) => {
    const value = event.target.value;
    const numberValue = Number(value.replaceAll(",", ""));
    if (Number.isNaN(numberValue)) return;
    dispatch(
      DealSliceAction.setDetailsValuation(!!numberValue ? +numberValue : "")
    );
    hasValuationAmountError &&
      setErrors(DealErrorKey["details.valuation.amount"]);
  };
  const setValuationType = useCallback(
    (value: ValuationType) => {
      dispatch(DealSliceAction.setDetailsValuationType(value));
      hasValuationTypeError &&
        setErrors(DealErrorKey["details.valuation.type"]);
    },
    [profile.details?.valuation?.type, hasValuationTypeError, errors]
  );
  const setValuationCurrency = (currency: Currency) => {
    dispatch(DealSliceAction.setDetailsValuationCurrency(currency));
    hasValuationCurrencyError &&
      setErrors(DealErrorKey["details.valuation.currency"]);
  };

  // Total Round size
  const setTotalRoundSize = (event: InputEvent) => {
    const value = event.target.value;
    const numberValue = Number(value.replaceAll(",", ""));
    if (Number.isNaN(numberValue)) return;
    dispatch(
      DealSliceAction.setDetailsTotalRoundSize(
        !!numberValue ? +numberValue : ""
      )
    );
    hasTotalRoundSizeAmountError &&
      setErrors(DealErrorKey["details.totalRoundSize.amount"]);
  };
  const setTotalRoundSizeCurrency = (currency: Currency) => {
    dispatch(DealSliceAction.setDetailsTotalRoundSizeCurrency(currency));
    hasTotalRoundSizeCurrencyError &&
      setErrors(DealErrorKey["details.totalRoundSize.currency"]);
  };

  return (
    <Box>
      <Typography variant="h2" color={colors.grey900} mb={4}>
        Details
      </Typography>

      {/* <Wrapper>
        <h3>Round Size</h3>
        <TextFieldStyled
          width={364}
          type="number"
          autoComplete="off"
          onChange={setAllocation}
          value={getValidNumberValue(
            profile.details?.availableAllocation?.amount
          )}
          filled={profile.details?.availableAllocation?.amount ? 1 : 0}
          error={hasAvailableAllocationAmountError}
        />
        <CurrencyWrapper>
          <CurrencySymbolSelect
            color={colors.white}
            onChange={setAllocationCurrency}
            selected={profile.details?.availableAllocation?.currency}
            error={hasAvailableAllocationCurrencyError}
          />
        </CurrencyWrapper>
      </Wrapper> */}

      <Wrapper>
        <h3>Commitment deadline</h3>
        <SelectContainer>
          <DatePicker
            onChange={setDeadLine}
            value={profile.details?.commitmentDeadline}
            hasError={hasCommitmentDeadlineError}
          />
        </SelectContainer>
        <Empty />
      </Wrapper>

      <Wrapper>
        <h3>Committed allocation from TA Ventures</h3>
        <TextFieldStyled
          width={364}
          autoComplete="off"
          onChange={setAllocationTA}
          value={getValidNumberValue(
            profile.details?.committedAllocationFromTaVentures?.amount
          )}
          filled={
            profile.details?.committedAllocationFromTaVentures?.amount ? 1 : 0
          }
          error={hasCommittedAllocationFromTaVenturesAmountError}
        />
        <CurrencyWrapper>
          <CurrencySymbolSelect
            color={colors.white}
            onChange={setAllocationTACurrency}
            selected={
              profile.details?.committedAllocationFromTaVentures?.currency
            }
            error={hasCommittedAllocationFromTaVenturesCurrencyError}
          />
        </CurrencyWrapper>
      </Wrapper>

      <Wrapper>
        <h3>Valuation</h3>

        <div>
          <Container>
            <TextFieldStyled
              width={364}
              autoComplete="off"
              onChange={setValuation}
              value={getValidNumberValue(profile.details?.valuation?.amount)}
              filled={profile.details?.valuation?.amount ? 1 : 0}
              error={hasValuationAmountError}
            />
            <CurrencyWrapper>
              <CurrencySymbolSelect
                color={colors.white}
                onChange={setValuationCurrency}
                selected={profile.details?.valuation?.currency}
                error={hasValuationCurrencyError}
              />
            </CurrencyWrapper>
          </Container>

          <Container>
            <SelectContainer>
              <CustomSelect
                maxHeight={300}
                onChange={(value) => setValuationType(value as ValuationType)}
                placeholder="Valuation"
                selected={profile.details?.valuation?.type}
                options={valuationOptions}
                error={hasValuationTypeError}
              />
            </SelectContainer>
            <Empty />
          </Container>
        </div>
      </Wrapper>

      <Wrapper>
        <h3>Total Round size</h3>
        <TextFieldStyled
          width={364}
          autoComplete="off"
          onChange={setTotalRoundSize}
          value={getValidNumberValue(profile.details?.totalRoundSize?.amount)}
          filled={profile.details?.totalRoundSize?.amount ? 1 : 0}
          error={hasTotalRoundSizeAmountError}
        />
        <CurrencyWrapper>
          <CurrencySymbolSelect
            color={colors.white}
            onChange={setTotalRoundSizeCurrency}
            selected={profile.details?.totalRoundSize?.currency}
            error={hasTotalRoundSizeCurrencyError}
          />
        </CurrencyWrapper>
      </Wrapper>
    </Box>
  );
};

export default DetailsSection;
