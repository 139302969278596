import axiosInstance from "../axiosInstance";
import {
  PortfolioParams,
  PortfolioDto,
  InvestorDto,
  InvestorStatusParams,
  CreateInvestmentResponseDto,
  CreateCommitmentDto,
  CreateCommitmentResponse,
  DeleteInvestorParams,
  DeleteMultipleInvestorsParams,
  PortfolioInvestorDetailsResponseDto,
  ChangeInvestmentParams,
  CurrentCurrencyParams,
  UpdateCurrentCurrencyParams,
  DeleteCurrentCurrencyParams,
  GetPortfolioInvestmentParams,
  DealAdminResponseTotalsDto,
  InvestorParamsAPI,
  InvestorsParamsAPI,
} from "./models";

const PortfolioApi = {
  getPortfolio: (params: PortfolioParams) => {
    const { currency, ...restParams } = params;
    return axiosInstance.get<PortfolioDto>("/api/admin/deal/portfolio/list", {
      params: { ...restParams, ...(currency ? { currency } : {}) },
    });
  },
  getInvestors: (params: InvestorsParamsAPI) => {
    const { id, search, currency, key, ...restParams } = params;

    return axiosInstance.get<InvestorDto>(
      `/api/admin/deal/${id}/portfolio/investors`,
      {
        params: {
          ...restParams,
          ...(currency ? { currency } : {}),
          ...(search ? { search } : {}),
        },
        headers: {
          "X-Encryption-Key": key
        }
      }
    );
  },
  getInvestorById: (params: InvestorParamsAPI) => {
    const { dealId, investorId, currency, key } = params;
    return axiosInstance.get<PortfolioInvestorDetailsResponseDto>(
      `/api/admin/deal/${dealId}/portfolio/investors/${investorId}`,
      {
        params: {
          ...(currency ? { currency } : {}),
        },
        headers: {
          "X-Encryption-Key": key
        }
      }
    );
  },
  changeInvestorStatus: (data: InvestorStatusParams) => {
    const { id, investorId, ...restData } = data;
    return axiosInstance.patch<CreateInvestmentResponseDto>(
      `/api/admin/deal/${id}/portfolio/investors/${investorId}/status`,
      restData
    );
  },
  createInvestor: (data: CreateCommitmentDto) => {
    const { id, ...restData } = data;
    return axiosInstance.post<CreateCommitmentResponse>(
      `/api/admin/deal/${id}/portfolio/investors`,
      restData
    );
  },
  deleteInvestor: (data: DeleteInvestorParams) => {
    return axiosInstance.delete(
      `/api/admin/deal/${data.dealId}/portfolio/investors/${data.investorId}`
    );
  },
  deleteInvestors: (data: DeleteMultipleInvestorsParams) => {
    return axiosInstance.delete(
      `/api/admin/deal/${data.dealId}/portfolio/investors`,
      {
        data: data.investorsIds,
      }
    );
  },
  changeInvestment: (params: ChangeInvestmentParams) => {
    const { id, investmentId, ...rest } = params;
    return axiosInstance.patch(
      `/api/admin/deal/${id}/portfolio/investment/${investmentId}/status`,
      rest
    );
  },
  addInvestmentAnalytics: (params: CurrentCurrencyParams) => {
    const { id, investorId, data } = params;
    return axiosInstance.post(
      `/api/admin/deal/${id}/portfolio/investors/${investorId}/analytics`,
      data
    );
  },
  updateInvestmentAnalytic: (params: UpdateCurrentCurrencyParams) => {
    const { id, investorId, analyticId, data } = params;
    return axiosInstance.put(
      `/api/admin/deal/${id}/portfolio/investors/${investorId}/analytics/${analyticId}`,
      data
    );
  },
  deleteInvestmentAnalytic: (params: DeleteCurrentCurrencyParams) => {
    const { id, investorId, analyticId } = params;
    return axiosInstance.delete(
      `/api/admin/deal/${id}/portfolio/investors/${investorId}/analytics/${analyticId}`
    );
  },
  getPortfolioInvestmentTotal: (params: GetPortfolioInvestmentParams) => {
    const { search, currency } = params;
    return axiosInstance.get<DealAdminResponseTotalsDto>(
      `/api/admin/deal/portfolio/list/totals`,
      {
        params: {
          ...(search ? { search } : {}),
          ...(currency ? { currency } : {}),
        },
      }
    );
  },
};

export default PortfolioApi;
