import { Box } from "@mui/system";
import React, { useState } from "react";
import { ClickAwayListener } from "@mui/material";

import { Currency } from "integration/api/DealsApi/models";
import {
  getCurrencyIcon,
  getCurrencyTitle,
} from "application/helpers/currency";

import {
  CurrencyIcon,
  DropdownItem,
  DropdownStyle,
  SelectContainer,
  CurrencySelectButton,
} from "./styles";
import theme from "application/theme";

import { ArrowDropUp } from "application/assets/ArrowDropUp";
import { ArrowDropDown } from "application/assets/ArrowDropDown";

interface Props {
  color?: string;
  selected?: Currency;
  onChange: (value: Currency) => void;
  error?: boolean;
}

export const CurrencySymbolSelect = ({
  color,
  selected,
  onChange,
  error,
}: Props) => {
  const [open, setOpen] = useState(false);

  const toggleSelect = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value: Currency) => {
    onChange(value);
    handleClose();
  };

  const currencyArray = Object.keys(Currency) as Currency[];

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <SelectContainer>
        <CurrencySelectButton onClick={toggleSelect}>
          <CurrencyIcon
            color={error ? theme.palette.common.errorBg : color}
            error={error}
          >
            {getCurrencyIcon(selected)}
          </CurrencyIcon>
          {open ? (
            <ArrowDropUp color={theme.palette.common.grey500} />
          ) : (
            <ArrowDropDown color={theme.palette.common.grey500} />
          )}
        </CurrencySelectButton>
        <DropdownStyle visible={open ? 1 : 0}>
          <Box px={1.5} bgcolor={theme.palette.common.white}>
            {currencyArray.map((item) => (
              <DropdownItem
                key={item}
                onClick={() => handleChange(item)}
                active={item === selected ? 1 : 0}
              >
                {getCurrencyTitle(item)}
              </DropdownItem>
            ))}
          </Box>
        </DropdownStyle>
      </SelectContainer>
    </ClickAwayListener>
  );
};
