import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { PathNames } from "application/routes";
import DealsActions from "features/deals/store/actions";
import DealActions from "features/deal-profile/store/actions";
import { dealsSelector } from "features/deals/store/selectors";
import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { requestSelector } from "application/store/request/selectors";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { useDebounce } from "application/hooks/useDebounce";
import { getNavigationState } from "application/helpers/navigationState";
import { SearchParamsRoutesNames } from "application/routes/constants";
import { PaginationMUI } from "application/components";

import Empty from "application/containers/Empty";
import DealsTable from "features/deals/containers/DealsTable";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import DealsHeader from "features/deals/components/DealsHeader";
import SuccessPopUp from "application/containers/SuccessPopUp";

const Deals = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { getDeals, deleteDeal, getDealById } = useAppSelector(requestSelector);
  const { data, sort, totalPages } = useAppSelector(dealsSelector).deals;
  const loading = deleteDeal.loading || getDeals.loading || getDealById.loading;
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const [showDelete, setShowDelete] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const search = useDebounce(searchValue, 300);

  const onPageChange = (_: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
    dispatch(
      DealsActions.getDeals({
        page,
        ...(!!searchValue ? { search: searchValue } : {}),
      })
    );
    setSearchParams({
      page: String(page),
      ...(searchValue ? { search: searchValue } : {}),
    });
  };

  const onDelete = (id: number) => {
    setIdToDelete(id);
    setShowDelete(true);
  };

  const onDeleteSuccess = () => {
    const isPageEmpty = page > 1 && data.length === 1;
    const currentPage = isPageEmpty ? page - 1 : page;
    if (isPageEmpty) {
      setPage((prev) => prev - 1);
    }
    dispatch(
      DealsActions.getDeals({
        page: currentPage,
        search,
      })
    );
    setIdToDelete(null);
    setShowDelete(false);
    setShowSuccessModal(true);
  };

  const onSubmitDelete = async () => {
    await dispatch(DealsActions.deleteDeal(idToDelete!)).then((res) => {
      isResponseFulfilled(res, onDeleteSuccess);
    });
  };

  const onSubmitSuccess = () => {
    setShowSuccessModal(false);
  };

  const onCloseDelete = () => {
    setIdToDelete(null);
    setShowDelete(false);
  };

  const onEditSuccess = (id: number) => () => {
    navigate(
      `${PathNames.deals}/${id}`,
      getNavigationState(SearchParamsRoutesNames.deal)
    );
  };

  const onEdit = (id: number) => {
    dispatch(DealActions.getDealById(id)).then((res) => {
      isResponseFulfilled(res, onEditSuccess(id));
    });
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setPage(1);
    setSearchParams({
      page: "1",
      ...(value ? { search: String(value) } : {}),
    });
  };

  useEffect(() => {
    dispatch(DealsActions.getDeals({ page, ...(search ? { search } : {}) }));
  }, [sort, search]);

  return (
    <React.Fragment>
      <DealsHeader searchValue={searchValue} setSearchValue={handleSearch} />

      {!!data.length || loading ? (
        <React.Fragment>
          <DealsTable
            onEdit={onEdit}
            onDelete={onDelete}
            data={data}
            loading={loading}
          />

          <PaginationMUI
            page={page}
            variant="text"
            color="primary"
            count={totalPages}
            onChange={onPageChange}
          />
        </React.Fragment>
      ) : (
        <Empty description="You haven’t deals yet!" />
      )}

      {showDelete && (
        <ConfirmPopUp
          title="Delete Deal"
          onClose={onCloseDelete}
          onSubmit={onSubmitDelete}
          description="You want to delete deal. Are you sure?"
        />
      )}
      {showSuccessModal && (
        <SuccessPopUp title="The deal is deleted" onSubmit={onSubmitSuccess} />
      )}
    </React.Fragment>
  );
};

export default Deals;
