import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

export const EmptyWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  svg {
    width: 267px;
    height: 148px;
    margin-bottom: 20px;
  }
  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 140%;
    font-style: normal;
    text-align: center;
    font-family: "Unica77";
    color: ${colors.grey900};
  }
`;
