import React, { FC } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

import { Button } from "application/components";

import { Container, BackButton } from "./styles";
import { UserDetailsHeaderProps } from "./models";

const UserDetailsHeader: FC<UserDetailsHeaderProps> = ({ onDelete }) => {
  const navigate = useNavigate();

  const hideBackButton = window?.history?.length <= 2;

  const goBack = () => {
    const path = (window?.history?.state?.usr?.userDetails || -1) as any;
    navigate(path, { state: window?.history?.state?.usr });
  };

  return (
    <Container>
      {!hideBackButton && (
        <BackButton onClick={goBack}>
          <ArrowBackIcon />
          Back to users
        </BackButton>
      )}
      <div>
        <Button label="Delete user" red onClick={onDelete} />
      </div>
    </Container>
  );
};

export default UserDetailsHeader;
