import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const CurrencySelectButton = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 44px;
`;

export const SelectContainer = styled(Box)`
  position: relative;
  width: ${theme.spacing(18.5)};
  margin: ${theme.spacing(0)};
`;

export const IconWrapper = styled(Box)<{
  borderColor: string;
  bgcolor: string;
}>`
  border: ${(props) => `1px solid ${props.borderColor}`};
  height: 100%;
  border-radius: ${theme.spacing(0, 1, 1, 0)};
  border-left: none;
  background-color: ${(props) => props.bgcolor};
  display: flex;
  align-items: center;
`;

export const TextWrapper = styled(Box)<{
  borderColor: string;
  bgcolor: string;
}>`
  height: 100%;
  width: ${theme.spacing(10)};
  padding: ${theme.spacing(0, 2.5)};
  border: ${(props) => `1px solid ${props.borderColor}`};
  border-radius: ${theme.spacing(1, 0, 0, 1)};
  background-color: ${(props) => props.bgcolor};
  display: flex;
  align-items: center;
`;

export const DropdownStyle = styled(Box)<{ visible: number }>`
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  overflow-y: scroll;
  border-radius: ${theme.spacing(0.5)};
  box-shadow: 0px 4px 30px 0px rgba(222, 224, 230, 0.8);
  padding: ${(props) => (props.visible ? theme.spacing(1.5, 0) : "0px")};
  max-height: ${(props) => (props.visible ? theme.spacing(40) : "0px")};
  background-color: ${theme.palette.common.white};
`;

export const DropdownItem = styled(Box)<{ active: number }>`
  font-family: Unica77;
  height: ${theme.spacing(4.75)};
  display: flex;
  align-items: center;
  font-size: ${theme.spacing(2)};
  font-weight: ${(props) => (props.active ? 700 : 400)};
  color: ${theme.palette.common.grey900};

  padding: ${theme.spacing(0, 1.5)};
  border-radius: ${theme.spacing(0.5)};
  margin-bottom: ${theme.spacing(0.5)};
  cursor: pointer;
  background-color: ${(props) =>
    !!props.active ? theme.palette.common.grey100 : "white"};
  &:hover,
  :focus,
  :focus:hover {
    background-color: ${(props) =>
      !!props.active
        ? theme.palette.common.blue50
        : theme.palette.common.grey100};
    outline: none;
  }
`;
