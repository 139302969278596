import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import {
  AdminAnalyticResponse,
  CompanyData,
  CompanyStatus,
  TeamDto,
} from "integration/api/CompanyApi/models";

import { CompanyErrorKey } from "../helpers/formatCompanyError";

import CompanyListActions from "./actions";
import { CompanyListState } from "./model";

const defaultState = {
  totalElements: 0,
  totalPages: 0,
  size: 0,
  content: [],
  number: 0,
  sort: {
    empty: true,
    sorted: true,
    unsorted: true,
  },
  numberOfElements: 0,
  pageable: {
    offset: 0,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    pageNumber: 0,
    pageSize: 0,
    paged: true,
    unpaged: true,
  },
  first: true,
  last: true,
  empty: true,
};

const defaultCompanyState = {
  businessId: "",
  logoFileId: null,
  name: "",
  description: "",
  shortDescription: "",
  website: "",
  status: CompanyStatus.DRAFT,
  foundedYear: "",
  creationDate: "",
  team: [],
};

const initialState: CompanyListState = {
  companies: defaultState,
  deals: defaultState,
  portfolio: defaultState,
  investors: defaultState,
  company: defaultCompanyState,
  initialCompany: defaultCompanyState,
  companyName: "",
  hasErrors: false,
  errors: [],
  analytics: [],
  companySuggestions: [],
  totalInvestments: 0,
  totalFmvValue: 0,
  totalRealizedProfit: 0,
  multiple: 0,
};

export const companyListSlice = createSlice({
  name: "companyList",
  initialState,
  reducers: {
    setCompanyStatus: (state, action: PayloadAction<CompanyStatus>) => {
      state.company.status = action.payload;
    },
    setCompanyData: (state, action: PayloadAction<CompanyData>) => {
      state.company[action.payload.key] = action.payload.value;
      state.errors = state.errors.filter((item) => item !== action.payload.key);
    },
    setTeam: (state, action: PayloadAction<TeamDto[]>) => {
      state.company.team = action.payload;
      state.errors = state.errors.filter(
        (item) => item !== CompanyErrorKey.team
      );
    },
    resetCompany: (state) => {
      state.company = defaultCompanyState;
      state.hasErrors = false;
      state.errors = [];
    },
    setHasErrors: (state, action: PayloadAction<boolean>) => {
      state.hasErrors = action.payload;
    },
    setErrors: (state, action: PayloadAction<string[]>) => {
      state.errors = action.payload;
    },
    setAnalytics: (state, action: PayloadAction<AdminAnalyticResponse[]>) => {
      state.analytics = action.payload;
    },
    resetCompanies: (state) => {
      state.companies.content = [];
      state.companies.totalPages = 0;
    },
  },
  extraReducers: (builder) => {
    // get company list
    builder.addCase(
      CompanyListActions.getCompanyList.fulfilled,
      (state, action) => {
        state.companies = action.payload;
      }
    );
    // get company by id
    builder.addCase(
      CompanyListActions.getCompanyById.fulfilled,
      (state, action) => {
        state.company = { ...state.company, ...action.payload };
        state.initialCompany = { ...state.company, ...action.payload };
      }
    );
    //  update company
    builder.addCase(
      CompanyListActions.updateCompany.rejected,
      (state, action) => {
        const response = action.payload as AxiosError<{
          errorCode: string;
          errorMessage: string;
        }>;

        state.errors =
          response?.response?.data.errorMessage.replace(/\s/g, "").split(",") ||
          [];
        state.hasErrors = !!response?.response?.data.errorMessage;
      }
    );
    // get company deals list
    builder.addCase(
      CompanyListActions.getCompanyDealsList.fulfilled,
      (state, action) => {
        state.deals = action.payload.dealsPage;
        state.companyName = action.payload.companyName;
      }
    );
    // get company portfolio list
    builder.addCase(
      CompanyListActions.getCompanyPortfolioList.fulfilled,
      (state, action) => {
        state.portfolio = action.payload.dealsPage;
        state.companyName = action.payload.companyName;
      }
    );
    // get company investors list
    builder.addCase(
      CompanyListActions.getCompanyInvestorsList.fulfilled,
      (state, action) => {
        state.investors = action.payload;
      }
    );
    // update company logo
    builder.addCase(
      CompanyListActions.uploadCompanyLogo.fulfilled,
      (state, action) => {
        state.company.logoFileId = action.payload.fileId;
        state.initialCompany.logoFileId = action.payload.fileId;
      }
    );
    // get analytics list
    builder.addCase(
      CompanyListActions.getCompanyAnalyticsList.fulfilled,
      (state, action) => {
        state.companyName = action.payload.companyName;
        state.analytics = action.payload.adminAnalyticResponses;
      }
    );
    // get company suggestions
    builder.addCase(
      CompanyListActions.getCompanySuggestions.fulfilled,
      (state, action) => {
        state.companySuggestions = action.payload;
      }
    );
    // get company totals
    builder.addCase(
      CompanyListActions.getCompanyTotals.fulfilled,
      (state, action) => {
        state.totalInvestments = action.payload.totalInvestments;
        state.totalFmvValue = action.payload.totalFmvValue;
        state.totalRealizedProfit = action.payload.totalRealizedProfit;
        state.multiple = action.payload.multiple;
      }
    );
  },
});

export const CompanySliceAction = companyListSlice.actions;
export default companyListSlice.reducer;
