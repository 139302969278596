import "reset-css";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./application/App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

reportWebVitals();
