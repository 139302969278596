import styled from "@emotion/styled";

import { colors } from "application/theme/palette";
import { ReactComponent as LogoSvg } from "application/assets/logo.svg";
import { ReactComponent as LogoutSvg } from "features/side-menu/assets/logout.svg";
import { ReactComponent as LogoSmallSvg } from "application/assets/logo-small.svg";
import { ReactComponent as ArrowInSvg } from "features/side-menu/assets/arrow-in.svg";
import { ReactComponent as ArrowOutSvg } from "features/side-menu/assets/arrow-out.svg";

interface StyledProps {
  open?: boolean;
}

export const Wrapper = styled.div<StyledProps>`
  height: 100vh;
  display: flex;
  flex-direction: column;
  transition: width 0.3s ease-in-out;
  width: ${({ open }) => (open ? 280 : 76)}px;
  background: linear-gradient(184deg, #111928 0%, #0c2b5c 100%);
`;

export const Header = styled.div<StyledProps>`
  display: flex;
  padding: 30px 24px;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ open }) => (open ? "row" : "column")};
`;

export const LogoImage = styled(LogoSvg)`
  width: 176px;
  height: 40px;
`;
export const LogoSmallImage = styled(LogoSmallSvg)`
  width: 55px;
  height: 40px;
  margin-bottom: 42px;
`;

export const ArrowContainer = styled.div`
  cursor: pointer;
`;
export const ArrowIn = styled(ArrowInSvg)`
  width: 24px;
  height: 24px;
  color: ${colors.grey300};
`;
export const ArrowOut = styled(ArrowOutSvg)`
  width: 24px;
  height: 24px;
  color: ${colors.grey300};
`;

export const Footer = styled.div<StyledProps>`
  height: 100px;
  display: flex;
  padding: 0 24px;
  margin-top: auto;
  align-items: center;
  border-top: 1px solid ${colors.grey700};
  justify-content: ${({ open }) => (open ? "flex-end" : "center")};
  p {
    left: 24px;
    position: ${({ open }) => (open ? "static" : "absolute")};
    /* font */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    font-style: normal;
    font-family: Unica77;
    letter-spacing: 0.08px;
    color: ${colors.grey200};
  }
`;
export const LogoutIcon = styled(LogoutSvg)`
  min-width: 24px;
  min-height: 24px;
`;

export const LogoutButton = styled("button")`
  all: unset;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
