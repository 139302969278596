import { SearchParamsRoutesNames } from "application/routes/constants";

export const getNavigationState = (key: SearchParamsRoutesNames) => {
  return {
    state: {
      ...(window?.history?.state?.usr || {}),
      [key]: window.location.pathname + window.location.search,
    },
  };
};
