import React, { FC, useState } from "react";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { Button } from "application/components";
import PrimaryButton from "application/components/PrimaryButton";

import AddFilePopUp from "../AddFilePopUp";

import { ManagementAnalyticsHeaderProps } from "./models";
import {
  Wrapper,
  BackButton,
  Container,
  ButtonSection,
  AddIcon,
} from "./styles";

const ManagementAnalyticsHeader: FC<ManagementAnalyticsHeaderProps> = ({
  companyName,
  updateAnalyticsList,
  uploadAnalytic,
  disableSaveButton,
}) => {
  const navigate = useNavigate();

  const [showAddModal, setShowAddModal] = useState(false);

  const hideBackButton = window?.history?.length <= 2;

  const goBack = () => {
    const path = (window?.history?.state?.usr?.companyManagementAnalytics ||
      -1) as string;
    navigate(path, {
      state: window?.history?.state?.usr,
    });
  };

  const handleOpenAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  const handleSave = () => {
    updateAnalyticsList();
  };

  return (
    <Wrapper>
      {!hideBackButton && (
        <BackButton type="button" onClick={goBack}>
          <ArrowBack />
          Back to company overview
        </BackButton>
      )}
      <Container>
        <h1>{companyName}</h1>
        <ButtonSection>
          <Button
            variant="outlined"
            label="Save changes"
            onClick={handleSave}
            disabled={disableSaveButton}
          />
          <PrimaryButton
            type="button"
            loading={false}
            onClick={handleOpenAddModal}
            title="Add new"
            leftIcon={<AddIcon />}
          />
        </ButtonSection>
      </Container>
      {showAddModal && (
        <AddFilePopUp onClose={handleCloseAddModal} onSubmit={uploadAnalytic} />
      )}
    </Wrapper>
  );
};

export default ManagementAnalyticsHeader;
