import React, { FC } from "react";

import { Typography } from "@mui/material";

import PrimaryButton from "application/components/PrimaryButton";

import CompanyApi from "integration/api/CompanyApi";

import { HeaderProps } from "./model";
import { AddIcon, HeaderStyled, Container, ExportButton } from "./styles";
import { downloadDocument } from "application/helpers/downloadDocument";

const Header: FC<HeaderProps> = ({ currency, onAddCompany }) => {
  const exportCSV = async () => {
    try {
      const response = await CompanyApi.exportInvestments(currency);
      downloadDocument({ response, fileName: "iclub-investments" });
    } catch (e) {
      console.log("Download document error: ", e);
    }
  };

  const exportCurrencies = async () => {
    try {
      const response = await CompanyApi.exportCurrencies();
      downloadDocument({ response, fileName: "exchange_rates" });
    } catch (e) {
      console.log("Download document error: ", e);
    }
  };

  return (
    <HeaderStyled>
      <Typography variant="h1">Companies</Typography>
      <Container>
        <ExportButton type="button" onClick={exportCurrencies}>
          Export Currencies
        </ExportButton>
        <ExportButton type="button" onClick={exportCSV}>
          Export as CSV
        </ExportButton>
        <PrimaryButton
          type="button"
          loading={false}
          onClick={onAddCompany}
          title="Add new company"
          leftIcon={<AddIcon />}
        />
      </Container>
    </HeaderStyled>
  );
};

export default Header;
