import styled from "@emotion/styled";
import theme from "application/theme";
import { colors } from "application/theme/palette";

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 60px;
  margin-top: 32px;
  border-radius: 8px;
  background: ${colors.white};
  box-shadow: 0px 0px 30px 0px rgba(222, 224, 230, 0.06);
`;
