import { colors } from "application/theme/palette";

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export const userStatuses = [
  { value: "", label: "All" },
  { value: UserStatus.ACTIVE, label: "Active" },
  { value: UserStatus.INACTIVE, label: "Deleted" },
];

export const getUserStatusColor = (status?: UserStatus) => {
  switch (status) {
    case UserStatus.ACTIVE:
      return colors.green700;
    case UserStatus.INACTIVE:
      return colors.red700;
    default:
      return UserStatus.INACTIVE;
  }
};
