import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { PaginationMUI } from "application/components";

import { PortfolioTableRow } from "features/companies/components";
import CompanyListActions from "features/companies/store/actions";

import { PortfolioTableProps } from "./models";
import { TableStyled, Wrapper } from "./styles";

const PortfolioTable: FC<PortfolioTableProps> = ({
  search,
  searchParams,
  setSearchParams,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { totalPages, content } = useAppSelector(
    (state) => state.companyList
  ).portfolio;

  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );

  useEffect(() => {
    if (id) {
      dispatch(
        CompanyListActions.getCompanyPortfolioList({
          id: Number(id),
          page: page - 1,
          search,
        })
      );
    }
  }, [id, page, search]);

  const getSearchParams = () => {
    const params = {} as { [key: string]: string };
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  };

  const onPageChange = async (_: React.ChangeEvent<unknown>, page: number) => {
    const params = getSearchParams();
    setPage(page);
    setSearchParams({
      ...params,
      page: String(page),
    });
  };

  return (
    <>
      <Wrapper>
        <TableStyled>
          <thead>
            <tr>
              <th>Logo</th>
              <th>Title</th>
              <th>ID</th>
              <th>Start date</th>
              <th>ICLUB allocation</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {content.map((item) => (
              <PortfolioTableRow key={item.id} item={item} />
            ))}
          </tbody>
        </TableStyled>
      </Wrapper>
      <PaginationMUI
        page={page}
        variant="text"
        color="primary"
        count={totalPages}
        onChange={onPageChange}
      />
    </>
  );
};

export default PortfolioTable;
