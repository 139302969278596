import {
  DealPageState,
  TractionMultiTableKey,
  TractionSingleTableKey,
} from "../store/models";
import {
  MetricType,
  DealTractionType,
  DealTractionMultiType,
} from "integration/api/DealsApi/models";
import { getMonthByIndex } from "application/helpers/date";
import { TractionTableItem } from "../components/TractionTable/models";

export const groupTractionMultiType = (
  arr: DealTractionMultiType[],
  metric: MetricType,
  keyProperty: keyof DealTractionMultiType
) => {
  const sortedArray = arr.filter((item) => item.metricType === metric);

  return sortedArray.reduce((output, item) => {
    const key = String(item[keyProperty]);
    output[key] ||= [];
    if (item.metricType === metric) {
      output[key].push({
        id: item.id,
        index: item.amount,
        currency: item.currency,
        titleOriginal: item.tractionMonth || null,
        title: item.tractionMonth ? getMonthByIndex(item.tractionMonth) : null,
      });
    }
    return output;
  }, {} as Record<string | number, TractionTableItem[]>);
};

export const formatTractionData = (
  data: DealPageState["profile"]
): DealPageState["profile"] => {
  const object = {} as DealPageState["profile"];

  // # Single Table # //
  const singleTableKeys = Object.keys(TractionSingleTableKey);
  for (let i = 0; i < singleTableKeys.length; i++) {
    const key = singleTableKeys[i] as TractionSingleTableKey;
    // 1. Filter new rows
    const array = data[key].reduce<DealTractionType[]>((prev, curr) => {
      // new row
      if (typeof curr.id === "string") {
        return prev.concat({
          amount: curr.amount,
          currency: curr.currency || null,
          metricType: curr.metricType,
          tractionYear: curr.tractionYear,
        });
      }
      // old row
      if (typeof curr.id === "number") {
        return prev.concat(curr);
      }
      return prev;
    }, []);
    // 3. Add formatted data to object
    object[key] = array;
  }

  // # Multi Table # //
  const multiTableKeys = Object.keys(TractionMultiTableKey);
  for (let i = 0; i < multiTableKeys.length; i++) {
    const key = multiTableKeys[i] as TractionMultiTableKey;
    // 1. Filter new rows
    const array = data[key].reduce<DealTractionMultiType[]>((prev, curr) => {
      // new row
      if (typeof curr.id === "string") {
        return prev.concat({
          amount: curr.amount,
          currency: curr.currency || null,
          metricType: curr.metricType,
          tractionYear: curr.tractionYear,
          tractionMonth: curr.tractionMonth,
        });
      }
      // old row
      if (typeof curr.id === "number") {
        return prev.concat(curr);
      }
      return prev;
    }, []);
    // 2. Add formatted data to object
    object[key as TractionMultiTableKey] = array;
  }

  const newData = { ...data, ...object };

  const formattedData = {
    ...newData,
    minInvestmentAmount: !newData.minInvestmentAmount
      ? null
      : newData.minInvestmentAmount,
    totalFundingGoal: {
      ...(newData.totalFundingGoal || {}),
      amount: !newData.totalFundingGoal?.amount
        ? null
        : newData.totalFundingGoal?.amount,
    },
    overview: {
      ...(newData.overview || {}),
      team: (newData.overview.team || []).map((item) => {
        const isTemporaryId = typeof item.id === "string";
        const { id, ...restData } = item;
        return {
          ...restData,
          ...(isTemporaryId ? { id: "" } : { id }),
          base64Image: item.base64Image || null,
        };
      }),
    },
    details: {
      ...(newData.details || {}),
      availableAllocation: {
        ...(newData.details?.availableAllocation || {}),
        amount: !newData.details?.availableAllocation?.amount
          ? null
          : newData.details?.availableAllocation?.amount,
      },
      committedAllocationFromTaVentures: {
        ...(newData.details?.committedAllocationFromTaVentures || {}),
        amount: !newData.details?.committedAllocationFromTaVentures?.amount
          ? null
          : newData.details?.committedAllocationFromTaVentures?.amount,
      },
      valuation: {
        ...(newData.details?.valuation || {}),
        amount: !newData.details?.valuation?.amount
          ? null
          : newData.details?.valuation?.amount,
      },
      totalRoundSize: {
        ...(newData.details?.totalRoundSize || {}),
        amount: !newData.details?.totalRoundSize?.amount
          ? null
          : newData.details?.totalRoundSize?.amount,
      },
    },
    ltv: {
      amount: newData.ltv?.amount || null,
      currency: newData.ltv?.currency,
    },
    cac: {
      amount: newData.cac?.amount || null,
      currency: newData.cac?.currency,
    },
    aov: {
      amount: newData.aov?.amount || null,
      currency: newData.aov?.currency,
    },
    nps: newData.nps || null,
  };

  return formattedData;
};
