import { styled } from "@mui/system";
import { Link } from "react-router-dom";

import theme from "application/theme";

export const HeaderStyled = styled("header")`
  display: grid;
  grid-template-columns: auto;
  gap: 16px;
  width: 100%;
  margin-bottom: ${theme.spacing(4)};

  h1 {
    color: ${theme.palette.common.grey900};
    font-size: ${theme.spacing(4)};
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export const BackButtonStyled = styled("button")`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  text-decoration: none;
  color: ${theme.palette.common.blue700};
  text-align: center;
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;

  svg {
    margin-right: 8px;
    path {
      fill: ${theme.palette.common.blue700};
    }
  }
`;

export const ButtonSection = styled("div")`
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 20px;

  button {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12px;
      width: 24px;
      height: 24px;

      path {
        fill: ${theme.palette.common.white};
      }
    }
  }
`;
