import React from "react";
import { Card, Title, Wrapper, Actions } from "./styles";
import { Button, LoadingButton } from "application/components";
import { Box } from "@mui/system";

interface Props {
  title: string;
  onClose: () => void;
  onSubmit: () => void;
  children: JSX.Element;
  disabled?: boolean;
  isLoading?: boolean;
}

const BasicPopUp = ({
  title,
  onClose,
  onSubmit,
  children,
  disabled,
  isLoading,
}: Props) => {
  const handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Wrapper>
      <Card>
        <Title>{title}</Title>
        {children}

        <Actions>
          <Box mr={1.5}>
            <Button label="Cancel" onClick={onClose} disabled={isLoading} />
          </Box>
          <LoadingButton
            onClick={handleSubmit}
            type="submit"
            label="OK"
            disabled={disabled}
            isLoading={isLoading}
          />
        </Actions>
      </Card>
    </Wrapper>
  );
};

export default BasicPopUp;
