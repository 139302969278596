import { styled } from "@mui/system";
import Add from "@mui/icons-material/Add";

import theme, { commonColors } from "application/theme";
import { colors } from "application/theme/palette";

export const HeaderStyled = styled("header")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${theme.spacing(4)};

  h1 {
    color: ${theme.palette.common.grey900};
    font-size: ${theme.spacing(4)};
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

export const AddIcon = styled(Add)`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  color: ${colors.white};
`;

export const Container = styled("div")`
  display: flex;
  align-items: center;
`;

export const ExportButton = styled("button")`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  height: 44px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;

  /* font styles */
  color: ${commonColors.blue700};
  font-family: Unica77;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
`;
