import { styled } from "@mui/system";

import { colors } from "application/theme/palette";

export const Container = styled("div")`
  display: grid;
  grid-template-rows: max-content;
  gap: 20px;
  width: 415px;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid ${colors.grey200};
  background: ${colors.white};
`;

export const ButtonSection = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
`;

export const InvestmentAmount = styled("div")`
  display: flex;
  height: 44px;
  align-items: center;
  padding: 0px 16px;
  border-radius: 8px;
  border: 1px solid ${colors.grey700};
  background: ${colors.grey50};
  cursor: pointer;
`;

export const LostButton = styled("button")`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;
  height: 44px;
  padding: 0 20px;
  margin-left: 12px;
  border-radius: 35px;
  background: ${colors.red700};
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  font-style: normal;
  text-align: center;
  letter-spacing: 0.08px;
  font-family: Unica77;
  color: ${colors.white};
`;
