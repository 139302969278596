import { Box, styled } from "@mui/system";
import { colors } from "application/theme/palette";
interface StyledProps {
  error?: boolean;
}

export const InputWrapper = styled("div")<StyledProps>`
  display: flex;
  flex-direction: column;

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: ${({ error }) => (error ? colors.red700 : "")};
      background-color: ${({ error }) => (error ? colors.errorBg : "")};
    }
  }
`;
