import { Box, styled } from "@mui/system";
import theme from "application/theme";

interface StyledProps {
  color?: string;
  error?: boolean;
}

export const CurrencySelectButton = styled(Box)`
  display: flex;
  align-items: center;
  height: ${theme.spacing(5.5)};
  width: ${theme.spacing(9)};
  cursor: pointer;
`;

export const CurrencyIcon = styled("div")<StyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: ${theme.spacing(5.5)};
  width: ${theme.spacing(5.5)};
  padding: ${theme.spacing(1.25)};
  border: 1px solid
    ${({ error }) =>
      error ? theme.palette.common.red700 : theme.palette.common.grey300};
  border-radius: ${theme.spacing(1)};
  background-color: ${({ color }) =>
    color ? color : theme.palette.common.lemon};
  margin-right: ${theme.spacing(0.5)};
`;

export const SelectContainer = styled(Box)`
  position: relative;
  margin: ${theme.spacing(0)};
`;

export const DropdownStyle = styled(Box)<{ visible: number }>`
  position: absolute;
  z-index: 10;
  top: ${theme.spacing(6)};
  left: 0;
  width: fit-content;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  overflow-y: scroll;
  border-radius: ${theme.spacing(0.5)};
  box-shadow: 0px 4px 30px 0px rgba(222, 224, 230, 0.8);
  padding: ${(props) => (props.visible ? theme.spacing(1.5, 0) : "0px")};
  max-height: ${(props) => (props.visible ? theme.spacing(40) : "0px")};
  background-color: ${theme.palette.common.white};
`;

export const DropdownItem = styled(Box)<{ active: number }>`
  font-family: Unica77;
  display: flex;
  align-items: center;
  font-size: ${theme.spacing(2)};
  font-weight: ${(props) => (props.active ? 700 : 400)};
  color: ${theme.palette.common.grey900};
  height: ${theme.spacing(4.75)};
  padding: ${theme.spacing(0, 1.5)};
  border-radius: ${theme.spacing(0.5)};
  margin-bottom: ${theme.spacing(0.5)};
  cursor: pointer;
  background-color: ${(props) =>
    !!props.active ? theme.palette.common.grey100 : "white"};
  &:hover,
  :focus,
  :focus:hover {
    background-color: ${(props) =>
      !!props.active
        ? theme.palette.common.blue50
        : theme.palette.common.grey100};
    outline: none;
  }
`;
