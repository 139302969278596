import React from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  List,
  Card,
  Title,
  Wrapper,
  Actions,
  SubmitButton,
  DescriptionContainer,
} from "./styles";

interface Props {
  errors: string[];
  onPress: () => void;
}

const ErrorValidationPopUp = ({ errors, onPress }: Props) => {
  return (
    <Wrapper>
      <Card>
        <Title>Continue to fill information</Title>
        <DescriptionContainer>
          <InfoOutlinedIcon />
          <p>
            You are about to close this item within needed information. Please,
            continue to fill information about:
          </p>
        </DescriptionContainer>

        <List>
          {errors.map((error) => (
            <li key={error}>{error}</li>
          ))}
        </List>

        <Actions>
          <SubmitButton onClick={onPress}>
            <p>Ok</p>
          </SubmitButton>
        </Actions>
      </Card>
    </Wrapper>
  );
};

export default ErrorValidationPopUp;
