import React, { FC } from "react";
import { Container } from "./styles";
import PrimaryButton from "application/components/PrimaryButton";
import { Link } from "react-router-dom";

const ErrorPage: FC = () => {
  return (
    <Container>
      <h1>404</h1>
      <h2>We can’t seem to find a page you are looking for.</h2>
      <h3>
        You might typed in the wrong address or the page has moved. In the
        meantime, try again or return to the homepage.
      </h3>
      <Link to={"/"}>
        <PrimaryButton title="Go to homepage" />
      </Link>
    </Container>
  );
};

export default ErrorPage;
