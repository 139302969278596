import axiosInstance from "../axiosInstance";
import { CountryDto } from "./models";

const CountryApi = {
  getCountries: () => {
    return axiosInstance.get<CountryDto[]>("/api/admin/countries", {});
  },
};

export default CountryApi;
