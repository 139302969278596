import React, { FC, useState } from "react";
import { SaveAlt } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import BasicPopUp from "application/containers/BasicPopUp";
import { colors } from "application/theme/palette";

import { AddFilePopUpProps } from "./model";
import { FileInput } from "./styles";

const AddFilePopUp: FC<AddFilePopUpProps> = ({ onClose, onSubmit }) => {
  const [newAdditionalMaterial, setNewAdditionalMaterial] =
    useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      const file = e?.target?.files[0];
      setNewAdditionalMaterial(file);
    }
  };

  const handleSubmit = async () => {
    if (!newAdditionalMaterial) return;
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", newAdditionalMaterial);
    await onSubmit(formData).then(() => {
      onClose();
    });
    setIsLoading(false);
  };

  return (
    <BasicPopUp
      title="Add file"
      onClose={onClose}
      onSubmit={handleSubmit}
      disabled={isLoading}
      isLoading={isLoading}
    >
      <Box>
        <input
          color="primary"
          accept="application/pdf, image/png, image/jpeg"
          type="file"
          onChange={(e) => handleFileInput(e)}
          id="icon-button-file"
          style={{ display: "none" }}
        />
        <label htmlFor="icon-button-file">
          <FileInput>
            <Box mr={1}>
              <SaveAlt />
            </Box>
            <Typography variant="body2" color={colors.grey900}>
              {newAdditionalMaterial?.name
                ? newAdditionalMaterial.name
                : "Upload the file"}
            </Typography>
          </FileInput>
        </label>
      </Box>
    </BasicPopUp>
  );
};

export default AddFilePopUp;
