import React, { FC, useEffect, useState } from "react";
import { CallMade, VisibilityOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { LogoType } from "integration/api/DealsApi/models";

import { ReactComponent as MoneysSvg } from "application/assets/icons/moneys.svg";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { SearchParamsRoutesNames } from "application/routes/constants";
import { getNavigationState } from "application/helpers/navigationState";
import { formatTotal } from "application/helpers/formatTotal";

import DealsActions from "features/deals/store/actions";
import { formatDealTableDate } from "features/deals/helpers/date";

import CopyButton from "../CopyButton";

import { TableProps } from "./model";
import { Actions, ImageContainer, MultiRowStyled, Total } from "./styles";

const TableRow: FC<TableProps> = ({
  portfolio,
  setSelectedBusinessId,
  currency,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [logo, setLogo] = useState("");

  const date = formatDealTableDate(portfolio.creationDate);

  const dateFVM = portfolio.investmentAnalytic?.fmvChangeDate
    ? formatDealTableDate(portfolio.investmentAnalytic?.fmvChangeDate)
    : "";
  const totalValue = formatTotal(
    portfolio.investmentAnalytic?.totalFmvValue || 0,
    currency
  );
  const totalProfit = formatTotal(
    portfolio.investmentAnalytic?.totalRealizedProfit || 0,
    currency
  );

  const totalInvested = formatTotal(
    portfolio.investmentAmount || 0,
    portfolio.investmentCurrency
  );

  useEffect(() => {
    if (portfolio.logoFileId) {
      dispatch(
        DealsActions.getDealLogo({
          id: portfolio.id,
          logoId: portfolio.logoFileId,
          logoType: LogoType.THUMBNAIL,
        })
      ).then((response) => {
        const data = response.payload as Blob;
        const logoBlob = new Blob([data], {
          type: data.type as string,
        });
        const logoUrl = URL.createObjectURL(logoBlob);
        setLogo(logoUrl);
      });
    }
  }, [portfolio.logoFileId]);

  const goToInvestors = () => {
    navigate(
      `${portfolio.id}/investors/`,
      getNavigationState(SearchParamsRoutesNames.portfolioInvestors)
    );
  };

  return (
    <tr>
      <td>
        {logo && (
          <ImageContainer>
            <img src={logo} alt="portfolio-logo" />
          </ImageContainer>
        )}
      </td>
      <td>
        <Actions>
          <button onClick={() => setSelectedBusinessId(portfolio.businessId)}>
            <VisibilityOutlined />
          </button>
          <CopyButton copyText={portfolio.businessId} />
        </Actions>
      </td>
      <td>{portfolio.title}</td>
      <td>{date}</td>
      <td>
        <Total>
          <MoneysSvg />
          <span>{totalInvested}</span>
        </Total>
      </td>
      <td>
        <MultiRowStyled>
          <div>
            <h2>Current value (FMV)</h2>
            <p>{totalValue}</p>
          </div>
          <div>
            <h2>Total realized profit</h2>
            <p>{totalProfit}</p>
          </div>
          <div>
            <h2>Date of last FMV change</h2>
            <p>{dateFVM}</p>
          </div>
        </MultiRowStyled>
      </td>
      <td>{portfolio.investmentAnalytic?.multiple || 0}</td>
      <td>
        <Actions>
          <button onClick={goToInvestors}>
            <CallMade />
          </button>
        </Actions>
      </td>
    </tr>
  );
};

export default TableRow;
