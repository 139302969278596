import React, { FC } from "react";

import { ReactComponent as SuccessIcon } from "application/assets/icons/success.svg";
import { colors } from "application/theme/palette";

import { SuccessPopUpProps } from "./models";
import { Card, Title, Wrapper, SubmitButton } from "./styles";

const SuccessPopUp: FC<SuccessPopUpProps> = ({
  title,
  onSubmit,
  iconColor,
}) => {
  return (
    <Wrapper>
      <Card>
        <SuccessIcon color={iconColor || colors.green700} />
        <Title>{title}</Title>
        <SubmitButton onClick={onSubmit}>
          <p>Ok</p>
        </SubmitButton>
      </Card>
    </Wrapper>
  );
};

export default SuccessPopUp;
