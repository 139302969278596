import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  box-sizing: border-box;

  > p {
    position: absolute;
    left: 26px;
    padding: 4px 8px;
    color: ${colors.green700};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.06px;
    border-radius: 4px;
    background: ${colors.green200};
  }
`;

export const CopyButtonStyled = styled("button")`
  position: relative;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${colors.grey200};
  background: ${colors.white};

  &:hover {
    .id-tooltip {
      display: flex;
    }
  }

  > svg {
    width: 20px;
    height: 20px;
  }
`;

export const CopyTooltip = styled("div")`
  position: absolute;
  left: 32px;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 4px;
  background: ${colors.grey700};
  //font
  color: ${colors.white};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.06px;

  svg {
    right: 32px;
    position: absolute;
    width: 28px;
    height: 28px;

    path {
      fill: ${colors.grey700};
    }
  }
`;
