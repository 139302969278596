import React, { memo } from "react";
import { CustomSelect, CustomSelectI } from ".";
import { SelectWithTextWrapper } from "./styles";
import { Typography } from "@mui/material";
import theme from "application/theme";

interface SelectWithTextProps extends CustomSelectI {
  helperText?: string;
  error?: boolean;
}

export const SelectWithText = memo(
  ({
    onChange,
    placeholder,
    options,
    selected,
    fontWeight,
    maxHeight,
    helperText,
    error,
    withSearch,
    search,
    setSearch,
    searchPlaceholder,
  }: SelectWithTextProps) => {
    return (
      <SelectWithTextWrapper>
        {helperText && (
          <Typography
            variant="subtitle1"
            color={
              error ? theme.palette.common.red700 : theme.palette.common.grey500
            }
            mb={0.5}
          >
            {helperText}
          </Typography>
        )}
        <CustomSelect
          placeholder={placeholder}
          onChange={onChange}
          options={options}
          selected={selected}
          maxHeight={maxHeight}
          fontWeight={fontWeight}
          error={error}
          withSearch={withSearch}
          search={search}
          setSearch={setSearch}
          searchPlaceholder={searchPlaceholder}
        />
      </SelectWithTextWrapper>
    );
  }
);
