import { styled } from "@mui/system";

import theme from "application/theme";
import { colors } from "application/theme/palette";

export const HeaderStyled = styled("header")`
  display: grid;
  grid-template-columns: auto;
  gap: 16px;
  width: 100%;
  margin-bottom: ${theme.spacing(4)};

  h1 {
    color: ${theme.palette.common.grey900};
    font-size: ${theme.spacing(4)};
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }

  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export const ButtonSection = styled("div")`
  display: grid;
  grid-template-columns: max-content max-content;

  & > :last-child {
    margin-left: 20px;
  }

  a {
    all: unset;
  }

  button {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12px;
      width: 24px;
      height: 24px;

      path {
        fill: ${theme.palette.common.white};
      }
    }
  }
`;

export const LoaderContainer = styled("div")`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BackButton = styled("button")`
  all: unset;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.blue700};
  text-align: center;
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
  cursor: pointer;

  svg {
    margin-right: 8px;
    path {
      fill: ${colors.blue700};
    }
  }
`;
