import React, { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { ReactComponent as EmptyFolderSvg } from "application/assets/images/empty-folder.svg";
import { useAppSelector } from "application/hooks/useAppSelector";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import InvestmentAnalyticPopUp from "application/containers/InvestmentAnalyticPopUp";

import {
  InvestmentAnalyticRequestDto,
  InvestmentAnalyticsHistoryDto,
} from "integration/api/PortfolioAPI/models";

import { CurrentCurrency } from "features/portfolio-investor-detail/components";
import InvestorActions from "features/portfolio-investor-detail/store/actions";
import AnalyticsTableRow from "features/portfolio-investor-detail/components/AnalyticsTableRow";

import { Table, Wrapper, Title, EmptyStateStyled } from "./styles";

const InvestmentAnalytics: FC = () => {
  const { id, investorId } = useParams();
  const dispatch = useAppDispatch();
  const investor = useAppSelector((state) => state.portfolioInvestor).investor;

  const [selectedAnalytics, setSelectedAnalytics] =
    useState<InvestmentAnalyticsHistoryDto | null>(null);
  const [showCurrencyModal, setShowCurrencyModal] = useState(false);
  const [showCurrencySuccessModal, setShowCurrencySuccessModal] =
    useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);

  const currentCurrencyPopUpTitle = selectedAnalytics
    ? "Edit current value"
    : "Add new current value";

  const showSuccessModal = showCurrencySuccessModal || showDeleteSuccessModal;
  const successModalTitle = useMemo(() => {
    return showCurrencySuccessModal && selectedAnalytics
      ? "Investment analytics was successfully updated"
      : showDeleteSuccessModal
      ? "Investment analytics was successfully deleted"
      : "Investment analytics was successfully added";
  }, [showSuccessModal]);

  const handleCloseSuccessModal = () => {
    setShowCurrencySuccessModal(false);
    setShowDeleteSuccessModal(false);
  };

  const getInvestor = async () => {
    await dispatch(
      InvestorActions.getInvestorById({
        dealId: Number(id),
        investorId: Number(investorId),
      })
    );
  };

  const openCurrencyModal = () => {
    setShowCurrencyModal(true);
  };

  const closeCurrencyModal = () => {
    setShowCurrencyModal(false);
    setSelectedAnalytics(null);
  };

  const onDelete = (value: InvestmentAnalyticsHistoryDto) => {
    setSelectedAnalytics(value);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setSelectedAnalytics(null);
    setShowDeleteModal(false);
  };

  const onEdit = (value: InvestmentAnalyticsHistoryDto) => {
    setSelectedAnalytics(value);
    setShowCurrencyModal(true);
  };

  const deleteAnalytic = async () => {
    const res = await dispatch(
      InvestorActions.deleteInvestmentAnalytic({
        id: Number(id),
        investorId: Number(investorId),
        analyticId: Number(selectedAnalytics?.id),
      })
    );
    isResponseFulfilled(res, async () => {
      await getInvestor();
      closeDeleteModal();
      setShowDeleteSuccessModal(true);
    });
  };

  const getUpdatedData = (res: { type: string }) => {
    isResponseFulfilled(res, async () => {
      await getInvestor();
      closeCurrencyModal();
      setShowCurrencySuccessModal(true);
    });
  };

  const onSubmit = async (data: InvestmentAnalyticRequestDto) => {
    if (selectedAnalytics) {
      const res = await dispatch(
        InvestorActions.updateInvestmentAnalytic({
          id: Number(id),
          investorId: Number(investorId),
          analyticId: Number(selectedAnalytics?.id),
          data: {
            ...data,
            fmvChangeDate: data.fmvChangeDate,
          },
        })
      );
      getUpdatedData(res);
    } else {
      const res = await dispatch(
        InvestorActions.addInvestmentAnalytics({
          id: Number(id),
          investorId: Number(investorId),
          data: {
            ...data,
            fmvChangeDate: data.fmvChangeDate,
          },
        })
      );
      getUpdatedData(res);
    }
  };

  return (
    <>
      {!!investor.investmentAnalytics ? (
        <>
          <CurrentCurrency
            currentCurrency={investor.investmentAnalytics.current}
            openCurrencyModal={openCurrencyModal}
          />
          <Title>History</Title>
          <Wrapper>
            <Table>
              <thead>
                <tr>
                  <th>Date of last FMV change</th>
                  <th>Current value (FMV)</th>
                  <th>Realized profit</th>
                  <th>Multiple</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {investor.investmentAnalytics.history.map(
                  (analytics, index) => (
                    <AnalyticsTableRow
                      key={`${analytics.fmvChangeDate}-${index}`}
                      analytics={analytics}
                      onEdit={onEdit}
                      onDelete={onDelete}
                    />
                  )
                )}
              </tbody>
            </Table>
          </Wrapper>
        </>
      ) : (
        <EmptyStateStyled>
          <EmptyFolderSvg />
          <h1>No investment analytics for this investment.</h1>
          <button type="button" onClick={openCurrencyModal}>
            Add new current value
          </button>
        </EmptyStateStyled>
      )}
      {showCurrencyModal && (
        <InvestmentAnalyticPopUp
          title={currentCurrencyPopUpTitle}
          onClose={closeCurrencyModal}
          onSubmit={onSubmit}
          defaultValues={selectedAnalytics}
        />
      )}
      {showDeleteModal && (
        <ConfirmPopUp
          title="Delete current value"
          description="You want to delete current value. Are you sure?"
          onClose={closeDeleteModal}
          onSubmit={deleteAnalytic}
        />
      )}
      {showSuccessModal && (
        <SuccessPopUp
          title={successModalTitle}
          onSubmit={handleCloseSuccessModal}
        />
      )}
    </>
  );
};

export default InvestmentAnalytics;
