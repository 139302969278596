import { FundraisingStatus } from "integration/api/PortfolioAPI/models";

export const fundraisingStatuses = [
  {
    label: "Paid and signed",
    value: FundraisingStatus.SIGNED_DOCS_AND_RECEIVED_MONEY,
  },
  {
    label: "Signed & not paid",
    value: FundraisingStatus.SIGNED_DOCS,
  },
  {
    label: "Paid & not signed",
    value: FundraisingStatus.RECEIVED_MONEY,
  },
  {
    label: "Not signed & not paid",
    value: FundraisingStatus.COMMITTED,
  },
  {
    label: "None",
    value: FundraisingStatus.NOT_STARTED,
  },
];
