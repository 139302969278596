import React from "react";

export const RatioStatusIcon = ({ color = "#E5A100" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke={color} />
      <circle cx="8" cy="8" r="4" fill={color} />
    </svg>
  );
};
