import React from "react";

import Logo from "application/assets/logo.svg";
import { LoginPageContentWrapper, IClubLogo, ImageAuth } from "./styles";

const AuthImage = () => {
  return (
    <LoginPageContentWrapper>
      <ImageAuth>
        <IClubLogo src={Logo} alt="logo" />
      </ImageAuth>
    </LoginPageContentWrapper>
  );
};

export default AuthImage;
