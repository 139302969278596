import { Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React from "react";
import { LoginPageContentWrapper, LoginPageWrapper } from "./styles";
import { HelpOutline } from "@mui/icons-material";
import AuthImage from "./AuthImage";
import { MTheme } from "application/theme";

export const AuthPageWrapper = ({ children }: { children: JSX.Element }) => {
  const theme: MTheme = useTheme();
  return (
    <LoginPageWrapper>
      <AuthImage />
      <LoginPageContentWrapper>
        <Box />
        {children}
        <Box />
      </LoginPageContentWrapper>
    </LoginPageWrapper>
  );
};
