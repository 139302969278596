import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import { colors } from "application/theme/palette";

import { TextFieldStyled } from "../TextFieldStyled/style";

interface InputWithTitleProps {
  title: string;
  value: string;
  onChange: (value: string) => void;
  onPaste: (value: React.ClipboardEvent<HTMLDivElement>) => void;
  hasError: boolean;
}

export const InputWithTitle = ({
  title,
  value,
  onChange,
  onPaste,
  hasError,
}: InputWithTitleProps) => {
  const valueLength = value?.length ?? 0;
  return (
    <Box mb={4}>
      <Typography
        variant="h3"
        color={hasError ? colors.red700 : colors.grey900}
        fontWeight={500}
        mb={2.5}
      >
        {title}
      </Typography>
      <TextFieldStyled
        placeholder={"Enter some text"}
        onChange={(e) => onChange(e.target.value)}
        filled={1}
        autoComplete="off"
        error={hasError}
        fullWidth
        onPaste={(e) => onPaste(e)}
        value={value || ""}
      />
      <Typography
        variant="body2"
        color={hasError ? colors.red700 : colors.grey700}
        mt={2}
      >
        {`${valueLength}/40`}
      </Typography>
    </Box>
  );
};
