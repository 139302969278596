export enum Sort {
  asc = "name,asc",
  desc = "name,desc",
}

export enum LogoType {
  ORIGINAL = "ORIGINAL",
  THUMBNAIL = "THUMBNAIL",
}

export enum CompanyStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  RIGHT_OFF = "RIGHT_OFF",
  EXIT = "EXIT",
  DRAFT = "DRAFT",
}

export enum Currency {
  USD = "USD",
  EURO = "EURO",
  POUNDS = "POUNDS",
  SWISS_FRANCS = "SWISS_FRANCS",
}

export enum Status {
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  MOVED_TO_PORTFOLIO = "MOVED_TO_PORTFOLIO",
}

export enum InvestorStatus {
  EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
  EMAIL_VERIFIED = "EMAIL_VERIFIED",
  PHONE_VERIFIED = "PHONE_VERIFIED",
  SURVEY_FILLED = "SURVEY_FILLED",
  KYC_VERIFIED = "KYC_VERIFIED",
}

export enum KYCStatus {
  NOT_STARTED = "NOT_STARTED",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface GetCompanyListParams {
  search?: string;
  page: number;
  size?: number;
  sort?: Sort;
  status?: string;
  currency: Currency;
}

export interface InvestmentAnalyticsCurrentDto {
  fmvChangeDate: string;
  totalFmvValue: number;
  totalRealizedProfit: number;
  investmentAmount: number;
  multiple: number;
  currency: Currency;
}

export interface CompanyListDto {
  id: number;
  businessId: string;
  companyName: string;
  website: string;
  yearFounded: number;
  status: CompanyStatus;
  logoFileId: number;
  investmentAnalytic: InvestmentAnalyticsCurrentDto;
  investmentAmount: number;
  investmentCurrency: Currency;
}

interface SortDto {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

interface PageableDto {
  offset: number;
  sort: SortDto;
  paged: boolean;
  unpaged: boolean;
  pageNumber: number;
  pageSize: number;
}

interface PageDto {
  totalPages: number;
  totalElements: number;
  first: boolean;
  last: boolean;
  size: number;
  number: number;
  sort: SortDto;
  numberOfElements: number;
  pageable: PageableDto;
  empty: boolean;
}

export interface PageCompanyAdminPageDto extends PageDto {
  content: CompanyListDto[];
}

export interface CreateCompanyResponse {
  id: number;
  businessId: string;
  creationDate: string;
}

export interface TeamDto {
  id?: number | string;
  url?: string;
  ordinality?: number;
  firstName?: string;
  lastName?: string;
  title?: string;
  linkedinUrl?: string;
  isResponsibleForQa?: boolean;
  base64Image?: string;
  imageDeleted?: boolean;
  imageUrl?: string;
  imageName?: string;
}

export interface CompanyDto {
  name: string;
  businessId: string;
  creationDate: string;
  status: CompanyStatus;
  description: string;
  shortDescription: string;
  website: string;
  foundedYear: string;
  logoFileId: number | null;
  team: TeamDto[];
}

export interface CompanyUpdateParams {
  id: string;
  data: CompanyDto;
}

type CompanyDataKeys =
  | "businessId"
  | "name"
  | "description"
  | "website"
  | "foundedYear"
  | "shortDescription";

export interface CompanyData {
  key: CompanyDataKeys;
  value: string;
}

export interface PortfolioDealsParams {
  id: number;
  page: number;
  search?: string;
}

export interface PortfolioDealDto {
  id: number;
  businessId: string;
  creationDate: string;
  title: string;
  status: Status;
  investmentCurrency: Currency;
  totalFundingGoal: number;
  logoFileId: number;
}
export interface PortfolioDealAdminPageDto extends PageDto {
  content: PortfolioDealDto[];
}

export interface CompanyDealsResponse {
  companyName: string;
  dealsPage: PortfolioDealAdminPageDto;
}

export interface CompanyInvestorsParams {
  id: number;
  page: number;
}

export interface CompanyInvestorsParamsAPI extends CompanyInvestorsParams {
  key: string;
}

export interface CompanyInvestorDto {
  id: number;
  firstName: string;
  lastName: string;
  registrationDate: string;
  registrationStatus: InvestorStatus;
  kycStatus: KYCStatus;
  investmentAmount: number | null;
  currency: Currency;
}

export interface PageCompanyInvestorInfoDto extends PageDto {
  content: CompanyInvestorDto[];
}

export interface CompanyGetLogoParams {
  id: number;
  logoId: number;
  logoType: LogoType;
}

export interface CompanyDeleteLogoParams {
  id: number;
  logoId: number;
}

export interface CompanyUploadLogoParams {
  id: number;
  file: FormData;
}

export interface UploadCompanyLogoResponse {
  fileId: number;
}

export interface AdminAnalyticResponse {
  fileId: number;
  fileName: string;
  ordinal: number;
  creationDate: string;
}

export interface CompanyAnalyticAdminResponse {
  companyName: string;
  adminAnalyticResponses: AdminAnalyticResponse[];
}

export interface UpdateOrdinalDto {
  id: number;
  ordinal: number;
}

export interface UpdateCompanyAnalyticParams {
  id: number;
  data: UpdateOrdinalDto[];
}

export interface UploadCompanyAnalyticParams {
  id: number;
  file: FormData;
}

export interface CompanyAnalyticParams {
  id: number;
  fileId: number;
}

export interface CompanySuggestionDto {
  businessId: string;
  companyName: string;
  description: string;
  id: number;
  logoFileId: null;
  status: CompanyStatus;
  website: string;
  yearFounded: string;
}

export interface GetCompanyTotalsParams {
  search?: string;
  status?: string;
  currency: Currency;
}

export interface CompanyAdminResponseTotalsDto {
  totalInvestments: number;
  totalFmvValue: number;
  totalRealizedProfit: number;
  multiple: number;
}
