import { createAsyncThunk } from "@reduxjs/toolkit";

import DealsApi from "integration/api/DealsApi";
import { RequestEnum } from "application/store/request/model";

class LostReasonsActions {
  static getLostReasons = createAsyncThunk(
    `deals/${RequestEnum.getLostReasons}`,
    async (_, { rejectWithValue }) => {
      try {
        const { data } = await DealsApi.getLostReasons();
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default LostReasonsActions;
