import { styled } from "@mui/system";
import { colors } from "application/theme/palette";

interface TextFieldProps {
  isActive: boolean;
  filled: boolean;
}

interface ListItemProps {
  selected: boolean;
}

export const Wrapper = styled("div")`
  display: flex;
  position: relative;
  width: 100%;
`;

export const TextField = styled("div")<TextFieldProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  width: 100%;
  padding: 0px 16px;
  border-radius: 8px;
  border: 1px solid
    ${({ isActive, filled }) =>
      isActive ? colors.blue500 : filled ? colors.grey700 : colors.grey300};
  background: ${({ isActive }) => (isActive ? colors.blue50 : colors.grey50)};
  cursor: pointer;

  p {
    color: ${({ filled }) => (filled ? colors.grey900 : colors.grey700)};
    font-family: Unica77;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
  }

  svg {
    transform: ${({ isActive }) =>
      isActive ? "rotate(180deg)" : "rotate(0deg)"};
    path {
      fill: ${colors.grey500};
    }
  }
`;

export const InputSearch = styled("input")`
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  outline: none;
  padding: 0 16px 0 48px;
  border-radius: 8px;
  border: 1px solid ${colors.grey300};

  /* font */
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  font-style: normal;
  letter-spacing: 0.08px;
  font-family: Unica77;
  color: ${colors.grey700};
  background-color: ${colors.grey50};
  margin-bottom: 10px;

  &:focus {
    background-color: ${colors.blue50};
    border: 1px solid ${colors.blue700};
  }
`;

export const DropdownList = styled("div")`
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  top: 48px;
  z-index: 2;
  position: absolute;
  border-radius: 4px;
  background: ${colors.white};
  box-shadow: 0px 4px 30px 0px rgba(222, 224, 230, 0.8);
`;

export const InputWrapper = styled("div")`
  position: relative;
  box-sizing: border-box;

  svg {
    position: absolute;
    top: 10px;
    left: 16px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;

    path {
      fill: ${colors.grey700};
    }
  }
`;

export const ListStyled = styled("div")`
  max-height: 250px;
  overflow-y: auto;
`;

export const ListItem = styled("div")<ListItemProps>`
  padding: 6px 16px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${({ selected }) =>
    selected ? colors.blue50 : colors.white};

  &:hover {
    background-color: ${colors.grey100};
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    font-style: normal;
    font-family: "Unica77";
    letter-spacing: 0.08px;
    color: ${colors.grey900};
  }
  svg {
    width: 24px;
    height: 24px;
    color: ${colors.grey500};
  }
`;
