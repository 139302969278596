import { Link } from "react-router-dom";
import { Box, styled } from "@mui/system";
import { Button } from "@mui/material";
import theme from "application/theme";
import AuthBg from "./assets/authBg.png";

export const ButtonStyled = styled(Button)`
  min-width: 400px;
`;
export const paperStyled = {
  maxWidth: 400,
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  background: "#fff",
  borderRadius: 1,
  padding: 2,
};

export const LoginPageContentWrapper = styled(Box)`
  width: 50%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

export const ImageAuth = styled("div")`
  width: 100%;
  height: 100%;
  background-image: url(${AuthBg});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
`;

export const IClubLogo = styled("img")`
  max-width: ${theme.spacing(45)};
  max-height: ${theme.spacing(10)};
`;

export const LoginPageWrapper = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
`;

export const InfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${theme.palette.common.grey900};
`;

export const FormWrapper = styled(Box)`
  width: ${theme.spacing(45)};
`;

export const LinkStyled = styled(Link)`
  font-family: Unica77;
  color: ${theme.palette.common.blue700};
  font-weight: 500;
  font-size: ${theme.spacing(1.75)};
  line-height: ${theme.spacing(2.5)};
  text-decoration: none;
`;

export const HelperTextWrapper = styled(Box)`
  display: flex;
  align-self: start;
  align-items: center;
`;

export const ErrorMessageWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: ${theme.palette.common.errorBg};
  border-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(1, 1.5)};

  span {
    text-align: left;
  }
`;
