import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SearchOutlined } from "@mui/icons-material";

import useOnClickOutside from "application/hooks/useOnClickOutside";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { ArrowDropDown } from "application/assets/ArrowDropDown";

import {
  CompanyStatus,
  CompanySuggestionDto,
} from "integration/api/CompanyApi/models";

import CompanyListActions from "features/companies/store/actions";

import { CompanyDropdownProps } from "./models";
import {
  Wrapper,
  DropdownList,
  InputWrapper,
  InputSearch,
  ListItem,
  ListStyled,
  TextField,
} from "./styles";

const CompanyDropdown: FC<CompanyDropdownProps> = ({
  companyId,
  selectedCompany,
  setSelectedCompany,
}) => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const { companySuggestions } = useAppSelector((state) => state.companyList);
  const [showList, setShowList] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useOnClickOutside(ref, () => {
    setShowList(false);
  });

  const filteredCompaniesList = useMemo(() => {
    const filteredCompanies = companySuggestions?.filter((item) => {
      return (
        (item.status === CompanyStatus.RIGHT_OFF ||
          item.status === CompanyStatus.ACTIVE) &&
        item.companyName?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
    return filteredCompanies || [];
  }, [companySuggestions, searchValue]);

  const selectedCompanyName = useMemo(() => {
    const currentCompany = companySuggestions?.find(
      (item) => item.id === selectedCompany?.id || item.id === companyId
    );
    return currentCompany?.companyName || "Choose a company";
  }, [companySuggestions, selectedCompany]);

  useEffect(() => {
    dispatch(CompanyListActions.getCompanySuggestions());
  }, []);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleChoseCompany = (company: CompanySuggestionDto) => {
    setSelectedCompany(company);
    setShowList(false);
  };

  const showCompanyList = showList;

  return (
    <Wrapper ref={ref}>
      <TextField
        isActive={showList}
        filled={!!selectedCompany?.id}
        onClick={() => {
          setShowList((prev) => !prev);
        }}
      >
        <p>{selectedCompanyName}</p>
        <ArrowDropDown />
      </TextField>
      {showCompanyList && (
        <DropdownList>
          <InputWrapper>
            <SearchOutlined />
            <InputSearch
              placeholder={"Search..."}
              value={searchValue}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </InputWrapper>
          <ListStyled>
            {filteredCompaniesList.map((company) => (
              <ListItem
                key={company.id}
                onClick={() => handleChoseCompany(company)}
                selected={
                  company.id === selectedCompany?.id || company.id === companyId
                }
              >
                <p>{company.companyName}</p>
              </ListItem>
            ))}
          </ListStyled>
        </DropdownList>
      )}
    </Wrapper>
  );
};

export default CompanyDropdown;
