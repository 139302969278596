import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  InvestmentAnalyticRequestDto,
  InvestmentAnalyticsHistoryDto,
} from "integration/api/PortfolioAPI/models";
import { Currency } from "integration/api/DealsApi/models";

import { DatePicker, TextFieldStyled } from "application/components";
import PrimaryButton from "application/components/PrimaryButton";
import CurrencySelect from "application/components/CurrencySelect";

import { investmentAnalyticSchema } from "./validation";
import { Card, Title, Wrapper, Actions, CancelButton } from "./styles";
import { FormattedTextField } from "application/components/FormattedTextField";

interface Props {
  title: string;
  defaultValues: InvestmentAnalyticsHistoryDto | null;
  onClose: () => void;
  onSubmit: (data: InvestmentAnalyticRequestDto) => void;
}

const formatter = new Intl.NumberFormat("en-US");

const InvestmentAnalyticPopUp: FC<Props> = ({
  title,
  defaultValues,
  onClose,
  onSubmit,
}) => {
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<InvestmentAnalyticRequestDto>({
    defaultValues: defaultValues
      ? {
          fmvChangeDate: defaultValues.fmvChangeDate,
          fmvValue: defaultValues.fmvValue,
          realizedProfit: defaultValues.realizedProfit,
          currency: defaultValues.currency,
        }
      : {},
    resolver: yupResolver(investmentAnalyticSchema),
  });

  const values = watch();

  const onAmountChange = (
    value: string,
    key: keyof InvestmentAnalyticRequestDto
  ) => {
    const numberValue = Number(value.replaceAll(",", ""));
    if (Number.isNaN(numberValue)) return;
    setValue(key, numberValue, { shouldValidate: !!errors[key] });
  };

  const onCurrencyChange = (currency: Currency) => {
    setValue("currency", currency, { shouldValidate: true });
  };

  const hasErrors = !!Object.keys(errors).length;

  return (
    <Wrapper>
      <Card>
        <Title>{title}</Title>
        <DatePicker
          placeholder="Choose date of last FMV change"
          onChange={(value) =>
            setValue("fmvChangeDate", value, {
              shouldValidate: !!errors.fmvChangeDate,
            })
          }
          value={watch("fmvChangeDate")}
          hasError={!!errors.fmvChangeDate}
        />
        <FormattedTextField
          fullWidth
          id="fmvValue"
          size="small"
          color="secondary"
          placeholder="Current value (FMV)"
          filled={values?.fmvValue}
          error={!!errors.fmvValue}
          label="Current value (FMV)"
          value={
            watch("fmvValue") ? formatter.format(Number(watch("fmvValue"))) : ""
          }
          onChange={(e) => {
            onAmountChange(e, "fmvValue");
          }}
        />
        <FormattedTextField
          fullWidth
          id="realizedProfit"
          size="small"
          color="secondary"
          placeholder="Total realized profit"
          label="Total realized profit"
          filled={values.realizedProfit}
          error={!!errors.realizedProfit}
          value={
            watch("realizedProfit")
              ? formatter.format(Number(watch("realizedProfit")))
              : ""
          }
          onChange={(e) => {
            onAmountChange(e, "realizedProfit");
          }}
        />
        <CurrencySelect
          maxHeight={300}
          selected={values.currency}
          onChange={onCurrencyChange}
          placeholder="Choose currency"
          error={!!errors.currency?.message}
        />
        <Actions>
          <CancelButton onClick={onClose}>
            <p>Cancel</p>
          </CancelButton>

          <PrimaryButton
            title="Ok"
            disabled={hasErrors}
            onClick={handleSubmit(onSubmit)}
          />
        </Actions>
      </Card>
    </Wrapper>
  );
};

export default InvestmentAnalyticPopUp;
