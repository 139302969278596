import styled from "@emotion/styled";
import theme from "application/theme";

import { colors } from "application/theme/palette";

export const Container = styled("section")``;

export const TableStyled = styled("table")`
  width: 100%;
  border-collapse: separate;
  border-radius: 8px;
  background: ${theme.palette.common.white};
  box-shadow: 0px 0px 30px 0px rgba(222, 224, 230, 0.3);

  tr {
    height: 52px;
    th {
      padding: 10px 12px;
      text-align: start;
      vertical-align: middle;
      background: ${theme.palette.common.grey200};
      color: ${theme.palette.common.grey900};
      font-family: Unica77;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.08px;
      box-sizing: border-box;

      &.logo-column {
        max-width: 62px;
        min-width: 62px;
        width: 62px;
        border-top-left-radius: 8px;
      }

      &.founded-column {
        max-width: 90px;
        min-width: 90px;
        width: 90px;
      }
      &.status-column {
        max-width: 80px;
        min-width: 80px;
        width: 80px;
      }
      &.total-column {
        min-width: 160px;
        max-width: 160px;
        width: 160px;
      }
      &.analytics-column {
        min-width: 334px;
        max-width: 334px;
        width: 334px;
      }
      &.multiple-column {
        min-width: 86px;
        max-width: 86px;
        width: 86px;
      }
      &.action-column {
        min-width: 140px;
        max-width: 140px;
        width: 140px;
        text-align: center;
        border-top-right-radius: 8px;
      }
    }

    &:last-of-type {
      height: 62px;
      td {
        border-bottom: 1px solid ${colors.grey200};
        &:first-of-type {
          border-bottom-left-radius: 8px;
        }
        &:last-of-type {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TotalRowStyled = styled("tr")`
  height: 62px;

  td {
    border-right: none;
    background-color: ${colors.grey200};
    //font
    color: ${colors.grey900};
    font-family: Unica77;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.07px;
    vertical-align: middle;
    padding: 10px 12px;
  }
`;

export const RowTitle = styled("h1")`
  color: ${colors.grey900};
  font-family: Unica77;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

export const Amount = styled("div")`
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    path {
      stroke: ${colors.grey900};
    }
  }
  p {
    color: ${colors.grey900};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
    margin: 2px 4px 0;
  }
`;

export const MultiRowStyled = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    h2 {
      color: ${colors.grey700};
      font-family: Unica77;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.07px;
      width: 172px;
    }

    p {
      color: ${colors.grey900};
      font-family: Unica77;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: 0.07px;
    }
  }
`;
