import theme from "application/theme";

export const buttonHelper = (
  type: "button" | "submit" | "reset" | undefined
) => {
  switch (type) {
    case "button":
      return {
        background: theme.palette.common.white,
        color: theme.palette.common.grey700,
        hoverBg: theme.palette.common.grey100,
        border: `1px solid${theme.palette.common.grey500}`,
        disabledBorderColor: theme.palette.common.grey300,
        disableBg: theme.palette.common.white,
      };
    case "submit":
      return {
        background: theme.palette.common.blue700,
        color: theme.palette.common.white,
        hoverBg: theme.palette.common.blue500,
        disableBg: theme.palette.common.grey200,
      };
    case "reset":
      return {
        background: theme.palette.common.white,
        color: theme.palette.common.blue700,
        hoverBg: theme.palette.common.blue50,
        border: `1px solid${theme.palette.common.blue700}`,
        disabledBorderColor: theme.palette.common.grey300,
        disableBg: theme.palette.common.white,
      };
    default:
      return {
        background: theme.palette.common.white,
        color: theme.palette.common.blue700,
        hoverBg: theme.palette.common.blue50,
        disableBg: theme.palette.common.white,
      };
  }
};
