import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "@mui/icons-material";
import { Checkbox, InputAdornment, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { TextFieldStyled } from "application/components";
import BasicPopUp from "application/containers/BasicPopUp";
import { CommonErrors } from "application/helpers/validations";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import theme from "application/theme";
import { LabelStyled } from "features/companies/components/TeamMemberAddModal/styles";
import { DragAndDropArea } from "features/deal-profile/components/DragAndDropArea";
import { OverviewSectionUploads } from "features/deal-profile/pages/OverviewSection/types";
import { dealPageSelector } from "features/deal-profile/store/selectors";
import { setOverviewVideos } from "features/deal-profile/store/slice";
import { DealStatus } from "integration/api/DealsApi/models";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";

export interface Item {
  id: any;
  url?: string;
  linkedinUrl?: string;
  firstName?: string;
  lastName?: string;
  title?: string;
  fileName?: string;
  deepDiveVideo?: boolean;
  ordinal?: number;
  imageUrl?: string;
  imageName?: string;
  isResponsibleForQa?: boolean;
  base64Image?: string;
  imageDeleted?: boolean;
}

const schema = yup
  .object({
    url: yup
      .string()
      .url(CommonErrors.wrongUrlFormat)
      .required(CommonErrors.requiredField),
  })
  .required();

export const VideosContainer = () => {
  const { overview, status } = useAppSelector(dealPageSelector).profile;
  const videos = overview.videos;
  const dispatch = useAppDispatch();

  const [videoLinks, setVideoLinks] = useState<Item[]>([]);
  const [newVideoLink, setNewVideoLink] = useState("");
  const [addVideoModal, setAddVideoModal] = useState(false);
  const [removeVideoModal, setRemoveVideoModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<Item>();
  const [checked, setChecked] = useState<boolean>(false);
  const [defaultStatus, setDefaultStatus] = useState<DealStatus>()

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<{ url: string }>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setDefaultStatus(status)
    const videoLinksArray = videos.map((e) => {
      return { id: e.id, ordinal: e.ordinality, url: e.url, deepDiveVideo: e.deepDiveVideo };
    });
    setVideoLinks(videoLinksArray);
  }, []);

  useEffect(() => {
    setVideosInStore();
  }, [videoLinks]);

  const setVideosInStore = () => {
    const video = videoLinks.map((e, idx) => {
      return { id: e.id, ordinality: e.ordinal || idx + 1, url: e.url, deepDiveVideo: e.deepDiveVideo };
    });
    dispatch(setOverviewVideos(video));
  };

  const addVideoLink = () => {
    dispatch(
      setOverviewVideos([
        ...videos,
        {
          id: newVideoLink,
          url: newVideoLink,
          ordinality: videos.length + 1,
          deepDiveVideo: checked,
        },
      ])
    );
    setVideoLinks([
      ...videoLinks,
      {
        id: newVideoLink,
        url: newVideoLink,
        deepDiveVideo: checked,
      },
    ]);
    setNewVideoLink("");
    reset();
    setAddVideoModal(false);
  };

  const handleCloseModal = () => {
    reset();
    setAddVideoModal(false);
  };

  const handleDeleteVideo = (id: any) => {
    const videoToRemove = videos.filter((e) => e.id === id)[0];
    setSelectedVideo(videoToRemove);
    setRemoveVideoModal(true);
  };

  const submitDeletingVideo = () => {
    const videoLefted = videos.filter((e) => e.id !== selectedVideo?.id);
    dispatch(setOverviewVideos(videoLefted));
    setVideoLinks(videoLefted);
    setRemoveVideoModal(false);
  };

  const disabledCheckbox = defaultStatus === DealStatus.DRAFT || status === DealStatus.DRAFT

  return (
    <Box>
      <DragAndDropArea
        header={OverviewSectionUploads.video}
        cards={videoLinks}
        setCards={setVideoLinks}
        addCard={() => setAddVideoModal(true)}
        removeCard={handleDeleteVideo}
        placeholder="Add Video"
      />
      {addVideoModal && (
        <BasicPopUp
          title="Add video"
          onClose={handleCloseModal}
          onSubmit={handleSubmit(addVideoLink)}
        >
          <form onSubmit={handleSubmit(addVideoLink)}>
            <Box>
              <TextFieldStyled
                {...register("url")}
                mb={7}
                label={"Link"}
                placeholder="https://www.example.com"
                fullWidth
                onChange={(e) => setNewVideoLink(e.target.value)}
                id="url"
                type="url"
                error={!!errors.url}
                helperText={!!errors.url && errors.url?.message}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{
                        zIndex: 10,
                        paddingLeft: "2px",
                        marginRight: "-8px",
                      }}
                    >
                      {<Link />}
                    </InputAdornment>
                  ),
                }}
              />
              <LabelStyled
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                    disabled={disabledCheckbox}
                  />
                }
                label="This is deep-dive video, user will be notified when it will be added"
              />
            </Box>
          </form>
        </BasicPopUp>
      )}
      {removeVideoModal && (
        <BasicPopUp
          title="Delete video"
          onClose={() => setRemoveVideoModal(false)}
          onSubmit={submitDeletingVideo}
        >
          <Box display="flex" alignItems="center">
            <Box mr={1.5}>
              <Link />
            </Box>
            <Typography variant="body2" color={theme.palette.common.grey900}>
              {selectedVideo?.url}
            </Typography>
          </Box>
        </BasicPopUp>
      )}
    </Box>
  );
};
