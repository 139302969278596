import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import ReactCountryFlag from "react-country-flag";

import useOnClickOutside from "application/hooks/useOnClickOutside";
import { ArrowDropDown } from "application/assets/ArrowDropDown";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { getCountries } from "application/store/Country/ActionCreators";

import { CountryCodeProps } from "./model";
import {
  DropdownStyled,
  TextField,
  DropdownList,
  ListItem,
  Input,
} from "./styles";
import { useAppSelector } from "application/hooks/useAppSelector";

const CountryDropdown: FC<CountryCodeProps> = ({
  value,
  onChange,
  label,
  placeholder,
}) => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const { countries } = useAppSelector((state) => state.countries);
  const [showList, setShowList] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useOnClickOutside(ref, () => setShowList(false));

  useEffect(() => {
    dispatch(getCountries());
  }, []);

  const filteredCountryList = useMemo(() => {
    return countries.filter((country) =>
      country.name.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, countries]);

  const selectedCountry = useMemo(() => {
    return countries.find((country) => country.id === value)?.name;
  }, [value, countries]);

  const handleSelectCountry = (code: number) => {
    setShowList(false);
    setSearchValue("");
    onChange(code);
  };

  return (
    <DropdownStyled ref={ref}>
      {label && <h3>{label}</h3>}
      <TextField
        isActive={showList}
        filled={!!value}
        onClick={() => setShowList((prev) => !prev)}
      >
        <p>{selectedCountry || placeholder}</p>
        <ArrowDropDown />
      </TextField>
      <DropdownList opened={showList}>
        <div>
          <Input
            placeholder="Search country"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onClick={() => setShowList(true)}
          />
          {filteredCountryList?.length ? (
            filteredCountryList.map((country) => (
              <ListItem
                key={country.id}
                selected={country.id === value}
                onClick={() => handleSelectCountry(country.id)}
              >
                <ReactCountryFlag countryCode={country.isoCode} />
                {country.name}
              </ListItem>
            ))
          ) : (
            <span>No results</span>
          )}
        </div>
      </DropdownList>
    </DropdownStyled>
  );
};

export default CountryDropdown;
