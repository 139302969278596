import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequestEnum } from "application/store/request/model";
import {
  CreateCommitmentDto,
  DeleteInvestorParams,
  DeleteMultipleInvestorsParams,
  InvestorStatusParams,
  InvestorsParams,
} from "integration/api/PortfolioAPI/models";
import PortfolioApi from "integration/api/PortfolioAPI";
import {
  decryptAESKey,
  decryptName,
  encryptName,
  generateRSAKeyPair,
} from "application/helpers/encryptionWithNoKey";

class InvestorsActions {
  static getInvestors = createAsyncThunk(
    `portfolio/${RequestEnum.getInvestors}`,
    async (params: InvestorsParams, { rejectWithValue }) => {
      try {
        const keys = await generateRSAKeyPair();
        const response = await PortfolioApi.getInvestors({
          ...params,
          key: keys?.publicKeyBase64 as string,
        });

        const secretKeyEncrypted = response.headers["x-data-key"];
        const decriptedKey = await decryptAESKey(
          secretKeyEncrypted,
          keys?.privateKey as CryptoKey
        );

        const content = await Promise.all(
          response.data.investorsPage.content.map(async (e) => {
            const firstName = (await decryptName(
              e.firstName as string,
              decriptedKey
            )) as string;
            const lastName = (await decryptName(
              e.lastName as string,
              decriptedKey
            )) as string;
            return { ...e, firstName, lastName };
          })
        );

        return {
          ...response.data,
          investorsPage: { ...response.data.investorsPage, content },
        };
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static changeInvestorStatus = createAsyncThunk(
    `portfolio/${RequestEnum.changeInvestorStatus}`,
    async (data: InvestorStatusParams, { rejectWithValue }) => {
      try {
        const { data: responseData } = await PortfolioApi.changeInvestorStatus(
          data
        );
        return responseData;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static createInvestor = createAsyncThunk(
    `portfolio/${RequestEnum.createInvestor}`,
    async (data: CreateCommitmentDto, { rejectWithValue }) => {
      try {
        const { data: responseData } = await PortfolioApi.createInvestor(data);
        return responseData;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deleteInvestor = createAsyncThunk(
    `portfolio/${RequestEnum.deleteInvestor}`,
    async (params: DeleteInvestorParams, { rejectWithValue }) => {
      try {
        const { data } = await PortfolioApi.deleteInvestor(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deleteInvestors = createAsyncThunk(
    `portfolio/${RequestEnum.deleteInvestors}`,
    async (params: DeleteMultipleInvestorsParams, { rejectWithValue }) => {
      try {
        const { data } = await PortfolioApi.deleteInvestors(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default InvestorsActions;
