import React from "react";

import { useAppSelector } from "application/hooks/useAppSelector";

import { Wrapper, Card, Name, Role, Content } from "./styles";

interface Props {
  open: boolean;
}

const UserInfo = ({ open }: Props) => {
  const { profile } = useAppSelector((state) => state.auth);
  const fullName = `${profile?.firstName || ""} ${profile?.lastName || ""}`;
  const userInitials = `${profile?.firstName[0] || ""}${
    profile?.lastName[0] || ""
  }`;

  return (
    <Wrapper open={open}>
      <Card>
        <p>{userInitials}</p>
      </Card>
      {open && (
        <Content>
          <Name>{fullName}</Name>
          <Role>Admin</Role>
        </Content>
      )}
    </Wrapper>
  );
};

export default UserInfo;
