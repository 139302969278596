import { createSlice } from "@reduxjs/toolkit";

import { Currency } from "integration/api/DealsApi/models";

import InvestorsActions from "./actions";
import { InvestorsState } from "./model";

const initialState: InvestorsState = {
  investors: {
    totalElements: 0,
    totalPages: 0,
    size: 0,
    content: [],
    number: 0,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    numberOfElements: 0,
    pageable: {
      offset: 0,
      sort: {
        empty: true,
        sorted: true,
        unsorted: true,
      },
      pageNumber: 0,
      pageSize: 0,
      paged: true,
      unpaged: true,
    },
    first: true,
    last: true,
    empty: true,
  },
  title: "",
  dealHasParent: false,
  dealImported: false,
  dealCurrency: Currency.USD,
  totalCurrency: Currency.USD,
  totalInvestmentAmount: 0,
  totalCurrentValue: 0,
  totalRealizedProfit: 0,
  multiple: 0,
};

export const investorsSlice = createSlice({
  name: "investors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get portfolio
    builder.addCase(
      InvestorsActions.getInvestors.fulfilled,
      (state, action) => {
        state.investors = action.payload.investorsPage;
        state.title = action.payload.title;
        state.dealHasParent = action.payload.dealHasParent;
        state.dealImported = action.payload.dealImported;
        state.dealCurrency = action.payload.dealCurrency;
        state.totalCurrency = action.payload.totalCurrency;
        state.totalInvestmentAmount = action.payload.totalInvestmentAmount;
        state.totalCurrentValue = action.payload.totalCurrentValue;
        state.totalRealizedProfit = action.payload.totalRealizedProfit;
        state.multiple = action.payload.multiple;
      }
    );
  },
});

export const InvestorsSliceAction = investorsSlice.actions;
export default investorsSlice.reducer;
