import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const SortToggle = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 149px;
  height: 44px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid ${colors.grey300};
  background: ${colors.white};

  &:hover {
    background: ${colors.grey100};
  }

  color: ${colors.grey900};
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.08px;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-right: 12px;

    path {
      fill: ${colors.grey700};
    }
  }
`;

export const StatusWrapper = styled.div`
  width: 220px;
`;

export const SectionStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Input = styled.input`
  height: 44px;
  width: 364px;
  outline: none;
  text-indent: 48px;
  border-radius: 8px;
  border: 1px solid ${colors.grey300};
  /* font */
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  font-style: normal;
  letter-spacing: 0.08px;
  font-family: Unica77;
  color: ${colors.grey700};
  &:focus {
    background-color: ${colors.blue50};
    border: 1px solid ${colors.blue700};
  }
`;

export const InputContainer = styled.div`
  position: relative;
  z-index: 3;
  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    top: 10px;
    left: 16px;
    position: absolute;
    color: ${colors.grey500};
  }
`;
