import * as yup from "yup";

import { Currency } from "integration/api/DealsApi/models";
import { CommonErrors } from "application/helpers/validations";

export const EditRowMultiTypeSchema = (isMonthOptional: boolean) => {
  return yup.object().shape({
    index: yup
      .number()
      .typeError(CommonErrors.number)
      .required(CommonErrors.requiredField),
    month: isMonthOptional
      ? yup.number()
      : yup
          .number()
          .typeError(CommonErrors.number)
          .required(CommonErrors.requiredField),
    currency: yup.string(),
  });
};

export interface EditRowMultiTypeFieldValues {
  month: number;
  index: number;
  currency: Currency;
}
