import styled from "@emotion/styled";
import Pagination from "@mui/material/Pagination";

import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const TableStyled = styled.table`
  width: 100%;
  border-radius: 8px;
  border-collapse: separate;

  th {
    top: 0;
    height: 52px;
    padding: 0 12px;
    vertical-align: middle;
    background-color: white;
    background-color: ${colors.grey200};
    /* font */
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    line-height: 140%;
    font-style: normal;
    letter-spacing: 0.08px;
    font-family: "Unica77";
    color: ${colors.grey900};
    z-index: 5;
    box-sizing: border-box;

    &:last-of-type {
      border-top-right-radius: 8px;
    }
    &:first-of-type {
      border-top-left-radius: 8px;
    }
  }

  td {
    height: 52px;
    padding: 10px 12px;
    vertical-align: middle;
    border-top: 1px solid ${colors.grey200};
    border-right: 1px solid ${colors.grey200};
    /* font */
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    font-style: normal;
    font-family: "Unica77";
    letter-spacing: 0.07px;
    color: ${colors.grey900};
    box-sizing: border-box;

    &:first-of-type {
      border-left: 1px solid ${colors.grey200};
    }
  }

  tbody {
    tr:last-of-type {
      td {
        border-bottom: 1px solid ${colors.grey200};
        &:first-of-type {
          border-bottom-left-radius: 8px;
        }
        &:last-of-type {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FiltersSection = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  > div:first-of-type {
    display: flex;
    align-items: center;
    gap: 20px;

    > div:first-of-type {
      width: 220px;
    }
  }
`;

export const Input = styled.input`
  height: 44px;
  width: 364px;
  outline: none;
  text-indent: 48px;
  border-radius: 8px;
  border: 1px solid ${colors.grey300};
  /* font */
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  font-style: normal;
  letter-spacing: 0.08px;
  font-family: "Unica77";
  color: ${colors.grey700};
  &:focus {
    background-color: ${colors.blue50};
    border: 1px solid ${colors.blue700};
  }
`;

export const InputContainer = styled.div`
  position: relative;
  svg {
    width: 24px;
    height: 24px;
    top: 10px;
    left: 16px;
    position: absolute;
    color: ${colors.grey500};
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DeleteContainer = styled.div`
  z-index: 2;
  display: flex;
  cursor: pointer;
  margin-left: 8px;
  border-radius: 4px;
  position: relative;
  background-color: ${colors.white};

  svg {
    width: 20px;
    height: 20px;
    color: ${colors.grey700};
  }
`;

export const TotalRowStyled = styled("tr")`
  td {
    border-right: none;
    background-color: ${colors.grey200};
    //font
    color: ${colors.grey900};
    font-family: Unica77;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.07px;
  }
`;

export const RowTitle = styled("h1")`
  color: ${colors.grey900};
  font-family: Unica77;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
`;

export const Amount = styled("div")`
  display: flex;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    path {
      stroke: ${colors.grey900};
    }
  }
  p {
    color: ${colors.grey900};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
    margin: 2px 4px 0;
  }
`;

export const MultiRowStyled = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    h2 {
      color: ${colors.grey700};
      font-family: Unica77;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.07px;
      width: 172px;
    }

    p {
      color: ${colors.grey900};
      font-family: Unica77;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: 0.07px;
    }
  }
`;
