import { Box } from "@mui/system";
import React from "react";
import { StyledLink, VideoLinkWrapper } from "./styles";
import { Link } from "@mui/icons-material";

export interface VideoLinkProps {
  url: string | undefined;
}

export const VideoLink = ({ url }: VideoLinkProps) => {
  return (
    <VideoLinkWrapper>
      <VideoLinkWrapper mr={1.5}>
        <Link />
      </VideoLinkWrapper>
      <StyledLink target="_blank" href={url}>
        {url}
      </StyledLink>
    </VideoLinkWrapper>
  );
};
