import axiosInstance from "../axiosInstance";

import {
  Sort,
  ConsentDto,
  ConsentListParams,
  CreateConsentDto,
  PageConsentDto,
  UpdateConsentRequest,
} from "./models";

const ConsentsApi = {
  getConsentList: (params: ConsentListParams) => {
    const { page, size, status } = params;
    return axiosInstance.get<PageConsentDto>("/api/admin/consent/list", {
      params: {
        ...(status?.length ? { status } : {}),
        page,
        size,
        sort: Sort.desc,
      },
    });
  },
  getConsentById: (id: number) => {
    return axiosInstance.get<ConsentDto>(`/api/admin/consent/${id}`);
  },
  updateConsent: (data: UpdateConsentRequest) => {
    return axiosInstance.put("/api/admin/consent", data);
  },
  addConsent: () => {
    return axiosInstance.post<CreateConsentDto>("/api/admin/consent");
  },
  deleteConsent: (id: number) => {
    return axiosInstance.delete(`/api/admin/consent/${id}`);
  },
};

export default ConsentsApi;
