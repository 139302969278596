import React, { FC, useEffect, useMemo, useState } from "react";
import {
  AssignmentTurnedInOutlined,
  EditOutlined,
  CloseOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { PathNames } from "application/routes";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { getNavigationState } from "application/helpers/navigationState";
import { SearchParamsRoutesNames } from "application/routes/constants";
import { formatTotal } from "application/helpers/formatTotal";
import { formatDate } from "application/helpers/formatDate";

import { LogoType } from "integration/api/CompanyApi/models";

import CompanyListActions from "features/companies/store/actions";

import { TableRowProps } from "./models";
import {
  StatusStyled,
  TableRowStyled,
  ActionsStyled,
  ImageContainer,
  CompanyName,
  MultiRowStyled,
} from "./styles";
import { CompanySliceAction } from "features/companies/store/slice";

const TableRow: FC<TableRowProps> = ({ item, onDeleteCompany }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [logo, setLogo] = useState("");

  const status = useMemo(() => {
    const capitalizeFirstLetter =
      item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase();
    return capitalizeFirstLetter.replace("_", " ");
  }, [item.status]);

  const investmentAmount = item.investmentAmount
    ? formatTotal(item.investmentAmount, item.investmentCurrency)
    : "";
  const totalFmvValue = item.investmentAnalytic?.totalFmvValue
    ? formatTotal(
        item.investmentAnalytic.totalFmvValue,
        item.investmentAnalytic?.currency
      )
    : "";
  const totalRealizedProfit = item.investmentAnalytic?.totalRealizedProfit
    ? formatTotal(
        item.investmentAnalytic.totalRealizedProfit,
        item.investmentAnalytic?.currency
      )
    : "";
  const date = item.investmentAnalytic?.fmvChangeDate
    ? formatDate(item.investmentAnalytic?.fmvChangeDate)
    : "";
  const multiple = item.investmentAnalytic?.multiple || 0;

  useEffect(() => {
    if (item.logoFileId) {
      dispatch(
        CompanyListActions.getCompanyLogo({
          id: item.id,
          logoId: item.logoFileId,
          logoType: LogoType.THUMBNAIL,
        })
      ).then((response) => {
        const data = response.payload as Blob;
        const logoBlob = new Blob([data], {
          type: data.type as string,
        });
        const logoUrl = URL.createObjectURL(logoBlob);
        setLogo(logoUrl);
      });
    }
  }, [item.logoFileId]);

  const onPreviewDealsList = () => {
    navigate(
      `${PathNames.companies}/${item.id}/deals`,
      getNavigationState(SearchParamsRoutesNames.companyDeals)
    );
  };

  const onEdit = () => {
    navigate(
      `${PathNames.companies}/${item.id}`,
      getNavigationState(SearchParamsRoutesNames.company)
    );
    dispatch(CompanySliceAction.resetCompanies());
  };

  const onDelete = () => {
    onDeleteCompany(item);
  };

  return (
    <TableRowStyled>
      <td>
        {logo && (
          <ImageContainer>
            <img src={logo} alt="portfolio-logo" />
          </ImageContainer>
        )}
      </td>
      <td>
        <CompanyName>{item.companyName}</CompanyName>
      </td>
      <td>{item.yearFounded}</td>
      <td>
        <StatusStyled status={item.status}>{status}</StatusStyled>
      </td>
      <td>{investmentAmount}</td>
      <td>
        <MultiRowStyled>
          <div>
            <h2>Current value (FMV)</h2>
            <p>{totalFmvValue}</p>
          </div>
          <div>
            <h2>Total realized profit</h2>
            <p>{totalRealizedProfit}</p>
          </div>
          <div>
            <h2>Date of last FMV change</h2>
            <p>{date}</p>
          </div>
        </MultiRowStyled>
      </td>
      <td>{multiple}</td>
      <td>
        <ActionsStyled>
          <button type="button" onClick={onPreviewDealsList}>
            <AssignmentTurnedInOutlined />
          </button>
          <button type="button" onClick={onEdit}>
            <EditOutlined />
          </button>
          <button type="button" onClick={onDelete}>
            <CloseOutlined />
          </button>
        </ActionsStyled>
      </td>
    </TableRowStyled>
  );
};

export default TableRow;
