import * as yup from "yup";

import { CommonErrors } from "application/helpers/validations";

export const getEditAmountSchema = (hideCurrency?: boolean) =>
  yup.object().shape({
    amount: yup
      .number()
      .typeError(CommonErrors.number)
      .required(CommonErrors.requiredField),
    ...(!hideCurrency && {
      currency: yup.string(),
    }),
  });
