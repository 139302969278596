import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import useOnClickOutside from "application/hooks/useOnClickOutside";
import { ArrowDropDown } from "application/assets/ArrowDropDown";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import PipedriveActions from "application/store/Pipedrive/ActionCreators";

import { PipedriveSourcesDropdownProps } from "./model";
import {
  DropdownStyled,
  TextField,
  DropdownList,
  ListItem,
  Input,
} from "./styles";

const PipedriveSourcesDropdown: FC<PipedriveSourcesDropdownProps> = ({
  value,
  onChange,
  label,
  placeholder,
  helperText,
}) => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const { pipedriveSources } = useAppSelector((state) => state.pipedrive);
  const [showList, setShowList] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useOnClickOutside(ref, () => setShowList(false));

  useEffect(() => {
    dispatch(PipedriveActions.getPipedriveSources());
  }, []);

  const filteredSourcesList = useMemo(() => {
    return pipedriveSources.filter((source) =>
      source.label.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [searchValue, pipedriveSources]);

  const selectedSourceName = useMemo(() => {
    return pipedriveSources.find((source) => source.id === value)?.label;
  }, [value, pipedriveSources]);

  const handleSelectSource = (code: number) => {
    setShowList(false);
    setSearchValue("");
    onChange(code);
  };

  return (
    <DropdownStyled ref={ref}>
      {label && <h3>{label}</h3>}
      <TextField
        isActive={showList}
        filled={!!value}
        onClick={() => setShowList((prev) => !prev)}
      >
        {helperText && (
          <label>
            {helperText}
            <div />
          </label>
        )}
        <p>{selectedSourceName || placeholder}</p>
        <ArrowDropDown />
      </TextField>
      <DropdownList opened={showList}>
        <div>
          <Input
            placeholder="Search source"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onClick={() => setShowList(true)}
          />
          {filteredSourcesList?.length ? (
            filteredSourcesList.map((source) => (
              <ListItem
                key={source.id}
                selected={source.id === value}
                onClick={() => handleSelectSource(source.id)}
              >
                <h2>{source.label}</h2>
              </ListItem>
            ))
          ) : (
            <span>No results</span>
          )}
        </div>
      </DropdownList>
    </DropdownStyled>
  );
};

export default PipedriveSourcesDropdown;
