import React, { FC, useState } from "react";

import { Typography } from "@mui/material";

import { CurrencySymbolSelect, InputWithText } from "application/components";
import { colors } from "application/theme/palette";

import { HeaderProps } from "./model";
import { HeaderStyled, Container } from "./styles";

const Header: FC<HeaderProps> = ({
  searchValue,
  setSearchValue,
  currency,
  setCurrency,
}) => {
  const [searchItem, setSearchItem] = useState(searchValue);

  const handleSearch = (value: string) => {
    setSearchItem(value);
    setSearchValue(value.length > 2 ? value : "");
  };

  return (
    <HeaderStyled>
      <Typography variant="h1">Portfolio</Typography>
      <Container>
        <CurrencySymbolSelect
          color={colors.white}
          onChange={setCurrency}
          selected={currency}
        />
        <InputWithText
          placeholder="Search..."
          onChange={handleSearch}
          value={searchItem}
        />
      </Container>
    </HeaderStyled>
  );
};

export default Header;
