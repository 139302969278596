import React, { FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import PrimaryButton from "application/components/PrimaryButton";
import SuccessPopUp from "application/containers/SuccessPopUp";

import AddInvestor from "../AddInvestor";
import { HeaderStyled, Title, AddIcon, Container, BackButton } from "./styles";
import DealInvestorsActions from "features/deal-investors/store/actions";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { AddInvestorForm } from "../AddInvestor/models";
import { HeaderProps } from "./model";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { useAppSelector } from "application/hooks/useAppSelector";
import { DealStatus } from "integration/api/DealsApi/models";

const Header: FC<HeaderProps> = ({ getInvestors }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dealStatus, dealImported } = useAppSelector(
    (state) => state.dealInvestors
  );
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const canAddInvestor = dealStatus === DealStatus.ACTIVE && !dealImported;
  const hideBackButton = window?.history?.length <= 2;

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const goBack = () => {
    const path = (window?.history?.state?.usr?.dealInvestors || -1) as string;
    navigate(path, { state: window?.history?.state?.usr });
  };

  const onSubmit = async (data: AddInvestorForm) => {
    const { investorId, investmentAmount } = data;
    setIsLoading(true);
    await dispatch(
      DealInvestorsActions.addDealInvestor({
        dealId: Number(id),
        investorId: Number(investorId),
        investmentAmount,
      })
    ).then((res) => {
      isResponseFulfilled(res, () => {
        getInvestors();
        closeModal();
        setShowSuccessModal(true);
      });
    });
    setIsLoading(false);
  };

  return (
    <>
      <HeaderStyled>
        {!hideBackButton && (
          <BackButton onClick={goBack}>
            <ArrowBackIcon />
            Back to deal
          </BackButton>
        )}
        <Container>
          <Title>Commitments</Title>
          {canAddInvestor && (
            <PrimaryButton
              loading={false}
              onClick={openModal}
              title="Add investor"
              leftIcon={<AddIcon />}
            />
          )}
        </Container>
      </HeaderStyled>
      {showModal && (
        <AddInvestor
          isLoading={isLoading}
          onClose={closeModal}
          onSubmit={onSubmit}
        />
      )}
      {showSuccessModal && (
        <SuccessPopUp
          title={"Commitment successfully added for the investor"}
          onSubmit={closeSuccessModal}
        />
      )}
    </>
  );
};

export default Header;
