import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { PathNames } from "application/routes";
import DealsActions from "features/deals/store/actions";
import DealActions from "features/deal-profile/store/actions";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { formatDealError } from "features/deal-profile/helpers/formatDealError";

import Loader from "application/components/Loader";
import {
  BackToPage,
  Button,
  ErrorValidationPopUp,
} from "application/components";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import SuccessPopUp from "application/containers/SuccessPopUp";
import LeavePagePopUp from "application/containers/LeavePagePopUp";

import { DealSliceAction } from "features/deal-profile/store/slice";
import compareObjectsChanges from "application/helpers/compareObjectsChanges";
import { DetectChangesSavedSliceActions } from "application/store/DetectChangesSaved/DetectChangesSavedSlice";
import { colors } from "application/theme/palette";

import TestEmailPopUp from "../TestEmailPopUp";
import { HeaderWrapper, LoaderContainer } from "./styles";

export const Header = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { hasUnsavedChanges } = useAppSelector(
    (state) => state.detectChangesSavedSlice
  );

  const { getAttachmentFile, updateDealById, deleteDeal } =
    useAppSelector(requestSelector);
  const {
    errors: errorsList,
    hasErrors,
    profile,
    initialProfile,
  } = useAppSelector((state) => state.dealPage);

  const isLoading =
    getAttachmentFile.loading || updateDealById.loading || deleteDeal.loading;

  const [errors, setErrors] = useState<string[]>([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const [showSaveSuccessModal, setShowSaveSuccessModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showSuccessEmailModal, setShowSuccessEmailModal] = useState(false);

  useEffect(() => {
    setErrors(formatDealError(errorsList.join(",")));
  }, [errorsList]);

  const handleSuccess = () => {
    setShowSaveSuccessModal(true);
  };

  const handleSaveChanges = async () => {
    if (id) {
      const res = await dispatch(DealActions.updateDealById()).then((res) => {
        isResponseFulfilled(res, () => {
          dispatch(DealActions.getDealById(Number(id)));
          handleSuccess();
        });
        return res;
      });
      return res.meta.requestStatus;
    }
  };

  const clearErrors = () => {
    dispatch(DealSliceAction.setHasErrors(false));
    setErrors([]);
  };

  const onDelete = () => {
    setShowDelete(true);
  };

  const onCloseDelete = () => {
    setShowDelete(false);
  };

  const onDeleteSuccess = () => {
    setShowDelete(false);
    setShowDeleteSuccessModal(true);
  };

  const onSubmitDelete = async () => {
    await dispatch(DealsActions.deleteDeal(Number(id))).then((res) => {
      isResponseFulfilled(res, onDeleteSuccess);
    });
  };

  const onSubmitSuccessDelete = () => {
    setShowDeleteSuccessModal(false);
    navigate(PathNames.deals);
  };

  const onSubmitSuccessSave = () => {
    setShowSaveSuccessModal(false);
  };

  const handleOpenEmailPopup = () => {
    if (hasUnsavedChanges) {
      dispatch(DetectChangesSavedSliceActions.setShowModal(true));
    }
    setShowEmailModal(true);
  };

  const handleCloseEmailPopup = () => {
    setShowEmailModal(false);
  };

  const handleCloseSuccessEmailPopup = () => {
    setShowSuccessEmailModal(false);
  };

  const onSendTestEmail = async ({ email }: { email: string }) => {
    await dispatch(DealActions.sendTestEmail({ id: Number(id), email })).then(
      (res) => {
        isResponseFulfilled(res, () => {
          handleCloseEmailPopup();
          setShowSuccessEmailModal(true);
        });
      }
    );
  };

  useEffect(() => {
    const isChanged = compareObjectsChanges(profile, initialProfile, true);
    dispatch(DetectChangesSavedSliceActions.setHasUnsavedChanges(isChanged));
  }, [profile, initialProfile]);

  return (
    <HeaderWrapper>
      {isLoading && (
        <LoaderContainer>
          <Loader color={colors.grey500} />
        </LoaderContainer>
      )}
      <BackToPage title="deals" backPath={`${PathNames.deals}`} />
      <Box display="flex">
        <Box mr={2.5}>
          <Button
            label="Save changes"
            type="reset"
            onClick={handleSaveChanges}
          />
        </Box>
        <Box mr={2.5}>
          <Button
            label="Send Test Email"
            type="button"
            onClick={handleOpenEmailPopup}
          />
        </Box>
        <Button label="Delete deal" red type="button" onClick={onDelete} />
      </Box>
      {showDelete && (
        <ConfirmPopUp
          title="Delete Deal"
          onClose={onCloseDelete}
          onSubmit={onSubmitDelete}
          description="You want to delete deal. Are you sure?"
        />
      )}
      {showDeleteSuccessModal && (
        <SuccessPopUp
          title="The deal is deleted"
          onSubmit={onSubmitSuccessDelete}
        />
      )}
      {showSaveSuccessModal && (
        <SuccessPopUp
          title="Deal successfully saved"
          onSubmit={onSubmitSuccessSave}
        />
      )}
      {hasErrors && (
        <ErrorValidationPopUp errors={errors} onPress={clearErrors} />
      )}
      {showEmailModal && (
        <TestEmailPopUp
          onClose={handleCloseEmailPopup}
          onSubmit={onSendTestEmail}
        />
      )}
      {showSuccessEmailModal && (
        <SuccessPopUp
          title="Test email was sent successfully"
          onSubmit={handleCloseSuccessEmailPopup}
        />
      )}
      <LeavePagePopUp onSubmit={handleSaveChanges} />
    </HeaderWrapper>
  );
};
