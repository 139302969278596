import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequestEnum } from "application/store/request/model";
import { OwnersParams } from "integration/api/OwnersApi/models";
import OwnersApi from "integration/api/OwnersApi";

class OwnersActions {
  static getOwners = createAsyncThunk(
    `owners/${RequestEnum.getOwners}`,
    async (params: OwnersParams, { rejectWithValue }) => {
      try {
        const { data } = await OwnersApi.getOwners(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static addOwner = createAsyncThunk(
    `owners/${RequestEnum.addOwner}`,
    async (pipedriveUserId: number, { rejectWithValue }) => {
      try {
        const { data } = await OwnersApi.addOwner(pipedriveUserId);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deleteOwner = createAsyncThunk(
    `owners/${RequestEnum.deleteOwner}`,
    async (id: number, { rejectWithValue }) => {
      try {
        const { data } = await OwnersApi.deleteOwner(id);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default OwnersActions;
