import styled from "@emotion/styled";
import { colors } from "application/theme/palette";
import { ConsentStatuses } from "integration/api/ConsentsApi/models";
import { StyledProps } from "./models";

export const getStatusColor = (status?: ConsentStatuses) => {
  switch (status) {
    case ConsentStatuses.PUBLISHED:
      return colors.green700;
    default:
      return colors.orange700;
  }
};

export const TableRowStyled = styled("tr")`
  height: 52px;

  td {
    box-sizing: border-box;
    height: 52px;
    padding: 10px 12px;
    vertical-align: middle;
    border-top: 1px solid ${colors.grey200};
    border-right: 1px solid ${colors.grey200};
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    font-style: normal;
    font-family: Unica77;
    letter-spacing: 0.07px;
    color: ${colors.grey900};
    box-sizing: border-box;

    &:first-of-type {
      border-left: 1px solid ${colors.grey200};
    }

    &:last-of-type {
      text-align: center;
    }
  }
`;

export const NameStyled = styled.p`
  width: 165px;
  word-wrap: break-word;
`;

export const StatusStyled = styled.p<StyledProps>`
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  font-style: normal;
  letter-spacing: 0.07px;
  font-family: "Unica77";
  color: ${({ status }) => getStatusColor(status)};
`;

export const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1.2px solid ${colors.grey200};
    background: ${colors.white};

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;

      path {
        fill: ${colors.grey700};
      }
    }
  }
`;
