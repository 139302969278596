import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequestEnum } from "application/store/request/model";
import { OwnersParams } from "integration/api/OwnersApi/models";
import ReferralsApi from "integration/api/ReferralsApi";
import {
  AddLeadOwnerRequest,
  UpdateLeadOwnerRequest,
} from "integration/api/ReferralsApi/models";

class ReferralsActions {
  static getReferrals = createAsyncThunk(
    `referrals/${RequestEnum.getReferrals}`,
    async (params: OwnersParams, { rejectWithValue }) => {
      try {
        const { data } = await ReferralsApi.getReferrals(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static addReferrals = createAsyncThunk(
    `referrals/${RequestEnum.addReferrals}`,
    async (data: AddLeadOwnerRequest, { rejectWithValue }) => {
      try {
        const { data: responseData } = await ReferralsApi.addReferrals(data);
        return responseData;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static editReferrals = createAsyncThunk(
    `referrals/${RequestEnum.editReferrals}`,
    async (params: UpdateLeadOwnerRequest, { rejectWithValue }) => {
      try {
        const { data } = await ReferralsApi.editReferrals(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static deleteReferrals = createAsyncThunk(
    `referrals/${RequestEnum.deleteReferrals}`,
    async (id: number, { rejectWithValue }) => {
      try {
        const { data } = await ReferralsApi.deleteReferrals(id);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default ReferralsActions;
