import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

interface TabStyledProps {
  isActive: boolean;
}

export const LoaderContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TabsSection = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 24px;
`;

export const TabStyled = styled.button<TabStyledProps>`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  position: relative;
  top: 1px;
  display: flex;
  align-items: center;
  padding: 9px 16px;
  border-bottom: 2px solid
    ${({ isActive }) => (isActive ? colors.blue700 : "transparent")};

  color: ${colors.grey900};
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;

  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-right: 6px;
  }
`;
