import React from "react";
import SubtitlesOutlinedIcon from "@mui/icons-material/SubtitlesOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

import { DocumentInfoDto } from "integration/api/UsersApi/models";

import { InfoItem } from "../UserInformation/styles";

interface Props {
  documentInfo: DocumentInfoDto[] | undefined;
}

const DocumentInfo = ({ documentInfo }: Props) => {
  return (
    <React.Fragment>
      {documentInfo?.map((item) => {
        return (
          <React.Fragment key={item.id + item.docType}>
            <InfoItem>
              <h3>
                <AssignmentOutlinedIcon />
                Type of document:
              </h3>
              <h2>{item.docType}</h2>
            </InfoItem>

            <InfoItem>
              <h3>
                <SubtitlesOutlinedIcon />
                Number of document:
              </h3>
              <h2>{item.docNumber}</h2>
            </InfoItem>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default DocumentInfo;
