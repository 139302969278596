import React, { FC, useMemo, useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import ReactCountryFlag from "react-country-flag";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";

import DocumentInfo from "../DocumentInfo";
import KYCStatusCard from "../KYCStatusCard";
import { formatDate } from "application/helpers/formatDate";
import { usersSelector } from "features/users/store/selectors";
import { useAppSelector } from "application/hooks/useAppSelector";
import { UserStatus, userStatuses } from "application/helpers/userStatuses";
import { ReactComponent as EditSvg } from "application/assets/icons/edit.svg";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { colors } from "application/theme/palette";

import { EditType } from "features/users/pages/UserDetails/types";
import EditModal from "../EditModal";

import {
  KYCItem,
  InfoItem,
  Container,
  CopyButton,
  IconStyled,
  KYCSection,
  CopyTooltip,
  CountryStyled,
  DetailSection,
  UserInfoSection,
  DetailInfoSection,
  UserStatusStyled,
  NameWrapper,
  GeneratePasswordButton,
} from "./styles";

const UserDetail: FC = () => {
  const { selected } = useAppSelector(usersSelector);
  const [isEmailCopied, setIsEmailCopied] = useState(false);
  const [isPhoneCopied, setIsPhoneCopied] = useState(false);
  const [edit, setEdit] = useState<EditType>(EditType.empty);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const showEditModal = !showErrorModal && !showSuccessModal && edit;

  const userStatus =
    userStatuses.find((status) => status.value === selected?.userStatus)
      ?.label || UserStatus.ACTIVE;

  const successModalTitle = useMemo(() => {
    if (edit === EditType.password) {
      return `New password has been sent to the user's email.`;
    }
    const editName = edit.charAt(0).toUpperCase() + edit.slice(1);
    return `${editName} was successfully updated`;
  }, [edit]);

  const copyTextToClipboard = async (text = "") => {
    return await navigator.clipboard.writeText(text);
  };

  const handleCopyEmail = () => {
    copyTextToClipboard(selected?.email).then(() => {
      setIsEmailCopied(true);
      setTimeout(() => {
        setIsEmailCopied(false);
      }, 1500);
    });
  };

  const handleCopyPhone = () => {
    copyTextToClipboard(selected?.phone).then(() => {
      setIsPhoneCopied(true);
      setTimeout(() => {
        setIsPhoneCopied(false);
      }, 1500);
    });
  };

  const handleEdit = (editItem: EditType) => {
    setEdit(editItem);
  };

  const closeEdit = () => {
    setEdit(EditType.empty);
  };

  const closeSuccessModal = () => {
    closeEdit();
    setShowSuccessModal(false);
  };

  const closeErrorModal = () => {
    closeEdit();
    setShowErrorModal(false);
  };

  return (
    <Container>
      <DetailSection>
        <DetailInfoSection>
          <IconStyled>
            {selected?.firstName?.charAt(0)} {selected?.lastName?.charAt(0)}
          </IconStyled>
          <div>
            <NameWrapper>
              <h1>
                {selected?.firstName} {selected?.lastName}
              </h1>
              <CopyButton
                type="button"
                onClick={() => handleEdit(EditType.name)}
              >
                <EditSvg />
              </CopyButton>
            </NameWrapper>
            <CountryStyled>
              <ReactCountryFlag
                countryCode={selected?.country?.isoCode || ""}
              />
              <p>{selected?.country?.name}</p>
            </CountryStyled>
          </div>
        </DetailInfoSection>
        <KYCSection>
          {selected && (
            <KYCItem>
              <p>Status:</p>
              <UserStatusStyled status={selected.userStatus}>
                {userStatus}
              </UserStatusStyled>
            </KYCItem>
          )}
          <KYCItem>
            <p>KYC status:</p>
            <KYCStatusCard status={selected?.kycStatus} />
          </KYCItem>
          <KYCItem>
            <p>KYC status date:</p>
            <h2>{formatDate(selected?.kycStatusDate)}</h2>
          </KYCItem>
        </KYCSection>
      </DetailSection>
      <UserInfoSection>
        <InfoItem>
          <h3>
            <MailOutlineIcon />
            Email:
          </h3>
          <span>{selected?.email}</span>
          <CopyButton type="button" onClick={() => handleEdit(EditType.email)}>
            <EditSvg />
          </CopyButton>
          <CopyButton type="button" onClick={handleCopyEmail}>
            <LinkIcon />
            <CopyTooltip className="email-tooltip">
              <ArrowLeftIcon />
              Copy
            </CopyTooltip>
          </CopyButton>
          {isEmailCopied && <p>Copied!</p>}
        </InfoItem>
        <InfoItem>
          <h3>
            <LocalPhoneOutlinedIcon />
            Phone number:
          </h3>
          <h2>{selected?.phone}</h2>
          <CopyButton type="button" onClick={() => handleEdit(EditType.phone)}>
            <EditSvg />
          </CopyButton>
          <CopyButton type="button" onClick={handleCopyPhone}>
            <LinkIcon />
            <CopyTooltip className="phone-tooltip">
              <ArrowLeftIcon />
              Copy
            </CopyTooltip>
          </CopyButton>
          {isPhoneCopied && <p>Copied!</p>}
        </InfoItem>

        <DocumentInfo documentInfo={selected?.documentInfo} />
        <InfoItem>
          <h3>
            <VpnKeyOutlinedIcon />
            Password:
          </h3>
          <GeneratePasswordButton
            type="button"
            onClick={() => handleEdit(EditType.password)}
          >
            Generate new password
          </GeneratePasswordButton>
        </InfoItem>
      </UserInfoSection>
      {showEditModal && (
        <EditModal
          editType={edit}
          onClose={closeEdit}
          setShowSuccessModal={setShowSuccessModal}
          setShowErrorModal={setShowErrorModal}
        />
      )}
      {showSuccessModal && (
        <SuccessPopUp title={successModalTitle} onSubmit={closeSuccessModal} />
      )}
      {showErrorModal && (
        <SuccessPopUp
          title={"Failed to generate new password. Try again."}
          onSubmit={closeErrorModal}
          iconColor={colors.red700}
        />
      )}
    </Container>
  );
};

export default UserDetail;
