import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import BasicPopUp from "application/containers/BasicPopUp";
import CurrencySelect from "application/components/CurrencySelect";
import { Currency } from "integration/api/DealsApi/models";
import { commitmentsPopUpSchema } from "features/portfolio-investors/helpers/validations";

import { CommitmentsPopUpForm, CommitmentsPopUpProps } from "./model";
import { TextFieldStyled } from "./styles";

const CommitmentsPopUp: FC<CommitmentsPopUpProps> = ({
  onSubmit,
  onClose,
  isLoading,
  amount,
  currency,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CommitmentsPopUpForm>({
    defaultValues: { investmentAmount: amount, currency },
    resolver: yupResolver(commitmentsPopUpSchema),
  });

  const handleChangeCurrency = (currency: Currency) => {
    setValue("currency", currency, { shouldValidate: true });
  };

  return (
    <BasicPopUp
      title="Your commitments confirm"
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
    >
      <form>
        <TextFieldStyled
          mb={20}
          id="investmentAmount"
          fullWidth
          size="small"
          type="number"
          color="secondary"
          label="Set index"
          placeholder="Enter investment amount"
          filled={watch("investmentAmount")}
          error={!!errors.investmentAmount?.message}
          {...register("investmentAmount")}
        />
        <CurrencySelect
          maxHeight={300}
          selected={watch("currency")}
          onChange={handleChangeCurrency}
          placeholder="Choose currency"
          error={!!errors.currency?.message}
        />
      </form>
    </BasicPopUp>
  );
};

export default CommitmentsPopUp;
