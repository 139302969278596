import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CallMade } from "@mui/icons-material";

import { PathNames } from "application/routes";
import { formatDate } from "application/helpers/formatDate";
import { formatTotal } from "application/helpers/formatTotal";
import { ReactComponent as MoneysSvg } from "application/assets/icons/moneys.svg";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { SearchParamsRoutesNames } from "application/routes/constants";
import { getNavigationState } from "application/helpers/navigationState";

import { LogoType } from "integration/api/DealsApi/models";

import DealsActions from "features/deals/store/actions";

import { PortfolioTableRowProps } from "./models";
import { TableRowStyled, ActionsStyled, Total, ImageContainer } from "./styles";

const PortfolioTableRow: FC<PortfolioTableRowProps> = ({ item }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [logo, setLogo] = useState("");

  const date = formatDate(item.creationDate);
  const total = formatTotal(item.totalFundingGoal, item.investmentCurrency);

  useEffect(() => {
    if (item.logoFileId) {
      dispatch(
        DealsActions.getDealLogo({
          id: item.id,
          logoId: item.logoFileId,
          logoType: LogoType.THUMBNAIL,
        })
      ).then((response) => {
        const data = response.payload as Blob;
        const logoBlob = new Blob([data], {
          type: data.type as string,
        });
        const logoUrl = URL.createObjectURL(logoBlob);
        setLogo(logoUrl);
      });
    }
  }, [item.logoFileId]);

  const onPreview = () => {
    navigate(
      `${PathNames.portfolio}/${item.id}/investors`,
      getNavigationState(SearchParamsRoutesNames.portfolioInvestors)
    );
  };

  return (
    <TableRowStyled>
      <td>
        {logo && (
          <ImageContainer>
            <img src={logo} alt="table-row" />
          </ImageContainer>
        )}
      </td>
      <td>{item.title}</td>
      <td>{item.businessId}</td>
      <td>{date}</td>
      <td>
        <Total>
          <MoneysSvg />
          <p>{total}</p>
        </Total>
      </td>
      <td>
        <ActionsStyled>
          <button type="button" onClick={onPreview}>
            <CallMade />
          </button>
        </ActionsStyled>
      </td>
    </TableRowStyled>
  );
};

export default PortfolioTableRow;
