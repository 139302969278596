import React, { FC, memo, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { ErrorOutline } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { v4 } from "uuid";

import { TeamType } from "integration/api/DealsApi/models";

import theme from "application/theme";
import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import BasicPopUp from "application/containers/BasicPopUp";

import { setOverviewTeam } from "features/deal-profile/store/slice";
import { dealPageSelector } from "features/deal-profile/store/selectors";
import { OverviewSectionUploads } from "features/deal-profile/pages/OverviewSection/types";
import TeamMemberAddModal from "features/deal-profile/components/TeamMemberAddModal";
import { DragAndDropArea } from "features/deal-profile/components/DragAndDropArea";

import { Item } from "../Videos";

import { TeamMembersContainerProps } from "./model";

export const TeamMembersContainer: FC<TeamMembersContainerProps> = memo(
  ({ hasError, handleClearTeamError }) => {
    const { overview } = useAppSelector(dealPageSelector).profile;
    const dispatch = useAppDispatch();
    const [newTeamMember, setNewTeamMember] = useState<TeamType>();
    const [addTeamMemberModal, setAddTeamMemberModal] = useState(false);
    const [removeTeamMemberModal, setRemoveTeamMemberModal] = useState(false);
    const [selectedTeamMember, setSelectedTeamMember] = useState<Item>();
    const [isEditing, setIsEditing] = useState(false);

    const teamMembers = useMemo(() => {
      const teamMembersArray = overview.team
        .map((e) => {
          return {
            id: e.id,
            url: e.linkedinUrl,
            firstName: e.firstName,
            lastName: e.lastName,
            title: e.title,
            ordinal: e.ordinality,
            imageUrl: e.imageUrl,
            imageName: e.imageName,
            isResponsibleForQa: !!e.isResponsibleForQa,
            base64Image: e.base64Image || "",
            imageDeleted: e.imageDeleted,
          };
        })
        .sort((a, b) => {
          return (a.ordinal || 0) - (b.ordinal || 0);
        });
      return teamMembersArray;
    }, [overview.team]);

    const setTeamMembersInStore = (teamMembers: Item[]) => {
      const team: TeamType[] = teamMembers.map((e, idx) => {
        return {
          id: e.id,
          ordinality: idx + 1,
          firstName: e.firstName,
          lastName: e.lastName,
          title: e.title,
          linkedinUrl: e.url,
          imageUrl: e.imageUrl,
          imageName: e.imageName,
          isResponsibleForQa: !!e.isResponsibleForQa,
          base64Image: e.base64Image || "",
          imageDeleted: e.imageDeleted,
        };
      });
      dispatch(setOverviewTeam(team));
    };

    const addTeamMember = () => {
      dispatch(
        setOverviewTeam([
          ...overview.team,
          {
            id: newTeamMember?.id,
            linkedinUrl: newTeamMember?.linkedinUrl,
            ordinality: overview.team.length + 1,
            title: newTeamMember?.title,
            firstName: newTeamMember?.firstName,
            lastName: newTeamMember?.lastName,
            imageUrl: newTeamMember?.imageUrl,
            imageName: newTeamMember?.imageName,
            isResponsibleForQa: newTeamMember?.isResponsibleForQa,
            base64Image: newTeamMember?.base64Image || "",
            imageDeleted: newTeamMember?.imageDeleted,
          },
        ])
      );
      setNewTeamMember(undefined);
      setAddTeamMemberModal(false);
      handleClearTeamError();
    };

    const handleDeleteTeamMember = (id: number | string) => {
      const teamMemberToRemove = overview.team.filter((e) => e.id === id)[0];
      setSelectedTeamMember({
        ...teamMemberToRemove,
        base64Image: teamMemberToRemove?.base64Image || "",
      });
      setRemoveTeamMemberModal(true);
    };

    const closeDeleteModal = () => {
      setRemoveTeamMemberModal(false);
      setSelectedTeamMember(undefined);
    };

    const submitDeletingTeamMember = () => {
      const teamMembersLefted = overview.team.filter(
        (e) => e.id !== selectedTeamMember?.id
      );
      dispatch(setOverviewTeam(teamMembersLefted));
      setRemoveTeamMemberModal(false);
      setSelectedTeamMember(undefined);
    };

    const setTeamMemberFirstName = (value: string) => {
      if (isEditing) {
        const changedItem = { ...selectedTeamMember, firstName: value } as Item;
        setSelectedTeamMember(changedItem);
      } else {
        setNewTeamMember({
          ...newTeamMember,
          firstName: value,
          ordinality: teamMembers.length + 1,
          id: selectedTeamMember?.id || v4(),
        });
      }
    };

    const setTeamMemberLastName = (value: string) => {
      if (isEditing) {
        const changedItem = { ...selectedTeamMember, lastName: value } as Item;
        setSelectedTeamMember(changedItem);
      } else {
        setNewTeamMember({
          ...newTeamMember,
          lastName: value,
          ordinality: teamMembers.length + 1,
          id: selectedTeamMember?.id || v4(),
        });
      }
    };

    const setTeamMemberPosition = (value: string) => {
      if (isEditing) {
        const changedItem = { ...selectedTeamMember, title: value } as Item;
        setSelectedTeamMember(changedItem);
      } else {
        setNewTeamMember({
          ...newTeamMember,
          title: value,
          ordinality: teamMembers.length + 1,
          id: selectedTeamMember?.id || v4(),
        });
      }
    };

    const setTeamMemberLinkedin = (value: string) => {
      if (isEditing) {
        const changedItem = {
          ...selectedTeamMember,
          linkedinUrl: value,
        } as Item;
        setSelectedTeamMember(changedItem);
      } else {
        setNewTeamMember({
          ...newTeamMember,
          ordinality: teamMembers.length + 1,
          id: selectedTeamMember?.id || v4(),
          linkedinUrl: value,
        });
      }
    };

    const setTeamMemberResponsible = (value: boolean) => {
      const changedItem = {
        ...selectedTeamMember,
        isResponsibleForQa: value,
      } as Item;
      setSelectedTeamMember(changedItem);
      if (!isEditing) {
        setNewTeamMember({
          ...newTeamMember,
          isResponsibleForQa: value,
          ordinality: teamMembers.length + 1,
          id: selectedTeamMember?.id || v4(),
        });
      }
    };

    const setTeamMemberBase64Image = (value: string) => {
      if (isEditing) {
        const changedItem = {
          ...selectedTeamMember,
          base64Image: value,
          imageDeleted:
            !!selectedTeamMember?.imageUrl || selectedTeamMember?.imageDeleted,
        } as Item;
        setSelectedTeamMember(changedItem);
      } else {
        setNewTeamMember({
          ...newTeamMember,
          base64Image: value,
          ordinality: teamMembers.length + 1,
          id: selectedTeamMember?.id || v4(),
        });
      }
    };

    const handleDeleteImage = (value: boolean) => {
      if (isEditing) {
        const changedItem = {
          ...selectedTeamMember,
          imageDeleted: value,
          base64Image: "",
        } as Item;
        setSelectedTeamMember(changedItem);
      }
    };

    const handleEditTeamMember = (id: any) => {
      const teamMemberToEdit = overview.team.filter((e) => e.id === id)[0];
      setSelectedTeamMember({
        ...teamMemberToEdit,
        base64Image: teamMemberToEdit.base64Image || "",
      });
      setAddTeamMemberModal(true);
      setIsEditing(true);
    };

    const saveEditing = () => {
      const saveTeamMembersWithchanges = overview.team.map((e) => {
        if (e.id === selectedTeamMember?.id) {
          return selectedTeamMember;
        } else {
          return {
            ...e,
            isResponsibleForQa: !!selectedTeamMember?.isResponsibleForQa
              ? false
              : e.isResponsibleForQa,
          };
        }
      });
      dispatch(setOverviewTeam(saveTeamMembersWithchanges));
      handleClearTeamError();
      handleClose();
    };

    const handleClose = () => {
      setAddTeamMemberModal(false);
      setSelectedTeamMember(undefined);
      setIsEditing(false);
    };

    return (
      <Box>
        <DragAndDropArea
          header={OverviewSectionUploads.team}
          cards={teamMembers}
          setCards={setTeamMembersInStore}
          addCard={() => setAddTeamMemberModal(true)}
          removeCard={handleDeleteTeamMember}
          editCard={handleEditTeamMember}
          placeholder="Add team member"
          hasError={hasError}
        />
        {addTeamMemberModal && (
          <TeamMemberAddModal
            handleClose={handleClose}
            onSubmit={isEditing ? saveEditing : addTeamMember}
            selectedTeamMember={selectedTeamMember}
            setTeamMemberFirstName={setTeamMemberFirstName}
            setTeamMemberLastName={setTeamMemberLastName}
            setTeamMemberPosition={setTeamMemberPosition}
            setTeamMemberLinkedin={setTeamMemberLinkedin}
            setTeamMemberResponsible={setTeamMemberResponsible}
            setTeamMemberBase64Image={setTeamMemberBase64Image}
            handleDeleteImage={handleDeleteImage}
          />
        )}

        {removeTeamMemberModal && (
          <BasicPopUp
            title="Delete team member"
            onClose={closeDeleteModal}
            onSubmit={submitDeletingTeamMember}
          >
            <Box display="flex" alignItems="center">
              <Box mr={1.5}>
                <ErrorOutline />
              </Box>
              <Typography variant="body2" color={theme.palette.common.grey900}>
                You want to delete team member
              </Typography>
            </Box>
          </BasicPopUp>
        )}
      </Box>
    );
  }
);
