import React, { FC } from "react";
import { Bounce, ToastContainer, toast } from "react-toastify";

const ErrorToast: FC = () => {
  return (
    <ToastContainer
      position={"top-right"}
      autoClose={3000}
      newestOnTop={true}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss={true}
      draggable={true}
      theme={"light"}
      transition={Bounce}
    />
  );
};

export default ErrorToast;
