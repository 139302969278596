import React, { FC, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { authService } from "common";
import { loginAllTabs, logoutAllTabs } from "common/auth/authService";
import { PathNames } from "./constants";
import { authRouts, publicRouts } from "./routes";
import NoInternetConnectionPage from "application/pages/NoInternetConnection";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { getProfile } from "application/store/Auth/ActionCreators";
import { useAppSelector } from "application/hooks/useAppSelector";
import ErrorToast from "application/components/ErrorToast";

import "react-toastify/dist/ReactToastify.css";

export const AppRouter: FC = () => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector((state) => state.auth);
  const isAuth = authService.getAccessToken();
  const [online, setOnline] = useState(true);

  useEffect(() => {
    window.addEventListener("online", () => setOnline(true));
    window.addEventListener("offline", () => setOnline(false));

    return () => {
      window.removeEventListener("online", () => setOnline(true));
      window.removeEventListener("offline", () => setOnline(false));
    };
  });

  useEffect(() => {
    if (!profile && isAuth) {
      dispatch(getProfile());
    }
  }, [profile, isAuth]);

  useEffect(() => {
    logoutAllTabs();
    loginAllTabs();
  }, []);

  return (
    <>
      {online && (
        <BrowserRouter>
          <Routes>
            {isAuth && (
              <Route
                path="/login"
                element={<Navigate to={PathNames.deals} />}
              />
            )}
            {isAuth
              ? authRouts.map(({ path, component }) => (
                  <Route path={path} element={component} key={path} />
                ))
              : publicRouts.map(({ path, component }) => (
                  <Route path={path} element={component} key={path} />
                ))}
            {!isAuth ? (
              <Route path="/*" element={<Navigate to={PathNames.login} />} />
            ) : (
              <Route path="/*" element={<Navigate to={PathNames.error} />} />
            )}
          </Routes>
        </BrowserRouter>
      )}
      <NoInternetConnectionPage isNoInternetConnection={!online} />
      <ErrorToast />
    </>
  );
};
