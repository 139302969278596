import { Box } from "@mui/system";
import React, { FC, useState } from "react";
import { ClickAwayListener, Typography } from "@mui/material";

import theme from "application/theme";
import { ArrowDropUp } from "application/assets/ArrowDropUp";
import { ArrowDropDown } from "application/assets/ArrowDropDown";
import { RatioStatusIcon } from "application/assets/RationStatusIcon";

import { ConsentStatuses } from "integration/api/ConsentsApi/models";

import { consentsStatuses } from "features/consents/helpers/consentsStatuses";
import { colorHandler } from "features/consents/helpers/colorHandler";

import { StatusSelectProps } from "./models";
import {
  TextWrapper,
  IconWrapper,
  DropdownItem,
  DropdownStyle,
  SelectContainer,
  CurrencySelectButton,
} from "./styles";

const consentStatusesList = consentsStatuses.filter(
  (item) => item.value !== "ALL"
);

const StatusSelect: FC<StatusSelectProps> = ({ selected, onChange }) => {
  const [open, setOpen] = useState(false);

  const toggleSelect = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (value: ConsentStatuses) => {
    onChange(value);
    handleClose();
  };

  const label = consentStatusesList.filter((e) => e.value === selected)[0]
    ?.label;

  const currentColor = colorHandler(selected);

  return (
    <Box>
      <Typography
        variant="subtitle1"
        color={theme.palette.common.grey500}
        mb={0.5}
      >
        Status:
      </Typography>
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <SelectContainer>
          <CurrencySelectButton
            className={"status-select"}
            onClick={toggleSelect}
          >
            <TextWrapper
              bgcolor={currentColor.bgColor}
              borderColor={currentColor.borderColor}
            >
              <Typography variant="subtitle2" color={currentColor.borderColor}>
                {label}
              </Typography>
            </TextWrapper>

            <IconWrapper
              bgcolor={currentColor.bgColor}
              borderColor={currentColor.borderColor}
            >
              {open ? (
                <ArrowDropUp color={theme.palette.common.grey500} />
              ) : (
                <ArrowDropDown color={theme.palette.common.grey500} />
              )}
            </IconWrapper>
          </CurrencySelectButton>
          <DropdownStyle visible={open ? 1 : 0}>
            <Box px={1.5} bgcolor={theme.palette.common.white}>
              {consentStatusesList?.map((status) => (
                <DropdownItem
                  onClick={() => handleChange(status.value as ConsentStatuses)}
                  active={status.value === selected ? 1 : 0}
                  key={status.value}
                >
                  <Box mr={1.5}>
                    <RatioStatusIcon
                      color={
                        colorHandler(status.value as ConsentStatuses)
                          .borderColor
                      }
                    />
                  </Box>
                  {status.label}
                </DropdownItem>
              ))}
            </Box>
          </DropdownStyle>
        </SelectContainer>
      </ClickAwayListener>
    </Box>
  );
};

export default StatusSelect;
