import styled from "@emotion/styled";
import { colors } from "application/theme/palette";
import { CompanyStatus } from "integration/api/CompanyApi/models";
import { StyledProps } from "./models";

export const getStatusColor = (status?: CompanyStatus) => {
  switch (status) {
    case CompanyStatus.ACTIVE:
      return colors.green700;
    case CompanyStatus.ARCHIVED:
      return colors.grey700;
    case CompanyStatus.RIGHT_OFF:
      return colors.blue900;
    case CompanyStatus.EXIT:
      return colors.red700;
    default:
      return colors.orange700;
  }
};

export const TableRowStyled = styled("tr")`
  height: 52px;

  td {
    box-sizing: border-box;
    height: 52px;
    padding: 10px 12px;
    vertical-align: middle;
    border-top: 1px solid ${colors.grey200};
    border-right: 1px solid ${colors.grey200};
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    font-style: normal;
    font-family: Unica77;
    letter-spacing: 0.07px;
    color: ${colors.grey900};
    box-sizing: border-box;

    &:first-of-type {
      border-left: 1px solid ${colors.grey200};
    }

    &:last-of-type {
      text-align: center;
    }
  }
`;

export const CompanyName = styled.p`
  width: 165px;
  word-wrap: break-word;
`;

export const StatusStyled = styled.p<StyledProps>`
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  font-style: normal;
  letter-spacing: 0.07px;
  font-family: "Unica77";
  color: ${({ status }) => getStatusColor(status)};
`;

export const ActionsStyled = styled.div`
  display: flex;
  gap: 20px;
  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1.2px solid ${colors.grey200};
    background: ${colors.white};

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;

      path {
        fill: ${colors.grey700};
      }
    }
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 1px solid ${colors.grey100};
  }
`;

export const MultiRowStyled = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    h2 {
      color: ${colors.grey700};
      font-family: Unica77;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.07px;
      width: 172px;
    }

    p {
      color: ${colors.grey900};
      font-family: Unica77;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: 0.07px;
    }
  }
`;
