import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

export const Container = styled.div`
  width: 364px;
  height: 44px;
  border-radius: 8px;
  background: ${colors.white};
  border: 1px solid ${colors.grey200};
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;

  p {
    color: ${colors.grey900};
    font-family: "Unica77";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.08px;
  }
`;
