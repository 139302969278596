import React, { FC, useState } from "react";
import { isValidPhoneNumber } from "libphonenumber-js";

import ConfirmPopUp from "application/containers/ConfirmPopUp";
import { TextFieldStyled } from "application/components";
import BasicPopUp from "application/containers/BasicPopUp";
import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { emailValidation } from "application/constants/emailValidation";

import { usersSelector } from "features/users/store/selectors";
import UsersActions from "features/users/store/actions";
import { UsersSliceAction } from "features/users/store/slice";
import { EditType } from "features/users/pages/UserDetails/types";

import { EditModalProps } from "./models";
import { ErrorStyled, TipStyled } from "./styles";

const EditModal: FC<EditModalProps> = ({
  editType,
  onClose,
  setShowSuccessModal,
  setShowErrorModal,
}) => {
  const dispatch = useAppDispatch();
  const { selected } = useAppSelector(usersSelector);

  const [editedEmail, setEmail] = useState(selected?.email);
  const [editedPhone, setPhone] = useState(selected?.phone);
  const [editedName, setName] = useState(selected?.firstName);
  const [editedLastName, setLastName] = useState(selected?.lastName);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailError, setEmailError] = useState("");

  const isEditEmail = editType === EditType.email;
  const isEditPhone = editType === EditType.phone;
  const isEditName = editType === EditType.name;
  const isEditPassword = editType === EditType.password;

  const handleChangePhone = (value: string) => {
    const isValidNumber = isValidPhoneNumber(String(value));
    setIsPhoneValid(isValidNumber);
    setPhone(value);
  };

  const handleChangeEmail = (value: string) => {
    const isValidEmail = !!value.match(emailValidation);
    setIsEmailValid(isValidEmail);
    setEmail(value);
    setEmailError("");
  };

  const updateUserName = async () => {
    if (selected) {
      const res = await dispatch(
        UsersActions.updateUserName({
          id: selected.id,
          firstName: editedName || "",
          lastName: editedLastName || "",
        })
      );

      isResponseFulfilled(res, () => {
        dispatch(
          UsersSliceAction.setSelected({
            ...selected,
            firstName: editedName || "",
            lastName: editedLastName || "",
          })
        );
        setShowSuccessModal(true);
      });
    }
  };

  const updateUserPhone = async () => {
    if (selected) {
      const res = await dispatch(
        UsersActions.updateUserPhone({
          id: selected.id,
          phoneNumber: editedPhone || "",
        })
      );
      isResponseFulfilled(res, () => {
        dispatch(
          UsersSliceAction.setSelected({
            ...selected,
            phone: editedPhone || "",
          })
        );
        setShowSuccessModal(true);
      });
    }
  };
  const updateUserEmail = async () => {
    if (selected) {
      const res = await dispatch(
        UsersActions.updateUserEmail({
          id: selected.id,
          email: editedEmail || "",
        })
      );
      isResponseFulfilled(
        res,
        () => {
          dispatch(
            UsersSliceAction.setSelected({
              ...selected,
              email: editedEmail || "",
            })
          );
          setShowSuccessModal(true);
        },
        () => {
          setEmailError("User with such email already exists in the system");
        }
      );
    }
  };
  const generatePassword = async () => {
    if (selected) {
      const res = await dispatch(
        UsersActions.generateUserPassword(selected.id)
      );
      isResponseFulfilled(
        res,
        () => {
          setShowSuccessModal(true);
        },
        () => {
          setShowErrorModal(true);
        }
      );
    }
  };

  return (
    <>
      {isEditEmail && (
        <BasicPopUp
          title={"Edit user's email"}
          onClose={onClose}
          onSubmit={updateUserEmail}
          disabled={!isEmailValid || !!emailError}
        >
          <>
            <TextFieldStyled
              id="email"
              fullWidth
              size="small"
              type="text"
              color="secondary"
              onChange={(e) => handleChangeEmail(e.target.value)}
              value={editedEmail}
              label="Set email"
              error={!isEmailValid || !!emailError}
            />
            {emailError && <ErrorStyled>{emailError}</ErrorStyled>}
          </>
        </BasicPopUp>
      )}
      {isEditPhone && (
        <BasicPopUp
          title={"Edit user's phone number"}
          onClose={onClose}
          onSubmit={updateUserPhone}
          disabled={!isPhoneValid}
        >
          <>
            <TextFieldStyled
              id="phone"
              fullWidth
              size="small"
              type="text"
              color="secondary"
              value={editedPhone}
              onChange={(e) => handleChangePhone(e.target.value)}
              label="Enter your phone"
              error={!isPhoneValid}
            />
            <TipStyled>
              *Phone is expected to be with "+" at the beginning
            </TipStyled>
          </>
        </BasicPopUp>
      )}
      {isEditName && (
        <BasicPopUp
          title={"Edit user's name & surname"}
          onClose={onClose}
          onSubmit={updateUserName}
        >
          <div>
            <TextFieldStyled
              id="name"
              fullWidth
              size="small"
              type="text"
              color="secondary"
              onChange={(e) => setName(e.target.value)}
              value={editedName}
              label="User name"
              mb={24}
            />
            <TextFieldStyled
              id="lastName"
              fullWidth
              size="small"
              type="text"
              color="secondary"
              onChange={(e) => setLastName(e.target.value)}
              value={editedLastName}
              label="User surname"
            />
          </div>
        </BasicPopUp>
      )}
      {isEditPassword && (
        <ConfirmPopUp
          title={"Generate new password"}
          description={`A new password will be generated and sent to the user's email. Do you want to proceed?`}
          submitButtonTitle={"Generate"}
          onClose={onClose}
          onSubmit={generatePassword}
        />
      )}
    </>
  );
};

export default EditModal;
