import React, { FC, useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useDebounce } from "application/hooks/useDebounce";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";
import Loader from "application/components/Loader";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { colors } from "application/theme/palette";

import { Currency, InvestmentStatus } from "integration/api/DealsApi/models";
import { InvestorContentDto } from "integration/api/PortfolioAPI/models";

import { Header, Table } from "../components";
import InvestorsActions from "../store/actions";
import { LoaderContainer } from "../components/Table/styles";

const defaultSize = 10;

const InvestorsPage: FC = () => {
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { content } = useAppSelector((state) => state.investors).investors;
  const { title, dealHasParent, totalCurrency } =
    useAppSelector((state) => state.investors);
  const { loading } = useAppSelector(requestSelector).getInvestors;
  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const [selectedStatus, setSelectedStatus] = useState<InvestmentStatus | "">(
    ""
  );
  const [deletedInvestor, setDeletedInvestor] =
    useState<InvestorContentDto | null>(null);
  const [selectedInvestorsIds, setSelectedInvestorsIds] = useState<number[]>(
    []
  );
  const [showMultipleDeleteModal, setShowMultipleDeleteModal] = useState(false);
  const [showSuccessMultipleDeleteModal, setShowSuccessMultipleDeleteModal] =
    useState(false);
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [currency, setCurrency] = useState<Currency | undefined>(
    (searchParams.get("currency") as Currency) || undefined
  );
  const [searchValue, setSearchValue] = useState("");
  const search = useDebounce(searchValue, 300);

  const getInvestors = ({
    currentPage,
    currentCurrency,
  }: {
    currentPage?: number;
    currentCurrency?: Currency;
  }) => {
    if (currentPage) {
      setPage(currentPage);
    }
    dispatch(
      InvestorsActions.getInvestors({
        id: Number(id),
        page: currentPage ? currentPage - 1 : page - 1,
        search: search,
        commitmentStatus: selectedStatus || undefined,
        size: defaultSize,
        currency: currentCurrency || currency,
      })
    );
  };

  const getSearchParams = () => {
    const params = {} as { [key: string]: string };
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  };

  useEffect(() => {
    getInvestors({});
  }, [page, id, selectedStatus, search, defaultSize]);

  useEffect(() => {
    if (totalCurrency) {
      setCurrency(totalCurrency);
    }
  }, [totalCurrency]);

  const onDeleteInvestor = (investor: InvestorContentDto) => {
    setDeletedInvestor(investor);
  };

  const onCloseDelete = () => {
    setDeletedInvestor(null);
  };

  const onSubmitDelete = () => {
    dispatch(
      InvestorsActions.deleteInvestor({
        dealId: Number(id),
        investorId: Number(deletedInvestor?.investorId),
      })
    ).then((res) => {
      isResponseFulfilled(res, () => {
        const isEmptyPage = content.length === 1;
        const currentPage = isEmptyPage && page > 1 ? page - 2 : page - 1;
        onCloseDelete();
        setShowSuccessDeleteModal(true);
        dispatch(
          InvestorsActions.getInvestors({
            id: Number(id),
            page: currentPage,
            search: search,
            commitmentStatus: selectedStatus || undefined,
            size: defaultSize,
            currency,
          })
        );
      });
    });
  };

  const handleOpenMultipleDeleteModal = () => {
    setShowMultipleDeleteModal(true);
  };

  const handleCloseMultipleDeleteModal = () => {
    setShowMultipleDeleteModal(false);
  };
  const handleCloseSuccessDeleteModal = () => {
    setShowSuccessMultipleDeleteModal(false);
    setShowSuccessDeleteModal(false);
    setSelectedInvestorsIds([]);
  };

  const handleDeleteMultiple = () => {
    dispatch(
      InvestorsActions.deleteInvestors({
        dealId: Number(id),
        investorsIds: selectedInvestorsIds,
      })
    ).then((res) => {
      isResponseFulfilled(res, () => {
        const isEmptyPage = selectedInvestorsIds.length === content.length;
        const currentPage = isEmptyPage && page > 1 ? page - 2 : page - 1;
        handleCloseMultipleDeleteModal();
        setShowSuccessDeleteModal(true);
        dispatch(
          InvestorsActions.getInvestors({
            id: Number(id),
            page: currentPage,
            search: search,
            commitmentStatus: selectedStatus || undefined,
            size: defaultSize,
            currency,
          })
        );
      });
    });
  };

  const handleChangePage = (page: number) => {
    const params = getSearchParams();
    setPage(page);
    setSearchParams(
      { ...params, page: String(page) },
      { state: location.state }
    );
  };

  const handleChangeCurrency = (value: Currency) => {
    const params = getSearchParams();
    setCurrency(value);
    getInvestors({ currentCurrency: value });
    setSearchParams(
      {
        ...params,
        currency: value,
      },
      { state: location.state }
    );
  };

  const showSuccessDeletePopUp =
    showSuccessDeleteModal || showSuccessMultipleDeleteModal;

  return (
    <>
      {loading && (
        <LoaderContainer>
          <Loader color={colors.grey500} />
        </LoaderContainer>
      )}
      <Header
        getInvestors={getInvestors}
        title={title}
        dealHasParent={dealHasParent}
      />
      <Table
        getInvestors={getInvestors}
        page={page}
        setPage={handleChangePage}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onDelete={onDeleteInvestor}
        selectedInvestorsIds={selectedInvestorsIds}
        setSelectedInvestorsIds={setSelectedInvestorsIds}
        handleDeleteMultiple={handleOpenMultipleDeleteModal}
        currency={currency}
        setCurrency={handleChangeCurrency}
      />
      {!!deletedInvestor && (
        <ConfirmPopUp
          title="Delete Investor"
          onClose={onCloseDelete}
          onSubmit={onSubmitDelete}
          description={`You want to delete ${deletedInvestor.firstName} ${deletedInvestor.lastName}. Are you sure?`}
        />
      )}
      {showMultipleDeleteModal && (
        <ConfirmPopUp
          title="Delete Investors"
          onClose={handleCloseMultipleDeleteModal}
          onSubmit={handleDeleteMultiple}
          description="You want to delete investors. Are you sure?"
        />
      )}
      {showSuccessDeletePopUp && (
        <SuccessPopUp
          title={`The ${
            showSuccessDeleteModal ? "investor" : "investors"
          } are deleted`}
          onSubmit={handleCloseSuccessDeleteModal}
        />
      )}
    </>
  );
};

export default InvestorsPage;
