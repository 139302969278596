import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

interface StylesProps {
  open?: boolean;
  $active?: boolean;
}

export const Wrapper = styled.div<StylesProps>`
  display: flex;
  height: 64px;
  cursor: pointer;
  padding-left: 24px;
  align-items: center;
  border-bottom: 1px solid ${colors.grey700};
  background-color: ${({ $active }) => ($active ? colors.grey800 : "inherit")};
  svg {
    min-width: 24px;
    min-height: 24px;
  }
`;

export const Title = styled.p`
  margin-left: 16px;
  /* font */
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  font-style: normal;
  font-family: Unica77;
  letter-spacing: 0.08px;
  color: ${colors.white};
`;
