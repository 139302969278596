import { CommonErrors } from "application/helpers/validations";
import * as yup from "yup";

export const commitmentsPopUpSchema = yup.object({
  investmentAmount: yup.number().required(CommonErrors.requiredField),
  currency: yup.string().required(CommonErrors.requiredField),
});

export const currentCurrencySchema = yup.object({
  fmvChangeDate: yup.string().required(CommonErrors.requiredField),
  fmvValue: yup.number().required(CommonErrors.requiredField),
  realizedProfit: yup.number().required(CommonErrors.requiredField),
  currency: yup.string().required(CommonErrors.requiredField),
});
