import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

interface StyledProps {
  isActive: boolean;
}

export const Wrapper = styled.div<StyledProps>`
  display: flex;
  flex-direction: column;
  min-height: 64px;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid ${colors.grey700};
  background-color: ${({ isActive }) =>
    isActive ? colors.grey800 : "inherit"};
  svg {
    min-width: 24px;
    min-height: 24px;
  }
`;

export const ItemStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
  height: 64px;
  box-sizing: border-box;
  padding: 0 24px;
  > div {
    width: inherit;
    display: flex;
    align-items: center;
    svg {
      path {
        fill: ${colors.grey300};
      }
    }
  }
`;

export const MenuItemStyled = styled.div<StyledProps>`
  display: flex;
  align-items: center;
  height: 44px;
  box-sizing: border-box;
  background-color: ${({ isActive }) =>
    isActive ? colors.grey800 : "transparent"};

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
    padding: 0 24px 0 64px;

    color: ${({ isActive }) => (isActive ? colors.blue500 : colors.grey200)};
    text-align: start;
    font-family: Unica77;
    font-size: 16px;
    font-style: normal;
    font-weight: ${({ isActive }) => (isActive ? 500 : 400)};
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;

export const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.p`
  width: inherit;
  /* font */
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  font-style: normal;
  font-family: Unica77;
  letter-spacing: 0.08px;
  color: ${colors.white};
`;
