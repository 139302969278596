import React from "react";

import { TractionTableItem } from "../TractionTable/models";
import { getCurrencyIcon } from "application/helpers/currency";

import { Actions } from "./styles";
import { ReactComponent as EditSvg } from "application/assets/icons/edit.svg";
import { ReactComponent as CloseSvg } from "application/assets/icons/close.svg";

interface Props {
  item: TractionTableItem;
  onEditRow: (item: TractionTableItem) => void;
  onDeleteRow: (item: TractionTableItem) => void;
}

const formatter = new Intl.NumberFormat("en-US");

const TractionTableRow = ({ item, onEditRow, onDeleteRow }: Props) => {
  const index = item.index ? formatter.format(Number(item.index)) : "";
  return (
    <tr>
      <td>{item.title}</td>
      <td>{index}</td>
      <td>{getCurrencyIcon(item.currency)}</td>
      <td>
        <Actions>
          <EditSvg onClick={() => onEditRow(item)} />
          <CloseSvg onClick={() => onDeleteRow(item)} />
        </Actions>
      </td>
    </tr>
  );
};

export default TractionTableRow;
