import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

interface StylesProps {
  open?: boolean;
}

export const Wrapper = styled.div<StylesProps>`
  display: flex;
  align-items: center;
  transition: padding 0.3s ease-in-out;
  border-bottom: 1px solid ${colors.grey700};
  padding: ${({ open }) => (open ? "30px 24px" : "30px 16px")};
`;

export const Card = styled.div`
  min-width: 44px;
  min-height: 44px;
  border-radius: 100%;
  background-color: ${colors.grey800};
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 140%;
    font-style: normal;
    color: ${colors.white};
    letter-spacing: 0.08px;
    font-family: Unica77;
  }
`;

export const Content = styled.div`
  margin-left: 16px;
`;

export const Name = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  font-style: normal;
  color: ${colors.white};
  letter-spacing: 0.08px;
  font-family: Unica77;
`;

export const Role = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  font-style: normal;
  font-family: Unica77;
  letter-spacing: 0.07px;
  color: ${colors.grey200};
`;
