import React, { FC, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";

import { Button, ErrorValidationPopUp } from "application/components";
import { PathNames } from "application/routes";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { DetectChangesSavedSliceActions } from "application/store/DetectChangesSaved/DetectChangesSavedSlice";
import LeavePagePopUp from "application/containers/LeavePagePopUp";

import CompanyListActions from "features/companies/store/actions";
import { formatCompanyError } from "features/companies/helpers/formatCompanyError";
import { CompanySliceAction } from "features/companies/store/slice";

import { Wrapper, BackButton, ButtonSection } from "./styles";

const CompanyHeader: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    company,
    hasErrors,
    errors: companyErrors,
  } = useAppSelector((state) => state.companyList);

  const { hasUnsavedChanges } = useAppSelector(
    (state) => state.detectChangesSavedSlice
  );

  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const [showSaveSuccessModal, setShowSaveSuccessModal] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const hideBackButton = window?.history?.length <= 2;

  useEffect(() => {
    setErrors(formatCompanyError(companyErrors.join(",")));
  }, [companyErrors]);

  const goBack = () => {
    if (hasUnsavedChanges) {
      dispatch(
        DetectChangesSavedSliceActions.setNextPath(
          (window?.history?.state?.usr?.company as string) ||
            PathNames.companies
        )
      );
    } else {
      const path = (window?.history?.state?.usr?.company || -1) as string;
      navigate(path, {
        state: window?.history?.state?.usr,
      });
    }
  };

  const onSaveChanges = async () => {
    if (id) {
      const filteredTeam = company.team.map((item) => {
        const { id: memberId, ...restData } = item;
        if (typeof memberId === "string") {
          return restData;
        }
        return item;
      });
      const response = await dispatch(
        CompanyListActions.updateCompany({
          id,
          data: { ...company, team: filteredTeam },
        })
      );
      isResponseFulfilled(response, async () => {
        await dispatch(CompanyListActions.getCompanyById(String(id)));
        setShowSaveSuccessModal(true);
      });
      return response.meta.requestStatus;
    }
  };

  const onDelete = () => {
    setShowDelete(true);
  };

  const onCloseDelete = () => {
    setShowDelete(false);
  };

  const onDeleteSuccess = () => {
    setShowDelete(false);
    setShowDeleteSuccessModal(true);
  };

  const onSubmitDelete = async () => {
    await dispatch(CompanyListActions.deleteCompany(Number(id))).then((res) => {
      isResponseFulfilled(res, onDeleteSuccess);
    });
  };

  const onSubmitSuccessDelete = () => {
    setShowDeleteSuccessModal(false);
    navigate(PathNames.companies);
  };

  const onSubmitSuccessSave = () => {
    setShowSaveSuccessModal(false);
  };

  const clearErrors = () => {
    dispatch(CompanySliceAction.setHasErrors(false));
    setErrors([]);
  };

  return (
    <Wrapper>
      {!hideBackButton && (
        <BackButton type="button" onClick={goBack}>
          <ArrowBackIcon />
          Back to companies
        </BackButton>
      )}
      <ButtonSection>
        <Button label="Save changes" type="reset" onClick={onSaveChanges} />
        <Button label="Delete company" red type="button" onClick={onDelete} />
      </ButtonSection>

      {showDelete && (
        <ConfirmPopUp
          title="Delete company"
          onClose={onCloseDelete}
          onSubmit={onSubmitDelete}
          description="You want to delete company. Are you sure?"
        />
      )}
      {showDeleteSuccessModal && (
        <SuccessPopUp
          title="The company is deleted"
          onSubmit={onSubmitSuccessDelete}
        />
      )}
      {showSaveSuccessModal && (
        <SuccessPopUp
          title="Company successfully saved"
          onSubmit={onSubmitSuccessSave}
        />
      )}
      {hasErrors && (
        <ErrorValidationPopUp errors={errors} onPress={clearErrors} />
      )}
      <LeavePagePopUp onSubmit={onSaveChanges} />
    </Wrapper>
  );
};

export default CompanyHeader;
