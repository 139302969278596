import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";

import { OverviewIcon } from "application/assets/OverviewIcon";
import { colors } from "application/theme/palette";
import { DealsTabs } from "features/deal-profile/types";
import { DetailsIcon } from "application/assets/DetailsIcon";
import { TractionIcon } from "application/assets/TractionIcon";
import { EmailNotificationIcon } from "application/assets/EmailNotification";

import { DealPageTab, IconWrapper } from "./styles";

interface TabItemProps {
  tabName: DealsTabs;
  hasError: boolean;
}

export const TabItem = ({ tabName, hasError }: TabItemProps) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const tab = searchParams.get("tab") || DealsTabs.overview;
  const active = tab === tabName;
  const itemContent = (hasError: boolean) => {
    const iconColor = hasError
      ? colors.red700
      : active
      ? colors.blue700
      : colors.grey500;

    switch (tabName) {
      case DealsTabs.details:
        return {
          icon: <DetailsIcon color={iconColor} />,
          label: "Details",
        };
      case DealsTabs.overview:
        return {
          icon: <OverviewIcon color={iconColor} />,
          label: "Overview",
        };
      case DealsTabs.emailNotifications:
        return {
          icon: <EmailNotificationIcon color={iconColor} />,
          label: "Email Notifications",
        };
      case DealsTabs.traction:
      default:
        return {
          icon: <TractionIcon color={iconColor} />,
          label: "Traction",
        };
    }
  };

  const handleClick = () => {
    setSearchParams(
      {
        tab: tabName,
      },
      {
        state: window?.history?.state?.usr,
      }
    );
  };

  return (
    <DealPageTab
      onClick={handleClick}
      active={active ? 1 : 0}
      hasError={hasError}
    >
      <IconWrapper>{itemContent(hasError).icon}</IconWrapper>
      <Typography
        variant="button"
        color={hasError ? colors.red700 : colors.grey900}
      >
        {itemContent(hasError).label}
      </Typography>
    </DealPageTab>
  );
};
