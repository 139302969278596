import styled from "@emotion/styled";
import { Add } from "@mui/icons-material";
import { colors } from "application/theme/palette";

export const Wrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

export const BackButton = styled("button")`
  all: unset;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.blue700};
  text-align: center;
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
  cursor: pointer;

  svg {
    margin-right: 8px;
    path {
      fill: ${colors.blue700};
    }
  }
`;

export const Container = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0 32px;

  > h1 {
    color: ${colors.grey900};
    font-family: Unica77;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

export const ButtonSection = styled("div")`
  display: flex;
  gap: 20px;
  align-items: center;

  button {
    min-width: fit-content;
  }
`;

export const AddIcon = styled(Add)`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  color: ${colors.white};
`;
