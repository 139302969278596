import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  margin-bottom: 32px;
`;

export const Title = styled.h3`
  margin-bottom: 12px;
  /* font */
  color: ${colors.grey900};
  font-family: "Unica77";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.08px;
`;

export const Container = styled.div`
  border-radius: 8px;
  padding: 20px 24px;
  background: ${colors.natural100};
`;
