import React, { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { DetectChangesSavedSliceActions } from "application/store/DetectChangesSaved/DetectChangesSavedSlice";

import { SubMenuItem } from "features/side-menu/config/menu-list";

import { MenuAccordionItemProps } from "./models";
import {
  Wrapper,
  ItemStyled,
  MenuItemStyled,
  Title,
  OptionsList,
} from "./styles";

const MenuAccordionItem: FC<MenuAccordionItemProps> = ({
  open,
  options,
  mainPath,
  icon,
  iconActive,
  title,
  setOpen,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = location.pathname?.includes(mainPath);
  const dispatch = useAppDispatch();
  const { hasUnsavedChanges } = useAppSelector(
    (state) => state.detectChangesSavedSlice
  );

  const [showList, setShowList] = useState(open);

  const Icon = isActive ? iconActive : icon;

  const onChangePath = (item: SubMenuItem) => {
    if (location.pathname === item.path) return;
    if (hasUnsavedChanges) {
      dispatch(DetectChangesSavedSliceActions.setNextPath(item.path));
    } else {
      navigate(item.path);
    }
  };

  return (
    <Wrapper isActive={isActive && !showList} onClick={() => setOpen(true)}>
      <ItemStyled
        onClick={() => {
          setShowList((prev) => (!open ? true : !prev));
        }}
      >
        <Icon />
        {open && (
          <div>
            <Title className="accordion-title">{title}</Title>
            {showList ? <ExpandLess /> : <ExpandMore />}
          </div>
        )}
      </ItemStyled>
      {showList && open && (
        <OptionsList>
          {options.map((item) => (
            <MenuItemStyled
              key={item.path}
              isActive={location.pathname === item.path}
            >
              <button type="button" onClick={() => onChangePath(item)}>
                {item.title}
              </button>
            </MenuItemStyled>
          ))}
        </OptionsList>
      )}
    </Wrapper>
  );
};

export default MenuAccordionItem;
