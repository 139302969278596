import React, { FC, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import Empty from "application/containers/Empty";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { requestSelector } from "application/store/request/selectors";
import { PathNames } from "application/routes";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { PaginationMUI } from "application/components";

import {
  ConsentDto,
  CreateConsentDto,
} from "integration/api/ConsentsApi/models";

import { Header } from "features/consents/components";
import { Table } from "features/consents/containers";
import ConsentsActions from "features/consents/store/actions";
import { consentsStatuses } from "features/consents/helpers/consentsStatuses";

import { EmptyPageContainer } from "./styles";

const defaultSize = 10;

const ConsentsPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getConsentList, addConsent, deleteConsent } =
    useAppSelector(requestSelector);
  const { content, totalPages } = useAppSelector(
    (state) => state.consents.consents
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [consentToDelete, setConsentToDelete] = useState<ConsentDto | null>(
    null
  );
  const [showSuccessDeleteModal, setShowSuccessDeleteModal] = useState(false);
  const [page, setPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  );
  const [status, setStatus] = useState<string>(
    searchParams.get("status") || consentsStatuses[0]?.value
  );

  const loading =
    getConsentList.loading || addConsent.loading || deleteConsent.loading;

  useEffect(() => {
    dispatch(
      ConsentsActions.getConsentList({
        page: page - 1,
        size: defaultSize,
        status: status === "ALL" ? "" : status,
      })
    );
  }, [page, status]);

  const getSearchParams = () => {
    const params = {} as { [key: string]: string };
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  };

  const onPageChange = async (_: React.ChangeEvent<unknown>, page: number) => {
    const params = getSearchParams();
    setPage(page);
    setSearchParams({
      ...params,
      page: String(page),
    });
  };

  const onAddConsent = async () => {
    const response = await dispatch(ConsentsActions.addConsent());

    isResponseFulfilled(response, () => {
      const consent = response.payload as CreateConsentDto;
      navigate(`${PathNames.consent}/${consent.id}`, {
        state: location.search || "",
      });
    });
  };

  const handleChangeStatus = (value: string) => {
    const params = getSearchParams();
    setStatus(value);
    setPage(1);
    setSearchParams({
      ...params,
      page: "1",
      status: value,
    });
  };

  const onDeleteConsent = (consent: ConsentDto) => {
    setConsentToDelete(consent);
  };

  const onCloseDelete = () => {
    setConsentToDelete(null);
  };

  const onSubmitDelete = () => {
    dispatch(ConsentsActions.deleteConsent(Number(consentToDelete?.id))).then(
      (res) => {
        isResponseFulfilled(res, () => {
          const isEmptyPage = content.length === 1;
          const currentPage = isEmptyPage && page > 1 ? page - 2 : page - 1;
          onCloseDelete();
          setShowSuccessDeleteModal(true);
          if (isEmptyPage) {
            const params = getSearchParams();
            setPage(page - 1);
            setSearchParams({
              ...params,
              page: String(page - 1),
            });
          } else {
            dispatch(
              ConsentsActions.getConsentList({
                page: currentPage,
                size: defaultSize,
                status: status === "ALL" ? "" : status,
              })
            );
          }
        });
      }
    );
  };

  const handleCloseSuccessDeleteModal = () => {
    setShowSuccessDeleteModal(false);
  };

  return (
    <React.Fragment>
      <Header
        status={status}
        handleChangeStatus={handleChangeStatus}
        handleShowAddModal={onAddConsent}
      />
      {!!content.length || loading ? (
        <>
          <Table loading={loading} onDeleteConsent={onDeleteConsent} />
          {!!totalPages && (
            <PaginationMUI
              variant="text"
              color="primary"
              page={page}
              count={totalPages}
              onChange={onPageChange}
            />
          )}
        </>
      ) : (
        <>
          <EmptyPageContainer>
            <Empty description="You haven’t any consent yet!" />
            <button type="button" onClick={onAddConsent}>
              Add new consent
            </button>
          </EmptyPageContainer>
        </>
      )}
      {!!consentToDelete && (
        <ConfirmPopUp
          title="Delete consent"
          onClose={onCloseDelete}
          onSubmit={onSubmitDelete}
          description={`You want to delete ${
            consentToDelete.name || ""
          } consent. Are you sure?`}
        />
      )}
      {showSuccessDeleteModal && (
        <SuccessPopUp
          title={"The consent is deleted"}
          onSubmit={handleCloseSuccessDeleteModal}
        />
      )}
    </React.Fragment>
  );
};

export default ConsentsPage;
