import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Checkbox, ClickAwayListener, InputAdornment } from "@mui/material";
import { Box } from "@mui/system";
import { Link, SaveAlt, Edit } from "@mui/icons-material";

import { CommonErrors } from "application/helpers/validations";
import { ImageLoader, TextFieldStyled } from "application/components";
import BasicPopUp from "application/containers/BasicPopUp";

import { socialMediaValidation } from "features/deal-profile/utils";

import { TeamMemberAddModalProps, TeamMemberValidation } from "./model";
import {
  AvatarPlaceholder,
  DeleteContainer,
  EditLogo,
  ImageWrapper,
  LabelStyled,
  LogoPopup,
  LogoPopupItem,
} from "./styles";
import CropImage from "features/crop-image";

const schema = yup
  .object({
    linkedinUrl: yup
      .string()
      .required(CommonErrors.requiredField)
      .test(
        "matchSocialMedia",
        CommonErrors.wrongUrlFormat,
        function (value, context) {
          if (!value) return true;
          const isValid = socialMediaValidation(String(value));
          return isValid;
        }
      ),
    firstName: yup.string().required(CommonErrors.requiredField),
    lastName: yup.string().required(CommonErrors.requiredField),
    title: yup.string().required(CommonErrors.requiredField),
    logo: yup.string().required(CommonErrors.requiredField),
  })
  .required();

const TeamMemberAddModal: FC<TeamMemberAddModalProps> = ({
  isEditing,
  handleClose,
  onSubmit,
  selectedTeamMember,
  setSelectedTeamMember,
  setTeamMemberFirstName,
  setTeamMemberLastName,
  setTeamMemberPosition,
  setTeamMemberLinkedin,
  setTeamMemberResponsible,
  setTeamMemberBase64Image,
  handleDeleteImage,
}) => {
  const [showLogoPopup, setShowLogoPopup] = useState(false);
  const [logo, setLogo] = useState("");
  const [isFileLoading, setIsFileLoading] = useState(false);

  const [image, setImage] = useState<string>("");
  const [showCropperModal, setShowCropperModal] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm<TeamMemberValidation>({
    resolver: yupResolver(schema),
  });

  const getLogoUrl = async () => {
    const file = await dataUrlToFileUsingFetch(
      selectedTeamMember?.base64Image || "",
      "image",
      "image/png"
    );
    const logoUrl = URL.createObjectURL(file);
    setLogo(logoUrl);
  };

  useEffect(() => {
    if (selectedTeamMember?.base64Image) {
      getLogoUrl();
    }
    if (selectedTeamMember?.imageUrl && !selectedTeamMember?.base64Image) {
      setLogo(
        !!selectedTeamMember?.imageDeleted ? "" : selectedTeamMember.imageUrl
      );
      setValue(
        "logo",
        !!selectedTeamMember?.imageDeleted ? "" : selectedTeamMember.imageUrl
      );
    }
  }, [
    selectedTeamMember?.base64Image,
    selectedTeamMember?.imageUrl,
    selectedTeamMember?.imageDeleted,
  ]);

  useEffect(() => {
    return () => {
      reset();
      setSelectedTeamMember(null);
    };
  }, []);

  const getBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setTeamMemberBase64Image(reader.result as string);
      setValue("logo", reader.result as string);
    };
  };

  const dataUrlToFileUsingFetch = async (
    url: string,
    fileName: string,
    mimeType: string
  ) => {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();

    return new File([buffer], fileName, { type: mimeType });
  };

  const handleImageUpload = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    const logoUrl = URL.createObjectURL(file);
    setLogo(logoUrl);
    getBase64(file);

    setShowLogoPopup(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e.target.files;
    if (files?.length) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.onloadend = () => {
        setShowCropperModal(true);
      };

      reader.readAsDataURL(files[0]);
    }
  };

  const handleDeleteFile = () => {
    handleDeleteImage(!!selectedTeamMember?.imageUrl);
    setLogo("");
    setShowLogoPopup(false);
  };

  const handleLogoClick = () => {
    setShowLogoPopup(true);
  };

  const handleChangeFirstName = (value: string) => {
    setTeamMemberFirstName(value);
    clearErrors("firstName");
  };

  const handleChangeLastName = (value: string) => {
    setTeamMemberLastName(value);
    clearErrors("lastName");
  };

  const handleChangePosition = (value: string) => {
    setTeamMemberPosition(value);
    clearErrors("title");
  };

  const handleChangeLinkedin = (value: string) => {
    setTeamMemberLinkedin(value);
    clearErrors("linkedinUrl");
  };

  return (
    <>
      <BasicPopUp
        title={`${isEditing ? "Edit" : "Add"} team member`}
        onClose={handleClose}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box>
          <ImageWrapper hasError={!!errors.logo}>
            <AvatarPlaceholder onClick={handleLogoClick}>
              {isFileLoading ? (
                <ImageLoader />
              ) : (
                <>
                  {logo && <img src={logo} alt="deal-avatar" />}
                  {logo ? (
                    <EditLogo>
                      <Edit />
                    </EditLogo>
                  ) : (
                    <SaveAlt />
                  )}
                </>
              )}
            </AvatarPlaceholder>
            {!isFileLoading && showLogoPopup && (
              <ClickAwayListener onClickAway={() => setShowLogoPopup(false)}>
                <LogoPopup>
                  <input
                    color="primary"
                    accept="image/jpeg,image/png"
                    type="file"
                    onChange={(e) => onChange(e)}
                    id="member-icon"
                    style={{ display: "none" }}
                  />
                  <Box mb={logo ? 1.5 : 0}>
                    <label htmlFor="member-icon">
                      <LogoPopupItem>Upload file</LogoPopupItem>
                    </label>
                  </Box>

                  {logo && (
                    <DeleteContainer>
                      <LogoPopupItem onClick={handleDeleteFile}>
                        Delete file
                      </LogoPopupItem>
                    </DeleteContainer>
                  )}
                </LogoPopup>
              </ClickAwayListener>
            )}
          </ImageWrapper>
          <TextFieldStyled
            {...register("firstName")}
            label={"First name"}
            size="small"
            mb={20}
            id="firstName"
            type="firstName"
            fullWidth
            onChange={(e) => handleChangeFirstName(e.target.value)}
            value={selectedTeamMember?.firstName}
            filled={selectedTeamMember?.firstName ? 1 : 0}
            error={!!errors.firstName}
            helperText={!!errors.firstName && errors.firstName?.message}
          />
          <TextFieldStyled
            {...register("lastName")}
            label={"Last name"}
            size="small"
            mb={20}
            id="lastName"
            type="lastName"
            fullWidth
            onChange={(e) => handleChangeLastName(e.target.value)}
            value={selectedTeamMember?.lastName}
            filled={selectedTeamMember?.lastName ? 1 : 0}
            error={!!errors.lastName}
            helperText={!!errors.lastName && errors.lastName?.message}
          />
          <TextFieldStyled
            {...register("title")}
            label={"Position"}
            size="small"
            mb={20}
            id="title"
            type="title"
            fullWidth
            onChange={(e) => handleChangePosition(e.target.value)}
            value={selectedTeamMember?.title}
            filled={selectedTeamMember?.title ? 1 : 0}
            error={!!errors.title}
            helperText={!!errors.title && errors.title?.message}
          />
          <TextFieldStyled
            {...register("linkedinUrl")}
            label={"LinkedIn link"}
            placeholder="https://example.com"
            size="small"
            fullWidth
            mb={20}
            id="linkediUrl"
            type="linkedinUrl"
            onChange={(e) => handleChangeLinkedin(e.target.value)}
            value={selectedTeamMember?.linkedinUrl}
            filled={selectedTeamMember?.linkedinUrl ? 1 : 0}
            error={!!errors.linkedinUrl}
            helperText={!!errors.linkedinUrl && errors.linkedinUrl?.message}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  sx={{
                    zIndex: 10,
                    paddingLeft: "2px",
                    marginRight: "-8px",
                  }}
                >
                  {<Link />}
                </InputAdornment>
              ),
            }}
          />
          <LabelStyled
            control={
              <Checkbox
                checked={!!selectedTeamMember?.isResponsibleForQa}
                onChange={(e) => setTeamMemberResponsible(e.target.checked)}
              />
            }
            label="Responsible for Q&A"
          />
        </Box>
      </BasicPopUp>
      <CropImage
        handleImageUpload={handleImageUpload}
        image={image}
        showCropperModal={showCropperModal}
        setShowCropperModal={setShowCropperModal}
      />
    </>
  );
};

export default TeamMemberAddModal;
