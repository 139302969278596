import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import BasicPopUp from "application/containers/BasicPopUp";
import CurrencySelect from "application/components/CurrencySelect";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";

import { Currency } from "integration/api/DealsApi/models";

import { commitmentsPopUpSchema } from "features/portfolio-investor-detail/helpers/validations";

import { CommitmentsPopUpForm, CommitmentsPopUpProps } from "./model";
import { TextFieldStyled } from "./styles";

const CommitmentsPopUp: FC<CommitmentsPopUpProps> = ({
  onSubmit,
  onClose,
  title,
  hideCurrency,
  defaultValues,
}) => {
  const { loading } = useAppSelector(requestSelector).changeInvestorStatus;
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<CommitmentsPopUpForm>({
    resolver: yupResolver(commitmentsPopUpSchema),
    defaultValues,
  });

  const handleChangeCurrency = (currency: Currency) => {
    setValue("currency", currency, { shouldValidate: true });
  };

  return (
    <BasicPopUp
      isLoading={loading}
      title={title}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <form>
        <TextFieldStyled
          mb={20}
          id="investmentAmount"
          fullWidth
          size="small"
          type="number"
          color="secondary"
          label="Set index"
          placeholder="Enter investment amount"
          filled={watch("investmentAmount")}
          error={!!errors.investmentAmount?.message}
          {...register("investmentAmount")}
        />
        {!hideCurrency && (
          <CurrencySelect
            maxHeight={300}
            selected={watch("currency")}
            onChange={handleChangeCurrency}
            placeholder="Choose currency"
            error={!!errors.currency?.message}
          />
        )}
      </form>
    </BasicPopUp>
  );
};

export default CommitmentsPopUp;
