import React, { FC, useState } from "react";
import { SearchOutlined, SwapVertOutlined } from "@mui/icons-material";

import { CurrencySymbolSelect, SelectWithText } from "application/components";
import { colors } from "application/theme/palette";

import { Sort } from "integration/api/CompanyApi/models";

import { companiesStatuses } from "features/companies/helpers/companiesStatuses";

import { FilterSectionProps } from "./models";
import {
  Container,
  SortToggle,
  StatusWrapper,
  SectionStyled,
  InputContainer,
  Input,
} from "./styles";

const FilterSection: FC<FilterSectionProps> = ({
  searchValue,
  setSearchValue,
  status,
  handleChangeStatus,
  sort,
  toggleSort,
  currency,
  setCurrency,
}) => {
  const [searchItem, setSearchItem] = useState(searchValue);

  const isAscSort = sort === Sort.asc;

  const handleSearch = (value: string) => {
    setSearchItem(value);
    setSearchValue(value.length > 2 ? value : "");
  };

  return (
    <Container>
      <SectionStyled>
        <SortToggle onClick={toggleSort}>
          <SwapVertOutlined />
          Sort: {isAscSort ? "A to Z" : "Z to A"}
        </SortToggle>
        <CurrencySymbolSelect
          color={colors.white}
          onChange={setCurrency}
          selected={currency}
        />
      </SectionStyled>
      <SectionStyled>
        <StatusWrapper>
          <SelectWithText
            placeholder="Status"
            onChange={(value) => {
              handleChangeStatus(String(value));
            }}
            options={companiesStatuses}
            selected={status}
            maxHeight={300}
          />
        </StatusWrapper>
        <InputContainer>
          <SearchOutlined />
          <Input
            placeholder="Search"
            value={searchItem}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </InputContainer>
      </SectionStyled>
    </Container>
  );
};

export default FilterSection;
