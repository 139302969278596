import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { colors } from "application/theme/palette";
import { TextEditorWithTitle } from "application/components/TextEditorWithTitle";
import { AdditionalMaterialsContainer } from "features/deal-profile/containers/AdditionalMaterials";
import { PitchDeckContainer } from "features/deal-profile/containers/PitchDeck";
import { TeamMembersContainer } from "features/deal-profile/containers/TeamMembers";
import { VideosContainer } from "features/deal-profile/containers/Videos";
import {
  DealSliceAction,
  setOverviewDescription,
  setOverviewMarket,
  setOverviewProblem,
  setOverviewShortDescription,
  setOverviewSolution,
} from "features/deal-profile/store/slice";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";
import { DealErrorKey } from "features/deal-profile/helpers/formatDealError";
import { Website } from "features/deal-profile/containers/Website";
import { InputWithTitle } from "application/components/InputWithTitle";

export const OverviewSection = () => {
  const dispatch = useAppDispatch();
  const { overview } = useAppSelector((state) => state.dealPage).profile;
  const { overviewKey } = useAppSelector((state) => state.dealPage);
  const errors = useAppSelector((state) => state.dealPage).errors;
  const { loading } = useAppSelector(requestSelector).getDealById;

  const hasShortDescriptionError = errors.some((item) =>
    item.includes(DealErrorKey["overview.shortDescription"])
  );
  const hasShortDescriptionLengthError = errors.some((item) =>
    item.includes(DealErrorKey["overview.shortDescriptionLength"])
  );
  const hasDescriptionError = errors.some((item) =>
    item.includes(DealErrorKey["overview.description"])
  );
  const hasMarketError = errors.some((item) =>
    item.includes(DealErrorKey["overview.market"])
  );
  const hasProblemError = errors.some((item) =>
    item.includes(DealErrorKey["overview.problem"])
  );
  const hasSolutionError = errors.some((item) =>
    item.includes(DealErrorKey["overview.solution"])
  );
  const hasTeamError = useMemo(() => {
    return errors.some((item) => item.includes(DealErrorKey["overview.team"]));
  }, [errors]);

  const handleShortDescription = (text: string) => {
    if (text < overview.shortDescription || text.length <= 40)
      dispatch(setOverviewShortDescription(text));
    !!text &&
      dispatch(
        DealSliceAction.setErrors(
          errors.filter(
            (item) =>
              item !== DealErrorKey["overview.shortDescription"] &&
              item !== DealErrorKey["overview.shortDescriptionLength"]
          )
        )
      );
  };

  const handlePasteShortDescription = (
    e: React.ClipboardEvent<HTMLDivElement>
  ) => {
    e.preventDefault();
    const pasted = e.clipboardData.getData("text");
    dispatch(setOverviewShortDescription(pasted));
    !!pasted &&
      dispatch(
        DealSliceAction.setErrors(
          errors.filter(
            (item) =>
              item !== DealErrorKey["overview.shortDescription"] &&
              item !== DealErrorKey["overview.shortDescriptionLength"]
          )
        )
      );
  };

  const handleDescription = (text: string) => {
    dispatch(setOverviewDescription(text));
    !!text &&
      dispatch(
        DealSliceAction.setErrors(
          errors.filter((item) => item !== DealErrorKey["overview.description"])
        )
      );
  };
  const handleProblem = (text: string) => {
    dispatch(setOverviewProblem(text));
    !!text &&
      dispatch(
        DealSliceAction.setErrors(
          errors.filter((item) => item !== DealErrorKey["overview.problem"])
        )
      );
  };
  const handleSolution = (text: string) => {
    dispatch(setOverviewSolution(text));
    !!text &&
      dispatch(
        DealSliceAction.setErrors(
          errors.filter((item) => item !== DealErrorKey["overview.solution"])
        )
      );
  };
  const handleMarket = (text: string) => {
    dispatch(setOverviewMarket(text));

    !!text &&
      dispatch(
        DealSliceAction.setErrors(
          errors.filter((item) => item !== DealErrorKey["overview.market"])
        )
      );
  };
  const handleClearTeamError = useCallback(() => {
    dispatch(
      DealSliceAction.setErrors(
        errors.filter((item) => item !== DealErrorKey["overview.team"])
      )
    );
  }, [errors]);

  return (
    <Box>
      <Typography variant="h2" color={colors.grey900} mb={4}>
        Overview
      </Typography>
      {!loading && (
        <React.Fragment key={`overviewKey-${overviewKey}`}>
          <InputWithTitle
            value={overview.shortDescription}
            title="Short description"
            onChange={handleShortDescription}
            hasError={
              hasShortDescriptionError || hasShortDescriptionLengthError
            }
            onPaste={handlePasteShortDescription}
          />
          <TextEditorWithTitle
            value={overview.description}
            title="Short overview"
            onChange={handleDescription}
            hasError={hasDescriptionError}
          />
          <VideosContainer />
          <TextEditorWithTitle
            value={overview.problem}
            title="Problem"
            onChange={handleProblem}
            hasError={hasProblemError}
          />
          <TextEditorWithTitle
            value={overview.solution}
            title="Solution"
            onChange={handleSolution}
            hasError={hasSolutionError}
          />
          <TextEditorWithTitle
            value={overview.market}
            title="Market"
            onChange={handleMarket}
            hasError={hasMarketError}
          />
        </React.Fragment>
      )}
      <TeamMembersContainer
        hasError={hasTeamError}
        handleClearTeamError={handleClearTeamError}
      />
      <PitchDeckContainer />
      <AdditionalMaterialsContainer />
      <Website />
    </Box>
  );
};
