import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import {
  DealPageState,
  AddTractionTypeAction,
  EditTractionTypeAction,
  AddTractionAmountAction,
  DeleteTractionTypeAction,
  EditTractionAmountAction,
  DeleteTractionAmountAction,
  VocabularyDataDto,
  AddTractionMultiTypeAction,
  EditTractionMultiTypeAction,
  DeleteTractionMultiTypeAction,
  DeleteTractionMultiTypeYearAction,
  EditTractionMultiTypeYearAction,
  SetNotificationsData,
  AttachedCompanyData,
} from "./models";
import DealActions from "./actions";
import {
  Currency,
  DealStatus,
  FileType,
  ValuationType,
} from "integration/api/DealsApi/models";
import { tractionMultiTypeInitial, tractionTypeInitial } from "./initial";

const defaultProfile = {
  activationDate: null,
  id: 0,
  title: "",
  businessId: "",
  content: "",
  logoFileId: null,
  creationDate: "",
  status: DealStatus.DRAFT,
  investmentCurrency: Currency.USD,
  minInvestmentAmount: 0,
  broadCategoryId: null,
  stageId: null,
  businessModelId: null,
  totalFundingGoal: {
    amount: 0,
    currency: Currency.USD,
    commitmentDeadline: "",
  },
  overview: {
    shortDescription: "",
    description: "",
    problem: "",
    solution: "",
    market: "",
    videos: [],
    team: [],
    pitchDecks: [],
    additionalMaterials: [],
  },
  arr: [],
  ebitda: [],
  revenue: [],
  gmv: [],
  mrr: [],
  ltv: {
    amount: 0,
    currency: null,
  },
  cac: {
    amount: 0,
    currency: null,
  },
  aov: {
    amount: 0,
    currency: null,
  },
  nps: 0,
  tractionOverview: "",
  details: {
    commitmentDeadline: "",
    availableAllocation: {
      amount: 0,
      currency: Currency.USD,
    },
    committedAllocationFromTaVentures: {
      amount: 0,
      currency: Currency.USD,
    },
    valuation: {
      type: ValuationType.POST_MONEY,
      amount: 0,
      currency: Currency.USD,
    },
    totalRoundSize: {
      amount: 0,
      currency: Currency.USD,
    },
  },
  parentDeal: null,
  notificationsData: {
    strengths: "",
    risks: "",
    linkToQACall: "",
    notificationText: "",
    newsletterPicture: null,
    callWithFounderDate: "",
  },
  dealCurrency: null,
  foundedYear: "",
  country: null,
  website: "",
  countryId: null,
  companyId: null,
  copyLogoFromCompany: false,
  dynamicLink: "",
  dealUrl: ""
};

const defaultState: DealPageState = {
  initialProfile: defaultProfile,
  profile: defaultProfile,
  technologies: [],
  stages: [],
  industries: [],
  businessModels: [],
  broadCategories: [],
  errors: [],
  hasErrors: false,
  overviewKey: 1,
};

const initialState = defaultState;

export const dealPageSlice = createSlice({
  name: "deal",
  initialState,
  reducers: {
    reset: () => defaultState,
    setDetailsAllocationCurrency: (state, action: PayloadAction<Currency>) => {
      state.profile.details = {
        ...(state.profile.details || {}),
        availableAllocation: {
          ...(state.profile.details?.availableAllocation || {}),
          currency: action.payload,
        },
      };
    },
    setDetailsAllocation: (state, action: PayloadAction<number | string>) => {
      state.profile.details = {
        ...(state.profile.details || {}),
        availableAllocation: {
          ...(state.profile.details?.availableAllocation || {}),
          amount: action.payload,
        },
      };
    },
    setDetailsCommitmentDeadline: (state, action: PayloadAction<string>) => {
      state.profile.details = {
        ...(state.profile.details || {}),
        commitmentDeadline: action.payload,
      };
    },
    setDetailsAllocationTA: (state, action: PayloadAction<number | string>) => {
      state.profile.details = {
        ...(state.profile.details || {}),
        committedAllocationFromTaVentures: {
          ...(state.profile.details?.committedAllocationFromTaVentures || {}),
          amount: action.payload,
        },
      };
    },
    setDetailsAllocationTACurrency: (
      state,
      action: PayloadAction<Currency>
    ) => {
      state.profile.details = {
        ...(state.profile.details || {}),
        committedAllocationFromTaVentures: {
          ...(state.profile.details?.committedAllocationFromTaVentures || {}),
          currency: action.payload,
        },
      };
    },
    setDetailsValuation: (state, action: PayloadAction<number | string>) => {
      state.profile.details = {
        ...(state.profile.details || {}),
        valuation: {
          ...(state.profile.details?.valuation || {}),
          amount: action.payload,
        },
      };
    },
    setDetailsValuationType: (state, action: PayloadAction<ValuationType>) => {
      state.profile.details = {
        ...(state.profile.details || {}),
        valuation: {
          ...(state.profile.details?.valuation || {}),
          type: action.payload,
        },
      };
    },
    setDetailsValuationCurrency: (state, action: PayloadAction<Currency>) => {
      state.profile.details = {
        ...(state.profile.details || {}),
        valuation: {
          ...(state.profile.details?.valuation || {}),
          currency: action.payload,
        },
      };
    },
    setDetailsTotalRoundSize: (
      state,
      action: PayloadAction<number | string>
    ) => {
      state.profile.details = {
        ...(state.profile.details || {}),
        totalRoundSize: {
          ...(state.profile.details?.totalRoundSize || {}),
          amount: action.payload,
        },
      };
    },
    setDetailsTotalRoundSizeCurrency: (
      state,
      action: PayloadAction<Currency>
    ) => {
      state.profile.details = {
        ...(state.profile.details || {}),
        totalRoundSize: {
          ...(state.profile.details?.totalRoundSize || {}),
          currency: action.payload,
        },
      };
    },
    setBusinessId: (state, { payload }) => {
      state.profile.businessId = payload;
    },
    setTitle: (state, { payload }) => {
      state.profile.title = payload;
    },
    setStatus: (state, { payload }) => {
      const isStatusChanged = state.profile.status !== payload;
      state.profile.status = payload;
      if (isStatusChanged) {
        state.errors = [];
      }
    },
    setStartDate: (state, { payload }) => {
      state.profile.creationDate = payload;
    },
    setDealVocabularyData: (
      state,
      action: PayloadAction<VocabularyDataDto>
    ) => {
      state.profile[action.payload.key] = action.payload.value;
    },
    setTotalGoal: (state, { payload }) => {
      state.profile.totalFundingGoal = {
        ...(state.profile.totalFundingGoal || {}),
        amount: payload,
      };
    },
    setTotalGoalCurrency: (state, { payload }) => {
      state.profile.totalFundingGoal = {
        ...(state.profile.totalFundingGoal || {}),
        currency: payload,
      };
    },
    setMinimalInvestments: (state, { payload }) => {
      state.profile.minInvestmentAmount = payload;
    },
    setMinimalInvestmentsCurency: (state, { payload }) => {
      state.profile.investmentCurrency = payload;
    },
    setOverviewShortDescription: (state, { payload }) => {
      state.profile.overview.shortDescription = payload;
    },
    setOverviewDescription: (state, { payload }) => {
      state.profile.overview.description = payload;
    },
    setOverviewProblem: (state, { payload }) => {
      state.profile.overview.problem = payload;
    },
    setOverviewSolution: (state, { payload }) => {
      state.profile.overview.solution = payload;
    },
    setOverviewMarket: (state, { payload }) => {
      state.profile.overview.market = payload;
    },
    setOverviewVideos: (state, { payload }) => {
      state.profile.overview.videos = payload;
    },
    setOverviewTeam: (state, { payload }) => {
      state.profile.overview.team = payload;
    },
    setOverviewPitchDecks: (state, { payload }) => {
      state.profile.overview.pitchDecks = payload;
    },
    setOverviewAdditionalMaterials: (state, { payload }) => {
      state.profile.overview.additionalMaterials = payload;
    },

    // Traction Multi Type
    editTractionMultiTypeYear: (
      state,
      { payload }: EditTractionMultiTypeYearAction
    ) => {
      const { key, year, oldYear } = payload;
      state.profile[key] = state.profile[key].map((item) => {
        if (item.tractionYear === oldYear) {
          return {
            ...item,
            tractionYear: year,
          };
        }
        return item;
      });
    },
    deleteTractionMultiTypeYear: (
      state,
      { payload }: DeleteTractionMultiTypeYearAction
    ) => {
      const { key, year } = payload;
      state.profile[key] = state.profile[key].filter(
        (item) => item.tractionYear !== year
      );
    },
    addTractionMultiType: (state, { payload }: AddTractionMultiTypeAction) => {
      const { metric, key, year } = payload;
      const empty = tractionMultiTypeInitial(metric, year);
      state.profile[key] = state.profile[key].concat(empty);
    },
    deleteTractionMultiType: (
      state,
      { payload }: DeleteTractionMultiTypeAction
    ) => {
      const { id, key } = payload;
      state.profile[key] = state.profile[key].filter((item) => item.id !== id);
    },
    editTractionMultiType: (
      state,
      { payload }: EditTractionMultiTypeAction
    ) => {
      const { key, id, index, month, currency } = payload;
      state.profile[key] = state.profile[key].map((item) => {
        if (id === item.id) {
          return {
            ...item,
            currency,
            amount: index,
            tractionMonth: month,
          };
        }
        return item;
      });
    },

    // Traction Type
    addTractionType: (state, { payload }: AddTractionTypeAction) => {
      const { metric, key } = payload;
      const empty = tractionTypeInitial(metric);
      state.profile[key] = state.profile[key].concat(empty);
    },
    deleteTractionType: (state, { payload }: DeleteTractionTypeAction) => {
      const { id, key } = payload;
      state.profile[key] = state.profile[key].filter((item) => item.id !== id);
    },
    editTractionType: (state, { payload }: EditTractionTypeAction) => {
      const { key, id, index, year, currency } = payload;
      state.profile[key] = state.profile[key].map((item) => {
        if (id === item.id) {
          return {
            ...item,
            currency,
            amount: index,
            tractionYear: year,
          };
        }
        return item;
      });
    },

    // Traction Amount Object
    addTractionAmount: (state, { payload }: AddTractionAmountAction) => {
      state.profile[payload] = { amount: 0, currency: null };
    },
    deleteTractionAmount: (state, { payload }: DeleteTractionAmountAction) => {
      state.profile[payload] = { amount: null, currency: null };
    },
    editTractionAmount: (state, { payload }: EditTractionAmountAction) => {
      const { key, data } = payload;
      state.profile[key] = { amount: data.amount, currency: data.currency };
    },

    // NPS
    setNPS: (state, { payload }: PayloadAction<number | null>) => {
      state.profile.nps = payload;
    },
    // traction overview
    setTractionOverview: (state, { payload }: PayloadAction<string | null>) => {
      state.profile.tractionOverview = payload;
    },
    resetDealProfile: (state) => {
      state.profile = defaultState.profile;
      state.stages = defaultState.stages;
      state.technologies = defaultState.technologies;
      state.broadCategories = defaultState.broadCategories;
      state.businessModels = defaultState.businessModels;
      state.industries = defaultState.industries;
      state.errors = [];
      state.hasErrors = false;
    },
    setErrors: (state, action: PayloadAction<string[]>) => {
      state.errors = action.payload;
    },
    setHasErrors: (state, action: PayloadAction<boolean>) => {
      state.hasErrors = action.payload;
    },
    setNotificationsData: (
      state,
      action: PayloadAction<SetNotificationsData>
    ) => {
      state.profile.notificationsData = {
        ...(state.profile.notificationsData || {}),
        [action.payload.key]: action.payload.value,
      };
    },
    setNewsletterPictureName: (state, action: PayloadAction<string>) => {
      state.profile.notificationsData = {
        ...(state.profile.notificationsData || {}),
        newsletterPicture: {
          ...(state.profile.notificationsData?.newsletterPicture! || {}),
          originalFileName: action.payload,
        },
      };
    },
    setPitchDecks: (state, action: PayloadAction<FileType>) => {
      state.profile.overview = {
        ...(state.profile.overview || {}),
        pitchDecks: [
          ...(state.profile.overview.pitchDecks || {}),
          {
            ...action.payload,
          },
        ],
      };
    },
    setAdditionalMaterial: (state, action: PayloadAction<FileType>) => {
      state.profile.overview = {
        ...(state.profile.overview || {}),
        additionalMaterials: [
          ...(state.profile.overview.additionalMaterials || {}),
          {
            ...action.payload,
          },
        ],
      };
    },
    setDealCurrency: (state, action: PayloadAction<Currency>) => {
      state.profile.dealCurrency = action.payload;
    },
    setFoundedYear: (state, action: PayloadAction<string>) => {
      state.profile.foundedYear = action.payload;
    },
    setCountryId: (state, action: PayloadAction<number>) => {
      state.profile.countryId = action.payload;
    },
    setWebsite: (state, action: PayloadAction<string | null>) => {
      state.profile.website = action.payload;
    },
    setCompanyId: (state, action: PayloadAction<number>) => {
      state.profile.companyId = action.payload;
    },
    setCopyLogoFromCompany: (state, action: PayloadAction<boolean>) => {
      state.profile.copyLogoFromCompany = action.payload;
    },
    setAttachedCompanyData: (
      state,
      action: PayloadAction<AttachedCompanyData>
    ) => {
      state.overviewKey = state.overviewKey + 1;
      state.profile.companyId = action.payload.companyId;
      state.profile.title = action.payload.title;
      state.profile.logoFileId = action.payload.logoFileId;
      state.profile.foundedYear = action.payload.foundedYear;
      state.profile.website = action.payload.website;
      state.profile.overview.description = action.payload.description;
      state.profile.copyLogoFromCompany = action.payload.copyLogoFromCompany;
    },
  },
  extraReducers: (builder) => {
    // createDeal
    builder.addCase(DealActions.createDeal.fulfilled, (state, action) => {
      state.profile.id = action.payload.id;
      state.profile.businessId = action.payload.businessId;
      state.profile.creationDate = action.payload.creationDate;
      state.initialProfile.id = action.payload.id;
      state.initialProfile.businessId = action.payload.businessId;
      state.initialProfile.creationDate = action.payload.creationDate;
    });
    //deletePitchDeck file
    builder.addCase(DealActions.deletePitchDeck.fulfilled, (state, action) => {
      state.profile.overview.pitchDecks = action.payload;
    });
    //deleteAdditionalMaterials file
    builder.addCase(
      DealActions.deleteAdditionalMaterials.fulfilled,
      (state, action) => {
        state.profile.overview.additionalMaterials = action.payload;
      }
    );

    builder.addCase(DealActions.getDealById.fulfilled, (state, action) => {
      const profileData = {
        ...action.payload,
        notificationsData: {
          strengths: action.payload.notificationsData?.strengths || "",
          risks: action.payload.notificationsData?.risks || "",
          linkToQACall: action.payload.notificationsData?.linkToQACall || "",
          notificationText:
            action.payload.notificationsData?.notificationText || "",
          newsletterPicture:
            action.payload.notificationsData?.newsletterPicture || null,
          callWithFounderDate:
            action.payload.notificationsData?.callWithFounderDate || "",
        },
      };
      state.profile = profileData;
      state.initialProfile = profileData;
    });
    // get technologies
    builder.addCase(DealActions.getTechnologies.fulfilled, (state, action) => {
      state.technologies = action.payload;
    });
    // get stages
    builder.addCase(DealActions.getStages.fulfilled, (state, action) => {
      state.stages = action.payload;
    });
    // get industries
    builder.addCase(DealActions.getIndustries.fulfilled, (state, action) => {
      state.industries = action.payload;
    });
    // get business models
    builder.addCase(
      DealActions.getBusinessModels.fulfilled,
      (state, action) => {
        state.businessModels = action.payload;
      }
    );
    // get broad categories
    builder.addCase(
      DealActions.getBroadCategories.fulfilled,
      (state, action) => {
        state.broadCategories = action.payload;
      }
    );
    // upload deal logo
    builder.addCase(DealActions.uploadDealLogo.fulfilled, (state, action) => {
      state.profile.logoFileId = action.payload.fileId;
      state.profile.copyLogoFromCompany = false;
    });
    // delete deal logo
    builder.addCase(DealActions.deleteLogo.fulfilled, (state) => {
      state.profile.logoFileId = null;
      state.profile.copyLogoFromCompany = false;
    });
    // update deal by id
    builder.addCase(DealActions.updateDealById.rejected, (state, action) => {
      const response = action.payload as AxiosError<{
        errorCode: string;
        errorMessage: string;
      }>;
      state.errors =
        response?.response?.data.errorMessage.replace(/\s/g, "").split(",") ||
        [];
      state.hasErrors = !!response?.response?.data.errorMessage;
      state.profile.copyLogoFromCompany = false;
    });
    // delete newsletter picture
    builder.addCase(DealActions.deleteNewsletterPicture.fulfilled, (state) => {
      state.profile.notificationsData!.newsletterPicture = null;
      state.initialProfile.notificationsData = {
        ...(state.initialProfile.notificationsData! || {}),
        newsletterPicture: null,
      };
    });
    // upload newsletter picture
    builder.addCase(
      DealActions.uploadNewsletterPicture.fulfilled,
      (state, action) => {
        state.profile.notificationsData!.newsletterPicture = {
          ...(state.profile.notificationsData!.newsletterPicture! || {}),
          id: action.payload.fileId,
        };
      }
    );
  },
});

export const {
  setBusinessId,
  setTitle,
  setStatus,
  setStartDate,
  setDealVocabularyData,
  setTotalGoal,
  setTotalGoalCurrency,
  setMinimalInvestments,
  setMinimalInvestmentsCurency,
  // overview
  setOverviewShortDescription,
  setOverviewDescription,
  setOverviewProblem,
  setOverviewSolution,
  setOverviewMarket,
  setOverviewVideos,
  setOverviewTeam,
  setOverviewPitchDecks,
  setOverviewAdditionalMaterials,
  resetDealProfile,
  setNotificationsData,
  setPitchDecks,
  setNewsletterPictureName,
  setAdditionalMaterial,
  setDealCurrency,
  setFoundedYear,
  setCountryId,
  setWebsite,
  setCopyLogoFromCompany,
} = dealPageSlice.actions;
export const DealSliceAction = dealPageSlice.actions;

export default dealPageSlice.reducer;
