import { v4 } from "uuid";

import {
  MetricType,
  DealTractionType,
  DealTractionMultiType,
} from "integration/api/DealsApi/models";

export const tractionTypeInitial = (
  metricType: MetricType
): DealTractionType => ({
  id: v4(),
  metricType,
  tractionYear: null,
  amount: null,
  currency: null,
});

export const tractionMultiTypeInitial = (
  metricType: MetricType,
  year: number
): DealTractionMultiType => ({
  id: v4(),
  metricType,
  amount: null,
  currency: null,
  tractionYear: year,
  tractionMonth: null,
});
