import styled from "@emotion/styled";
import Pagination from "@mui/material/Pagination";

import { colors } from "application/theme/palette";

export const EmptyPageContainer = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    flex: 0;
  }
  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    padding: 20px 32px;
    margin-top: 4px;
    color: ${colors.blue700};
    text-align: center;
    font-family: Unica77;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
`;
