import React, { FC, useState } from "react";
import { Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Button } from "application/components";
import PrimaryButton from "application/components/PrimaryButton";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import SuccessPopUp from "application/containers/SuccessPopUp";
import { requestSelector } from "application/store/request/selectors";
import { useAppSelector } from "application/hooks/useAppSelector";
import Loader from "application/components/Loader";
import { getNavigationState } from "application/helpers/navigationState";
import { SearchParamsRoutesNames } from "application/routes/constants";

import { CreateCommitmentDto } from "integration/api/PortfolioAPI/models";

import InvestorsActions from "features/portfolio-investors/store/actions";
import AddInvestor from "../AddInvestor";

import { HeaderProps } from "./model";
import {
  HeaderStyled,
  ButtonSection,
  LoaderContainer,
  BackButton,
} from "./styles";

const Header: FC<HeaderProps> = ({
  getInvestors,
  title,
  dealHasParent,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(requestSelector).createInvestor;
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const hideBackButton = window?.history?.length <= 2;

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const goBack = () => {
    const path = (window?.history?.state?.usr?.portfolioInvestors || -1) as any;
    navigate(path, { state: window?.history?.state?.usr });
  };

  const onSubmit = (data: CreateCommitmentDto) => {
    dispatch(InvestorsActions.createInvestor(data)).then(() => {
      getInvestors({ currentPage: 1 });
      closeModal();
      setShowSuccessModal(true);
    });
  };

  return (
    <>
      {loading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <HeaderStyled>
        {!hideBackButton && (
          <BackButton onClick={goBack}>
            <ArrowBackIcon />
            Back to portfolio
          </BackButton>
        )}
        <div>
          <Typography variant="h1">{title}</Typography>
          <ButtonSection>
            {!dealHasParent && (
              <Link
                to={"management-analytics"}
                state={getNavigationState(
                  SearchParamsRoutesNames.portfolioInvestorsManagementAnalytics
                )}
              >
                <Button variant="outlined" label="Managment analytics" />
              </Link>
            )}
            <PrimaryButton
              loading={false}
              onClick={openModal}
              title="Add investor"
              leftIcon={<AddIcon />}
            />
          </ButtonSection>
        </div>
      </HeaderStyled>
      {showModal && <AddInvestor onClose={closeModal} onSubmit={onSubmit} />}
      {showSuccessModal && (
        <SuccessPopUp
          title={"Commitment successfully added for the investor"}
          onSubmit={closeSuccessModal}
        />
      )}
    </>
  );
};

export default Header;
