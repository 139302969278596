import { authService } from "common";
import dayjs from "dayjs";

export const checkTokenExpiration = (): boolean => {
  const { accessTokenExpiresAt } = authService.getAuthTokenStorage();
  const expiresDate = accessTokenExpiresAt || "";
  const tokenExpiresDate = new Date(
    new Date(expiresDate).setMinutes(new Date(expiresDate).getMinutes() - 1)
  );
  const isTokenExpired = tokenExpiresDate <= new Date();
  const isDateValid = dayjs(tokenExpiresDate).diff(dayjs(), "minute") <= 59;

  return !isDateValid || isTokenExpired;
};
