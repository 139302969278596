import React from "react";

export const ArrowDropUp = ({ color = "#2B2B2B" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 9L7 14L8.175 15.175L12 11.3583L15.825 15.175L17 14L12 9Z"
        fill={color}
      />
    </svg>
  );
};
