import { CommonErrors } from "application/helpers/validations";
import * as yup from "yup";

export const commitmentsPopUpSchema = yup.object({
  investmentAmount: yup.number().required(CommonErrors.requiredField),
  currency: yup.string().required(CommonErrors.requiredField),
});

export const addInvestorSchema = yup.object({
  investorId: yup.number().required(CommonErrors.requiredField),
  investmentAmount: yup.number().required(CommonErrors.requiredField),
  currency: yup.string().required(CommonErrors.requiredField),
});
