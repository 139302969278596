import React, { FC, useEffect, useMemo, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import { Checkbox, Radio, Typography } from "@mui/material";

import { useAppSelector } from "application/hooks/useAppSelector";
import { ReactComponent as EditSvg } from "application/assets/icons/edit.svg";
import {
  NumberOfCompaniesToInvest,
  numberOfCompaniesToInvest,
} from "application/helpers/numberOfCompaniesToInvest";
import BasicPopUp from "application/containers/BasicPopUp";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { isResponseFulfilled } from "application/helpers/responseHelper";

import {
  BroadCategoryDto,
  NumberOfCompaniesToInvestDto,
  StageDto,
} from "integration/api/UsersApi/models";

import { usersSelector } from "features/users/store/selectors";
import UsersActions from "features/users/store/actions";

import { CopyButton } from "../UserInformation/styles";
import {
  Container,
  ExpandButton,
  Divider,
  Table,
  EditSurveyWrapper,
  TextNumber,
  EditWrapper,
} from "./styles";
import SuccessPopUp from "application/containers/SuccessPopUp";

const SurveyInformation: FC = () => {
  const dispatch = useAppDispatch();
  const { selected } = useAppSelector(usersSelector);
  const [showTable, setShowTable] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedStages, setSelectedStages] = useState<StageDto[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<
    BroadCategoryDto[]
  >([]);
  const [selectedNumberCompaniesToInvest, setSelectedNumberCompaniesToInvest] =
    useState<NumberOfCompaniesToInvestDto[]>([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const stages = useMemo(() => {
    if (!selected) return [];
    return selected.surveyInfoDto.stages.filter((item) => item.isChosen);
  }, [selected?.surveyInfoDto.stages]);

  const broadCategories = useMemo(() => {
    if (!selected) return [];
    return selected.surveyInfoDto.broadCategories.filter(
      (item) => item.isChosen
    );
  }, [selected?.surveyInfoDto.broadCategories]);

  const getCompanyInvestName = (type: NumberOfCompaniesToInvest) => {
    return (
      numberOfCompaniesToInvest.find((item) => item.value === type)?.label || 0
    );
  };

  const numberOfCompanies = useMemo(() => {
    if (!selected) return 0;

    const currentInvestType =
      selected.surveyInfoDto.numberOfCompaniesToInvest.find(
        (item) => !!item.isChosen
      );

    return currentInvestType
      ? getCompanyInvestName(currentInvestType?.numberOfCompaniesToInvest)
      : 0;
  }, [selected?.surveyInfoDto.numberOfCompaniesToInvest]);

  useEffect(() => {
    if (!!selected?.surveyInfoDto) {
      setSelectedStages(selected.surveyInfoDto.stages);
      setSelectedCategories(selected.surveyInfoDto.broadCategories);
      setSelectedNumberCompaniesToInvest(
        selected.surveyInfoDto.numberOfCompaniesToInvest
      );
    }
  }, [selected]);

  const handleClose = () => {
    setEdit(false);
  };

  const openEdit = () => {
    setEdit(true);
  };

  const handleShowTable = () => {
    setShowTable((prev) => !prev);
  };

  const handleStagesCheckbox = (optionId: number) => {
    setSelectedStages((prev) =>
      prev.map((item) => {
        if (item.id === optionId) {
          return {
            ...item,
            isChosen: !item.isChosen,
          };
        }
        return item;
      })
    );
  };

  const handleBroadCheckbox = (optionId: number) => {
    setSelectedCategories((prev) =>
      prev.map((item) => {
        if (item.id === optionId) {
          return {
            ...item,
            isChosen: !item.isChosen,
          };
        }
        return item;
      })
    );
  };

  const handleNumberToInvestCompanies = (type: NumberOfCompaniesToInvest) => {
    setSelectedNumberCompaniesToInvest((prev) =>
      prev.map((item) => {
        if (item.numberOfCompaniesToInvest === type) {
          return {
            ...item,
            isChosen: true,
          };
        }
        return { ...item, isChosen: false };
      })
    );
  };

  const onUpdateSurveyInfo = async () => {
    if (selected) {
      const updatedStage = selectedStages.filter((item) => item.isChosen);
      const updatedCategories = selectedCategories.filter(
        (item) => item.isChosen
      );
      const updatedNumberCompaniesToInvest =
        selectedNumberCompaniesToInvest.find((item) => item.isChosen)
          ?.numberOfCompaniesToInvest!;

      const res = await dispatch(
        UsersActions.updateSurveyInformation({
          id: selected.id,
          data: {
            stageIds: updatedStage.map((item) => item.id),
            broadCategoryIds: updatedCategories.map((item) => item.id),
            numberOfCompaniesToInvest: updatedNumberCompaniesToInvest,
          },
        })
      );
      isResponseFulfilled(res, async () => {
        await dispatch(UsersActions.getInvestorById(Number(selected.id)));
        handleClose();
        setShowSuccessModal(true);
      });
    }
  };

  const disableSaveButton = useMemo(() => {
    if (!selected) return false;

    const hasStagesChanges =
      JSON.stringify(selected.surveyInfoDto.stages) ===
      JSON.stringify(selectedStages);

    const hasCategoriesChanges =
      JSON.stringify(selected.surveyInfoDto.broadCategories) ===
      JSON.stringify(selectedCategories);

    const hasNumberCompaniesToInvestChanges =
      JSON.stringify(selected.surveyInfoDto.numberOfCompaniesToInvest) ===
      JSON.stringify(selectedNumberCompaniesToInvest);

    return (
      hasStagesChanges &&
      hasCategoriesChanges &&
      hasNumberCompaniesToInvestChanges
    );
  }, [
    selected,
    selectedStages,
    selectedCategories,
    selectedNumberCompaniesToInvest,
  ]);

  return (
    <Container>
      <Divider />
      <EditWrapper>
        <ExpandButton
          type="button"
          expanded={showTable}
          onClick={handleShowTable}
        >
          Survey information
          <span>
            <ExpandMoreIcon />
          </span>
        </ExpandButton>
        <CopyButton type="button" onClick={openEdit}>
          <EditSvg />
        </CopyButton>
      </EditWrapper>
      {showTable && (
        <Table>
          <thead>
            <tr>
              <th>Question</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>What stage do you prefer to invest in?</td>
              <td>
                <ol>
                  {stages.map((stage) => (
                    <li key={stage.id}>{stage.name}</li>
                  ))}
                </ol>
              </td>
            </tr>
            <tr>
              <td>In what industries do you prefer to invest in?</td>
              <td>
                <ol>
                  {broadCategories.map((category) => (
                    <li key={category.id}>{category.name}</li>
                  ))}
                </ol>
              </td>
            </tr>
            <tr>
              <td>In how many companies do you consider to invest annually?</td>
              <td>{numberOfCompanies}</td>
            </tr>
          </tbody>
        </Table>
      )}
      {edit && (
        <BasicPopUp
          title={"Edit user's survey"}
          onClose={handleClose}
          onSubmit={onUpdateSurveyInfo}
          disabled={disableSaveButton}
        >
          <EditSurveyWrapper>
            <Box>
              <Typography variant="h4">
                <TextNumber>1.</TextNumber>
                <span>What stage do you prefer to invest in?</span>
              </Typography>
              {selectedStages.map((item) => {
                return (
                  <Box display="flex" gap={2} alignItems="center" key={item.id}>
                    <Checkbox
                      checked={item.isChosen}
                      onChange={() => handleStagesCheckbox(item.id)}
                    />
                    <Box>{item.name}</Box>
                  </Box>
                );
              })}
            </Box>
            <Divider />
            <Box>
              <Typography variant="h4">
                <TextNumber>2.</TextNumber>
                <span>In what industries do you prefer to invest in?</span>
              </Typography>
              {selectedCategories.map((item) => {
                return (
                  <Box display="flex" gap={2} alignItems="center" key={item.id}>
                    <Checkbox
                      checked={item.isChosen}
                      onChange={() => handleBroadCheckbox(item.id)}
                    />
                    <Box>{item.name}</Box>
                  </Box>
                );
              })}
            </Box>
            <Divider />
            <Box>
              <Typography variant="h4">
                <TextNumber>3.</TextNumber>
                <span>
                  In how many companies do you consider to invest annually?
                </span>
              </Typography>
              {selectedNumberCompaniesToInvest.map((item) => {
                return (
                  <Box
                    display="flex"
                    gap={2}
                    alignItems="center"
                    key={item.numberOfCompaniesToInvest}
                  >
                    <Radio
                      onChange={() =>
                        handleNumberToInvestCompanies(
                          item.numberOfCompaniesToInvest
                        )
                      }
                      checked={item.isChosen}
                    />
                    <Box>
                      {getCompanyInvestName(item.numberOfCompaniesToInvest)}
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </EditSurveyWrapper>
        </BasicPopUp>
      )}
      {showSuccessModal && (
        <SuccessPopUp
          title={"Survey was successfully updated"}
          onSubmit={() => setShowSuccessModal(false)}
        />
      )}
    </Container>
  );
};

export default SurveyInformation;
