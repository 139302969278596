import React from "react";

import { NotFoundWrapper } from "./styles";
import { ReactComponent as NotFoundSvg } from "application/assets/images/not-found.svg";

interface Props {
  description?: string;
}

const NotFound = ({ description = "No results found!" }: Props) => {
  return (
    <NotFoundWrapper>
      <NotFoundSvg />
      <p>{description}</p>
    </NotFoundWrapper>
  );
};

export default NotFound;
