import styled from "@emotion/styled";

import { DealStatus } from "integration/api/DealsApi/models";

import { colors } from "application/theme/palette";

interface StyledProps {
  status?: DealStatus;
}

export const getStatusColor = (status?: DealStatus) => {
  switch (status) {
    case DealStatus.ACTIVE:
      return colors.green700;
    case DealStatus.DRAFT:
      return colors.orange700;
    case DealStatus.MOVED_TO_PORTFOLIO:
      return colors.green700;
    default:
      return colors.orange700;
  }
};

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 1px solid ${colors.grey100};
  }
`;

export const Status = styled.p<StyledProps>`
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  font-style: normal;
  letter-spacing: 0.07px;
  font-family: "Unica77";
  text-transform: capitalize;
  color: ${({ status }) => getStatusColor(status)};
`;

export const Total = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-top: 2px;
    margin-left: 4px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 20px;
    &:last-of-type {
      margin-right: 0;
    }
    border-radius: 4px;
    border: 1px solid ${colors.grey200};
  }
`;
