export interface PaletteColorOptions {
  main: string;
  light: string;
  dark?: string;
}
export interface PaletteOptions {
  primary: PaletteColorOptions;
  secondary: PaletteColorOptions;
  text: PalleteTextOptions;
  success: PaletteColorOptions;
  error: PaletteColorOptions;
  warning: PaletteColorOptions;
  info: PaletteColorOptions;
  common: ColorsPallet;
}
export interface PalleteTextOptions {
  primary: string;
  secondary: string;
  disabled?: string;
}

export const colors = {
  // Grey
  grey900: "#111928",
  grey800: "#1F2E4A",
  grey700: "#374151",
  grey500: "#6B7280",
  grey300: "#D1D5DB",
  grey200: "#EBEDF2",
  grey100: "#F3F6FB",
  grey50: "#F9FAFB",

  // Blue (main/light)
  blue900: "#0050C9",
  blue700: "#0063F6",
  blue500: "#478BFF",
  blue200: "#CCDFFF",
  blue50: "#F2F7FF",

  // Additional colours
  white: "#FFFFFF",
  error: "#FF3A30",
  success: "#1BA904",
  errorBg: "#FDF7F7",
  lemon: "#EDFF6B",

  // green
  green700: "#1BA904",
  green200: "#DFF3DC",

  // orange
  orange700: "#E5A100",
  orange200: "#FBF2DB",

  //red
  red700: "#FF3A30",

  // Natural
  natural100: "#FAF7FF",

  //black
  black: "#000000",
};

export type ColorsPallet = typeof colors;

const Palette: PaletteOptions = {
  primary: {
    main: "#0063F6",
    light: "#6D6D6D",
    dark: "#0a222a",
  },
  secondary: {
    main: "#C9C9C9",
    light: "#00B3B6",
    dark: "#818181",
  },
  text: {
    primary: "#2B2B2B",
    secondary: "#4D4D4D",
  },
  success: {
    main: "#42B271",
    light: "#0ec48c",
    dark: "#0d8253",
  },
  warning: {
    main: "#F77421",
    light: "#ffbc00",
    dark: "#f6993f",
  },
  error: {
    main: "#E82425",
    light: "#D7151F",
  },
  info: {
    main: "#2176FF",
    light: "#0099FF",
  },
  common: colors,
};
export default Palette;
