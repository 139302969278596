import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  EditRowSingleTypeSchema,
  EditRowSingleTypeFieldValues,
} from "./validation";

import { TextFieldStyled } from "application/components";
import PrimaryButton from "application/components/PrimaryButton";
import CurrencySelect from "application/components/CurrencySelect";

import { Currency } from "integration/api/DealsApi/models";
import { Card, Title, Wrapper, Actions, CancelButton } from "./styles";

interface Props {
  defaultValues?: EditRowSingleTypeFieldValues;
  onClose: () => void;
  onSubmit: (data: EditRowSingleTypeFieldValues) => void;
  isFactMetric: boolean;
}

const formatter = new Intl.NumberFormat("en-US");

const EditSingleDataPopUp = ({
  defaultValues,
  onClose,
  onSubmit,
  isFactMetric,
}: Props) => {
  const editRowSingleTypeSchema = EditRowSingleTypeSchema(isFactMetric);
  const {
    watch,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditRowSingleTypeFieldValues>({
    defaultValues: {
      ...defaultValues,
      currency: defaultValues?.currency || undefined,
    },
    resolver: yupResolver(editRowSingleTypeSchema),
  });

  const values = watch();

  const onIndexChange = (value: string) => {
    const numberValue = Number(value.replaceAll(",", ""));
    if (Number.isNaN(numberValue)) return;
    setValue("index", numberValue, { shouldValidate: !!errors.index });
  };

  const onCurrencyChange = (currency: Currency) => {
    setValue("currency", currency, { shouldValidate: true });
  };
  const disableSubmitButton = useMemo(() => {
    return !values.index || !values.year;
  }, [values, errors]);

  return (
    <Wrapper>
      <Card>
        <Title>Edit year</Title>

        <TextFieldStyled
          mb={20}
          id="year"
          fullWidth
          size="small"
          type="number"
          color="secondary"
          label="Enter year"
          filled={values.year}
          error={!!errors.year}
          {...register("year")}
        />

        <TextFieldStyled
          mb={20}
          fullWidth
          id="index"
          size="small"
          color="secondary"
          label="Enter index"
          filled={values.index}
          error={!!errors.index}
          value={watch("index") ? formatter.format(watch("index")) : ""}
          onChange={(e) => {
            onIndexChange(e.target.value);
          }}
        />

        <CurrencySelect
          maxHeight={300}
          selected={values.currency}
          onChange={onCurrencyChange}
          placeholder="Choose currency"
          error={!!errors.currency?.message}
        />

        <Actions>
          <CancelButton onClick={onClose}>
            <p>Cancel</p>
          </CancelButton>

          <PrimaryButton
            title="Ok"
            disabled={disableSubmitButton}
            onClick={handleSubmit(onSubmit)}
          />
        </Actions>
      </Card>
    </Wrapper>
  );
};

export default EditSingleDataPopUp;
