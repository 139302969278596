import React, { useEffect, useState } from "react";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useLocation, useNavigate } from "react-router-dom";

import { PathNames } from "application/routes";
import { ReactComponent as EditSvg } from "application/assets/icons/edit.svg";
import { ReactComponent as CloseSvg } from "application/assets/icons/close.svg";
import { ReactComponent as MoneysSvg } from "application/assets/icons/moneys.svg";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { formatTotal } from "application/helpers/formatTotal";
import { SearchParamsRoutesNames } from "application/routes/constants";
import { getNavigationState } from "application/helpers/navigationState";

import DealsActions from "features/deals/store/actions";
import { DealReducedDto, LogoType } from "integration/api/DealsApi/models";
import { formatDealTableDate } from "features/deals/helpers/date";

import { Actions, ImageContainer, Status, Total } from "./styles";

interface Props {
  item: DealReducedDto;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
}

const DealsTableRow = ({ item, onEdit, onDelete }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [logo, setLogo] = useState("");

  const status = item.status.toLowerCase();
  const date = formatDealTableDate(item.creationDate);
  const total = formatTotal(item.totalFundingGoal, item.investmentCurrency);

  useEffect(() => {
    if (item.logoFileId) {
      dispatch(
        DealsActions.getDealLogo({
          id: item.id,
          logoId: item.logoFileId,
          logoType: LogoType.THUMBNAIL,
        })
      ).then((response) => {
        const data = response.payload as Blob;
        const logoBlob = new Blob([data], {
          type: data.type as string,
        });
        const logoUrl = URL.createObjectURL(logoBlob);
        setLogo(logoUrl);
      });
    }
  }, [item.logoFileId]);

  const handleDelete = () => {
    onDelete(item.id);
  };

  const handleEdit = () => {
    onEdit(item.id);
  };

  const goToProfile = () => {
    navigate(
      `${PathNames.deals}/${item.id}/commitments`,
      getNavigationState(SearchParamsRoutesNames.commitments)
    );
  };

  return (
    <tr>
      <td id={`logo-${item.id}`}>
        {logo && (
          <ImageContainer>
            <img src={logo} alt="table-row" />
          </ImageContainer>
        )}
      </td>
      <td>{item.title}</td>
      <td>{item.businessId}</td>
      <td>{date}</td>
      <td>
        <Status status={item.status}>{status}</Status>
      </td>
      <td>
        <Total>
          <MoneysSvg />
          <p>{total}</p>
        </Total>
      </td>
      <td>
        <Actions>
          <PersonOutlineOutlinedIcon onClick={goToProfile} />
          <EditSvg onClick={handleEdit} />
          <CloseSvg onClick={handleDelete} />
        </Actions>
      </td>
    </tr>
  );
};

export default DealsTableRow;
