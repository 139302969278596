import { TypographyOptions } from "@mui/material/styles/createTypography";
import { pxToRem } from "./utils/formatFontSize";

const FONT_PRIMARY = "Unica77";
const Typography: TypographyOptions = {
  fontFamily: FONT_PRIMARY,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,

  h1: {
    color: "inherit",
    fontWeight: 700,
    fontSize: pxToRem(32),
    lineHeight: "140%",
    textTransform: "none",
  },
  h2: {
    color: "inherit",
    fontWeight: 700,
    fontSize: pxToRem(24),
    lineHeight: "130%",
    textTransform: "none",
  },
  h3: {
    color: "inherit",
    fontWeight: 400,
    fontSize: pxToRem(20),
    lineHeight: "140%",
    textTransform: "none",
  },
  h4: {
    color: "inherit",
    fontWeight: 500,
    fontSize: pxToRem(18),
    lineHeight: "130%",
    textTransform: "none",
  },
  // paragraph
  h5: {
    color: "inherit",
    fontWeight: 400,
    fontSize: pxToRem(18),
    lineHeight: "130%",
    textTransform: "none",
  },
  body1: {
    color: "inherit",
    fontWeight: 700,
    fontSize: pxToRem(16),
    lineHeight: "140%",
    textTransform: "none",
  },
  body2: {
    color: "inherit",
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: "140%",
    textTransform: "none",
  },
  button: {
    color: "inherit",
    fontWeight: 500,
    fontSize: pxToRem(16),
    lineHeight: "140%",
    textTransform: "none",
  },
  subtitle1: {
    color: "inherit",
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: "130%",
    textTransform: "none",
  },
  subtitle2: {
    color: "inherit",
    fontWeight: 500,
    fontSize: pxToRem(14),
    lineHeight: "130%",
    textTransform: "none",
  },
  overline: {
    color: "inherit",
    fontSize: pxToRem(12),
    lineHeight: "130%",
    fontWeight: 400,
    textTransform: "none",
  },
  // caption: {
  //   color: "inherit",
  //   fontWeight: 400,
  //   fontSize: pxToRem(14),
  //   lineHeight: pxToRem(20),
  //   textTransform: "none",
  // },
};

export default Typography;
