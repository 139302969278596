import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

export const OwnerInfo = styled("div")`
  display: flex;
  flex-direction: column;

  h2 {
    color: ${colors.grey900};
    font-family: Unica77;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.08px;
    margin-bottom: 4px;
  }
  p {
    color: ${colors.grey500};
    font-family: Unica77;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.06px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    margin-right: 20px;
    &:last-of-type {
      margin-right: 0px;
    }
  }
`;

export const ActionButton = styled("button")`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid ${colors.grey200};
  width: 24px;
  height: 24px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const CopyButton = styled("button")`
  position: relative;
  all: unset;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding: 2px;
  border-radius: 4px;
  border: 1.2px solid ${colors.grey200};
  background: ${colors.white};

  > p {
    position: absolute;
    left: 28px;
    padding: 4px 8px;
    color: ${colors.green700};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.06px;
    border-radius: 4px;
    background: ${colors.green200};
    z-index: 10;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    .tooltip {
      display: flex;
    }
  }
`;

export const CopyTooltip = styled("div")`
  position: absolute;
  left: 36px;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 4px;
  background: ${colors.grey700};
  //font
  color: ${colors.white};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.06px;

  svg {
    right: 33.5px;
    position: absolute;
    width: 29px;
    height: 29px;

    path {
      fill: ${colors.grey700};
    }
  }
`;
