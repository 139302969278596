import React, { FC } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { colors } from "application/theme/palette";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";
import { TextEditorWithTitle } from "application/components/TextEditorWithTitle";

import { LinkQA } from "features/deal-profile/containers/LinkQA";
import { NotificationDealText } from "features/deal-profile/containers/NotificationDealText";
import { NewsletterPicture } from "features/deal-profile/containers/NewsletterPicture";
import {
  DealSliceAction,
  setNotificationsData,
} from "features/deal-profile/store/slice";
import { CallWithFounderDate } from "features/deal-profile/containers/CallWithFounderDate";
import { DealErrorKey } from "features/deal-profile/helpers/formatDealError";
import { NotificationPreview } from "features/deal-profile/containers/NotificationPreview";

export const EmailNotificationsSection: FC = () => {
  const dispatch = useAppDispatch();
  const { profile, errors } = useAppSelector((state) => state.dealPage);
  const { loading } = useAppSelector(requestSelector).getDealById;
  const notificationsData = profile.notificationsData;
  const hasStrengthsError = errors.includes(
    DealErrorKey["notificationsData.strengths"]
  );
  const hasRisksError = errors.includes(
    DealErrorKey["notificationsData.risks"]
  );

  const handleChangeStrengths = (text: string) => {
    dispatch(setNotificationsData({ key: "strengths", value: text }));
    if (text && hasStrengthsError) {
      dispatch(
        DealSliceAction.setErrors(
          errors.filter(
            (item) => item !== DealErrorKey["notificationsData.strengths"]
          )
        )
      );
    }
  };

  const handleChangeRisks = (text: string) => {
    dispatch(setNotificationsData({ key: "risks", value: text }));
    if (text && hasRisksError) {
      dispatch(
        DealSliceAction.setErrors(
          errors.filter(
            (item) => item !== DealErrorKey["notificationsData.risks"]
          )
        )
      );
    }
  };

  return (
    <Box>
      <Typography variant="h2" color={colors.grey900} mb={4}>
        Email Notifications
      </Typography>
      <NewsletterPicture />
      {!loading && (
        <>
          <TextEditorWithTitle
            value={notificationsData?.strengths || ""}
            title="Strengths"
            onChange={handleChangeStrengths}
            hasError={hasStrengthsError}
          />
          <TextEditorWithTitle
            value={notificationsData?.risks || ""}
            title="Risks"
            onChange={handleChangeRisks}
            hasError={hasRisksError}
          />
        </>
      )}
      <NotificationDealText />
      <NotificationPreview />
      <CallWithFounderDate />
      <LinkQA />
    </Box>
  );
};
