import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

export const BackButton = styled("button")`
  all: unset;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.blue700};
  text-align: center;
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
  cursor: pointer;

  svg {
    margin-right: 8px;
    path {
      fill: ${colors.blue700};
    }
  }
`;

export const SearchSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;

  > h1 {
    color: ${colors.grey900};
    font-family: Unica77;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

export const Input = styled.input`
  height: 44px;
  width: 364px;
  outline: none;
  text-indent: 48px;
  border-radius: 8px;
  border: 1px solid ${colors.grey300};
  /* font */
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  font-style: normal;
  letter-spacing: 0.08px;
  font-family: Unica77;
  color: ${colors.grey700};
  &:focus {
    background-color: ${colors.blue50};
    border: 1px solid ${colors.blue700};
  }
`;

export const InputContainer = styled.div`
  position: relative;
  z-index: 3;
  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    top: 10px;
    left: 16px;
    position: absolute;
    color: ${colors.grey500};
  }
`;
