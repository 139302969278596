const sortObjectByOrder = (object: { [key: string]: any }) => {
  const orderedObj = Object.keys(object)
    .sort()
    .reduce((obj: any, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
  return orderedObj;
};

const deepComparison: any = (
  firstObj: any,
  secondObj: any,
  checkOrder: boolean
) => {
  if (!firstObj || !secondObj) return true;

  return Object.keys(firstObj).some((property) => {
    if (Array.isArray(firstObj)) {
      if (checkOrder) {
        const valueFirstObj = JSON.stringify(firstObj);
        const valueSecondObj = JSON.stringify(secondObj);
        if (valueFirstObj !== valueSecondObj) {
          return true;
        }
      }
      const filteredArray = firstObj.filter((item) => {
        const valueNotChanged = secondObj?.some((data: any) => {
          return JSON.stringify(data) === JSON.stringify(item);
        });
        return valueNotChanged;
      });
      if (firstObj.length !== filteredArray.length) {
        return true;
      }
      return false;
    }
    if (typeof firstObj[property] === "object" && firstObj[property] !== null) {
      return deepComparison(
        firstObj[property],
        secondObj[property],
        checkOrder
      );
    } else {
      if (firstObj && !secondObj) return true;
      const valueFirstObj = JSON.stringify(firstObj[property]);
      const valueSecondObj = JSON.stringify(secondObj[property]);
      if (valueFirstObj !== valueSecondObj) {
        return true;
      }
    }
    return false;
  });
};

const compareObjectsChanges = (
  firstObj: any,
  secondObj: any,
  checkOrder = false
) => {
  const isChanged = deepComparison(firstObj, secondObj, checkOrder);
  return isChanged;
};

export default compareObjectsChanges;
