import { createAsyncThunk } from "@reduxjs/toolkit";
import { CountryActions } from "application/constants/actions";
import CountryApi from "integration/api/CountryApi";

export const getCountries = createAsyncThunk(
  `countries/${CountryActions.getCountries}`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await CountryApi.getCountries();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
