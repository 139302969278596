import React, { FC } from "react";

import { useAppSelector } from "application/hooks/useAppSelector";
import { PaginationMUI } from "application/components";

import TableRow from "../TableRow";

import { TableProps } from "./model";
import { TableStyled, Wrapper } from "./styles";

const Table: FC<TableProps> = ({ page, onPageChange, handleDelete }) => {
  const { owners } = useAppSelector((state) => state.owners);

  return (
    <>
      <Wrapper>
        <TableStyled>
          <thead>
            <tr>
              <th>Deal owner</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {owners.content.map((item) => (
              <TableRow
                key={item.id}
                owner={item}
                handleDelete={handleDelete}
              />
            ))}
          </tbody>
        </TableStyled>
      </Wrapper>

      <PaginationMUI
        page={page}
        variant="text"
        color="primary"
        count={owners.totalPages}
        onChange={onPageChange}
      />
    </>
  );
};

export default Table;
