import dayjs from "dayjs";

export const getYearsOptions = (startFrom: number) => {
  const currentYear = dayjs(Date.now()).format("YYYY");
  const yearsList = Array.from(
    Array(Number(currentYear) + 1 - startFrom).keys()
  );
  const yearsOptions = yearsList.map((item) => ({
    label: String(item + startFrom),
    value: String(item + startFrom),
  }));

  return yearsOptions.reverse();
};
