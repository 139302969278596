import React, { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import MoneyIcon from "@mui/icons-material/Money";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";

import { PathNames } from "application/routes";

import { Investments } from "features/portfolio-investor-detail/components";

import CommitmentsTable from "../CommitmentsTable";
import InvestmentAnalytics from "../InvestmentAnalytics";

import { TabSectionProps, Tabs } from "./model";
import { Container, TabsController, ControllerButton, Divider } from "./styles";

const TabsSection: FC<TabSectionProps> = ({
  dealTitle,
  commitments,
  investment,
  lostReason,
}) => {
  const location = useLocation();
  const isDealInvestor = location.pathname.includes(PathNames.deals);
  const [currentTab, setCurrentTab] = useState<Tabs>(Tabs.Commitments);

  const isCommitmentsTab = currentTab === Tabs.Commitments;
  const isInvestmentsTab = currentTab === Tabs.Investments;
  const isInvestmentAnalyticsTab = currentTab === Tabs.InvestmentAnalytics;

  return (
    <Container>
      <h1>{dealTitle}</h1>
      <TabsController>
        <ControllerButton
          isActive={isCommitmentsTab}
          onClick={() => setCurrentTab(Tabs.Commitments)}
        >
          <MoneyIcon />
          <p>Commitments</p>
        </ControllerButton>
        {!isDealInvestor && (
          <>
            <ControllerButton
              isActive={isInvestmentsTab}
              onClick={() => setCurrentTab(Tabs.Investments)}
              disabled={!investment}
            >
              <AssessmentOutlinedIcon />
              <p>Investments</p>
            </ControllerButton>
            <ControllerButton
              isActive={isInvestmentAnalyticsTab}
              onClick={() => setCurrentTab(Tabs.InvestmentAnalytics)}
            >
              <AssignmentIcon />
              <p>Investment Analytics</p>
            </ControllerButton>
          </>
        )}

        <Divider />
      </TabsController>
      {isCommitmentsTab && (
        <CommitmentsTable
          commitments={commitments}
          isDealInvestor={false}
          lostReason={lostReason}
        />
      )}
      {isInvestmentsTab && <Investments />}
      {isInvestmentAnalyticsTab && <InvestmentAnalytics />}
    </Container>
  );
};

export default TabsSection;
