import { createSlice } from "@reduxjs/toolkit";

import ReferralsActions from "./actions";
import { ReferralsState } from "./model";

const initialState: ReferralsState = {
  referrals: {
    totalElements: 0,
    totalPages: 0,
    size: 0,
    content: [],
    number: 0,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    numberOfElements: 0,
    pageable: {
      offset: 0,
      sort: {
        empty: true,
        sorted: true,
        unsorted: true,
      },
      pageNumber: 0,
      pageSize: 0,
      paged: true,
      unpaged: true,
    },
    first: true,
    last: true,
    empty: true,
  },
};

export const referralsSlice = createSlice({
  name: "referrals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get referrals
    builder.addCase(
      ReferralsActions.getReferrals.fulfilled,
      (state, action) => {
        state.referrals = action.payload;
      }
    );
  },
});

export const OwnersSliceAction = referralsSlice.actions;
export default referralsSlice.reducer;
