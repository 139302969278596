import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { DealsTabs } from "./types";
import DealActions from "./store/actions";
import { resetDealProfile } from "./store/slice";
import { dealPageSelector } from "./store/selectors";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { DetectChangesSavedSliceActions } from "application/store/DetectChangesSaved/DetectChangesSavedSlice";

import { Tabs } from "./containers/Tabs";
import { Header } from "./components/Header";
import TopSection from "./containers/TopSection";
import DetailsSection from "./pages/DetailsSection";
import TractionSection from "./pages/TractionSection";
import { OverviewSection } from "./pages/OverviewSection";
import { EmailNotificationsSection } from "./pages/EmailNotificationsSection";

import { MainContentWrapper } from "./styles";
import { DealsSliceAction } from "features/deals/store/slice";

const DealProfile = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { businessId } = useAppSelector(dealPageSelector).profile;
  const [searchParams] = useSearchParams();

  const tab = searchParams.get("tab");

  const switchContent = () => {
    switch (tab) {
      case DealsTabs.overview:
        return <OverviewSection />;
      case DealsTabs.details:
        return <DetailsSection />;
      case DealsTabs.traction:
        return <TractionSection />;
      case DealsTabs.emailNotifications:
        return <EmailNotificationsSection />;
      default:
        return <OverviewSection />;
    }
  };

  useEffect(() => {
    if (!businessId && id) {
      dispatch(DealActions.getDealById(+id));
    }
  }, [businessId, id]);

  useEffect(() => {
    return () => {
      dispatch(resetDealProfile());
      dispatch(DetectChangesSavedSliceActions.reset());
      dispatch(DealsSliceAction.resetDeals());
    };
  }, []);

  return (
    <React.Fragment>
      <Header />
      <TopSection />
      <MainContentWrapper>
        <Tabs />
        {switchContent()}
      </MainContentWrapper>
    </React.Fragment>
  );
};

export default DealProfile;
