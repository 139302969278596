import { Currency } from "integration/api/DealsApi/models";
import { getCurrencySymbol } from "application/helpers/currency";

export const formatTotal = (price = 0, currency: Currency) => {
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(price) + getCurrencySymbol(currency);
};
