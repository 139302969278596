import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import LinkIcon from "@mui/icons-material/Link";
import CloseIcon from "@mui/icons-material/Close";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import { InputAdornment, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { TextFieldStyled } from "application/components";
import BasicPopUp from "application/containers/BasicPopUp";
import { CommonErrors } from "application/helpers/validations";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { colors } from "application/theme/palette";

import {
  DealSliceAction,
  setNotificationsData,
} from "features/deal-profile/store/slice";
import { DealErrorKey } from "features/deal-profile/helpers/formatDealError";

import {
  Container,
  Content,
  LinkItem,
  AddButton,
  DeleteButton,
  CopyButton,
  CopyTooltip,
} from "./styles";

const schema = yup
  .object({
    url: yup
      .string()
      .url(CommonErrors.wrongUrlFormat)
      .required(CommonErrors.requiredField),
  })
  .required();

export const LinkQA: FC = () => {
  const dispatch = useAppDispatch();
  const { profile, errors: dealErrors } = useAppSelector(
    (state) => state.dealPage
  );
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const notificationsData = profile.notificationsData;
  const hasError = dealErrors.includes(
    DealErrorKey["notificationsData.linkToQACall"]
  );

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<{ url: string }>({
    resolver: yupResolver(schema),
    defaultValues: { url: notificationsData?.linkToQACall || "" },
  });

  useEffect(() => {
    if (!watch("url")) {
      setValue("url", notificationsData?.linkToQACall || "");
    }
  }, [notificationsData?.linkToQACall]);

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseAddModal = () => {
    setShowAddModal(false);
    reset({ url: notificationsData?.linkToQACall || "" });
  };

  const submitAddLink = ({ url }: { url: string }) => {
    dispatch(setNotificationsData({ key: "linkToQACall", value: url }));
    setShowAddModal(false);
  };

  const handleDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };
  const submitDeleteLink = () => {
    dispatch(setNotificationsData({ key: "linkToQACall", value: "" }));
    handleCloseDeleteModal();
  };

  const handleChangeLink = (value: string) => {
    setValue("url", value, { shouldValidate: !!errors.url?.message });
    if (value && hasError) {
      dispatch(
        DealSliceAction.setErrors(
          dealErrors.filter(
            (item) => item !== DealErrorKey["notificationsData.linkToQACall"]
          )
        )
      );
    }
  };

  const copyTextToClipboard = async (text: string) => {
    return await navigator.clipboard.writeText(text);
  };

  const handleCopyLink = () => {
    copyTextToClipboard(watch("url")).then(() => {
      setIsLinkCopied(true);
      setTimeout(() => {
        setIsLinkCopied(false);
      }, 1500);
    });
  };

  return (
    <Container>
      <Typography
        variant="h3"
        color={hasError ? colors.red700 : colors.grey900}
        fontWeight={500}
      >
        Link to Q&A call
      </Typography>
      <Content hasError={hasError}>
        {notificationsData?.linkToQACall ? (
          <LinkItem>
            <div>
              <CopyButton type="button" onClick={handleCopyLink}>
                <LinkIcon />
                <CopyTooltip className="email-tooltip">
                  <ArrowLeftIcon />
                  Copy
                </CopyTooltip>
              </CopyButton>
              {isLinkCopied && <p>Copied!</p>}
              <h3 onClick={handleShowAddModal}>
                {notificationsData.linkToQACall}
              </h3>
            </div>
            <DeleteButton className="delete_btn" onClick={handleDeleteModal}>
              <CloseIcon />
            </DeleteButton>
          </LinkItem>
        ) : (
          <AddButton type="button" onClick={handleShowAddModal}>
            <span>Add</span> Link to Q&A call
          </AddButton>
        )}
        {showAddModal && (
          <BasicPopUp
            title="Add link to Q&A call"
            onClose={handleCloseAddModal}
            onSubmit={handleSubmit(submitAddLink)}
          >
            <form onSubmit={handleSubmit(submitAddLink)}>
              <Box>
                <TextFieldStyled
                  {...register("url")}
                  label={"Link"}
                  placeholder="https://www.example.com"
                  fullWidth
                  onChange={(e) => handleChangeLink(e.target.value)}
                  id="url"
                  type="url"
                  error={!!errors.url}
                  helperText={!!errors.url && errors.url?.message}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{
                          zIndex: 10,
                          paddingLeft: "2px",
                          marginRight: "-8px",
                        }}
                      >
                        {<LinkIcon />}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </form>
          </BasicPopUp>
        )}
        {showDeleteModal && (
          <BasicPopUp
            title="Delete link to Q&A call"
            onClose={handleCloseDeleteModal}
            onSubmit={submitDeleteLink}
          >
            <Box display="flex" alignItems="center">
              <Box mr={1.5}>
                <LinkIcon />
              </Box>
              <Typography variant="body2" color={colors.grey900}>
                {notificationsData?.linkToQACall}
              </Typography>
            </Box>
          </BasicPopUp>
        )}
      </Content>
    </Container>
  );
};
