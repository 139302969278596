import { StorageFields } from "application/constants/localStorage";
import { PathNames } from "application/routes";
import { store } from "application/store";
import { clearStore } from "application/store/Auth/AuthSlice";
import { authTokenStorage } from "application/types/localStorage";

const logoutChannel = new BroadcastChannel("logout");
const loginChannel = new BroadcastChannel("login");

export const logout = () => {
  logoutChannel.postMessage("Logout");
  window.location.href = window.location.origin + PathNames.login;
  store?.dispatch(clearStore());
};

export const login = () => {
  loginChannel.postMessage("Login");
  sessionStorage.setItem("email", "");
  sessionStorage.setItem("password", "");
  window.location.href = window.location.origin + PathNames.deals;
};

export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    logout();
    logoutChannel.close();
  };
};
export const loginAllTabs = () => {
  loginChannel.onmessage = () => {
    login();
    loginChannel.close();
  };
};

export const authService = {
  setAuthTokenStorage(data: authTokenStorage) {
    localStorage.setItem(StorageFields.authTokenStorage, JSON.stringify(data));
  },

  getAuthTokenStorage(): authTokenStorage {
    const data = localStorage.getItem(StorageFields.authTokenStorage);
    const authTokenStorage = data ? JSON.parse(data) : "";
    return {
      accessToken: authTokenStorage?.accessToken,
      refreshToken: authTokenStorage?.refreshToken,
      accessTokenExpiresAt: authTokenStorage?.accessTokenExpiresAt,
      userId: authTokenStorage?.userId,
    };
  },

  getAccessToken() {
    const data = localStorage.getItem(StorageFields.authTokenStorage);
    const authTokenStorage = data ? JSON.parse(data) : "";
    return authTokenStorage?.accessToken;
  },

  checkAuth() {
    const data = localStorage.getItem(StorageFields.authTokenStorage);
    const authTokenStorage = data ? JSON.parse(data) : null;
    return !!authTokenStorage?.accessToken;
  },

  removeToken() {
    localStorage.removeItem(StorageFields.authTokenStorage);
  },

  eraseSessionData() {
    this.removeToken();
  },

  setEmail(value: string) {
    sessionStorage.setItem("email", value);
  },
  setPassword(value: string) {
    sessionStorage.setItem("password", value);
  }
};
