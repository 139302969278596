import React from "react";

export const EuroIcon = ({ color = "#111928" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 5.70317L15.6394 7.28101C14.9241 6.70933 14.1149 6.42349 13.212 6.42349C11.9865 6.42349 11.0235 6.76649 10.3228 7.45251C9.62211 8.13853 9.20141 8.90743 9.06069 9.75924H15.0589L14.8303 10.9169H8.91997L8.90237 11.2513L8.91997 12.0402H14.5752L14.3465 13.1979H9.10466C9.32747 14.3584 9.80973 15.2116 10.5514 15.7576C11.2932 16.3035 12.1302 16.5765 13.0624 16.5765C14.1648 16.5765 15.0237 16.2935 15.6394 15.7276V17.5026C14.9123 17.8342 14.1032 18 13.212 18C9.97537 18 8.02287 16.3993 7.35444 13.1979H6L6.25506 12.0402H7.16974C7.15802 11.903 7.15216 11.6458 7.15216 11.2685V10.9169H6L6.25506 9.75924H7.28408C7.58897 8.1814 8.29551 6.99373 9.4037 6.19624C10.5119 5.39875 11.793 5 13.2471 5C14.3377 5 15.2553 5.23439 16 5.70317Z"
        fill={color}
      />
    </svg>
  );
};
