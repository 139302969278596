import { createSlice } from "@reduxjs/toolkit";
import PipedriveActions from "./ActionCreators";
import {
  PipedriveDealOwnerDto,
  PipedriveSourceDto,
} from "integration/api/PipedriveApi/models";

interface InitialState {
  pipedriveOwners: PipedriveDealOwnerDto[];
  pipedriveSources: PipedriveSourceDto[];
}

const initialState: InitialState = {
  pipedriveOwners: [],
  pipedriveSources: [],
};

const pipedriveOwnersSlice = createSlice({
  name: "pipedrive",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      PipedriveActions.getPipedriveOwnersRegulars.fulfilled,
      (state, action) => {
        state.pipedriveOwners = action.payload;
      }
    );
    builder.addCase(
      PipedriveActions.getPipedriveOwnersReferrals.fulfilled,
      (state, action) => {
        state.pipedriveOwners = action.payload;
      }
    );
    builder.addCase(
      PipedriveActions.getPipedriveSources.fulfilled,
      (state, action) => {
        state.pipedriveSources = action.payload;
      }
    );
  },
});

export default pipedriveOwnersSlice.reducer;
