import { createAsyncThunk } from "@reduxjs/toolkit";
import { RequestEnum } from "application/store/request/model";
import {
  GetPortfolioInvestmentParams,
  PortfolioParams,
} from "integration/api/PortfolioAPI/models";
import PortfolioApi from "integration/api/PortfolioAPI";

class PortfolioActions {
  static getPortfolio = createAsyncThunk(
    `portfolio/${RequestEnum.getPortfolio}`,
    async (params: PortfolioParams, { rejectWithValue }) => {
      try {
        const { data } = await PortfolioApi.getPortfolio(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
  static getPortfolioInvestmentTotal = createAsyncThunk(
    `portfolio/${RequestEnum.getPortfolioInvestmentTotal}`,
    async (params: GetPortfolioInvestmentParams, { rejectWithValue }) => {
      try {
        const { data } = await PortfolioApi.getPortfolioInvestmentTotal(params);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );
}

export default PortfolioActions;
