import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputAdornment, Typography } from "@mui/material";
import { LoginDataT } from "./types";

import {
  InfoWrapper,
  FormWrapper,
  LinkStyled,
  ErrorMessageWrapper,
} from "../styles";
import { AuthPageWrapper } from "../AuthPageWrapper";
import { Box } from "@mui/system";
import theme from "application/theme";
import { ShowPasswordIcon } from "../assets/ShowPasswordIcon";
import { HidePasswordIcon } from "../assets/HidePasswordIcon";
import { PathNames } from "application/routes";
import { useSignIn } from "./useSignIn";
import { LoadingButton, TextFieldStyled } from "application/components";
import { CommonErrors } from "application/helpers/validations";
import { useAppSelector } from "application/hooks/useAppSelector";

const schema = yup
  .object({
    email: yup
      .string()
      .email(CommonErrors.emailFormat)
      .required(CommonErrors.requiredField),
    password: yup.string().required(CommonErrors.requiredField),
  })
  .required();

export const Login: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<LoginDataT>({
    resolver: yupResolver(schema),
  });
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const { authError } = useAppSelector((state) => state.auth);

  const { SignIn, loading, error } = useSignIn();

  const onSubmit = async ({ email, password }: LoginDataT) => {
    if (!loading) {
      SignIn({ email, password });
    }
  };

  const filledEmail = watch("email")?.length ? 1 : 0;
  const filledPassword = watch("password")?.length ? 1 : 0;

  useEffect(() => {
    const email = sessionStorage.getItem("email") as string;
    if (email) {
      setValue("email", email, {
        shouldValidate: !!errors.email,
      });
    }
  }, []);

  return (
    <AuthPageWrapper>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <InfoWrapper>
          <Typography variant="h1" mb={1}>
            Sign In
          </Typography>
          <Typography variant="h3" color={theme.palette.common.grey700} mb={5}>
            Please, Sign In to your account
          </Typography>

          <FormWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
              {error?.length || authError ? (
                <ErrorMessageWrapper mb={2.5}>
                  <Typography
                    variant="caption"
                    color={theme.palette.common.error}
                  >
                    {error ? error : authError}
                  </Typography>
                </ErrorMessageWrapper>
              ) : null}
              <TextFieldStyled
                {...register("email")}
                filled={filledEmail}
                onChange={(e) =>
                  setValue("email", e.target.value, {
                    shouldValidate: !!errors.email,
                  })
                }
                fullWidth
                type="Email"
                id="email"
                label="Enter your email"
                size="small"
                color="secondary"
                error={!!errors.email}
                helperText={!!errors.email && errors.email?.message}
                mb={30}
              />
              <TextFieldStyled
                {...register("password")}
                filled={filledPassword}
                onChange={(e) =>
                  setValue("password", e.target.value, {
                    shouldValidate: !!errors.password,
                  })
                }
                fullWidth
                type={showPassword ? "text" : "password"}
                id="password"
                label="Enter your password"
                size="small"
                color="secondary"
                error={!!errors.password}
                helperText={!!errors.password && errors.password?.message}
                mb={30}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        onClick={handleClickShowPassword}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          zIndex: 10,
                        }}
                      >
                        {showPassword ? (
                          <ShowPasswordIcon
                            color={
                              !!errors.password
                                ? theme.palette.common.error
                                : theme.palette.common.grey500
                            }
                          />
                        ) : (
                          <HidePasswordIcon
                            color={
                              !!errors.password
                                ? theme.palette.common.error
                                : theme.palette.common.grey500
                            }
                          />
                        )}
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />

              <Box sx={{ display: "flex", justifyContent: "end" }} mb={3.5}>
                <LinkStyled to={PathNames.forgotPassword}>
                  Forgot Password?
                </LinkStyled>
              </Box>
              <LoadingButton
                label="Sign In"
                type="submit"
                variant="contained"
                width="100%"
                onClick={handleSubmit(onSubmit)}
                isLoading={loading}
                fullWidth
              />
            </form>
          </FormWrapper>
        </InfoWrapper>
      </Box>
    </AuthPageWrapper>
  );
};
