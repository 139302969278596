export enum CompanyErrorKey {
  "website" = "website",
  "name" = "name",
  "shortDescription" = "shortDescription",
  "shortDescriptionLength" = "shortDescriptionLength",
  "description" = "description",
  "team" = "team",
  "foundedYear" = "foundedYear",
  "logoFileId" = "logoFileId",
}

const getError = (key: CompanyErrorKey) => {
  switch (key) {
    case CompanyErrorKey["name"]:
      return "Company Name";
    case CompanyErrorKey["foundedYear"]:
      return "Year founded";
    case CompanyErrorKey["shortDescription"]:
      return "Short description";
    case CompanyErrorKey["shortDescriptionLength"]:
      return "Short description is longer than 40 characters";
    case CompanyErrorKey["description"]:
      return "Short overview";
    case CompanyErrorKey["team"]:
      return "Team";
    case CompanyErrorKey["website"]:
      return "Company website";
    case CompanyErrorKey["logoFileId"]:
      return "Logo";

    default:
      return key;
  }
};

export const formatCompanyError = (error: string): string[] => {
  const array = error?.split(",") || [];
  const errorsArray = array.map((item) => getError(item as CompanyErrorKey));
  return errorsArray;
};
