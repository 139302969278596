import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { TextFieldStyled } from "application/components";
import BasicPopUp from "application/containers/BasicPopUp";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";

import { testEmailSchema } from "features/deal-profile/helpers/testEmailSchema";

import { TestEmailPopUpProps } from "./models";

const TestEmailPopUp: FC<TestEmailPopUpProps> = ({ onClose, onSubmit }) => {
  const { loading } = useAppSelector(requestSelector).sendTestEmail;
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{ email: string }>({
    resolver: yupResolver(testEmailSchema),
  });

  const handleChangeEmail = (value: string) => {
    setValue("email", value, { shouldValidate: !!errors.email });
  };

  return (
    <BasicPopUp
      title="Test email"
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={loading}
      disabled={loading}
    >
      <TextFieldStyled
        {...register("email")}
        filled={watch("email")?.length}
        onChange={(e) => handleChangeEmail(e.target.value)}
        fullWidth
        type="Email"
        id="email"
        label="Enter your email"
        size="small"
        color="secondary"
        error={!!errors.email}
        helperText={!!errors.email && errors.email?.message}
      />
    </BasicPopUp>
  );
};

export default TestEmailPopUp;
