import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LinkIcon from "@mui/icons-material/Link";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";

import BasicPopUp from "application/containers/BasicPopUp";
import {
  PipedriveOwnersDropdown,
  PipedriveSourcesDropdown,
} from "application/components";

import { addOwnerSchema } from "features/owners/helpers/validations";

import { FormData, OwnerEditModalProps } from "./models";
import { Container, CopyButton, CopyTooltip, UrlField } from "./styles";

const OwnerEditModal: FC<OwnerEditModalProps> = ({
  isLoading,
  handleClose,
  onSubmit,
  selectedOwner,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(addOwnerSchema),
    defaultValues: {
      pipedriveUserId: selectedOwner?.pipedriveUserId || undefined,
      pipedriveSourceId: selectedOwner?.pipedriveSourceId || undefined,
    },
  });

  const onSubmitForm = (data: FormData) => {
    if (!selectedOwner) return;
    onSubmit({ id: selectedOwner.id, ...data });
  };

  const handleSelectOwner = (id: number) => {
    setValue("pipedriveUserId", id, {
      shouldValidate: !!errors.pipedriveUserId,
    });
  };

  const handleSelectSource = (id: number) => {
    setValue("pipedriveSourceId", id, {
      shouldValidate: !!errors.pipedriveSourceId,
    });
  };

  const copyTextToClipboard = async (text: string) => {
    return await navigator.clipboard.writeText(text);
  };

  const handleCopy = () => {
    copyTextToClipboard(selectedOwner?.referralUrl || "").then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    });
  };

  return (
    <BasicPopUp
      title="Edit deal owner"
      onClose={handleClose}
      onSubmit={handleSubmit(onSubmitForm)}
      isLoading={isLoading}
      disabled={isLoading}
    >
      <Container>
        <PipedriveOwnersDropdown
          value={watch("pipedriveUserId")}
          onChange={handleSelectOwner}
          placeholder="Deal owner"
          helperText="Deal owner"
          isReferrals={true}
        />
        <PipedriveSourcesDropdown
          value={watch("pipedriveSourceId")}
          onChange={handleSelectSource}
          placeholder="Deal source"
          helperText="Deal source"
        />
        <UrlField>
          <label>
            Url identifier
            <div />
          </label>
          <CopyButton type="button" onClick={handleCopy}>
            <LinkIcon />
            <CopyTooltip className="tooltip">
              <ArrowLeftIcon />
              Copy
            </CopyTooltip>
            {isCopied && <p>Copied!</p>}
          </CopyButton>
          <h2>{selectedOwner?.referralUrl}</h2>
        </UrlField>
      </Container>
    </BasicPopUp>
  );
};

export default OwnerEditModal;
