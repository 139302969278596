import * as React from "react";

import SideMenu from "features/side-menu";

import { Container, Wrapper } from "./styles";

type Props = { children: React.ReactNode };

const WithSideBar = ({ children }: Props) => (
  <Wrapper>
    <SideMenu />

    <Container>{children}</Container>
  </Wrapper>
);

export default WithSideBar;
