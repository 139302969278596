import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

interface StyledProps {
  active: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const Button = styled.button<StyledProps>`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 130px;
  border-radius: 100px;
  background: ${({ active }) => (active ? colors.blue700 : colors.white)};
  border: ${({ active }) => (active ? "none" : `1px solid ${colors.grey200}`)};
  &:first-of-type {
    margin-right: 10px;
  }
  cursor: pointer;

  p {
    font-family: "Unica77";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
    color: ${({ active }) => (active ? colors.white : colors.grey900)};
  }
`;
