import React, { FC, useEffect, useRef, useState } from "react";
import { Search } from "@mui/icons-material";

import useOnClickOutside from "application/hooks/useOnClickOutside";

import { Input, InputContainer, List, ListItem, Wrapper } from "./styles";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import UsersActions from "features/users/store/actions";
import { useDebounce } from "application/hooks/useDebounce";
import { UserStatus } from "application/helpers/userStatuses";

interface Props {
  searchItem: string;
  setSearchItem: (value: string) => void;
  status?: UserStatus | "";
}

const UsersSearchInput: FC<Props> = ({ searchItem, setSearchItem, status }) => {
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const { autosuggestions } = useAppSelector((state) => state.users);

  const [showList, setShowList] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const search = useDebounce(searchValue, 300);

  useOnClickOutside(ref, () => setShowList(false));

  useEffect(() => {
    if (showList) {
      dispatch(UsersActions.getSearchAutosuggestions({ search, status }));
    }
  }, [search, status]);

  const handleSearch = (value: string) => {
    if (!value && searchItem) {
      setSearchItem("");
    }
    setSearchValue(value);
  };

  const handleChoseSuggest = (value: string) => {
    setSearchValue(value);
    setSearchItem(value);
    setShowList(false);
  };

  const showSuggestions = showList && !!autosuggestions.length && searchValue;

  return (
    <Wrapper ref={ref}>
      <InputContainer>
        <Search />
        <Input
          placeholder="Search"
          value={searchValue}
          onChange={(e) => handleSearch(e.target.value)}
          onClick={() => setShowList(true)}
        />
      </InputContainer>

      {showSuggestions && (
        <List>
          {autosuggestions.map((user) => (
            <ListItem
              key={user.id}
              onClick={() =>
                handleChoseSuggest(`${user.firstName} ${user.lastName}`)
              }
            >
              <Search />
              <p>
                {user.firstName} {user.lastName}
              </p>
            </ListItem>
          ))}
        </List>
      )}
    </Wrapper>
  );
};

export default UsersSearchInput;
