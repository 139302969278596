import React, { FC } from "react";

import { Typography } from "@mui/material";

import PrimaryButton from "application/components/PrimaryButton";
import { SelectWithText } from "application/components";

import { consentsStatuses } from "features/consents/helpers/consentsStatuses";

import { HeaderProps } from "./model";
import { AddIcon, HeaderStyled, StatusWrapper } from "./styles";

const Header: FC<HeaderProps> = ({
  status,
  handleChangeStatus,
  handleShowAddModal,
}) => {
  return (
    <HeaderStyled>
      <Typography variant="h1">Consents</Typography>
      <div>
        <StatusWrapper>
          <SelectWithText
            placeholder="Status"
            onChange={(value) => {
              handleChangeStatus(String(value));
            }}
            options={consentsStatuses}
            selected={status}
            maxHeight={300}
          />
        </StatusWrapper>
        <PrimaryButton
          loading={false}
          onClick={handleShowAddModal}
          title="Add new consent"
          leftIcon={<AddIcon />}
        />
      </div>
    </HeaderStyled>
  );
};

export default Header;
