import React, { useState } from "react";
import type { Identifier, XYCoord } from "dnd-core";
import type { FC } from "react";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import { ItemTypes } from "./ItemTypes";
import { Box } from "@mui/system";
import { ItemWrapper } from "./styles";
import { CommonIconWrapper } from "../DragAndDropArea/styles";
import { Close, DragIndicator } from "@mui/icons-material";
import { ReactComponent as EditSvg } from "application/assets/icons/edit.svg";
import { Typography } from "@mui/material";
import theme from "application/theme";

export interface CardProps {
  id: any;
  text: string | undefined;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  cardItem: JSX.Element;
  removeCard: (id: any) => void;
  editCard?: (id: any) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const DragAndDropItem: FC<CardProps> = ({
  id,
  text,
  index,
  moveCard,
  cardItem,
  removeCard,
  editCard,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [hovered, setHovered] = useState(false);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <ItemWrapper
      ref={ref}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{ opacity: opacity }}
    >
      <Box
        display="flex"
        justifyItems="start"
        alignItems="start"
        width={"100%"}
      >
        <Box width={24} height={24} mr={0.5}>
          {hovered && <DragIndicator />}
        </Box>
        <Box mr={1} display="flex" alignItems="center">
          <Typography
            width={24}
            variant="body2"
            color={theme.palette.common.grey900}
          >
            {index + 1}.
          </Typography>
        </Box>

        <Box data-handler-id={handlerId}>{cardItem}</Box>
      </Box>
      <Box height={"100%"} display="flex" alignItems="center">
        {hovered && editCard && (
          <Box mr={3}>
            <CommonIconWrapper onClick={() => editCard(id)}>
              <EditSvg />
            </CommonIconWrapper>
          </Box>
        )}
        {hovered && (
          <Box>
            <CommonIconWrapper onClick={() => removeCard(id)}>
              <Close />
            </CommonIconWrapper>
          </Box>
        )}
      </Box>
    </ItemWrapper>
  );
};
