import { AxiosResponse } from "axios";

export const downloadDocument = ({
  response,
  fileName,
}: {
  response: AxiosResponse<string, any>;
  fileName: string;
}) => {
  const downloadUrl = window.URL.createObjectURL(new Blob([response?.data]));
  const link = document.createElement("a");
  link.href = downloadUrl;
  link.download = fileName;
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
  link.remove();
};
