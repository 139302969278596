import { EmailPasswordTypes } from "integration/types";
import { useState } from "react";
import CryptoJS from "crypto-js";
import { getResponseError } from "application/helpers/responseHelper";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { signInApi } from "application/store/Auth/ActionCreators";
import { useNavigate } from "react-router-dom";
import { PathNames } from "application/routes";
import { authService } from "../authService";

export const useSignIn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const SignIn = async ({ email, password }: EmailPasswordTypes) => {
    const key = process.env.REACT_APP_CRYPTO_JS_KEY || "";
    const parsedKey = CryptoJS.enc.Base64.parse(key);
    const parsedPassword = CryptoJS.enc.Utf8.parse(password);
    const encryptedPassword = CryptoJS.AES.encrypt(parsedPassword, parsedKey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();

    setLoading(true);
    authService.setEmail(email);
    authService.setPassword(encryptedPassword);
    const response = await dispatch(
      signInApi({
        email,
      })
    );
    const isResponseRejected = response.meta.requestStatus === "rejected";

    if (isResponseRejected) {
      const errorMessage = getResponseError({
        payload: response.payload,
        type: "",
      });
      setError(errorMessage);
    } else {
      navigate(PathNames.loginVerification)
    }
    setLoading(false);
  };

  return { SignIn, loading, error };
};
