import axiosInstance from "../axiosInstance";
import {
  AdminAnalyticPageResponse,
  AnalyticParamsDto,
  UpdateOrdinalDto,
  UploadAnalyticParamsDto,
} from "./models";

const AnalyticManagementApi = {
  getAnalyticsList: (id: number) => {
    return axiosInstance.get<AdminAnalyticPageResponse>(
      `/api/admin/deal/${id}/portfolio/analytics/list`
    );
  },
  updateAnalyticsList: (data: UpdateOrdinalDto) => {
    const { dealId, data: orderedData } = data;
    return axiosInstance.put(
      `/api/admin/deal/${dealId}/portfolio/analytics/list`,
      [...orderedData]
    );
  },
  uploadAnalytic: (data: UploadAnalyticParamsDto) => {
    const { dealId, file } = data;
    return axiosInstance.post(
      `/api/admin/deal/${dealId}/portfolio/analytics`,
      file
    );
  },
  getAnalyticByFileId: (data: AnalyticParamsDto) => {
    const { dealId, fileId } = data;

    return axiosInstance.get(
      `/api/admin/deal/${dealId}/portfolio/analytics/${fileId}`,
      {
        responseType: "blob",
        headers: {
          Accept: "application/octet-stream",
          "Content-Type": "application/octet-stream",
        },
      }
    );
  },
  deleteAnalytic: (data: AnalyticParamsDto) => {
    const { dealId, fileId } = data;
    return axiosInstance.delete(
      `/api/admin/deal/${dealId}/portfolio/analytics/${fileId}`
    );
  },
};

export default AnalyticManagementApi;
