import React, { FC } from "react";

import NoInternetConnectionImage from "application/assets/images/no-internet-connection.png";

import { NoInternetContainer } from "./styles";

const NoInternetConnectionPage: FC<{ isNoInternetConnection: boolean }> = ({
  isNoInternetConnection,
}) => {
  return (
    <NoInternetContainer isNoInternetConnection={isNoInternetConnection}>
      <img src={NoInternetConnectionImage} alt="NoInternetConnectionImage" />
      <h1>Sorry</h1>
      <h2>No Internet connection was found. </h2>
      <h3>Check your connection or try again.</h3>
    </NoInternetContainer>
  );
};

export default NoInternetConnectionPage;
