import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { DealsState } from "./model";
import DealsActions from "./actions";
import { DealSort } from "integration/api/DealsApi/models";

const initialState: DealsState = {
  deals: {
    data: [],
    totalPages: 0,
    sort: DealSort.desc,
  },
};

export const dealsSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<DealSort>) => {
      state.deals.sort = action.payload;
    },
    resetDeals: (state) => {
      state.deals.data = [];
      state.deals.totalPages = 0;
    },
  },
  extraReducers: (builder) => {
    // getDeals
    builder.addCase(DealsActions.getDeals.fulfilled, (state, action) => {
      state.deals.data = action.payload.content;
      state.deals.totalPages = action.payload.totalPages;
    });
  },
});

export const DealsSliceAction = dealsSlice.actions;
export default dealsSlice.reducer;
