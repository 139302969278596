import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

import { InvestmentStatus } from "integration/api/DealsApi/models";

interface StyledProps {
  status?: InvestmentStatus;
}

export const getStatusColor = (status?: InvestmentStatus) => {
  switch (status) {
    case InvestmentStatus.CONFIRMED:
      return colors.green700;
    case InvestmentStatus.PENDING:
      return colors.orange700;
    default:
      return colors.red700;
  }
};

export const Status = styled.p<StyledProps>`
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  font-style: normal;
  letter-spacing: 0.07px;
  font-family: "Unica77";
  text-transform: capitalize;
  color: ${({ status }) => getStatusColor(status)};
`;

export const Total = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-top: 2px;
    margin-left: 4px;
  }
`;
