import React, { FC, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { SelectWithText } from "application/components";
import { CommonErrors } from "application/helpers/validations";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";

import BasicPopUp from "../BasicPopUp";
import { LostDealPopUpProps } from "./model";
import LostReasonsActions from "./store/actions";
import { DropdownContainer } from "./styles";

const schema = yup
  .object({
    reason: yup.string().required(CommonErrors.requiredField),
  })
  .required();

const LostDealPopUp: FC<LostDealPopUpProps> = ({
  defaultValue,
  onClose,
  onSubmit,
  isLoading,
}) => {
  const dispatch = useAppDispatch();
  const { lostReasons } = useAppSelector((state) => state.lostReasons);

  const lostReasonsOptions = useMemo(() => {
    return lostReasons.map((item) => ({ value: item, label: item }));
  }, [lostReasons]);

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<{ reason: string }>({
    resolver: yupResolver(schema),
    defaultValues: { reason: defaultValue },
  });

  useEffect(() => {
    dispatch(LostReasonsActions.getLostReasons());
  }, []);

  useEffect(() => {
    if (!watch("reason")) {
      setValue("reason", defaultValue || "");
    }
  }, [defaultValue]);

  const handleSelectReason = (value: string) => {
    setValue("reason", value, { shouldValidate: !!errors.reason });
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSubmitForm = () => {
    if (isLoading) return false;
    onSubmit(watch("reason"));
    handleClose();
  };

  return (
    <BasicPopUp
      title="Lost deal"
      onClose={handleClose}
      onSubmit={handleSubmit(handleSubmitForm)}
      isLoading={isLoading}
    >
      <DropdownContainer>
        <SelectWithText
          placeholder="Select reason"
          onChange={(value) => handleSelectReason(value as string)}
          options={lostReasonsOptions}
          selected={watch("reason")}
          maxHeight={300}
          error={!!errors.reason}
          helperText={errors.reason?.message}
        />
      </DropdownContainer>
    </BasicPopUp>
  );
};

export default LostDealPopUp;
