import { PayloadAction } from "@reduxjs/toolkit";

import {
  Currency,
  DealStatus,
  MetricType,
  DealDetails,
  DealOverview,
  DealTractionType,
  DealTractionAmount,
  DealTotalFundingGoal,
  TechnologyDto,
  StageDto,
  IndustryDto,
  BusinessModelDto,
  BroadCategoryDto,
  DealTractionMultiType,
  NewsletterPictureDto,
} from "integration/api/DealsApi/models";
import { EditRowSingleTypeFieldValues } from "../popups/EditSingleDataPopUp/validation";

interface NotificationsData {
  strengths?: string;
  risks?: string;
  linkToQACall?: string;
  notificationText?: string;
  newsletterPicture?: NewsletterPictureDto | null;
  callWithFounderDate?: string;
}

interface Profile {
  id: number;
  title: string;
  businessId: string;
  content: string;
  logoFileId: number | null;
  creationDate: string;
  status: DealStatus;
  investmentCurrency: Currency;
  minInvestmentAmount: number | null;
  broadCategoryId: number | null;
  stageId: number | null;
  businessModelId: number | null;
  totalFundingGoal: DealTotalFundingGoal;
  overview: DealOverview;
  ebitda: DealTractionType[];
  revenue: DealTractionType[];
  arr: DealTractionType[];
  mrr: DealTractionMultiType[];
  gmv: DealTractionMultiType[];
  ltv: DealTractionAmount;
  cac: DealTractionAmount;
  aov: DealTractionAmount;
  nps: number | null;
  tractionOverview: string | null;
  details: DealDetails;
  notificationsData: NotificationsData | null;
  dealCurrency: Currency | null;
  foundedYear: string;
  website: string | null;
  countryId: number | null;
  companyId: number | null;
  copyLogoFromCompany: boolean;
  dynamicLink: string;
  dealUrl: string;
}
export interface DealPageState {
  initialProfile: Profile;
  profile: Profile;
  technologies: TechnologyDto[];
  stages: StageDto[];
  industries: IndustryDto[];
  businessModels: BusinessModelDto[];
  broadCategories: BroadCategoryDto[];
  errors: string[];
  hasErrors: boolean;
  overviewKey: number;
}

export interface AttachedCompanyData {
  companyId: number;
  logoFileId: number | null;
  title: string;
  foundedYear: string;
  website: string | null;
  description: string;
  copyLogoFromCompany: boolean;
}

export enum FilePurpose {
  pitchDeck = "PITCH_DECK",
  additionalMaterials = "ADDITIONAL_MATERIALS",
  newsletterPicture = "NEWSLETTER_PICTURE",
  logo = "LOGO",
}

export enum FileFormat {
  image = "IMAGE",
  pdf = "PDF",
}

export interface UploadFile {
  file: File;
  purpose: FilePurpose;
  type: FileFormat;
  dealId: number | undefined;
}

export interface VocabularyDataDto {
  key: VocabularyDataKeys;
  value: number;
}

// Traction Type
export enum TractionSingleTableKey {
  arr = "arr",
  revenue = "revenue",
  ebitda = "ebitda",
}

export type AddTractionTypeAction = PayloadAction<{
  key: TractionSingleTableKey;
  metric: MetricType;
}>;

export type EditTractionTypeAction = PayloadAction<
  EditRowSingleTypeFieldValues & {
    id?: string | number;
    key: TractionSingleTableKey;
  }
>;

export type DeleteTractionTypeAction = PayloadAction<{
  key: TractionSingleTableKey;
  id?: string | number;
}>;

// Traction Amount
export enum TractionAmountKey {
  ltv = "ltv",
  cac = "cac",
  aov = "aov",
}

export type AddTractionAmountAction = PayloadAction<TractionAmountKey>;

export type DeleteTractionAmountAction = PayloadAction<TractionAmountKey>;

export type EditTractionAmountAction = PayloadAction<{
  key: TractionAmountKey;
  data: DealTractionAmount;
}>;

type VocabularyDataKeys = "broadCategoryId" | "stageId" | "businessModelId";

// Traction Multi Type
export enum TractionMultiTableKey {
  mrr = "mrr",
  gmv = "gmv",
}

interface EditRowMultiTypeFieldValues {
  month?: number;
  index: number;
  currency: Currency;
}

export type AddTractionMultiTypeAction = PayloadAction<{
  key: TractionMultiTableKey;
  metric: MetricType;
  year: number;
}>;

export type DeleteTractionMultiTypeAction = PayloadAction<{
  key: TractionMultiTableKey;
  id?: string | number;
}>;

export type EditTractionMultiTypeAction = PayloadAction<
  EditRowMultiTypeFieldValues & {
    id?: string | number;
    key: TractionMultiTableKey;
  }
>;

export type DeleteTractionMultiTypeYearAction = PayloadAction<{
  key: TractionMultiTableKey;
  year: number;
}>;

export type EditTractionMultiTypeYearAction = PayloadAction<{
  key: TractionMultiTableKey;
  year: number;
  oldYear: number;
}>;

export interface SetNotificationsData {
  key: keyof NotificationsData;
  value: string | null | NewsletterPictureDto;
}
