import { Box, styled } from "@mui/system";
import theme from "application/theme";

export const FileInput = styled(Box)`
  border: 1px solid ${theme.palette.common.grey200};
  display: flex;
  align-items: center;
  border-radius: ${theme.spacing(1)};
  padding: ${theme.spacing(0, 2)};
  height: ${theme.spacing(5.5)};
  cursor: pointer;
`;
