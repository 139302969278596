import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {RequestEnum, RequestState} from './model';
import {getResponseError} from 'application/helpers/responseHelper';

const initialState = Object.fromEntries(
  Object.values(RequestEnum).map(key => [
    key,
    {
      loading: false,
      error: '',
    },
  ]),
) as RequestState;

export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    resetErrors: () => ({
      ...initialState,
    }),
  },
  extraReducers: builder => {
    builder.addMatcher(
      action => action.type.endsWith('/pending'),
      (state, action) => {
        const requestName = action.type.split('/')[1] as RequestEnum;
        if (state[requestName]) {
          state[requestName].error = '';
          state[requestName].loading = true;
        }
      },
    );
    builder.addMatcher(
      action => action.type.endsWith('/rejected'),
      (state, action: PayloadAction<any>) => {
        const requestName = action.type.split('/')[1] as RequestEnum;
        if (state[requestName]) {
          state[requestName].error = getResponseError(action);
          state[requestName].loading = false;
        }
      },
    );
    builder.addMatcher(
      action => action.type.endsWith('/fulfilled'),
      (state, action) => {
        const requestName = action.type.split('/')[1] as RequestEnum;
        if (state[requestName]) {
          state[requestName] = initialState[requestName];
        }
      },
    );
  },
});

export const {resetErrors} = requestSlice.actions;
export default requestSlice.reducer;
