import React, { useMemo, useState } from "react";

import { TractionSingleDataProps } from "./models";
import { MetricType } from "integration/api/DealsApi/models";
import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { DealSliceAction } from "features/deal-profile/store/slice";
import { dealPageSelector } from "features/deal-profile/store/selectors";
import { singleTypeThArray } from "features/deal-profile/constants/data";
import { TractionTableItem } from "features/deal-profile/components/TractionTable/models";
import { EditRowSingleTypeFieldValues } from "features/deal-profile/popups/EditSingleDataPopUp/validation";
import { DealErrorKey } from "features/deal-profile/helpers/formatDealError";

import ConfirmPopUp from "application/containers/ConfirmPopUp";
import EmptyTraction from "features/deal-profile/components/EmptyTraction";
import TractionTable from "features/deal-profile/components/TractionTable";
import EditSingleDataPopUp from "features/deal-profile/popups/EditSingleDataPopUp";
import ForecastFactSwitch from "features/deal-profile/components/ForecastFactSwitch";

import { Container, Title, Wrapper } from "./styles";

const TractionSingleData = ({
  title,
  tractionKey,
}: TractionSingleDataProps) => {
  const key = tractionKey;
  const dispatch = useAppDispatch();
  const data = useAppSelector(dealPageSelector).profile[key];
  const { errors } = useAppSelector((state) => state.dealPage);
  const hasTractionError = errors.includes(DealErrorKey.tractions);

  const [metric, setMetric] = useState(MetricType.FORECAST);
  const [editRowVisible, setEditRowVisible] = useState(false);
  const [deleteRowVisible, setDeleteRowVisible] = useState(false);
  const [activeRow, setActiveRow] = useState<TractionTableItem | null>(null);

  // add row
  const onAddRow = () => {
    dispatch(DealSliceAction.addTractionType({ metric, key }));
  };

  // edit row
  const clearLocalState = () => {
    setActiveRow(null);
    setEditRowVisible(false);
    setDeleteRowVisible(false);
  };
  const onEditRow = (item: TractionTableItem) => {
    setActiveRow(item);
    setEditRowVisible(true);
  };
  const onEditRowSubmit = (data: EditRowSingleTypeFieldValues) => {
    const id = activeRow!.id;
    dispatch(DealSliceAction.editTractionType({ ...data, key, id }));
    clearLocalState();
    hasTractionError &&
      dispatch(
        DealSliceAction.setErrors(
          errors.filter((item) => item !== DealErrorKey.tractions)
        )
      );
  };

  // delete row
  const onDeleteRow = (item: TractionTableItem) => {
    setActiveRow(item);
    setDeleteRowVisible(true);
  };
  const onDeleteRowSubmit = () => {
    const id = activeRow!.id;
    dispatch(DealSliceAction.deleteTractionType({ id, key }));
    clearLocalState();
  };

  const tableData = useMemo(() => {
    const array: TractionTableItem[] = data
      .filter((item) => item.metricType === metric)
      .map((item) => ({
        id: item.id,
        index: item.amount,
        currency: item.currency,
        title: item.tractionYear,
        titleOriginal: item.tractionYear,
      }));
    return array;
  }, [data, metric]);

  const defaultValues = useMemo(() => {
    if (activeRow) {
      return {
        year: activeRow.title,
        index: activeRow.index,
        currency: activeRow.currency,
      } as EditRowSingleTypeFieldValues;
    }
  }, [activeRow]);

  const deleteYear = activeRow?.title || "empty row";
  const deleteDescription = `You want to delete ${deleteYear}. Are you sure?`;

  return (
    <React.Fragment>
      <Wrapper>
        <Title>{title}</Title>
        <Container>
          <ForecastFactSwitch metric={metric} setMetric={setMetric} />

          {tableData.length ? (
            <TractionTable
              data={tableData}
              onAddRow={onAddRow}
              onEditRow={onEditRow}
              onDeleteRow={onDeleteRow}
              thArray={singleTypeThArray}
            />
          ) : (
            <EmptyTraction onClick={onAddRow} title="new year " />
          )}
        </Container>
      </Wrapper>

      {editRowVisible && (
        <EditSingleDataPopUp
          onClose={clearLocalState}
          onSubmit={onEditRowSubmit}
          defaultValues={defaultValues}
          isFactMetric={metric === MetricType.FACT}
        />
      )}
      {deleteRowVisible && (
        <ConfirmPopUp
          title="Delete row"
          onClose={clearLocalState}
          onSubmit={onDeleteRowSubmit}
          description={deleteDescription}
        />
      )}
    </React.Fragment>
  );
};

export default TractionSingleData;
