import { SaveAlt } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import BasicPopUp from "application/containers/BasicPopUp";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import theme from "application/theme";
import { DragAndDropArea } from "features/deal-profile/components/DragAndDropArea";
import { dealPageSelector } from "features/deal-profile/store/selectors";
import React, { Fragment, useEffect, useState } from "react";
import { Item } from "../Videos";
import { ErrorMessage, FileInput } from "./styles";
import { colors } from "application/theme/palette";
import { OverviewSectionUploads } from "features/deal-profile/pages/OverviewSection/types";
import {
  setAdditionalMaterial,
  setOverviewAdditionalMaterials,
} from "features/deal-profile/store/slice";
import DealActions from "features/deal-profile/store/actions";
import { FileFormat, FilePurpose } from "features/deal-profile/store/models";
import { useParams } from "react-router-dom";
import { requestSelector } from "application/store/request/selectors";
import { AttachmentType, FileType } from "integration/api/DealsApi/models";
import { isResponseFulfilled } from "application/helpers/responseHelper";

export const AdditionalMaterialsContainer = () => {
  const { id } = useParams();
  const { overview } = useAppSelector(dealPageSelector).profile;
  const materials = overview.additionalMaterials;
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(requestSelector).uploadAdditionalMaterials;
  const deleteAdditionalMaterials =
    useAppSelector(requestSelector).deleteAdditionalMaterials;
  const isDeleting = deleteAdditionalMaterials.loading;

  const [additionalMaterials, setAdditionalMaterials] = useState<Item[]>([]);
  const [newAdditionalMaterial, setNewAdditionalMaterial] = useState<File>();
  const [addAdditionalMaterialModal, setAddAdditionalMaterialModal] =
    useState(false);
  const [removeAdditionalMaterialModal, setRemoveAdditionalMaterialModal] =
    useState(false);
  const [selectedAdditionalMaterial, setSelectedAdditionalMaterial] =
    useState<FileType>();
  const [fileSizeError, setFileSizeError] = useState(false);

  useEffect(() => {
    const additionalMaterialsArray = materials
      .map((e) => {
        return { id: e.id, fileName: e.originalFileName, ordinal: e.ordinal };
      })
      .sort((a, b) => {
        return (a.ordinal || 0) - (b.ordinal || 0);
      });
    setAdditionalMaterials(additionalMaterialsArray);
  }, [materials.length]);

  useEffect(() => {
    if (!isDeleting) {
      setRemoveAdditionalMaterialModal(false);
    }
  }, [isDeleting]);

  useEffect(() => {
    if (!loading) {
      setNewAdditionalMaterial(undefined);
      setAddAdditionalMaterialModal(false);
      setFileSizeError(false);
    }
  }, [loading]);

  const setAdditionalMaterialsInStore = (array: Item[]) => {
    const additionalMaterialsArray = array.map((e, idx) => {
      return { ...e, id: e.id, fileName: e.fileName, ordinal: idx + 1 };
    });
    setAdditionalMaterials(additionalMaterialsArray);
    const sortedMaterials = additionalMaterialsArray.map((item) => {
      const currentMaterial = materials.find(
        (material) => material.id === item.id
      );
      return currentMaterial
        ? { ...currentMaterial, ordinal: item.ordinal }
        : item;
    });
    dispatch(setOverviewAdditionalMaterials(sortedMaterials));
  };

  const addAdditionalMaterial = () => {
    if (newAdditionalMaterial) {
      const fileFormat = newAdditionalMaterial.type.split("/")[1];
      const isPdf = fileFormat === "pdf";
      dispatch(
        DealActions.uploadAdditionalMaterials({
          file: newAdditionalMaterial,
          type: isPdf ? FileFormat.pdf : FileFormat.image,
          purpose: FilePurpose.additionalMaterials,
          dealId: Number(id),
        })
      ).then((res) => {
        isResponseFulfilled(res, () => {
          const payload = res.payload as { fileId: number };
          dispatch(
            setAdditionalMaterial({
              id: payload.fileId,
              originalFileName: newAdditionalMaterial.name,
              ordinal: materials.length + 1,
              attachmentType: AttachmentType.PDF,
              attachmentPurpose: FilePurpose.pitchDeck,
              s3FileName: "",
            })
          );
        });
      });
    }
  };

  const handleCloseModal = () => {
    setAddAdditionalMaterialModal(false);
    setFileSizeError(false);
    setNewAdditionalMaterial(undefined);
  };

  const handleDeleteAdditionalMaterial = (id: any) => {
    const AdditionalMaterialToRemove = materials.filter((e) => e.id === id)[0];
    setSelectedAdditionalMaterial(AdditionalMaterialToRemove);
    setRemoveAdditionalMaterialModal(true);
  };

  const submitDeletingAdditionalMaterial = () => {
    dispatch(
      DealActions.deleteAdditionalMaterials({
        fileId: Number(selectedAdditionalMaterial?.id),
        dealId: Number(id),
      })
    );
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      const selectableMaxFileSize = 1024 * 1024 * 10;
      const file = e?.target?.files[0];
      if (file.size <= selectableMaxFileSize) {
        setNewAdditionalMaterial(file);
        setFileSizeError(false);
      } else {
        setNewAdditionalMaterial(undefined);
        setFileSizeError(true);
      }
    }
  };

  return (
    <Box>
      <DragAndDropArea
        header={OverviewSectionUploads.additionalMaterials}
        cards={additionalMaterials}
        setCards={setAdditionalMaterialsInStore}
        addCard={() => setAddAdditionalMaterialModal(true)}
        removeCard={handleDeleteAdditionalMaterial}
        placeholder="Add additional material"
      />
      {addAdditionalMaterialModal && (
        <BasicPopUp
          title="Add additional material"
          onClose={handleCloseModal}
          onSubmit={addAdditionalMaterial}
          disabled={!newAdditionalMaterial || loading}
          isLoading={loading}
        >
          <Box>
            <Fragment>
              <input
                color="primary"
                accept="application/pdf, image/png, image/jpeg"
                type="file"
                onChange={(e) => handleFileInput(e)}
                id="icon-button-file"
                style={{ display: "none" }}
              />
              <label htmlFor="icon-button-file">
                <FileInput hasError={fileSizeError}>
                  <Box mr={1}>
                    <SaveAlt />
                  </Box>
                  <Typography
                    variant="body2"
                    color={fileSizeError ? colors.red700 : colors.grey900}
                  >
                    {newAdditionalMaterial?.name
                      ? newAdditionalMaterial.name
                      : "Upload the file"}
                  </Typography>
                </FileInput>
              </label>
            </Fragment>
            {fileSizeError && (
              <ErrorMessage>The file size is more than 10 MB</ErrorMessage>
            )}
          </Box>
        </BasicPopUp>
      )}
      {removeAdditionalMaterialModal && (
        <BasicPopUp
          title="Delete additional material"
          onClose={() => setRemoveAdditionalMaterialModal(false)}
          onSubmit={submitDeletingAdditionalMaterial}
          isLoading={isDeleting}
          disabled={isDeleting}
        >
          <Box display="flex" alignItems="center">
            <Box mr={1.5}>
              <SaveAlt />
            </Box>
            <Typography variant="body2" color={theme.palette.common.grey900}>
              {selectedAdditionalMaterial?.originalFileName}
            </Typography>
          </Box>
        </BasicPopUp>
      )}
    </Box>
  );
};
