import {
  DealDto,
  GetDealsDto,
  DeleteFileParams,
  GetDealsResponseDto,
  CreateDealResponseDto,
  BroadCategoryDto,
  TechnologyDto,
  StageDto,
  IndustryDto,
  BusinessModelDto,
  GetDealLogoParams,
  UploadDealLogoParams,
  DeleteDealLogoParams,
  UploadDealLogoDto,
  InvestorParams,
  InvestorDetailsResponse,
  CreateCommitmentResponse,
  CreateCommitmentDto,
  DealInvestorResponse,
  SendTestEmailParams,
  GetAttachmentFilePrams,
  DealInvestorsParamsAPI,
} from "./models";
import axiosInstance from "../axiosInstance";
import { DealPageState } from "features/deal-profile/store/models";

const DealsApi = {
  getDeals: (params: GetDealsDto) => {
    const { search, ...restParams } = params;
    return axiosInstance.get<GetDealsResponseDto>("/api/admin/deal/list", {
      params: {
        ...restParams,
        ...(!!search ? { search } : {}),
      },
    });
  },

  createDeal: (companyId: number) => {
    return axiosInstance.post<CreateDealResponseDto>("/api/admin/deal", {
      companyId,
    });
  },

  getDeal: (id: number) => {
    return axiosInstance.get<DealDto>(`/api/admin/deal/${id}`);
  },

  uploadFile: ({
    data,
    dealId,
  }: {
    data: FormData;
    dealId: number | undefined;
  }) => {
    return axiosInstance.post<{ fileId: number }>(
      `/api/admin/deal/${dealId}/file`,
      data
    );
  },

  deleteFile: ({ fileId, dealId }: DeleteFileParams) => {
    return axiosInstance.delete(`/api/admin/deal/${dealId}/file/${fileId}`);
  },

  updateDealById: ({ data }: { data: DealPageState["profile"] }) => {
    const formattedVideos = data.overview.videos.map((video) => {
      if (typeof video.id === "string") {
        return { ordinality: video.ordinality, url: video.url, deepDiveVideo: video.deepDiveVideo };
      }
      return video;
    });

    return axiosInstance.put(`/api/admin/deal`, {
      ...data,
      overview: { ...data.overview, videos: formattedVideos },
    });
  },

  deleteDeal: (id: number) => {
    return axiosInstance.delete(`/api/admin/deal/${id}`);
  },
  getTechnologies: () => {
    return axiosInstance.get<TechnologyDto[]>("/api/admin/deal/technologies");
  },
  getStages: () => {
    return axiosInstance.get<StageDto[]>("/api/admin/deal/stages");
  },
  getIndustries: () => {
    return axiosInstance.get<IndustryDto[]>("/api/admin/deal/industries");
  },
  getBusinessModels: () => {
    return axiosInstance.get<BusinessModelDto[]>(
      "/api/admin/deal/business-models"
    );
  },
  getBroadCategories: () => {
    return axiosInstance.get<BroadCategoryDto[]>(
      "/api/admin/deal/broad-categories"
    );
  },
  getDealLogo: ({ id, logoId, logoType }: GetDealLogoParams) => {
    return axiosInstance.get<Blob>(`/api/admin/deal/${id}/logo/${logoId}`, {
      params: { logoType },
      responseType: "blob",
      headers: {
        Accept: "application/octet-stream",
        "Content-Type": "application/octet-stream",
      },
    });
  },
  uploadDealLogo: ({ id, data }: UploadDealLogoParams) => {
    return axiosInstance.post<UploadDealLogoDto>(
      `/api/admin/deal/${id}/logo`,
      data
    );
  },
  deleteLogo: ({ id, logoId }: DeleteDealLogoParams) => {
    return axiosInstance.delete(`/api/admin/deal/${id}/logo/${logoId}`);
  },
  getDealInvestors: (params: DealInvestorsParamsAPI) => {
    const { dealId, key, ...restParams } = params;
    return axiosInstance.get<DealInvestorResponse>(
      `/api/admin/deal/${dealId}/investor/list`,
      {
        params: restParams,
        headers: {
          "X-Encryption-Key": key,
        },
      }
    );
  },
  getInvestorById: (params: InvestorParams) => {
    const { dealId, investorId } = params;
    return axiosInstance.get<InvestorDetailsResponse>(
      `/api/admin/deal/${dealId}/investor/${investorId}`
    );
  },
  addDealInvestor: (data: CreateCommitmentDto) => {
    const { dealId, ...restData } = data;
    return axiosInstance.post<CreateCommitmentResponse>(
      `/api/admin/deal/${dealId}/investors`,
      restData
    );
  },
  getLostReasons: () => {
    return axiosInstance.get<string[]>("/api/admin/deal/lost-reasons");
  },
  sendTestEmail: ({ id, email }: SendTestEmailParams) => {
    return axiosInstance.post(`/api/admin/deal/${id}/test-email`, {
      email,
    });
  },
  getAttachmentFile: ({ id, fileId }: GetAttachmentFilePrams) => {
    return axiosInstance.get(`/api/admin/deal/${id}/file/${fileId}`, {
      responseType: "blob",
      headers: {
        Accept: "application/octet-stream",
        "Content-Type": "application/octet-stream",
      },
    });
  },
};

export default DealsApi;
