import { createAsyncThunk } from "@reduxjs/toolkit";
import { AuthActions } from "application/constants/actions";
import {
  authService,
  login,
  logout,
} from "common/auth/authService";
import { AuthAPI } from "integration/api/Auth";
import { ConfirmVerificationTypes, EmailTypes } from "integration/types";

export const refreshToken = createAsyncThunk(
  `auth/${AuthActions.refreshToken}`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await AuthAPI.refreshToken();
      authService.setAuthTokenStorage(data);
      return data;
    } catch (e) {
      logout();
      return rejectWithValue(e);
    }
  }
);

export const signInApi = createAsyncThunk(
  `auth/${AuthActions.signInApi}`,
  async ({ email }: EmailTypes, { rejectWithValue }) => {
    try {
      const data = await AuthAPI.signInApi({
        email,
      });
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const signInConfirmationApi = createAsyncThunk(
  `auth/${AuthActions.signInApi}`,
  async (
    { email, password, verificationCode }: ConfirmVerificationTypes,
    { rejectWithValue }
  ) => {
    try {
      const data = await AuthAPI.signInConfirmationApi({
        email,
        password,
        verificationCode,
      });
      authService.setAuthTokenStorage(data);
      login();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const logOut = createAsyncThunk(
  `auth/${AuthActions.logout}`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await AuthAPI.logOut();
      sessionStorage.setItem("email", "")
      logout();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getProfile = createAsyncThunk(
  `auth/${AuthActions.getProfile}`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await AuthAPI.getProfile();
      return data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
