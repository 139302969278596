import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 1px solid ${colors.grey100};
  }
`;

export const Total = styled.div`
  display: flex;
  align-items: center;
  p {
    color: ${colors.grey900};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
    margin: 2px 4px 0;
  }
  span {
    color: ${colors.grey500};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
    margin: 2px 0 0 4px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid ${colors.grey200};

    > svg {
      width: 20px;
      height: 20px;

      path {
        fill: ${colors.grey700};
      }
    }
  }
`;

export const MultiRowStyled = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    h2 {
      color: ${colors.grey700};
      font-family: Unica77;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.07px;
      width: 172px;
    }

    p {
      color: ${colors.grey900};
      font-family: Unica77;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: 0.07px;
    }
  }
`;
