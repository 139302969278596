import React from "react";

export const ArrowDropDown = ({ color = "#374151" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.825 9L12 12.8167L8.175 9L7 10.175L12 15.175L17 10.175L15.825 9Z"
        fill={color}
      />
    </svg>
  );
};
