import React, { useEffect, useState } from "react";
import {
  DropdownItem,
  DropdownStyle,
  SelectButton,
  SelectContainer,
  InputSearch,
  NoResultsText,
} from "./styles";
import { ClickAwayListener, Typography } from "@mui/material";
import theme from "application/theme";
import { ArrowDropUp } from "application/assets/ArrowDropUp";
import { ArrowDropDown } from "application/assets/ArrowDropDown";
import { Box } from "@mui/system";
import { useDebounce } from "application/hooks/useDebounce";

export interface CustomSelectI {
  onChange: (value: number | string) => void;
  placeholder: string;
  options: Array<{ label: string; value: number | string }>;
  selected?: string | number;
  fontWeight?: number;
  maxHeight: number;
  error?: boolean;
  withSearch?: boolean;
  search?: string;
  setSearch?: (value: string) => void;
  searchPlaceholder?: string;
}

export const CustomSelect = ({
  onChange,
  placeholder,
  options,
  selected,
  fontWeight,
  maxHeight,
  error,
  withSearch,
  search,
  setSearch,
  searchPlaceholder,
}: CustomSelectI) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(search || "");
  const searchItem = useDebounce(searchValue, 300);

  useEffect(() => {
    if (!!setSearch && withSearch) {
      setSearch(searchItem);
    }
  }, [searchItem]);

  const toggleSelect = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (value: number | string) => {
    if (onChange) onChange(value);
    handleClose();
  };

  const label = options.filter((e) => e.value === selected)[0]?.label;

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <SelectContainer>
        <SelectButton
          onClick={toggleSelect}
          active={open ? 1 : 0}
          filled={selected ? 1 : 0}
          hasError={error}
        >
          <Typography
            variant="body2"
            mr={1}
            color={
              error ? theme.palette.common.red700 : theme.palette.common.grey900
            }
            fontWeight={fontWeight}
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
          >
            {label ? label : placeholder}
          </Typography>
          {open ? (
            <ArrowDropUp
              color={
                error
                  ? theme.palette.common.red700
                  : theme.palette.common.grey500
              }
            />
          ) : (
            <ArrowDropDown
              color={
                error
                  ? theme.palette.common.red700
                  : theme.palette.common.grey500
              }
            />
          )}
        </SelectButton>
        <DropdownStyle visible={open ? 1 : 0} maxHeight={open ? maxHeight : 0}>
          <Box px={1.5} bgcolor={theme.palette.common.white}>
            {withSearch && (
              <InputSearch
                placeholder={searchPlaceholder}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            )}
            {options.map((e, index) => (
              <DropdownItem
                onClick={() => handleChange(e.value)}
                active={e.value === selected ? 1 : 0}
                key={e.value || index}
              >
                {e.label}
              </DropdownItem>
            ))}
            {withSearch && !options.length && (
              <NoResultsText>No results</NoResultsText>
            )}
          </Box>
        </DropdownStyle>
      </SelectContainer>
    </ClickAwayListener>
  );
};
