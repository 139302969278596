import React, { FC, Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SaveAlt } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import BasicPopUp from "application/containers/BasicPopUp";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { colors } from "application/theme/palette";
import { requestSelector } from "application/store/request/selectors";
import { isResponseFulfilled } from "application/helpers/responseHelper";

import { dealPageSelector } from "features/deal-profile/store/selectors";
import DealActions from "features/deal-profile/store/actions";
import { FileFormat, FilePurpose } from "features/deal-profile/store/models";
import {
  DealSliceAction,
  setNewsletterPictureName,
} from "features/deal-profile/store/slice";
import { DealErrorKey } from "features/deal-profile/helpers/formatDealError";

import {
  AddButton,
  Container,
  Content,
  DeleteButton,
  ErrorMessage,
  FileInput,
  PictureItem,
} from "./styles";

export const NewsletterPicture: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { notificationsData } = useAppSelector(dealPageSelector).profile;
  const { errors } = useAppSelector((state) => state.dealPage);

  const { loading } = useAppSelector(requestSelector).uploadNewsletterPicture;
  const { loading: loadingDeleteNewsletterPicture } =
    useAppSelector(requestSelector).deleteNewsletterPicture;

  const [picture, setPicture] = useState<File>();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);

  const hasError = errors.includes(
    DealErrorKey["notificationsData.newsletterPicture"]
  );

  const handleCloseAddModal = () => {
    setShowAddModal(false);
    setFileSizeError(false);
    setPicture(undefined);
  };

  const handleShowAddModal = () => {
    setShowAddModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleShowDeleteModal = () => {
    setShowDeleteModal(true);
  };

  const handleAddPicture = () => {
    if (picture) {
      dispatch(setNewsletterPictureName(picture.name));
      dispatch(
        DealActions.uploadNewsletterPicture({
          file: picture,
          type: FileFormat.image,
          purpose: FilePurpose.newsletterPicture,
          dealId: Number(id),
        })
      ).then((res) => {
        isResponseFulfilled(res, () => {
          handleCloseAddModal();
          if (hasError) {
            dispatch(
              DealSliceAction.setErrors(
                errors.filter(
                  (item) =>
                    item !== DealErrorKey["notificationsData.newsletterPicture"]
                )
              )
            );
          }
        });
      });
    }
  };

  const handleDelete = () => {
    dispatch(
      DealActions.deleteNewsletterPicture({
        fileId: Number(notificationsData?.newsletterPicture?.id),
        dealId: Number(id),
      })
    ).then((res) =>
      isResponseFulfilled(res, () => {
        handleCloseDeleteModal();
      })
    );
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      const selectableMaxFileSize = 1024 * 1024 * 10;
      const file = e?.target?.files[0];
      if (file.size <= selectableMaxFileSize) {
        setPicture(file);
        setFileSizeError(false);
      } else {
        setPicture(undefined);
        setFileSizeError(true);
      }
    }
  };

  useEffect(() => {
    return () => {
      handleCloseAddModal();
    };
  }, []);

  return (
    <Container>
      <Typography
        variant="h3"
        color={hasError ? colors.red700 : colors.grey900}
        fontWeight={500}
      >
        Newsletter picture
      </Typography>
      <Content hasError={hasError}>
        {!!notificationsData?.newsletterPicture?.id ? (
          <PictureItem>
            <div>
              <SaveAlt />
              <h3>{notificationsData.newsletterPicture.originalFileName}</h3>
            </div>
            <DeleteButton
              className="delete_btn"
              onClick={handleShowDeleteModal}
            >
              <CloseIcon />
            </DeleteButton>
          </PictureItem>
        ) : (
          <AddButton type="button" onClick={handleShowAddModal}>
            Drop the files here <span>upload</span>
          </AddButton>
        )}
        {showAddModal && (
          <BasicPopUp
            title="Add newsletter picture"
            onClose={handleCloseAddModal}
            onSubmit={handleAddPicture}
            disabled={!picture || loading}
            isLoading={loading}
          >
            <Box>
              <Fragment>
                <input
                  color="primary"
                  accept="image/jpeg,image/png"
                  type="file"
                  onChange={(e) => handleFileInput(e)}
                  id="icon-button-file"
                  style={{ display: "none" }}
                />
                <label htmlFor="icon-button-file">
                  <FileInput hasError={fileSizeError}>
                    <Box mr={1}>
                      <SaveAlt />
                    </Box>
                    <Typography
                      variant="body2"
                      color={fileSizeError ? colors.red700 : colors.grey900}
                    >
                      {picture?.name ? picture.name : "Upload the file"}
                    </Typography>
                  </FileInput>
                </label>
              </Fragment>
              {fileSizeError && (
                <ErrorMessage>The file size is more than 10 MB</ErrorMessage>
              )}
            </Box>
          </BasicPopUp>
        )}
        {showDeleteModal && (
          <BasicPopUp
            title="Delete newsletter picture"
            onClose={handleCloseDeleteModal}
            onSubmit={handleDelete}
            disabled={loadingDeleteNewsletterPicture}
            isLoading={loadingDeleteNewsletterPicture}
          >
            <Box display="flex" alignItems="center">
              <Box mr={1.5}>
                <SaveAlt />
              </Box>
              <Typography variant="body2" color={colors.grey900}>
                {notificationsData?.newsletterPicture?.originalFileName}
              </Typography>
            </Box>
          </BasicPopUp>
        )}
      </Content>
    </Container>
  );
};
