import { styled } from "@mui/system";
import { colors } from "application/theme/palette";
import { InvestmentStatus } from "integration/api/DealsApi/models";

interface DropdownContainerProps {
  status?: InvestmentStatus;
}

export const getStatusColor = (status?: InvestmentStatus) => {
  switch (status) {
    case InvestmentStatus.CONFIRMED:
      return colors.green700;
    case InvestmentStatus.PENDING:
      return colors.orange700;
    case InvestmentStatus.CANCELED:
      return colors.red700;
    default:
      return colors.orange700;
  }
};

export const Actions = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  svg {
    width: 20px;
    height: 20px;
  }

  a,
  button {
    all: unset;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid ${colors.grey200};
  }
`;

export const DropdownContainer = styled("div")<DropdownContainerProps>`
  > div {
    > div {
      > div:first-of-type {
        border: none;
        outline: none;
        width: fit-content;
        background-color: transparent;
        p {
          color: ${({ status }) => getStatusColor(status)};
        }
      }
    }
  }
`;

export const MultiRowStyled = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > div {
    display: flex;
    align-items: center;
    gap: 8px;

    h2 {
      color: ${colors.grey500};
      font-family: Unica77;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.07px;
      width: 52px;
    }

    p {
      color: ${colors.grey900};
      font-family: Unica77;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.07px;
    }
  }
`;

export const InvestmentAnalyticsStyled = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    h2 {
      width: 172px;
    }
  }

  & > div:last-of-type {
    gap: 12px;
    margin: 0 20px;
  }
`;
