import React, { FC, memo, useMemo, useState } from "react";
import { Box } from "@mui/system";
import { ErrorOutline } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { v4 } from "uuid";

import { TeamDto } from "integration/api/CompanyApi/models";

import theme from "application/theme";
import { useAppSelector } from "application/hooks/useAppSelector";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import BasicPopUp from "application/containers/BasicPopUp";

import { OverviewSectionUploads } from "features/deal-profile/pages/OverviewSection/types";
import { DragAndDropArea } from "features/deal-profile/components/DragAndDropArea";
import { CompanySliceAction } from "features/companies/store/slice";

import TeamMemberAddModal from "../TeamMemberAddModal";
import { TeamMembersProps } from "./model";

const TeamMembers: FC<TeamMembersProps> = ({ hasError }) => {
  const { company } = useAppSelector((state) => state.companyList);
  const dispatch = useAppDispatch();
  const [newTeamMember, setNewTeamMember] = useState<TeamDto>();
  const [addTeamMemberModal, setAddTeamMemberModal] = useState(false);
  const [removeTeamMemberModal, setRemoveTeamMemberModal] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState<TeamDto | null>(
    null
  );
  const [isEditing, setIsEditing] = useState(false);

  const teamMembers = useMemo(() => {
    const teamMembersArray = company?.team
      .map((e) => {
        return {
          id: e.id,
          url: e.linkedinUrl,
          firstName: e.firstName,
          lastName: e.lastName,
          title: e.title,
          ordinality: e.ordinality,
          imageUrl: e.imageUrl,
          imageName: e.imageName,
          isResponsibleForQa: !!e.isResponsibleForQa,
          base64Image: e.base64Image || "",
          imageDeleted: e.imageDeleted,
        };
      })
      .sort((a, b) => {
        return (a.ordinality || 0) - (b.ordinality || 0);
      });
    return teamMembersArray;
  }, [company?.team]);

  const setTeamMembersInStore = (teamMembers: TeamDto[]) => {
    const team: TeamDto[] = teamMembers.map((e, idx) => {
      return {
        id: e.id,
        ordinality: idx + 1,
        firstName: e.firstName,
        lastName: e.lastName,
        title: e.title,
        linkedinUrl: e.url,
        imageUrl: e.imageUrl,
        imageName: e.imageName,
        isResponsibleForQa: !!e.isResponsibleForQa,
        base64Image: e.base64Image || "",
        imageDeleted: e.imageDeleted,
      };
    });
    dispatch(CompanySliceAction.setTeam(team));
  };

  const addTeamMember = () => {
    dispatch(
      CompanySliceAction.setTeam([
        ...(company?.team || []),
        {
          id: newTeamMember?.id,
          linkedinUrl: newTeamMember?.linkedinUrl,
          ordinality: company?.team.length || 0 + 1,
          title: newTeamMember?.title,
          firstName: newTeamMember?.firstName,
          lastName: newTeamMember?.lastName,
          imageUrl: newTeamMember?.imageUrl,
          imageName: newTeamMember?.imageName,
          isResponsibleForQa: newTeamMember?.isResponsibleForQa,
          base64Image: newTeamMember?.base64Image || "",
          imageDeleted: newTeamMember?.imageDeleted,
        },
      ])
    );
    setNewTeamMember(undefined);
    setAddTeamMemberModal(false);
  };
  const handleDeleteTeamMember = (id: number | string) => {
    const teamMemberToRemove = company?.team.filter((e) => e.id === id)[0];
    if (teamMemberToRemove) {
      setSelectedTeamMember({
        ...teamMemberToRemove,
        base64Image: teamMemberToRemove?.base64Image || "",
      });
      setRemoveTeamMemberModal(true);
    }
  };

  const submitDeletingTeamMember = () => {
    const teamMembersLeft = company?.team.filter(
      (e) => e.id !== selectedTeamMember?.id
    );
    dispatch(CompanySliceAction.setTeam(teamMembersLeft));
    setRemoveTeamMemberModal(false);
    setSelectedTeamMember(null);
  };

  const setTeamMemberFirstName = (value: string) => {
    if (isEditing) {
      const changedItem = {
        ...selectedTeamMember,
        firstName: value,
      } as TeamDto;
      setSelectedTeamMember(changedItem);
    } else {
      setNewTeamMember({
        ...newTeamMember,
        firstName: value,
        ordinality: teamMembers?.length || 0 + 1,
        id: selectedTeamMember?.id || v4(),
      });
    }
  };

  const setTeamMemberLastName = (value: string) => {
    if (isEditing) {
      const changedItem = { ...selectedTeamMember, lastName: value } as TeamDto;
      setSelectedTeamMember(changedItem);
    } else {
      setNewTeamMember({
        ...newTeamMember,
        lastName: value,
        ordinality: teamMembers?.length || 0 + 1,
        id: selectedTeamMember?.id || v4(),
      });
    }
  };

  const setTeamMemberPosition = (value: string) => {
    if (isEditing) {
      const changedItem = { ...selectedTeamMember, title: value } as TeamDto;
      setSelectedTeamMember(changedItem);
    } else {
      setNewTeamMember({
        ...newTeamMember,
        title: value,
        ordinality: teamMembers?.length || 0 + 1,
        id: selectedTeamMember?.id || v4(),
      });
    }
  };

  const setTeamMemberLinkedin = (value: string) => {
    if (isEditing) {
      const changedItem = {
        ...selectedTeamMember,
        linkedinUrl: value,
      } as TeamDto;
      setSelectedTeamMember(changedItem);
    } else {
      setNewTeamMember({
        ...newTeamMember,
        ordinality: teamMembers?.length || 0 + 1,
        id: selectedTeamMember?.id || v4(),
        linkedinUrl: value,
      });
    }
  };

  const setTeamMemberResponsible = (value: boolean) => {
    const changedItem = {
      ...selectedTeamMember,
      isResponsibleForQa: value,
    } as TeamDto;
    setSelectedTeamMember(changedItem);
    if (!isEditing) {
      setNewTeamMember({
        ...newTeamMember,
        isResponsibleForQa: value,
        ordinality: teamMembers?.length || 0 + 1,
        id: selectedTeamMember?.id || v4(),
      });
    }
  };

  const setTeamMemberBase64Image = (value: string) => {
    if (isEditing) {
      const changedItem = {
        ...selectedTeamMember,
        base64Image: value,
        imageDeleted:
          !!selectedTeamMember?.imageUrl || selectedTeamMember?.imageDeleted,
      } as TeamDto;
      setSelectedTeamMember(changedItem);
    } else {
      setNewTeamMember({
        ...newTeamMember,
        base64Image: value,
        ordinality: teamMembers?.length || 0 + 1,
        id: selectedTeamMember?.id || v4(),
      });
    }
  };

  const handleDeleteImage = (value: boolean) => {
    if (isEditing) {
      const changedItem = {
        ...selectedTeamMember,
        imageDeleted: value,
        base64Image: "",
      } as TeamDto;
      setSelectedTeamMember(changedItem);
    }
  };

  const handleClose = () => {
    setAddTeamMemberModal(false);
    setSelectedTeamMember(null);
  };

  const handleEditTeamMember = (id: any) => {
    const teamMemberToEdit = company?.team.filter((e) => e.id === id)[0];
    if (teamMemberToEdit) {
      setSelectedTeamMember({
        ...teamMemberToEdit,
        base64Image: teamMemberToEdit?.base64Image || "",
      });
      setAddTeamMemberModal(true);
      setIsEditing(true);
    }
  };

  const saveEditing = () => {
    const updatedTeamMembers = company?.team.map((e) => {
      if (e.id === selectedTeamMember?.id) {
        return selectedTeamMember!;
      }
      return {
        ...e,
        isResponsibleForQa: !!selectedTeamMember?.isResponsibleForQa
          ? false
          : e.isResponsibleForQa,
      };
    });
    dispatch(CompanySliceAction.setTeam(updatedTeamMembers));
    setIsEditing(false);
    handleClose();
  };

  const handleCloseDeleteModal = () => {
    setSelectedTeamMember(null);
    setRemoveTeamMemberModal(false);
  };

  return (
    <Box>
      <DragAndDropArea
        header={OverviewSectionUploads.team}
        cards={teamMembers || []}
        setCards={setTeamMembersInStore}
        addCard={() => setAddTeamMemberModal(true)}
        removeCard={handleDeleteTeamMember}
        editCard={handleEditTeamMember}
        placeholder="Add team member"
        hasError={hasError}
      />
      {addTeamMemberModal && (
        <TeamMemberAddModal
          isEditing={isEditing}
          handleClose={handleClose}
          onSubmit={isEditing ? saveEditing : addTeamMember}
          selectedTeamMember={selectedTeamMember}
          setSelectedTeamMember={setSelectedTeamMember}
          setTeamMemberFirstName={setTeamMemberFirstName}
          setTeamMemberLastName={setTeamMemberLastName}
          setTeamMemberPosition={setTeamMemberPosition}
          setTeamMemberLinkedin={setTeamMemberLinkedin}
          setTeamMemberResponsible={setTeamMemberResponsible}
          setTeamMemberBase64Image={setTeamMemberBase64Image}
          handleDeleteImage={handleDeleteImage}
        />
      )}

      {removeTeamMemberModal && (
        <BasicPopUp
          title="Delete team member"
          onClose={handleCloseDeleteModal}
          onSubmit={submitDeletingTeamMember}
        >
          <Box display="flex" alignItems="center">
            <Box mr={1.5}>
              <ErrorOutline />
            </Box>
            <Typography variant="body2" color={theme.palette.common.grey900}>
              You want to delete team member
            </Typography>
          </Box>
        </BasicPopUp>
      )}
    </Box>
  );
};

export default memo(TeamMembers);
