import React, { useState } from "react";
import { Close, CreateOutlined } from "@mui/icons-material";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { DealSliceAction } from "features/deal-profile/store/slice";
import { DealTractionAmount } from "integration/api/DealsApi/models";
import { dealPageSelector } from "features/deal-profile/store/selectors";

import ConfirmPopUp from "application/containers/ConfirmPopUp";
import EmptyTraction from "features/deal-profile/components/EmptyTraction";
import EditAmountPopUp from "features/deal-profile/popups/EditAmountPopUp";
import TractionAmountInput from "features/deal-profile/components/TractionAmountInput";

import { Button, Container, Title, Wrapper } from "./styles";

const formatter = new Intl.NumberFormat("en-US");

const TractionNPS = () => {
  const dispatch = useAppDispatch();
  const amount = useAppSelector(dealPageSelector).profile.nps;

  const [editVisible, setEditVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);

  // add
  const onAdd = () => {
    dispatch(DealSliceAction.setNPS(0));
  };

  // delete
  const onDelete = () => {
    setDeleteVisible(true);
  };
  const onDeleteSubmit = () => {
    setDeleteVisible(false);
    dispatch(DealSliceAction.setNPS(null));
  };
  const onDeleteClose = () => {
    setDeleteVisible(false);
  };

  // edit
  const onEdit = () => {
    setEditVisible(true);
  };
  const onEditClose = () => {
    setEditVisible(false);
  };
  const onEditSubmit = (newData: DealTractionAmount) => {
    setEditVisible(false);
    dispatch(DealSliceAction.setNPS(newData.amount));
  };

  return (
    <React.Fragment>
      <Wrapper>
        <Title>Net promoter score (NPS)</Title>
        <Container>
          {amount === null ? (
            <EmptyTraction onClick={onAdd} title="amount" />
          ) : (
            <React.Fragment>
              <TractionAmountInput
                amount={formatter.format(amount || 0) as any}
                currency={null}
              />

              <Button onClick={onEdit}>
                <CreateOutlined />
              </Button>

              <Button onClick={onDelete}>
                <Close />
              </Button>
            </React.Fragment>
          )}
        </Container>
      </Wrapper>

      {deleteVisible && (
        <ConfirmPopUp
          title="Delete value"
          onClose={onDeleteClose}
          onSubmit={onDeleteSubmit}
          description="You want to delete NPS. Are you sure?"
        />
      )}
      {editVisible && (
        <EditAmountPopUp
          title="NPS"
          hideCurrency
          defaultValues={{
            amount,
            currency: null,
          }}
          onClose={onEditClose}
          onSubmit={onEditSubmit}
        />
      )}
    </React.Fragment>
  );
};

export default TractionNPS;
