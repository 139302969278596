import { styled } from "@mui/system";
import { colors } from "application/theme/palette";

export const Container = styled("section")`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  box-sizing: border-box;
`;

export const Content = styled("div")`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;

  > textarea {
    padding: 32px 20px;
    background: none;
  }
`;
