import React, { useState, useCallback } from "react";
import update from "immutability-helper";

import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { SaveAlt } from "@mui/icons-material";

import { colors } from "application/theme/palette";
import { useAppDispatch } from "application/hooks/useAppDispatch";

import { AnalyticPageResponseDto } from "integration/api/AnalyticManagementApi/models";

import DeleteFilePopUp from "../DeleteFilePopUp";
import { DragAndDropItem } from "../DragAndDropFile";

import { DragAndDropWrapper } from "./styles";
import { ManagementAnalyticsSliceAction } from "features/management-analytics/store/slice";

export interface DragAndDropAreaProps {
  files: AnalyticPageResponseDto[];
  deleteFile: (fileId: number) => Promise<void>;
  downloadFile: (fileId: number) => Promise<void>;
}

export const DragAndDropArea = ({
  files,
  deleteFile,
  downloadFile,
}: DragAndDropAreaProps) => {
  const dispatch = useAppDispatch();
  const [fileToDelete, setFileToDelete] =
    useState<AnalyticPageResponseDto | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenDeleteModal = (file: AnalyticPageResponseDto) => {
    setFileToDelete(file);
  };

  const handleCloseDeleteModal = () => {
    setFileToDelete(null);
  };

  const handleDeleteFile = async () => {
    setIsLoading(true);
    await deleteFile(Number(fileToDelete?.fileId)).then(() => {
      handleCloseDeleteModal();
    });
    setIsLoading(false);
  };

  const handleDownloadFile = (fileId: number) => {
    downloadFile(Number(fileId));
  };

  const moveFile = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      dispatch(
        ManagementAnalyticsSliceAction.setAnalytics(
          update(files, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, files[dragIndex]],
            ],
          })
        )
      );
    },
    [files]
  );

  return (
    <>
      <Box mb={4}>
        <DragAndDropWrapper>
          <ol style={{ listStyleType: "decimal" }}>
            {files.map((file, index) => (
              <DragAndDropItem
                key={file.fileId}
                index={index}
                id={file.fileId}
                text={file.fileName}
                moveFile={moveFile}
                fileItem={
                  <Box display="flex" alignItems="center">
                    <Box
                      mr={1.5}
                      onClick={() => handleDownloadFile(file.fileId)}
                    >
                      <SaveAlt />
                    </Box>
                    <Typography variant="subtitle1" color={colors.grey900}>
                      {file.fileName}
                    </Typography>
                  </Box>
                }
                removeFile={() => handleOpenDeleteModal(file)}
              />
            ))}
          </ol>
        </DragAndDropWrapper>
      </Box>
      {!!fileToDelete && (
        <DeleteFilePopUp
          onClose={handleCloseDeleteModal}
          onSubmit={handleDeleteFile}
          fileName={fileToDelete.fileName}
          isLoading={isLoading}
        />
      )}
    </>
  );
};
