import React, { FC } from "react";

import { ReactComponent as CloseSvg } from "application/assets/icons/close.svg";

import { TableProps } from "./model";
import { Actions, OwnerInfo } from "./styles";

const TableRow: FC<TableProps> = ({ owner, handleDelete }) => {
  return (
    <tr>
      <td>
        <OwnerInfo>
          <h2>{owner.name}</h2>
          <p>{owner.email}</p>
        </OwnerInfo>
      </td>
      <td>
        <Actions>
          <button onClick={() => handleDelete(owner)}>
            <CloseSvg />
          </button>
        </Actions>
      </td>
    </tr>
  );
};

export default TableRow;
