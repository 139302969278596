import { Box, styled } from "@mui/system";
import { colors } from "application/theme/palette";

interface StyledProps {
  hasError: boolean;
}

export const FileInput = styled(Box)<StyledProps>`
  border: 1px solid
    ${({ hasError }) => (hasError ? colors.red700 : colors.grey200)};
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0 4px;
  height: 44px;
  cursor: pointer;

  svg {
    path {
      fill: ${({ hasError }) => (hasError ? colors.red700 : colors.grey500)};
    }
  }
`;

export const ErrorMessage = styled("span")`
  color: ${colors.red700};
  font-family: Unica77;
  font-weight: 400;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 14px;
`;

export const Container = styled("section")`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  box-sizing: border-box;
`;

export const Content = styled("div")<StyledProps>`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: 32px 20px;
  border: 1px solid
    ${({ hasError }) => (hasError ? colors.red700 : colors.grey200)};
  border-radius: 8px;
  margin-top: 20px;
`;

export const PictureItem = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 28px 4px 4px;
  border-radius: 4px;

  > div {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    > p {
      position: absolute;
      left: 28px;
      padding: 4px 8px;
      color: ${colors.green700};
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.06px;
      border-radius: 4px;
      background: ${colors.green200};
    }
  }

  h3 {
    width: 100%;
    color: ${colors.grey900};
    font-family: Unica77;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.07px;
    margin-left: 12px;
  }

  &:hover {
    background: ${colors.grey100};
    .delete_btn {
      display: flex;
    }
  }
`;

export const AddButton = styled("button")`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  width: fit-content;
  color: ${colors.grey900};
  font-family: Unica77;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.07px;

  span {
    text-decoration-line: underline;
  }
`;

export const DeleteButton = styled("button")`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  width: 24px;
  height: 24px;
  display: none;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.grey200};
  border-radius: 4px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }
`;
