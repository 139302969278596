import { createSlice } from "@reduxjs/toolkit";

import { DealInvestorsState } from "./model";
import DealInvestorsActions from "./actions";
import { DealStatus } from "integration/api/DealsApi/models";

const initialState: DealInvestorsState = {
  dealStatus: DealStatus.DRAFT,
  dealImported: false,
  dealInvestors: {
    totalElements: 0,
    totalPages: 0,
    size: 0,
    content: [],
    number: 0,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    numberOfElements: 0,
    pageable: {
      offset: 0,
      sort: {
        empty: true,
        sorted: true,
        unsorted: true,
      },
      pageNumber: 0,
      pageSize: 0,
      paged: true,
      unpaged: true,
    },
    first: true,
    last: true,
    empty: true,
  },
};

export const dealInvestorsSlice = createSlice({
  name: "dealInvestors",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get deal investors
    builder.addCase(
      DealInvestorsActions.getDealInvestors.fulfilled,
      (state, action) => {
        state.dealStatus = action.payload.dealStatus;
        state.dealInvestors = action.payload.investorsPage;
        state.dealImported = action.payload.dealImported;
      }
    );
  },
});

export const DealInvestorsSliceAction = dealInvestorsSlice.actions;
export default dealInvestorsSlice.reducer;
