import React, { FC } from "react";
import { Close, EditOutlined } from "@mui/icons-material";

import { ReactComponent as MoneysSvg } from "application/assets/icons/moneys.svg";
import { formatTotal } from "application/helpers/formatTotal";

import { formatDealTableDate } from "features/deal-investors/helpers/date";

import { AnalyticsTableRowProps } from "./model";
import { Total, Actions } from "./styles";

const AnalyticsTableRow: FC<AnalyticsTableRowProps> = ({
  analytics,
  onEdit,
  onDelete,
}) => {
  const date = formatDealTableDate(analytics.fmvChangeDate);
  const fmvValue = formatTotal(analytics.fmvValue, analytics.currency);
  const realizedProfit = formatTotal(
    analytics.realizedProfit,
    analytics.currency
  );

  return (
    <tr>
      <td>{date}</td>
      <td>
        <Total>
          <MoneysSvg />
          <p>{fmvValue}</p>
        </Total>
      </td>
      <td>
        <Total>
          <MoneysSvg />
          <p>{realizedProfit}</p>
        </Total>
      </td>
      <td>{analytics.multiple}</td>
      <td>
        <Actions>
          <button onClick={() => onEdit(analytics)}>
            <EditOutlined />
          </button>
          <button onClick={() => onDelete(analytics)}>
            <Close />
          </button>
        </Actions>
      </td>
    </tr>
  );
};

export default AnalyticsTableRow;
