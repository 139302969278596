export enum NumberOfCompaniesToInvest {
  ZERO = "ZERO",
  ONE_TO_TWO = "ONE_TO_TWO",
  TWO_TO_FOUR = "TWO_TO_FOUR",
  FOUR_TO_SIX = "FOUR_TO_SIX",
  MORE_THAN_SIX = "MORE_THAN_SIX",
}

export const numberOfCompaniesToInvest = [
  { value: NumberOfCompaniesToInvest.ZERO, label: "0" },
  { value: NumberOfCompaniesToInvest.ONE_TO_TWO, label: "1-2" },
  { value: NumberOfCompaniesToInvest.TWO_TO_FOUR, label: "2-4" },
  { value: NumberOfCompaniesToInvest.FOUR_TO_SIX, label: "4-6" },
  { value: NumberOfCompaniesToInvest.MORE_THAN_SIX, label: ">6" },
];
