import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import UsersActions from "./actions";
import { UsersState } from "./model";
import { Investor } from "integration/api/UsersApi/models";

const initialState: UsersState = {
  investors: {
    totalPages: 0,
    totalElements: 0,
    size: 0,
    content: [],
    number: 0,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    numberOfElements: 0,
    pageable: {
      offset: 0,
      sort: {
        empty: true,
        sorted: true,
        unsorted: true,
      },
      pageNumber: 0,
      pageSize: 0,
      paged: true,
      unpaged: true,
    },
    first: true,
    last: true,
    empty: true,
  },
  selected: null,
  autosuggestions: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clearSelected: (state) => {
      state.selected = initialState.selected;
    },
    setSelected: (state, action: PayloadAction<Investor | null>) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get investors
    builder.addCase(UsersActions.getInvestors.fulfilled, (state, action) => {
      state.investors = action.payload;
    });
    // get search autosuggestions
    builder.addCase(
      UsersActions.getSearchAutosuggestions.fulfilled,
      (state, action) => {
        state.autosuggestions = action.payload;
      }
    );

    // getInvestorById
    builder.addCase(UsersActions.getInvestorById.fulfilled, (state, action) => {
      state.selected = action.payload;
    });
  },
});

export const UsersSliceAction = usersSlice.actions;
export default usersSlice.reducer;
