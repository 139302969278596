import { styled } from "@mui/system";

import ErrorPageBg from "application/assets/images/error-page-bg.png";
import { colors } from "application/theme/palette";

export const Container = styled("main")`
  width: 100%;
  height: 100%;
  background-image: url(${ErrorPageBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Unica77";

  h1 {
    color: ${colors.white};
    text-align: center;
    font-size: 240px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  h2 {
    text-align: center;
    color: ${colors.white};
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
  h3 {
    margin-top: 16px;
    text-align: center;
    max-width: 720px;
    color: ${colors.white};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  a {
    all: unset;
    margin-top: 43px;
  }
`;
