import React from "react";
import { ButtonProps, TypographyVariant } from "@mui/material";
import { buttonHelper } from "./utils";
import Button from ".";
import { LoadingIcon } from "application/assets/LoadingIcon";
import theme from "application/theme";

type CustomButtonProps = {
  label: string | undefined;
  height?: number;
  width?: number | string;
  red?: boolean;
  isLoading?: boolean;
  textVariant?: TypographyVariant;
};

export const LoadingButton = (props: ButtonProps & CustomButtonProps) => {
  const {
    onClick,
    label,
    variant,
    height = 5.5,
    width,
    red,
    type,
    isLoading,
    textVariant = "button",
  } = props;

  return (
    <Button
      height={height}
      width={width}
      red={red}
      label={label}
      textVariant={textVariant}
      type={type}
      variant={variant}
      rotate={isLoading}
      disabled={props.disabled}
      fullWidth={props.fullWidth}
      onClick={onClick}
      startAdornment={
        isLoading && <LoadingIcon color={red ? theme.palette.common.grey900 : buttonHelper(type).color} />
      }
    />
  );
};
