import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

export const Container = styled.article`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  > div:last-of-type {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
`;

export const BackButton = styled("button")`
  all: unset;
  min-width: fit-content;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${colors.blue700};
  text-align: center;
  font-family: Unica77;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.08px;
  cursor: pointer;

  svg {
    margin-right: 8px;
    path {
      fill: ${colors.blue700};
    }
  }
`;
