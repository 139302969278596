import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getProfile } from "./ActionCreators";
import { AdminProfileResponse } from "integration/api/Auth/models";

interface InitialState {
  profile: AdminProfileResponse | null;
  authError: string;
}

const initialState: InitialState = {
  profile: null,
  authError: ""
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearStore: (state) => {
      state.profile = null;
      localStorage.clear();
    },
    setAuthError: (state, action: PayloadAction<string>) => {
      state.authError = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
  },
});

export const { clearStore, setAuthError } = authSlice.actions;

export default authSlice.reducer;
