import { Box } from "@mui/system";
import React from "react";
import { SaveAlt } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import { colors } from "application/theme/palette";
import { useAppDispatch } from "application/hooks/useAppDispatch";

import DealActions from "features/deal-profile/store/actions";

export interface FileItemProps {
  fileId: number;
  fileName: string | undefined;
}

export const FileItem = ({ fileId, fileName }: FileItemProps) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const downloadFile = async () => {
    await dispatch(
      DealActions.getAttachmentFile({
        id: Number(id),
        fileId,
      })
    ).then((response) => {
      const data = response.payload as Blob;
      const fileBlob = new Blob([data], {
        type: data.type,
      });
      const fileUrl = URL.createObjectURL(fileBlob);
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", fileName || '');
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    });
  };

  return (
    <Box display="flex" alignItems="center">
      <Box mr={1.5} onClick={downloadFile}>
        <SaveAlt />
      </Box>
      <Typography variant="subtitle1" color={colors.grey900}>
        {fileName}
      </Typography>
    </Box>
  );
};
