import { styled } from "@mui/system";

import { colors } from "application/theme/palette";

import { ControllerButtonProps } from "./model";

export const Container = styled("section")`
  width: 100%;
  font-family: Unica77;

  > h1 {
    color: ${colors.grey900};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
  }
`;

export const TabsController = styled("div")`
  display: flex;
  align-items: end;
  margin: 20px 0 16px;
`;

export const ControllerButton = styled("button")<ControllerButtonProps>`
  all: unset;
  display: flex;
  align-items: center;
  padding: 9px 16px;
  min-width: fit-content;
  cursor: pointer;
  border-bottom: ${({ isActive }) =>
    isActive
      ? `2px solid ${colors.blue700}`
      : "1px  solid rgba(0, 0, 0, 0.12)"};

  p {
    color: ${colors.grey900};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.08px;
  }
  svg {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    path {
      fill: ${({ isActive }) => (isActive ? colors.blue700 : colors.grey500)};
    }
  }

  &:disabled {
    cursor: not-allowed;
    p {
      color: ${colors.grey300};
    }
    svg {
      path {
        fill: ${colors.grey300};
      }
    }
  }
`;

export const Divider = styled("div")`
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.12);
`;
