import styled from "@emotion/styled";
import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 16px 24px;

  border-radius: 8px;
  border: 1px solid ${colors.grey200};
  background: ${colors.white};
`;

export const TopSectionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:first-of-type {
    display: flex;
    align-items: center;
    > h2 {
      color: ${colors.grey900};
      font-family: Unica77;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      margin-right: 16px;
    }
  }
`;

export const InfoSectionStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ItemStyled = styled.div`
  display: flex;
  align-items: center;
  min-width: 250px;

  > div:last-of-type {
    p {
      color: ${colors.grey500};
      font-family: Unica77;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.07px;
      margin-bottom: 4px;
    }
    h2 {
      color: ${colors.grey900};
      font-family: Unica77;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.08px;
    }
  }
`;

export const IconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  padding: 10px;
  border-radius: 8px;
  background: ${colors.grey50};
  margin-right: 12px;

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;

    path {
      fill: ${colors.grey500};
    }
  }
`;
