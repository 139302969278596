import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import { dataUrlToFileUsingFetch } from "application/helpers/dataUrlToFileUsingFetch";
import { CropperWrapper } from "./style";
import BasicPopUp from "application/containers/BasicPopUp";

interface CropProps {
  handleImageUpload: (file: File) => Promise<void>;
  image: string;
  showCropperModal: boolean;
  setShowCropperModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const CropImage = ({
  handleImageUpload,
  image,
  setShowCropperModal,
  showCropperModal,
}: CropProps) => {
  const [cropper, setCropper] = useState<Cropper>();

  const getCropData = async () => {
    if (!cropper) return;
    const file = await dataUrlToFileUsingFetch(
      cropper?.getCroppedCanvas().toDataURL(),
      "image.png",
      "image/png"
    );
    setShowCropperModal(false);
    handleImageUpload(file);
  };

  return (
    <>
      {showCropperModal && (
        <BasicPopUp
          onClose={() => setShowCropperModal(false)}
          onSubmit={getCropData}
          title={"Crop image"}
        >
          <CropperWrapper>
            <Cropper
              style={{
                minHeight: 72,
                minWidth: 72,
                maxHeight: 400,
                maxWidth: 800,
              }}
              initialAspectRatio={72 / 72}
              aspectRatio={72 / 72}
              src={image}
              viewMode={1}
              minCropBoxHeight={72}
              minCropBoxWidth={72}
              zoomable={false}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              guides={true}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          </CropperWrapper>
        </BasicPopUp>
      )}
    </>
  );
};

export default CropImage;
