import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";
import CallMadeIcon from "@mui/icons-material/CallMade";

import { ReactComponent as MoneysSvg } from "application/assets/icons/moneys.svg";
import { PathNames } from "application/routes";
import { SelectWithText } from "application/components";
import { SearchParamsRoutesNames } from "application/routes/constants";
import { getNavigationState } from "application/helpers/navigationState";
import { formatTotal } from "application/helpers/formatTotal";

import { InvestmentStatus } from "integration/api/DealsApi/models";

import { formatDealTableDate } from "features/deal-investors/helpers/date";

import { DealInvestorsTableRowProps } from "./model";
import { Actions, DropdownContainer, Total } from "./styles";

const statusOptions = [
  { value: InvestmentStatus.PENDING, label: "Pending" },
  { value: InvestmentStatus.CONFIRMED, label: "Confirmed" },
  { value: InvestmentStatus.CANCELED, label: "Canceled" },
];

const DealInvestorsTableRow: FC<DealInvestorsTableRowProps> = ({
  item,
  handleChangeStatus,
}) => {
  const { id } = useParams();
  const date = formatDealTableDate(item.date);
  const total = formatTotal(item.investmentAmount, item.investmentCurrency);

  return (
    <tr>
      <td>{item.firstName}</td>
      <td>{item.lastName}</td>
      <td>
        <Total>
          <MoneysSvg />
          <p>{total}</p>
        </Total>
      </td>
      <td>{date}</td>
      <td>
        <DropdownContainer status={item.status}>
          <SelectWithText
            placeholder="Status"
            onChange={(value) =>
              handleChangeStatus(value as InvestmentStatus, item.id)
            }
            options={statusOptions}
            selected={item.status}
            maxHeight={300}
          />
        </DropdownContainer>
      </td>

      <td>
        <Actions>
          <Link
            to={`${PathNames.deals}/${id}/investors/${item.id}/detail`}
            state={getNavigationState(
              SearchParamsRoutesNames.portfolioInvestorsDetailCommitments
            )}
          >
            <CallMadeIcon />
          </Link>
        </Actions>
      </td>
    </tr>
  );
};

export default DealInvestorsTableRow;
