import React, { FC } from "react";
import { CalendarTodayOutlined } from "@mui/icons-material";

import { InputWithText, TextEditorWithTitle } from "application/components";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { formatDate } from "application/helpers/formatDate";

import { ConsentStatuses } from "integration/api/ConsentsApi/models";

import { ConsentsSliceAction } from "features/consents/store/slice";
import { ConsentErrorKey } from "features/consents/helpers/formatConsentError";
import { formateDate } from "features/consents/helpers/formatDate";

import StatusSelect from "../StatusSelect";

import { Wrapper, TitleSection, DateStyled, Container } from "./styles";

const ConsentForm: FC = () => {
  const dispatch = useAppDispatch();
  const { consent, errors } = useAppSelector((state) => state.consents);
  const { loading } = useAppSelector(requestSelector).getConsentById;

  const date = consent.publishedDate ? formateDate(consent.publishedDate) : "";

  const handleChangeName = (name: string) => {
    dispatch(ConsentsSliceAction.setConsentData({ name: name }));
    !!name &&
      dispatch(
        ConsentsSliceAction.setErrors(
          errors.filter((item) => item !== ConsentErrorKey.name)
        )
      );
  };

  const handleChangeStatus = (status: ConsentStatuses) => {
    dispatch(ConsentsSliceAction.setConsentStatus(status));
  };

  const handleChangeDescription = (description: string) => {
    dispatch(ConsentsSliceAction.setConsentData({ description }));
    !!description &&
      dispatch(
        ConsentsSliceAction.setErrors(
          errors.filter((item) => item !== ConsentErrorKey.description)
        )
      );
  };

  return (
    <Wrapper>
      <TitleSection>
        <h1>Create New Consent</h1>
        {date && (
          <DateStyled>
            <CalendarTodayOutlined />
            <p>Date published:</p>
            <h2>{date}</h2>
          </DateStyled>
        )}
      </TitleSection>
      <Container>
        <InputWithText
          placeholder="Enter the title of the consent"
          helperText="Consent Title"
          onChange={handleChangeName}
          value={consent.name}
          error={errors.includes(ConsentErrorKey.name)}
        />
        <StatusSelect onChange={handleChangeStatus} selected={consent.status} />
      </Container>
      {!loading && (
        <TextEditorWithTitle
          title="Consent agreement text"
          value={consent.description}
          onChange={handleChangeDescription}
          hasError={errors.includes(ConsentErrorKey.description)}
          mb={0}
        />
      )}
    </Wrapper>
  );
};

export default ConsentForm;
