import { TextEditorWithTitle } from "application/components/TextEditorWithTitle";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { requestSelector } from "application/store/request/selectors";
import { dealPageSelector } from "features/deal-profile/store/selectors";
import { DealSliceAction } from "features/deal-profile/store/slice";
import React from "react";

const TractionOverview = () => {
  const dispatch = useAppDispatch();
  const tractionOverview =
    useAppSelector(dealPageSelector).profile.tractionOverview;
  const { loading } = useAppSelector(requestSelector).getDealById;
  const handleTractionOverview = (value: string) => {
    dispatch(DealSliceAction.setTractionOverview(value));
  };
  return (
    <>
      {!loading && (
        <TextEditorWithTitle
          value={tractionOverview || ""}
          title="Traction overview"
          onChange={handleTractionOverview}
          hasError={false}
          mb={0}
        />
      )}
    </>
  );
};

export default TractionOverview;
