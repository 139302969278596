import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import BasicPopUp from "application/containers/BasicPopUp";
import { TextFieldStyled } from "application/components";
import UsersSearchInput from "application/components/UsersSearchInput";

import { addInvestorSchema } from "features/deal-investors/helpers/validations";

import { AddInvestorForm, AddInvestorProps } from "./models";
import { FieldsContainer } from "./styles";

const AddInvestor: FC<AddInvestorProps> = ({
  isLoading,
  onClose,
  onSubmit,
}) => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<AddInvestorForm>({
    resolver: yupResolver(addInvestorSchema),
  });

  const handleChangeInvestorId = (id: number | null) => {
    setValue("investorId", Number(id), { shouldValidate: true });
  };

  const onSubmitForm = () => {
    if (isLoading) return;
    const { investmentAmount, investorId } = watch();
    onSubmit({
      id: Number(id),
      investmentAmount,
      investorId: Number(investorId),
    });
  };

  return (
    <BasicPopUp
      isLoading={isLoading}
      title="Add investor"
      onClose={onClose}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <FieldsContainer>
        <UsersSearchInput
          searchItem={watch("investorId")}
          setSearchItem={handleChangeInvestorId}
          error={!!errors.investmentAmount?.message}
        />
        <TextFieldStyled
          id="investmentAmount"
          fullWidth
          size="small"
          type="number"
          color="secondary"
          placeholder="Enter investment amount"
          filled={watch("investmentAmount")}
          error={!!errors.investmentAmount?.message}
          {...register("investmentAmount")}
        />
      </FieldsContainer>
    </BasicPopUp>
  );
};

export default AddInvestor;
