import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CalendarTodayOutlined,
  WorkOutline,
  AccountBalanceWalletOutlined,
  SwapVert,
} from "@mui/icons-material";
import MoneyIcon from "@mui/icons-material/Money";

import { CurrencySymbolSelect } from "application/components";
import { colors } from "application/theme/palette";
import PrimaryButton from "application/components/PrimaryButton";
import { formatDate } from "application/helpers/formatDate";
import { formatTotal } from "application/helpers/formatTotal";
import { useAppDispatch } from "application/hooks/useAppDispatch";

import { Currency } from "integration/api/CompanyApi/models";

import InvestorActions from "features/portfolio-investor-detail/store/actions";

import { CurrentCurrencyProps } from "./models";
import {
  Wrapper,
  TopSectionStyled,
  InfoSectionStyled,
  ItemStyled,
  IconStyled,
} from "./styles";

const CurrentCurrency: FC<CurrentCurrencyProps> = ({
  currentCurrency,
  openCurrencyModal,
}) => {
  const { id, investorId } = useParams();
  const dispatch = useAppDispatch();
  const [currentValue, setCurrentValue] = useState<Currency>(
    currentCurrency.currency
  );

  const date = formatDate(currentCurrency.fmvChangeDate);

  const totalFmvValue = formatTotal(
    currentCurrency.fmvValue,
    currentValue
  );
  const totalRealizedProfit = formatTotal(
    currentCurrency.totalRealizedProfit,
    currentValue
  );
  const totalInvestments = formatTotal(
    currentCurrency.investmentAmount,
    currentValue
  );

  const handleChangeCurrentValue = async (currency: Currency) => {
    setCurrentValue(currency);
    await dispatch(
      InvestorActions.getInvestorById({
        dealId: Number(id),
        investorId: Number(investorId),
        currency,
      })
    );
  };

  return (
    <Wrapper>
      <TopSectionStyled>
        <div>
          <h2>Current value</h2>
          <CurrencySymbolSelect
            color={colors.white}
            onChange={handleChangeCurrentValue}
            selected={currentValue}
          />
        </div>
        <PrimaryButton
          loading={false}
          onClick={openCurrencyModal}
          title="Add new value"
        />
      </TopSectionStyled>
      <InfoSectionStyled>
        <ItemStyled>
          <IconStyled>
            <CalendarTodayOutlined />
          </IconStyled>
          <div>
            <p>Date of last FMV change</p>
            <h2>{date}</h2>
          </div>
        </ItemStyled>
        <ItemStyled>
          <IconStyled>
            <MoneyIcon />
          </IconStyled>
          <div>
            <p>Investment amount</p>
            <h2>{totalInvestments}</h2>
          </div>
        </ItemStyled>
        <ItemStyled>
          <IconStyled>
            <WorkOutline />
          </IconStyled>
          <div>
            <p>Current value (FMV)</p>
            <h2>{totalFmvValue}</h2>
          </div>
        </ItemStyled>
        <ItemStyled>
          <IconStyled>
            <AccountBalanceWalletOutlined />
          </IconStyled>
          <div>
            <p>Total realized profit</p>
            <h2>{totalRealizedProfit}</h2>
          </div>
        </ItemStyled>
        <ItemStyled>
          <IconStyled>
            <SwapVert />
          </IconStyled>
          <div>
            <p>Multiple</p>
            <h2>{currentCurrency.multiple}</h2>
          </div>
        </ItemStyled>
      </InfoSectionStyled>
    </Wrapper>
  );
};

export default CurrentCurrency;
