import React, { useState } from "react";

import { useAppDispatch } from "application/hooks/useAppDispatch";
import { logOut } from "application/store/Auth/ActionCreators";
import { PathNames } from "application/routes";

import { ReactComponent as UserSvg } from "features/side-menu/assets/user.svg";
import { ReactComponent as UserActiveSvg } from "features/side-menu/assets/user-active.svg";

import { menuList, usersSubMenuList } from "./config/menu-list";

import MenuItem from "./components/MenuItem";
import UserInfo from "./components/UserInfo";
import MenuAccordionItem from "./components/MenuAccordionItem";

import {
  Header,
  Footer,
  Wrapper,
  ArrowIn,
  ArrowOut,
  LogoImage,
  LogoutIcon,
  LogoSmallImage,
  ArrowContainer,
  LogoutButton,
} from "./styles";

const SideMenu = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);

  const dealsMenuItem = menuList.find((item) => item.path === PathNames.deals);
  const filteredMenuList = menuList.filter(
    (item) => item.path !== PathNames.deals
  );

  const toggleOpen = () => {
    setOpen(!open);
  };

  const onLogOut = async () => {
    await dispatch(logOut());
  };

  return (
    <Wrapper open={open}>
      <Header open={open}>
        {open ? <LogoImage /> : <LogoSmallImage />}
        <ArrowContainer onClick={toggleOpen}>
          {open ? <ArrowIn /> : <ArrowOut />}
        </ArrowContainer>
      </Header>

      <UserInfo open={open} />
      {dealsMenuItem && (
        <MenuItem key={dealsMenuItem.path} open={open} item={dealsMenuItem} />
      )}
      <MenuAccordionItem
        open={open}
        options={usersSubMenuList}
        title="Users"
        mainPath="users"
        icon={UserSvg}
        iconActive={UserActiveSvg}
        setOpen={setOpen}
      />
      {filteredMenuList.map((item) => (
        <MenuItem key={item.path} open={open} item={item} />
      ))}

      <Footer open={open}>
        <LogoutButton type="button" onClick={onLogOut}>
          {open && <p>Log Out</p>}
          <LogoutIcon />
        </LogoutButton>
      </Footer>
    </Wrapper>
  );
};

export default SideMenu;
