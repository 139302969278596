import React from "react";

import { Container } from "./styles";

interface Props {
  color?: string;
}

const SmallLoader = ({ color }: Props) => {
  return (
    <Container color={color}>
      <div></div>
      <div></div>
    </Container>
  );
};

export default SmallLoader;
