import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

import { Button, ErrorValidationPopUp } from "application/components";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import { useAppSelector } from "application/hooks/useAppSelector";
import { isResponseFulfilled } from "application/helpers/responseHelper";
import { PathNames } from "application/routes";
import ConfirmPopUp from "application/containers/ConfirmPopUp";
import SuccessPopUp from "application/containers/SuccessPopUp";
import LeavePagePopUp from "application/containers/LeavePagePopUp";
import { DetectChangesSavedSliceActions } from "application/store/DetectChangesSaved/DetectChangesSavedSlice";

import { ConsentsSliceAction } from "features/consents/store/slice";
import { formatConsentError } from "features/consents/helpers/formatConsentError";
import ConsentsActions from "features/consents/store/actions";

import { Wrapper, BackButton } from "./styles";

const ProfileHeader: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    consent,
    errors: errorsList,
    hasErrors,
  } = useAppSelector((state) => state.consents);
  const { hasUnsavedChanges } = useAppSelector(
    (state) => state.detectChangesSavedSlice
  );

  const [errors, setErrors] = useState<string[]>([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);

  useEffect(() => {
    setErrors(formatConsentError(errorsList.join(",")));
  }, [errorsList]);

  const goBack = () => {
    if (hasUnsavedChanges) {
      dispatch(
        DetectChangesSavedSliceActions.setNextPath(
          (window?.history?.state?.usr?.consent as string) || PathNames.consents
        )
      );
    } else {
      const path = (window?.history?.state?.usr?.consent || -1) as string;
      navigate(path, {
        state: window?.history?.state?.usr,
      });
    }
  };

  const onDelete = () => {
    setShowDeleteModal(true);
  };

  const onCloseDelete = () => {
    setShowDeleteModal(false);
  };

  const onDeleteSuccess = () => {
    setShowDeleteModal(false);
    setShowDeleteSuccessModal(true);
  };

  const onSubmitSuccess = () => {
    setShowSuccessModal(false);
  };

  const onSubmitSuccessDelete = () => {
    navigate(PathNames.consents);
    setShowDeleteSuccessModal(false);
  };

  const onSubmitDelete = async () => {
    const response = await dispatch(
      ConsentsActions.deleteConsent(Number(consent.id))
    );
    isResponseFulfilled(response, () => {
      onDeleteSuccess();
    });
  };

  const onSave = async () => {
    const response = await dispatch(
      ConsentsActions.updateConsent({
        id: Number(consent.id),
        name: consent.name,
        description: consent.description,
        status: consent.status,
      })
    );
    isResponseFulfilled(response, () => {
      dispatch(ConsentsActions.getConsentById(consent.id));
      setShowSuccessModal(true);
    });
  };

  const clearErrors = () => {
    dispatch(ConsentsSliceAction.setHasErrors(false));
  };

  const handleSaveChanges = async () => {
    const response = await dispatch(
      ConsentsActions.updateConsent({
        id: Number(consent.id),
        name: consent.name,
        description: consent.description,
        status: consent.status,
      })
    );
    return response.meta.requestStatus;
  };

  return (
    <Wrapper>
      <BackButton type="button" onClick={goBack}>
        <ArrowBack />
        Back to consents
      </BackButton>
      <div>
        <Button label="Save changes" type="reset" onClick={onSave} />
        <Button label="Delete consent" red type="button" onClick={onDelete} />
      </div>
      {showDeleteModal && (
        <ConfirmPopUp
          title="Delete consent"
          onClose={onCloseDelete}
          onSubmit={onSubmitDelete}
          description={`You want to delete ${
            consent.name || ""
          } consent. Are you sure?`}
        />
      )}
      {showDeleteSuccessModal && (
        <SuccessPopUp
          title={"The consent is deleted"}
          onSubmit={onSubmitSuccessDelete}
        />
      )}
      {showSuccessModal && (
        <SuccessPopUp
          title={"Consent successfully saved"}
          onSubmit={onSubmitSuccess}
        />
      )}

      {hasErrors && (
        <ErrorValidationPopUp errors={errors} onPress={clearErrors} />
      )}
      <LeavePagePopUp onSubmit={handleSaveChanges} />
    </Wrapper>
  );
};

export default ProfileHeader;
