import { AxiosError } from "axios";
import { PayloadAction } from "@reduxjs/toolkit";

import { ErrorCode, ResponseDto } from "integration/api/models";

export const isResponseFulfilled = (
  response: { type: string },
  callBack?: () => void | Promise<void>,
  callBackError?: () => void | Promise<void>
) => {
  if (response.type.endsWith("/fulfilled")) {
    callBack && callBack();
  } else {
    callBackError && callBackError();
  }
};

export const getResponseError = (
  action: PayloadAction<AxiosError<ResponseDto>>
) => {
  const error = action.payload;
  const errorCode = error?.response && error?.response.data.errorCode;

  switch (errorCode) {
    case ErrorCode.SIGN_IN_UNSUCCESSFUL:
      return "Wrong credentials provided";
    case ErrorCode.USER_WITH_SUCH_EMAIL_EXISTS:
      return "User with such email already exists";
    case ErrorCode.PASSWORD_VALIDATION_ERROR:
      return "Password is not valid";
    case ErrorCode.EMAIL_VALIDATION_ERROR:
      return "Email is not valid";
    case ErrorCode.EMAIL_VERIFICATION_CODE_INCORRECT:
      return "Verification code is incorrect";
    case ErrorCode.USER_WITH_SUCH_EMAIL_NOT_EXISTS:
      return "User with such email does not exist";
    case ErrorCode.INTERNAL_SERVER_ERROR:
      return "Error happened. Please try again or contact support";
    case ErrorCode.USER_NOT_FINISHED_SIGN_UP_PROCESS:
      return "Sign up process not finished";
    default:
      return "Error happened. Please try again or contact support";
  }
};
