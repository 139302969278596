import React, { FC, useEffect } from "react";
import { Close } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";

import Loader from "application/components/Loader";
import UsersTableRow from "features/users/components/UsersTableRow";

import {
  Table,
  Wrapper,
  DeleteContainer,
  LoaderContainer,
  ActionsContainer,
} from "./styles";
import { colors } from "application/theme/palette";
import { InvestorPageResponseDto } from "integration/api/UsersApi/models";

interface Props {
  loading: boolean;
  onDelete: (value: InvestorPageResponseDto) => void;
  users: InvestorPageResponseDto[];
  page: number;
  selectedUsersIds: number[];
  setSelectedUsersIds: (ids: number[]) => void;
  handleDeleteMultiple: () => void;
}

const UsersTable: FC<Props> = ({
  loading,
  onDelete,
  users,
  page,
  selectedUsersIds,
  setSelectedUsersIds,
  handleDeleteMultiple,
}) => {
  const isAllUsersSelected =
    users.length === selectedUsersIds.length && !!selectedUsersIds.length;

  useEffect(() => {
    setSelectedUsersIds([]);
  }, [page]);

  const handleSelectAll = () => {
    const usersIds = users.map((user) => user.id);
    setSelectedUsersIds(isAllUsersSelected ? [] : usersIds);
  };

  return (
    <>
      {loading && (
        <LoaderContainer>
          <Loader color={colors.grey500} />
        </LoaderContainer>
      )}
      <Wrapper>
        <Table>
          <thead>
            <tr>
              <th className="checkbox-column">
                <ActionsContainer>
                  <Checkbox
                    checked={isAllUsersSelected}
                    onChange={handleSelectAll}
                  />

                  {!!selectedUsersIds.length && (
                    <DeleteContainer onClick={handleDeleteMultiple}>
                      <Close />
                    </DeleteContainer>
                  )}
                </ActionsContainer>
              </th>
              <th className="first-name-column">First name</th>
              <th className="last-name-column">Last name</th>
              <th className="email-column">Email</th>
              <th className="status-column">User status</th>
              <th className="phone-column">Phone number</th>
              <th className="kyc-column">Status of KYC</th>
              <th className="action-column">Action</th>
            </tr>
          </thead>

          <tbody>
            {users.map((user) => (
              <UsersTableRow
                key={user.id}
                user={user}
                onDelete={onDelete}
                selectedUsersIds={selectedUsersIds}
                setSelectedUsersIds={setSelectedUsersIds}
              />
            ))}
          </tbody>
        </Table>
      </Wrapper>
    </>
  );
};

export default UsersTable;
