import React, { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { isResponseFulfilled } from "application/helpers/responseHelper";
import Loader from "application/components/Loader";
import { colors } from "application/theme/palette";
import { useAppDispatch } from "application/hooks/useAppDispatch";
import SuccessPopUp from "application/containers/SuccessPopUp";
import LostDealPopUp from "application/containers/LostDealPopUp";

import { InvestmentStatus } from "integration/api/DealsApi/models";

import { DealInvestorsTableRow } from "features/deal-investors/components";
import InvestorsActions from "features/portfolio-investors/store/actions";
import { CommitmentsPopUpForm } from "features/deal-investors/components/CommitmentsPopUp/model";
import CommitmentsPopup from "features/deal-investors/components/CommitmentsPopUp";

import { DealInvestorsTableProps } from "./model";
import { LoaderContainer, Table, Wrapper } from "./styles";

const DealInvestorsTable: FC<DealInvestorsTableProps> = ({
  loading,
  data,
  getInvestors,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [commitmentInvestorId, setCommitmentInvestorId] = useState<
    null | number
  >(null);
  const [showCommitmentModal, setShowCommitmentModal] = useState(false);
  const [showCommitmentSuccessModal, setShowCommitmentSuccessModal] =
    useState(false);
  const [showLostDealModal, setShowLostDealModal] = useState(false);
  const [showLostDealSuccessModal, setShowLostDealSuccessModal] =
    useState(false);

  const selectedInvestor = useMemo(() => {
    return data?.find((item) => item.id === commitmentInvestorId);
  }, [data, commitmentInvestorId]);

  const handleChangeStatus = (status: InvestmentStatus, investorId: number) => {
    if (status === InvestmentStatus.CANCELED) {
      setShowLostDealModal(true);
      setCommitmentInvestorId(investorId);
    }
    if (status === InvestmentStatus.CONFIRMED) {
      setShowCommitmentModal(true);
      setCommitmentInvestorId(investorId);
    }

    if (status === InvestmentStatus.PENDING) {
      dispatch(
        InvestorsActions.changeInvestorStatus({
          id: Number(id),
          investorId,
          commitmentStatus: status,
        })
      ).then(() => getInvestors());
    }
  };

  const onCommitmentClose = () => {
    setCommitmentInvestorId(null);
    setShowCommitmentModal(false);
  };

  const onCommitmentSuccessClose = () => {
    setShowCommitmentSuccessModal(false);
  };

  const onCommitmentSubmit = (data: CommitmentsPopUpForm) => {
    dispatch(
      InvestorsActions.changeInvestorStatus({
        id: Number(id),
        investorId: Number(commitmentInvestorId),
        commitmentStatus: InvestmentStatus.CONFIRMED,
        ...data,
      })
    ).then((res) => {
      isResponseFulfilled(res, () => {
        setCommitmentInvestorId(null);
        setShowCommitmentModal(false);
        setShowCommitmentSuccessModal(true);
        getInvestors();
      });
    });
  };

  const handleCloseLostDealModal = () => {
    setShowLostDealModal(false);
  };

  const onLostDealSuccessClose = () => {
    setShowLostDealSuccessModal(false);
  };

  const onSubmitLostDeal = (lostReason: string) => {
    dispatch(
      InvestorsActions.changeInvestorStatus({
        id: Number(id),
        investorId: Number(commitmentInvestorId),
        commitmentStatus: InvestmentStatus.CANCELED,
        lostReason: lostReason,
      })
    ).then((res) => {
      isResponseFulfilled(res, () => {
        setCommitmentInvestorId(null);
        setShowLostDealModal(false);
        setShowLostDealSuccessModal(true);
        getInvestors();
      });
    });
  };

  return (
    <>
      <Wrapper>
        {loading && (
          <LoaderContainer>
            <Loader color={colors.grey500} />
          </LoaderContainer>
        )}

        <Table>
          <thead>
            <tr>
              <th>First name</th>
              <th>Last name</th>
              <th>Investment amount</th>
              <th>Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {data?.map((item) => (
              <DealInvestorsTableRow
                item={item}
                key={item.id}
                handleChangeStatus={handleChangeStatus}
              />
            ))}
          </tbody>
        </Table>
      </Wrapper>
      {showCommitmentModal && (
        <CommitmentsPopup
          isLoading={loading}
          onSubmit={onCommitmentSubmit}
          onClose={onCommitmentClose}
          amount={selectedInvestor?.investmentAmount}
          currency={selectedInvestor?.investmentCurrency}
        />
      )}
      {showCommitmentSuccessModal && (
        <SuccessPopUp
          title="Your commitments confirm"
          onSubmit={onCommitmentSuccessClose}
        />
      )}
      {showLostDealModal && (
        <LostDealPopUp
          defaultValue={selectedInvestor?.lostReason}
          isLoading={loading}
          onClose={handleCloseLostDealModal}
          onSubmit={onSubmitLostDeal}
        />
      )}
      {showLostDealSuccessModal && (
        <SuccessPopUp
          title="Deal status changed to lost"
          onSubmit={onLostDealSuccessClose}
        />
      )}
    </>
  );
};

export default DealInvestorsTable;
