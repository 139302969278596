import axiosInstance from "../axiosInstance";
import {
  GetCompanyListParams,
  PageCompanyAdminPageDto,
  CreateCompanyResponse,
  CompanyUpdateParams,
  CompanyDto,
  PortfolioDealsParams,
  PageCompanyInvestorInfoDto,
  CompanyGetLogoParams,
  CompanyDeleteLogoParams,
  CompanyUploadLogoParams,
  CompanyDealsResponse,
  UploadCompanyLogoResponse,
  CompanyAnalyticAdminResponse,
  UpdateCompanyAnalyticParams,
  UploadCompanyAnalyticParams,
  CompanyAnalyticParams,
  CompanySuggestionDto,
  CompanyAdminResponseTotalsDto,
  GetCompanyTotalsParams,
  Currency,
  CompanyInvestorsParamsAPI,
} from "./models";

const CompanyApi = {
  getCompanyList: (params: GetCompanyListParams) => {
    const { page, size, search, sort, status, currency } = params;
    return axiosInstance.get<PageCompanyAdminPageDto>(
      "/api/admin/company/list",
      {
        params: {
          page,
          size,
          sort,
          ...(status ? { status } : {}),
          ...(search ? { search } : {}),
          currency,
        },
      }
    );
  },
  getCompanyById: (id: string) => {
    return axiosInstance.get<CompanyDto>(`/api/admin/company/${id}`);
  },
  addCompany: () => {
    return axiosInstance.post<CreateCompanyResponse>(`/api/admin/company`);
  },
  updateCompany: (params: CompanyUpdateParams) => {
    const { id, data } = params;
    return axiosInstance.put(`/api/admin/company/${id}`, data);
  },
  deleteCompany: (id: number) => {
    return axiosInstance.delete(`/api/admin/company/${id}`);
  },
  getCompanyDealsList: (params: PortfolioDealsParams) => {
    const { id, page, search } = params;
    return axiosInstance.get<CompanyDealsResponse>(
      `/api/admin/company/${id}/deal/list`,
      {
        params: { page, ...(search ? { search } : {}) },
      }
    );
  },
  getCompanyPortfolioList: (params: PortfolioDealsParams) => {
    const { id, page, search } = params;
    return axiosInstance.get<CompanyDealsResponse>(
      `/api/admin/company/${id}/portfolio/list`,
      {
        params: { page, ...(search ? { search } : {}) },
      }
    );
  },
  getCompanyInvestorsList: ({ id, page, key }: CompanyInvestorsParamsAPI) => {
    return axiosInstance.get<PageCompanyInvestorInfoDto>(
      `/api/admin/company/${id}/investor/list`,
      {
        params: { page },
        headers: {
          "X-Encryption-Key": key,
        },
      }
    );
  },
  getCompanyLogo: ({ id, logoId, logoType }: CompanyGetLogoParams) => {
    return axiosInstance.get<Blob>(`/api/admin/company/${id}/logo/${logoId}`, {
      params: { logoType },
      responseType: "blob",
      headers: {
        Accept: "application/octet-stream",
        "Content-Type": "application/octet-stream",
      },
    });
  },
  deleteCompanyLogo: ({ id, logoId }: CompanyDeleteLogoParams) => {
    return axiosInstance.delete<string>(
      `/api/admin/company/${id}/logo/${logoId}`
    );
  },
  uploadCompanyLogo: ({ id, file }: CompanyUploadLogoParams) => {
    return axiosInstance.post<UploadCompanyLogoResponse>(
      `/api/admin/company/${id}/logo`,
      file
    );
  },
  getCompanyAnalyticsList: (id: number) => {
    return axiosInstance.get<CompanyAnalyticAdminResponse>(
      `/api/admin/company/${id}/analytics/list`
    );
  },
  updateCompanyAnalyticsList: ({ id, data }: UpdateCompanyAnalyticParams) => {
    return axiosInstance.put<CompanyAnalyticAdminResponse>(
      `/api/admin/company/${id}/analytics/list`,
      data
    );
  },
  uploadCompanyAnalytic: ({ id, file }: UploadCompanyAnalyticParams) => {
    return axiosInstance.post(`/api/admin/company/${id}/analytics`, file);
  },
  getCompanyAnalyticByFileId: ({ id, fileId }: CompanyAnalyticParams) => {
    return axiosInstance.get<string>(
      `/api/admin/company/${id}/analytics/${fileId}`,
      {
        responseType: "blob",
        headers: {
          Accept: "application/octet-stream",
          "Content-Type": "application/octet-stream",
        },
      }
    );
  },
  deleteCompanyAnalytic: ({ id, fileId }: CompanyAnalyticParams) => {
    return axiosInstance.delete(`/api/admin/company/${id}/analytics/${fileId}`);
  },
  getCompanySuggestions: () => {
    return axiosInstance.get<CompanySuggestionDto[]>(
      "/api/admin/company/search-autosuggestions"
    );
  },
  getCompanyTotals: (params: GetCompanyTotalsParams) => {
    const { status, search, currency } = params;
    return axiosInstance.get<CompanyAdminResponseTotalsDto>(
      "/api/admin/company/list/totals",
      {
        params: {
          ...(status ? { status } : {}),
          ...(search ? { search } : {}),
          currency,
        },
      }
    );
  },
  exportInvestments: (currency: Currency) => {
    return axiosInstance.get<string>("/api/admin/export/investments", {
      params: {
        currency,
      },
      responseType: "blob",
      headers: {
        Accept: "application/octet-stream",
        "Content-Type": "application/octet-stream",
      },
    });
  },
  exportCurrencies: () => {
    return axiosInstance.get<string>("/api/admin/export/currencies", {
      responseType: "blob",
      headers: {
        Accept: "application/octet-stream",
        "Content-Type": "application/octet-stream",
      },
    });
  },
};

export default CompanyApi;
