import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  button {
    all: unset;
    box-sizing: border-box;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1.2px solid ${colors.grey200};
    background: ${colors.white};

    svg {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;

      path {
        fill: ${colors.grey700};
      }
    }
  }
`;

export const Total = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-top: 2px;
    margin-left: 4px;
  }
`;
