import { createSlice } from "@reduxjs/toolkit";

import OwnersActions from "./actions";
import { OwnersState } from "./model";

const initialState: OwnersState = {
  owners: {
    totalElements: 0,
    totalPages: 0,
    size: 0,
    content: [],
    number: 0,
    sort: {
      empty: true,
      sorted: true,
      unsorted: true,
    },
    numberOfElements: 0,
    pageable: {
      offset: 0,
      sort: {
        empty: true,
        sorted: true,
        unsorted: true,
      },
      pageNumber: 0,
      pageSize: 0,
      paged: true,
      unpaged: true,
    },
    first: true,
    last: true,
    empty: true,
  },
};

export const ownersSlice = createSlice({
  name: "owners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get owners
    builder.addCase(OwnersActions.getOwners.fulfilled, (state, action) => {
      state.owners = action.payload;
    });
  },
});

export const OwnersSliceAction = ownersSlice.actions;
export default ownersSlice.reducer;
