import styled from "@emotion/styled";

import { colors } from "application/theme/palette";

export const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

export const Input = styled.input`
  height: 44px;
  width: 364px;
  outline: none;
  text-indent: 48px;
  border-radius: 8px;
  border: 1px solid ${colors.grey300};
  /* font */
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  font-style: normal;
  letter-spacing: 0.08px;
  font-family: "Unica77";
  color: ${colors.grey700};
  &:focus {
    background-color: ${colors.blue50};
    border: 1px solid ${colors.blue700};
  }
`;

export const InputContainer = styled.div`
  z-index: 3;
  svg {
    width: 24px;
    height: 24px;
    top: 10px;
    left: 16px;
    position: absolute;
    color: ${colors.grey500};
  }
`;

export const List = styled.div`
  box-sizing: border-box;
  padding: 12px;
  width: 100%;
  top: 48px;
  z-index: 2;
  position: absolute;
  border-radius: 4px;
  background: ${colors.white};
  box-shadow: 0px 4px 30px 0px rgba(222, 224, 230, 0.8);
`;

export const ListItem = styled.div`
  padding: 6px 16px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: ${colors.grey100};
  }
  p {
    margin-left: 12px;
    /* font */
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    font-style: normal;
    font-family: "Unica77";
    letter-spacing: 0.08px;
    color: ${colors.grey900};
  }
  svg {
    width: 24px;
    height: 24px;
    color: ${colors.grey500};
  }
`;
